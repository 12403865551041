import React from "react";
import _ from "lodash";
import Moment from "moment";

import { getColorBadge } from "dictionary/badgeColor";

const StatusColor = [
  { value: 1, label: "Submitted", colorName: "Yellow" },
  { value: 2, label: "Sent To Printer", colorName: "Blue" },
  { value: 3, label: "Received From Printer", colorName: "Cyan" },
  { value: 4, label: "Completed", colorName: "Green" },
  { value: 5, label: "Cancelled", colorName: "Red" },
];

export const MyBookingColumnData = [
  {
    header: "Order No",
    accessor: "bannerNumber",
    searchFlag: "bannerNumber",
  },
  {
    header: "Product",
    searchFlag: ["productName", "size", "type"],
    columnStyle: { width: "80%" },
    contentClass: "at-column-responsive_left",
    customClass: "at-column-responsive_left",
    renderColumn: (rowData) => `${rowData.productName} (${rowData.size}) - ${rowData.type}`,
  },
  {
    header: "Quantity",
    accessor: "quantity",
    searchFlag: "quantity",
    columnStyle: { width: "40%" },
    contentClass: "at-column-responsive_left",
    customClass: "at-column-responsive_left",
  },
  {
    header: "Request Date",
    searchFlag: "createdDate",
    columnStyle: { width: "80%%" },
    renderColumn: (rowData) =>
      rowData.createdDate
        ? Moment(rowData.createdDate).format("DD MMM YYYY hh:mm a",)
        : "N/A",
  },
  {
    header: "Pickup Location",
    accessor: "pickupLocation",
    searchFlag: "pickupLocation",
    columnStyle: { width: "80%" },
    contentClass: "at-column-responsive_left",
    customClass: "at-column-responsive_left",
  },
  {
    header: "Status",
    searchFlag: "statusId",
    columnStyle: { width: "60%" },
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } = _.find(StatusColor, { value: rowData.statusId }) || {};

      return (
        <>
          <div
            className="at-status_badge"
            style={{ ...getColorBadge(colorName) }}
          >
            {_.find(StatusColor, { value: rowData.statusId })?.label || "N/A"}
          </div>
          {
            rowData?.status === 5 && (
              <div className="at-booking-reject-box">
                {_.find(StatusColor, { value: rowData.statusId })?.rejection_reason ? _.find(StatusColor, { value: rowData.statusId })?.rejection_reason : "N/A"}
              </div>
            )
          }
        </>
      );
    },
  },
];
