import React, { useState, useMemo } from "react";
import Moment from "moment";
import _ from "lodash";
import { Grid } from "@material-ui/core";

import AtlasButton from "components/Button";
import DatePicker from "components/Input/datetimepicker";

const DatepickerInput = ({ ...props }) => (
  <input
    type="text"
    style={{ cursor: "pointer", width: "100%", backgroundColor: "#ffff" }}
    width={"100%"}
    {...props}
    readOnly
  />
);

const Statistics = ({ exportStats }) => {
  const [dataToSubmit, setDataToSubmit] = useState({
    start_date: "",
    end_date: "",
  });

  const disableExport = useMemo(() => {
    return _.values(dataToSubmit).some((item) => !item);
  }, [dataToSubmit]);

  return (
    <div className={"at-card bg-white mb-20"}>
      <h4 className="at-card__title">Export Attendance Statistics</h4>
      <hr className={"w-100"} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} key={"start_date"}>
          <div className="mr-4">
            <label>Start Date from</label>
            <div className="at-form-input">
              <DatePicker
                value={Moment(dataToSubmit.start_date)}
                customInput={<DatepickerInput />}
                onChange={(val) => {
                  setDataToSubmit((prev) => ({
                    ...prev,
                    start_date: Moment(val).format("DD MMM YYYY"),
                  }));
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} key={"end_date"}>
          <div>
            <label>Start Date To</label>
            <div className="at-form-input">
              <DatePicker
                value={Moment(dataToSubmit.end_date)}
                customInput={<DatepickerInput />}
                onChange={(val) => {
                  setDataToSubmit((prev) => ({
                    ...prev,
                    end_date: Moment(val).format("DD MMM YYYY"),
                  }));
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <AtlasButton
        disabled={disableExport}
        className="btn-new btn-new--success mt-20"
        children={"Export"}
        onClick={() => {
          exportStats(dataToSubmit);
        }}
      />
    </div>
  );
};

export default Statistics;
