import React, { useEffect, useState } from "react";
import Moment from "moment";

import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import DatePicker from "components/Input/datetimepicker";

const ExportModal = ({ onChangeExportHOC, exportLeads }) => {
  const [startDate, setStartDate] = useState(
    Moment().subtract(1, "year").format("YYYY-MM-DD"),
  );
  const [endDate, setEndDate] = useState(Moment().format("YYYY-MM-DD"));

  return (
    <ModalDialog
      title={"Export Leads"}
      onClose={() => onChangeExportHOC(false, "showExportModal")}
      children={
        <>
          <p className={"fs-2 mb-4"}>Select Assign Date Range</p>
          <div className="row">
            <div className="col-md-6">
              <label className="at-form-input__title">Start Date</label>
              <div className="at-form-input">
                <DatePicker
                  value={Moment(startDate) || null}
                  onChange={(val) => setStartDate(Moment(val).format("YYYY-MM-DD"))}
                />
              </div>
            </div>
            <div className="col-md-6">
              <label className="at-form-input__title">End Date</label>
              <div className="at-form-input">
                <DatePicker
                  value={Moment(endDate) || null}
                  onChange={(val) => setEndDate(Moment(val).format("YYYY-MM-DD"))}
                />
              </div>
            </div>
          </div>
          <div className="at-modal_dialog-container-footer">
            <button
              className="btn-new btn-new--primary mr-2"
              onClick={() => exportLeads(startDate, endDate)}
            >
              Download
            </button>
            <button
              className="btn-new btn-new--secondary mr-2"
              onClick={() => {
                setStartDate(Moment().subtract(1, "year").format("YYYY-MM-DD"));
                setEndDate(Moment().format("YYYY-MM-DD"));
              }}
            >
              Reset
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onChangeExportHOC(false, "showExportModal")}
            >
              Close
            </button>
          </div>
        </>
      }
    />
  );
};

export default ExportModal;
