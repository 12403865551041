import React from "react";
import Moment from "moment";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import DatePicker from "components/Input/datetimepicker";

import isEmptyValue from 'utils/isEmpty';
import { TicketPricingFields } from '../assets'

const UpdateTicketPricing = ({
  onLoad,
  selectedTicketPricing = {},

  onClose,
  onChange,
  updateTicketPricing,
}) => {
  const onChangeField = (value, context) => {
    let temp = _.cloneDeep(selectedTicketPricing);
    temp[context] = value;
    onChange(temp, "selectedTicketPricing");
  };

  return (
    <ModalDialog
      title={"Edit Ticket Pricing"}
      onLoad={onLoad}
      onClose={onClose}
      children={
        <div className="grid-control">
          {
            TicketPricingFields.map( item => (
              <section key={ item.id } className="grid-full-col">
                <h4 className="at-form-input__title" required>{ item.label }</h4>
                {
                  item.type === 'radio' && (
                    <AtlasRadioGroup
                      horizontal={ true }
                      checkedValue={ selectedTicketPricing[ item.value ] }
                      selectedRadioValue={ val => onChangeField( val, item.value ) }
                      options={ item.options } />
                  )
                }
                {
                  [ 'text', 'number' ].indexOf( item.type ) > -1 && (
                    <CustomFormInput
                      type={ item.type  }
                      required={ true }
                      placeholder={ item.label }
                      value={ selectedTicketPricing[ item.value ] }
                      onChangeValue={ val => onChangeField( val, item.value ) } />
                  )
                }
                {
                  item.type === 'date' && (
                    <div className='at-form-input'>
                      <DatePicker
                        value={ Moment(selectedTicketPricing[ item.value ]) }
                        onChange={ value => onChangeField( Moment( value ).format( 'DD MMM YYYY' ), item.value ) }
                      />
                      <div className="at-form-input__required">required *</div>
                    </div>
                  )
                }
              </section>
            )
          )}
        </div>
      }
      footer={
        <div className="d-flex g-3">
          <CustomButton
            onClick={ () => updateTicketPricing( selectedTicketPricing.id ) }
            disabled={ TicketPricingFields.some( item => isEmptyValue( selectedTicketPricing[ item.value ] )) }
            className={ 'btn-new btn-new--success' }
            children={ 'Update' } />
          <CustomButton
            onClick={ onClose }
            className={ 'btn-new btn-new--outline-secondary' }
            children={ 'Close' } />
        </div>
      }/>
  );
};

export default UpdateTicketPricing;
