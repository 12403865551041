import React from "react";
import Moment from "moment";
import _ from "lodash";
import { MenuItem } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomFormHeader from "components/Form/header";
import AtlasRadioGroup from "components/RadioGroup";
import AtInput from "components/Input/formInput";
import AtSelectNew from "components/Select/new";
import DatePicker from "components/Input/datetimepicker";

const SearchContent = ({
  searchParams,

  getListAPI,
  onClearFilter,
  onChangeSearchParams,
}) => {
  const onChangeFilter = (index, value) => {
    let tmp = _.cloneDeep(searchParams);
    tmp[index].param = value;

    return onChangeSearchParams(tmp);
  };
  return (
    <div className="at-form__content">
      <CustomFormHeader
        title={"Search Attributes"}
        className={"mb-3"}
        moduleIcon={"atlas-search-status"}
      />
      <div className="row mb-10">
        {searchParams &&
          searchParams.map((item, index) => {
            return (
              <div
                key={item.value}
                className={`col-md-${item.col ? item.col : 6}`}
              >
                <h2 className="at-form-input__title">{item.label}</h2>
                {item.helpText && <p className="fs-2 mb-2">{item.helpText}</p>}
                {item.type === "input" && (
                  <AtInput
                    value={item.param}
                    prefix={
                      item.value === "address" ? (
                        <AtlasIcon
                          svgHref={"atlas-location"}
                          style={{ width: 18, height: 18 }}
                        />
                      ) : (
                        ""
                      )
                    }
                    onChangeValue={(value) => onChangeFilter(index, value)}
                  />
                )}
                {item.type === "select" && (
                  <AtSelectNew
                    value={item.param}
                    onChange={(e) => onChangeFilter(index, e.target.value)}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {item.options.map((option) => (
                      <MenuItem
                        key={
                          item.valueKey ? option[item.valueKey] : option.value
                        }
                        value={
                          item.valueKey ? option[item.valueKey] : option.value
                        }
                        style={{ width: "100%", fontSize: 14 }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </AtSelectNew>
                )}
                {item.type === "radio" && (
                  <AtlasRadioGroup
                    horizontal={true}
                    checkedValue={item.param}
                    containerClassName={"w-100"}
                    selectedRadioValue={(val) => onChangeFilter(index, val)}
                    options={item.options}
                  />
                )}
                {item.type === "date" && (
                  <div className="at-form-input">
                    <DatePicker
                      value={
                        item.param && item.param !== ""
                          ? Moment(item.param)
                          : item.param
                      }
                      onChange={(val) =>
                        onChangeFilter(index, Moment(val).format("YYYY-MM-DD"))
                      }
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {searchParams && searchParams.length > 0 && (
        <div className="d-flex mb-20">
          <button
            className="btn-new btn-new--outline-secondary mr-2"
            onClick={() => onClearFilter()}
          >
            Reset
          </button>
          <button
            disabled={
              !searchParams[0].param ||
              !searchParams[1].param ||
              searchParams[1].param.length < 3
            }
            className="btn-new btn-new--primary"
            onClick={() => getListAPI()}
          >
            Search
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchContent;
