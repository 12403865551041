import React, { Component } from "react";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";
import { getItem } from "utils/tokenStore";

export const searchParams = () => [
  {
    label: "Request Date",
    value: "from_date",
    type: "date",
    param: "",
  },
  {
    label: "Product",
    value: "product_id",
    type: "radio",
    param: "",
    options: [
      { value: 1, label: "Banner" },
      { value: 2, label: "Sticker" },
    ],
  },
  {
    label: "Type",
    value: "product_type_id",
    type: "radio",
    param: "",
    options: [
      { value: 1, label: "Sale" },
      { value: 2, label: "Rent" },
      { value: 3, label: "Sale/Rent" },
    ],
  },
  {
    label: "Size",
    value: "size",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "statusId",
    type: "radio",
    param: "",
    col: 8,
    options: [
      { value: 1, label: "Submitted" },
      { value: 2, label: "Sent To Printer" },
      { value: 3, label: "Received From Printer" },
      { value: 4, label: "Completed" },
      { value: 5, label: "Cancelled" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: true,

      bannerRequests: [],
      searchParams: searchParams(this.props.data.branchReducer.branch),
    };

    load = (param) => this.setState({ loading: param });

    onChangeBannerRequest = (val, context) => this.setState({ [context]: val });

    redirectTo = (param) => {
      const token = window.location.href.indexOf("/admin-impersonate") > -1
        ? getItem("IQI_ATLAS_JWT_AGENT_TOKEN")
        : getItem("IQI_ATLAS_JWT_TOKEN");
      const url = 'https://agentforms.iqiglobal.com/atlas-agentforms-login';
      const data = {
        token: token,
        slug: param.slug,
        referral: window.location.href
      };
    
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = url;
      form.target = '_blank'; 
    
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = data[key];
          form.appendChild(input);
        }
      }
    
      document.body.appendChild(form);
      form.submit();
    
      document.body.removeChild(form);
    };

    convertQueryParams(query) {
      const params = new URLSearchParams(query);
      const newParams = new URLSearchParams();
    
      params.forEach((value, key) => {
        const newKey = key.replace(/^q\[/, '').replace(/\]$/, '');
        newParams.append(newKey, value);
      });
    
      return `${newParams.toString()}&`;
    }

    getBannerRequests = (page, search) => {
      Get(
        `/profiles/banner_request?${this.convertQueryParams(search)}page=${page}`,
        this.getBannerRequestsSucccess,
        this.getBannerRequestsFailed,
        this.load,
      );
    }
    getBannerRequestsSucccess = (payload) => this.setState({ bannerRequests: payload });
    getBannerRequestsFailed = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadBannerRequest={this.state.loading}
            bannerRequests={this.state.bannerRequests}
            searchParams={this.state.searchParams}
            redirectTo={this.redirectTo}
            getBannerRequests={this.getBannerRequests}
            onChangeBannerRequest={this.onChangeBannerRequest}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {})(WithHOC);
};

export default HOC;
