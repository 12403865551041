import React, { Component } from "react";

import PaginatedTable from "components/NewPaginatedTable";
import LoadingModal from "components/LoadingModal";

import VersionCheckHOC from "actions/versionCheck";
import { MyBookingColumnData } from "./assets";

class OrderHistory extends Component {
  componentDidMount = () => {
    this.props.getBannerRequests(1, "");
  };

  renderCardContent = () => {
    const {
      searchParams,
      bannerRequests,
      onChangeBannerRequest,
      getBannerRequests
    } = this.props;

    return (
      <>
        <PaginatedTable
          enableSort={true}
          rowData={bannerRequests.data}
          meta={bannerRequests.metadata}
          columns={MyBookingColumnData}
          searchParams={searchParams}
          getListAPI={getBannerRequests}
          onChangeSearchParams={(val) => onChangeBannerRequest(val, "searchParams")}
        />
      </>
    );
  };

  render = () => {
    return (
      <>
        {this.renderCardContent()}
        {this.props.onLoadBannerRequest && <LoadingModal />}
      </>
    );
  };
}

export default VersionCheckHOC(OrderHistory);
