import React, { useState, useEffect } from "react";
import _ from "lodash";
import Moment from "moment";
import { Grid, MenuItem } from "@material-ui/core";
import EmailValidator from "email-validator";

import CustomSelect from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import DatePicker from "components/Input/datetimepicker";

import { priorityTypes } from "../assets";
import { AgentLeadType } from "dictionary/lead";
import { StatusOptions } from "../utils";
import CreateLeadHOC from "../actions/create";

const leadDetailsData = [
  {
    label: "Type",
    value: "type_id",
    grid: 4,
    type: "select",
    options: AgentLeadType,
    requiredFilled: true,
  },
  { label: "Source", value: "lead_source", type: "text", requiredFilled: true },
  {
    label: "Status",
    value: "status_id",
    grid: 4,
    type: "select",
    options: StatusOptions,
    requiredFilled: false,
  },
  {
    label: "Inquiry Date",
    value: "inquiry_date",
    type: "date",
    requiredFilled: true,
  },
  {
    label: "Close Date",
    value: "close_date",
    grid: 4,
    type: "date",
    requiredFilled: false,
  },
  {
    label: "Priority",
    value: "priority_id",
    grid: 4,
    type: "radio",
    options: priorityTypes,
    requiredFilled: false,
  },
];

const leadInformationData = [
  { label: "Name", value: "buyer_name", type: "text", requiredFilled: true },
  {
    label: "Email",
    value: "buyer_email",
    type: "email",
    requiredFilled: false,
  },
  {
    label: "Phone Number",
    value: "buyer_phone_number",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Wechat",
    value: "buyer_wechat",
    type: "text",
    requiredFilled: false,
  },
  { label: "Language", value: "language", type: "text", requiredFilled: false },
  { label: "", value: "", type: "", requiredFilled: false },
  {
    label: "Current City",
    value: "current_city",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Current State",
    value: "current_state",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Current Country",
    value: "current_country",
    type: "text",
    requiredFilled: false,
  },
];

const interestData = [
  {
    label: "Target City",
    value: "target_city",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Target State",
    value: "target_state",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Target Country",
    value: "target_country",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Property Category",
    value: "property_category",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Purchase Timing",
    value: "purchase_timing",
    type: "text",
    requiredFilled: false,
  },
  { label: "Budget", value: "budget", type: "text", requiredFilled: false },
  {
    label: "Motivation",
    value: "motivation",
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Initial Comments",
    value: "initial_comments",
    grid: 12,
    type: "text",
    requiredFilled: false,
  },
  {
    label: "Additional Comments",
    value: "additional_comments",
    grid: 12,
    type: "text",
    requiredFilled: false,
  },
];

const CreateLeadsModal = ({
  closeButton,
  onLoadLeads,
  onCreateLoading,
  createLeads,
  onChangeLeadsHOC,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [createData, onCreateData] = useState({});
  const [leadInformation, onSetLeadInformation] = useState(leadInformationData);
  const [errorChecking, setErrorChecking] = useState([]);

  useEffect(() => {
    const tmp = _.cloneDeep(leadInformation);
    setDisabled(false);
    tmp.map((item) => {
      if (item.value && !createData[item.value] && item.requiredFilled)
        setDisabled(true);
    });
  }, [createData]);

  const onBindData = (val, context) => {
    let tmp = {
      ...createData,
      [context]: val,
    };
    onCreateData(tmp);
  };

  const renderInputField = (dataField) =>
    dataField.map((item) => {
      return (
        <div
          className="at-form_field-col-4"
          item
          key={item.value}
          id={item.value}>
          <h2 className="at-form-input__title" required={item.requiredFilled}>
            {item.label || ""}
          </h2>
          {item && (item.type === "text" || item.type === "email") && (
            <CustomFormInput
              type={item.type}
              required={item.requiredFilled}
              containerStyle={{ position: "relative" }}
              value={createData[item.value] || " "}
              inputError={
                errorChecking &&
                errorChecking.find((i) => i === item.value) &&
                "Invalid Input"
              }
              onChangeValue={(val) => {
                let tmpErrorChecking = _.cloneDeep(errorChecking);
                let tmpError = false;
                let index = -1;

                onBindData(val, item.value);
                setTimeout(() => {
                  if (item.value && item.value.includes("email")) {
                    tmpError = val && !EmailValidator.validate(val);

                    if (tmpError) {
                      if (
                        tmpErrorChecking &&
                        !tmpErrorChecking.find((i) => i === item.value)
                      ) {
                        tmpErrorChecking.push(item.value);
                      }
                    } else {
                      if (
                        tmpErrorChecking &&
                        tmpErrorChecking.find((i) => i === item.value)
                      ) {
                        index = tmpErrorChecking.indexOf(item.value);
                        if (index > -1) {
                          tmpErrorChecking.splice(index, 1);
                        }
                      }
                    }
                    setErrorChecking(tmpErrorChecking);
                  }
                }, 800);
              }}
              error={
                createData && !createData[item.value] && item.requiredFilled
              }
            />
          )}
          {item && item.type === "select" && (
            <CustomSelect
              value={createData[item.value] || ""}
              required={item.requiredFilled}
              onChange={(e) => onBindData(e.target.value, item.value)}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {item.options?.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  classes={{ root: `at-select__dropdown-item` }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelect>
          )}
          {item && item.type === "date" && (
            <div className="at-form-input mb-3">
              <DatePicker
                value={Moment(createData[item.value])}
                onChange={(val) => onBindData(Moment(val).format("YYYY-MM-DD"), item.value)}
              />
              {item.requiredFilled && (
                <div className="at-form-input__required">required *</div>
              )}
            </div>
          )}
          {item && item.type === "radio" && (
            <AtlasRadioGroup
              horizontal={true}
              checkedValue={createData[item.value] || ""}
              containerClassName={"w-100"}
              selectedRadioValue={(val) => onBindData(val, item.value)}
              options={item.options}
            />
          )}
        </div>
      );
    });

  return (
    <>
      <div className="at-form__content">
        <div className="d-flex align-items-center mb-3 grid_gap-2">
          <div className="at-badge-icon at-badge-icon-warning">
            <AtlasIcon svgHref={"atlas-building-3"} />
          </div>
          <h2 className="fw-600 fs-3">Lead Details</h2>
        </div>
        <div className="at-form_fields_cont">
          {renderInputField(leadDetailsData)}
        </div>
      </div>
      <div className="at-form__content">
        <div className="d-flex align-items-center mb-3 grid_gap-2">
          <div className="at-badge-icon at-badge-icon-warning">
            <AtlasIcon svgHref={"atlas-building-3"} />
          </div>
          <h2 className="fw-600 fs-3">Lead Information</h2>
        </div>
        <div className="at-form_fields_cont">
          {renderInputField(leadInformation)}
        </div>
      </div>
      <div className="at-form__content">
        <div className="d-flex align-items-center mb-3 grid_gap-2">
          <div className="at-badge-icon at-badge-icon-warning">
            <AtlasIcon svgHref={"atlas-building-3"} />
          </div>
          <h2 className="fw-600 fs-3">Interest</h2>
        </div>
        <div className="at-form_fields_cont">
          {renderInputField(interestData)}
        </div>
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          className={"btn-new btn-new--primary"}
          disabled={disabled || (errorChecking && errorChecking.length !== 0)}
          onClick={() => createLeads(createData)}
        >
          Create
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={closeButton}
        >
          Cancel
        </button>
      </div>
      {(onLoadLeads || onCreateLoading) && <LoadingModal />}
    </>
  );
};

export default CreateLeadHOC(CreateLeadsModal);
