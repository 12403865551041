import React, { useState } from 'react';
import FileSaver from 'file-saver'

import CustomButton from 'components/Button';
import AtlasIcon from 'components/Icon/atlasIcon'
import ConfirmDeleteModal from 'components/Modal/delete'
import LoadingModal from 'components/LoadingModal'
import AtlasTable from 'components/NewTable'
import ModalDialog from 'components/Modal/ModalDialog';
import AttachmentCreateUpdateModal from './attachmentCreateUpdate'
import ShareContent from './share'

import permissionsChecker from "utils/permissionsChecker";
import AttachmentHOC from "../actions/attachments";
import "stylesheets/components/calendar/index.scss";

const Attachments = ({
  data,
  attachments,
  selectedAttachment,
  showConfirmDeleteDialog,
  showViewImage,
  showCreateModal,
  onLoadAttachment,
  deleteAttachment,
  createAttachment,
  regenerateQrCode,
  onChangeAttachmentHOC,
}) => {
  const [toRemoveItemID, setToRemoveItemID] = useState("");

  const { 
    can_create, 
    can_update,
    can_destroy 
  } = permissionsChecker( 'Company Events', data )

  const renderCheckBoxActions = () => (
    <div className='at-table__head-title'>
      <div className='at-badge-icon-sm at-badge-icon-warning mr-2' >
        <AtlasIcon svgHref={ 'atlas-document-text' } />
      </div>
      <h4>Attachments</h4>
      { can_create && (
        <button
          className="btn-new btn-new--primary ml-2"
          onClick={ () => onChangeAttachmentHOC( true, 'showCreateModal' ) }>
          <AtlasIcon svgHref={ 'atlas-add-linear' } className="mr-0" />
          New Attachment
        </button>
      ) }
    </div>
  )
  
  return (
    <>
      <AtlasTable
        hideSearch={ true }
        className={ 'mb-100' }
        columns={[{
          header: 'Title',
          accessor: 'title',
          searchFlag: 'title',
          columnStyle: { width: '70%' },
        }]}
        renderCheckBoxActions={ renderCheckBoxActions }
        actionColumnContent={[
          {
            name: 'view-doc', 
            tooltipContent: 'View',
            onClick: rowData => window.open( rowData.image_url, '_blank' )
          },
          {
            name: "preview",
            tooltipContent: "QR Code",
            onClick: (rowData) => {
              Promise.all([
                onChangeAttachmentHOC(rowData, "selectedAttachment"),
              ]).then(() => {
                onChangeAttachmentHOC(true, "showViewImage");
              });
            },
          },
          {
            name: 'delete', 
            color: '#F04438',
            onShow: () => can_destroy,
            onClick: (rowData) => {
              onChangeAttachmentHOC(true, "showConfirmDeleteDialog");
              setToRemoveItemID(rowData.id);
            },
          },
        ]}
        rowData={ attachments.data || [] }
        pagination={ true }/>
      {
        showViewImage && (
          <ModalDialog
            title={ 'QR Code' }
            onLoad={ onLoadAttachment }
            removeFooter={ true }
            onClose={ () => onChangeAttachmentHOC( false, 'showViewImage' )}
            children={
              <div className='d-flex flex-column align-items-center'>
                <img className='mb-3' style={{ width: 200 }} src={ selectedAttachment.qr_code_image_url } />
                <ShareContent
                  mediaURL={ selectedAttachment.qr_code_image_url }
                  shareLink={ selectedAttachment.qr_code_image_url }
                  downloadTooltip={ 'Download Image' }
                  showElement={ [ 'Whatsapp', 'Telegram', 'Download', 'Copy Link', 'Open in New Tab' ] }
                  downloadTarget={ '_self' }
                  onClickDownload={ () => FileSaver.saveAs( selectedAttachment.qr_code_image_url, selectedAttachment.title ) } />
                {
                  can_update && (
                    <CustomButton
                      style={{ width: '100%' }}
                      containerStyle={{ width: '100%' }}
                      className="btn-new btn-new--secondary mt-3"
                      children={ 'Re-generate QR Code' }
                      onClick={ () => regenerateQrCode( selectedAttachment.id ) } />
                  )
                }
              </div>
            }
          />
        )
      }
      { showCreateModal && <AttachmentCreateUpdateModal
          createAttachment={ createAttachment }
          onChangeAttachmentHOC={ onChangeAttachmentHOC }
          onClose={ () => onChangeAttachmentHOC( false, 'showCreateModal' ) }
          onLoad={ onLoadAttachment }
        /> 
      }
      <ConfirmDeleteModal
        open={ showConfirmDeleteDialog }
        loading={ onLoadAttachment }
        title={ 'Delete Attachment' }
        message={ 'Are you sure to remove the selected attachment?' }
        positiveAction={ () => deleteAttachment( toRemoveItemID ) }
        negativeAction={ () => onChangeAttachmentHOC( false, 'showConfirmDeleteDialog' ) } />
      { ( onLoadAttachment ) && <LoadingModal/> }
    </>
  );
};

export default AttachmentHOC(Attachments);
