import UserInfo from "components/NewPaginatedTable/components/UserInfo";

const formatDate = (date) => (date > 0 && date < 10) ? `0${date}` : `${date}`;
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;
const startMonth = currentMonth >= 3 ? currentMonth - 2 : 1;
const formattedStartMonth = formatDate(startMonth);
const formattedMonth = formatDate(currentMonth);
const last_day = new Date(currentYear, currentMonth, 0).getDate();
export const startDate = `01-${formattedStartMonth}-${currentYear}`;
export const endDate = `${last_day}-${formattedMonth}-${currentYear}`;


export const agentContents = [
  { label: "Full Name", value: "full_name" },
  { label: "Email", value: "email" },
  { label: "Mobile Contact Number", value: "mobile_contact_number" },
]

export const personalContents = (type) => {
  const datapersonalContents =  [
    { label: "Full Name", value: "full_name" },
    { label: "Gender", value: "gender" },
    { label: "NRIC/Passport No.", value: "nric" },  
    ...(type === "Confirmation Forms" ? [{ label: "Email", value: "email" }] : []),
    { label: "Mobile/Office Contact No.", value: "mobile_number" },
    { label: "Nationality", value: "nationality" },
    { label:  "Date of Birth", value: "dob" },
    ...(type === "Confirmation Forms" ? [{ label: "Occupation", value: "occupation" }] : [])
  ]
  return datapersonalContents;
}

export const companyContents = (tyoe) => {
  const datacompanyContents = [
    { label: "Company Name", value: "company_name" },
    { label: "Company Reg. No.", value: "company_registration_no" },
    { label: "Representative's Designation", value: "job_title" },  
    { label: "Representative's Name", value: "representative_name" },
    { label: "Representative's NRIC/Passport No.", value: "representative_nric" },
    { label: "Representative's Mobile Number", value: "representative_mobile_number" },
    ...(tyoe === "Confirmation Forms" ? [{ label: "Company/Representative's Email", value: "company_email"}] : []),
  ]
  return datacompanyContents;
}

export const addressContents = [
  { label: "Address", value: "property_address" },
  { label: "Country", value: "country" },
  { label: "State", value: "state" },
  { label: "Area / City", value: "town" },
  { label: "Postcode", value: "postcode" }
]

export const bankContents = [
  { label: "Bank Name", value: "bank_name" },
  { label: "Bank Account Number", value: "bank_account_number" },
  { label: "Bank Payable Name", value: "bank_payable_name" },
]

export const amlaPersonalContents = (type) => {
  const dataPersonalContents = [
    ...(type === "Confirmation Forms" ? [] : [{ label: "Occupation", value: "occupation"}]),
    { label: "Name of Employer or if self-employed, Nature of Business", value: "employer_name" },
    { label: "Monthly Income", value: "salary_range" },
    { label: "Purpose of Transaction", value: "transaction_purpose" },
    { label: "Has the client ever been declared bankrupt in any country or jurisdiction?", value: "declared_bankrupt" },
    { label: "Method of financing property sale/rental", value: "financing_method" }
  ]
  return dataPersonalContents;
}

export const amlaCompanyContentsPartOne = [
  { label: "Business Type", value: "business_type" },
  { label: "Nature of Company", value: "nature_of_business" },
  { label: "Country of Incorporation", value: "country_of_incorporation" },
]

export const amlaCompanyContentsPartTwo = [
  { label: "Name of Senior Management (CEO, CFO, CTO)", value: "senior_management" },
  { label: "Name of Beneficial Owners Through Other Means", value: "beneficial_owners" },
  { label: "Type of Ownership/Control/Relationship (Optional)", value: "ownership_type" },
  { label: "Method of financing property sale/rental", value: "financing_method" },
  { label: "Purpose of Transaction", value: "transaction_purpose" },
  { label: "Has the client ever been declared bankrupt in any country or jurisdiction?", value: "declared_bankrupt" },
]

export const confirmationAmlaCompanyContents= [
  { label: "Nature of Company", value: "nature_of_business" },
  { label: "Purpose of Transaction", value: "transaction_purpose" },
  { label: "Method of financing property sale/rental", value: "financing_method" },
  { label: "Has the client ever been declared bankrupt in any country or jurisdiction?", value: "declared_bankrupt" },
]

export const searchParams = [
  {
    label: "Agent Email",
    value: "email",
    type: "input",
    param: '',
  },
  {
    label: "Start Date",
    value: "start_date",
    type: "date",
    param: startDate,
    format: "DD-MM-YYYY",
  },
  {
    label: "End Date",
    value: "end_date",
    type: "date",
    param: endDate,
    format: "DD-MM-YYYY",
  },
]

export const columnData = {
  Personal: [
    {
      header: "Form No.",
      accessor: "form_no",
      searchFlag: "form_no",
    },
    {
      header: "Name",
      accessor: "full_name",
      searchFlag: "full_name",
    },
    {
      header: "Agent",
      columnStyle: { width: "140%" },
      customClass: "mb-5",
      searchFlag: "agent",
      renderColumn: (rowData) => (
        <UserInfo
          name={rowData.agent.full_name || "N/A"}
          email={rowData.agent.email || "N/A"}
          phone_number={rowData.agent.mobile_contact_number || "N/A"}
          hideRedirect={true}
        />  
      )
    },
    {
      header: "Occupation",
      accessor: "occupation",
      searchFlag: "occupation",
    },
    {
      header: "Purpose",
      accessor: "transaction_purpose",
      searchFlag: "transaction_purpose",
    },
    {
      header: "Method",
      accessor: "financing_method",
      searchFlag: "financing_method",
    }
  ],
  Company: [  
    {
      header: "Form No.",
      accessor: "form_no",
      searchFlag: "form_no",
    },
    {
      header: "Company Name",
      accessor: "company_name",
      searchFlag: "company_name",
    },
    {
      header: "Agent",
      columnStyle: { width: "140%" },
      customClass: "mb-5",
      searchFlag: "agent",
      renderColumn: (rowData) => (
        <UserInfo
          name={rowData.agent.full_name || "N/A"}
          email={rowData.agent.email || "N/A"}
          phone_number={rowData.agent.mobile_contact_number || "N/A"}
          hideRedirect={true}
        />  
      )
    },
    {
      header: "Nature of Company",
      accessor: "nature_of_business",
      searchFlag: "nature_of_business",
    },
    {
      header: "Purpose",
      accessor: "transaction_purpose",
      searchFlag: "transaction_purpose",
    },
    {
      header: "Method",
      accessor: "financing_method",
      searchFlag: "financing_method",
    }
  ]
} 