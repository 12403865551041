import React, { useState, useEffect, useCallback, useMemo } from "react";
import Moment from "moment";
import _ from "lodash";
import { FormGroup } from "@material-ui/core";

import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import CustomRadioGroup from "components/RadioGroup";
import ModuleFormHeader from "components/Form/header";
import AtlasAutosuggest from "components/Autosuggest";
import CustomFormInput from "components/Input/formInput";
import DatePicker from "components/Input/datetimepicker";

import { contact as ContactDictionary } from "dictionary/index";
import TownShipHOC from "actions/township";
import { requestError } from "utils/requestHandler";

import {
  FormData,
  OccupationOptions,
  DefaultFormValue,
  RepresentativeFormData,
} from "./assets";

const GenderOptions = [
  { id: "M", label: "Male", value: "M" },
  { id: "F", label: "Female", value: "F" },
];

const appendYearPrefix = (DOB) => {
  let checkAbove00Gen = new RegExp(/[0-3]/);
  let checkBelow90Gen = new RegExp(/[4-9]/);
  let firstDigit = DOB[0];

  if (checkAbove00Gen.test(firstDigit)) {
    return `20${DOB}`;
  } else if (checkBelow90Gen.test(firstDigit)) {
    return `19${DOB}`;
  }
};

const profileTypeOptions = [
  {
    value: 1,
    label: "Personal",
  },
  {
    value: 2,
    label: "Company",
  },
];

const Edit = ({
  data,
  banks,
  context,
  township,
  can_update,
  can_create,
  nationalities,
  selectedContact,
  onLoadContact,

  onClickClose,
  onClickSubmit,
  getSelectedTownShip,
}) => {
  const permissionChecking = context === "new" ? can_create : can_update

  const countries = _.map(data.dictionaryReducer.countries, (country) => {
    return { ...country, value: country.name };
  });

  const [contactForm, onChangeContactForm] = useState({ ...DefaultFormValue });
  const [states, setStates] = useState([]);
  const [formFields, setFormFields] = useState(FormData({
    banks: banks,
    company: contactForm.type_id === 2,
    tags: ContactDictionary.tags,
    countries: countries,
    states: states,
    genders: GenderOptions,
    township: township,
    nationalities: nationalities,
  }));
  const [statusFields, setStatusFields] = useState({})

  useEffect(() => {
    if(Object.keys(statusFields).length === 0) {
      let tmpStatusFields = _.cloneDeep(statusFields)
      setStatusFields(tmpStatusFields)
    } 

    setFormFields(FormData({
      banks: banks,
      company: contactForm.type_id === 2,
      tags: ContactDictionary.tags,
      countries: countries,
      states: states,
      genders: GenderOptions,
      township: township,
      nationalities: nationalities
    }))    
  }, [banks, contactForm, township, states])

  useEffect(() => {
    selectedContact && onChangeContactForm(selectedContact);
  }, []);

  useEffect(() => {
    let temp = _.find(
      countries,
      (country) => country.value === contactForm.country,
    );
    if (temp) {
      let tempStates = _.map(temp.states, (state) => {
        return { ...state, value: state.name };
      });
      setStates(tempStates);
    }
  }, [contactForm.country]);

  useEffect(() => {
    let temp = _.find(states, (state) => state.value === contactForm.state);
    if (temp) {
      getSelectedTownShip(temp.id);
    }
  }, [contactForm.state]);

  const checkingRequiredField = useMemo(() => {
    return _.some(formFields, (item) =>  item.required && (contactForm[item.value] === "" || contactForm[item.value] === null))
  }, [contactForm])

  const onChangeFormField = (key, val, field) => {
    let temp = _.cloneDeep(contactForm);
    let tmpStatusFields = _.cloneDeep(statusFields)
    if (key === "type_id") {
      temp[key] = val;
      temp.company = "";
      temp.company_registration_number = "";
    }
    if (key === "tags") {
      temp.ren_tag = val === 5 ? "" : temp.ren_tag;
      temp.tags.indexOf(val) > -1
        ? _.remove(temp.tags, (o) => o === val)
        : (temp.tags = [...temp.tags, val]);
    } else {
      temp[key] = val;
    }
    if (key === "identification_number") {
      temp["date_of_birth"] = dobGenerator(val);
    }

    let tmpIsError = false
    if(field) {
      if(field.type === "autosuggest") {
        const selectedObj = val !== "" 
          ? _.find(field?.options || [], item => item.value === val)
          : true
        tmpIsError = !selectedObj || (field.required && !val)
      } else {
        tmpIsError = field.required && (val === "" || val === null)
      }
      if(context === "new") {
        tmpStatusFields[key] = {
          ...tmpStatusFields[key],
          isError: tmpIsError,
          isInitialUpdate: false
        }
      } else {
        tmpStatusFields[key] = { isError: tmpIsError }
      }
    }

    setStatusFields(tmpStatusFields)
    onChangeContactForm(temp);
  };

  const dobGenerator = (val) => {
    let tmpVal = val.toString();
    let digitLength = tmpVal.length;
    let nricChecking = /^\d{6}-\d{2}-\d{4}$/;
    let tmpBirthday = tmpVal.substr(0, 6);
    tmpBirthday = appendYearPrefix(tmpBirthday);
    let birthday = Moment(tmpBirthday, "YYYYMMDD");
    let isValid = birthday.isValid();

    if ((!isNaN(tmpVal) && digitLength >= 6) || nricChecking.test(tmpVal)) {
      return isValid ? birthday.format("DD-MM-YYYY") : "";
    }
  };
  
  const debounceService = useCallback(
    _.debounce((val) => {
      return dobGenerator(val);
    }, 400),
    [],
  );

  const renderInput = (data) => {
    const tmpIsError = (context === "new")
      ? (statusFields?.[data.value]?.isError && !statusFields.isInitialUpdate)
      : statusFields?.[data.value]?.isError
    return (
      <section className={`grid-${data.grid || "half"}-col`}>
        <h2 className={`at-form-input__title ${data?.className ?? ""}`} required={data.required}>
          {data.label}
        </h2>
        {!data.type && (
          <CustomFormInput
            type="text"
            footerClass="mb-0"
            className={`mt-2 ${tmpIsError ? "at-form-input--error" : ""}`}
            disabled={!permissionChecking || (data.checkBoxValue && contactForm[data.checkBoxValue])}
            value={contactForm[data.value]}
            onChangeValue={(val) => onChangeFormField(data.value, val, data)}
            required={data.required}
            inputError={""}
            placeholder={data.placeholder || `Enter ${data.label}`}
          />
        )}
        {data.type === "select" && (
          <CustomSelect
            className="w-100"
            selectClassName={`${tmpIsError ? "at-form-input--error" : ""}`}
            footerClass="mb-0"
            labelKey={"name"}
            valueKey={"id"}
            required={data.required}
            selectItems={data.options}
            currentlySelected={_.find(data.options, {
              id: contactForm[data.value],
            })}
            updateSelect={(val) => onChangeFormField(data.value, val.id, data)}
          />
        )}
        {data.type === "calendar" && (
          <div className={`at-form-input ${tmpIsError ? "at-form-input--error" : ""}`}>
            <DatePicker
              value={contactForm[data.value] || null}
              onChange={(val) =>
                onChangeFormField(
                  data.value,
                  Moment(val).format("DD-MM-YYYY"),
                  data.type
                )
              }
            />
          </div>
        )}
        {data.type === "autosuggest" && (
          <AtlasAutosuggest
            className={`${tmpIsError ? "at-form-input--error" : ""}`}
            required={data.required}
            footerClass="mb-0"
            placeholder={data.placeholder || `Enter ${data.label}`}
            wrapperStyle={{ marginTop: 5, marginBottom: 5 }}
            value={contactForm[data.value] || ""}
            updateValue={(val) => onChangeFormField(data.value, val, data)}
            options={data.options}
          />
        )}
        {data.type === "radio" && (
          <CustomRadioGroup
            disabled={!permissionChecking}
            footerClass="mb-0"
            checkedValue={contactForm[data.value]}
            options={data.options}
            required={data.required}
            selectedRadioValue={(val) =>
              onChangeFormField(data.value, val, data.type)
            }
          />
        )}
        { data.checkBoxValue && (
          <CustomCheckbox
            labelClassname={"mb-2 mt-3"}
            checked={contactForm[data.checkBoxValue]}
            content={
              <span className="fw-500">
                {data.checkBoxDescription}
              </span>
            }
            onChangeCheckboxValue={ () => onChangeFormField(data.checkBoxValue, !contactForm[data.checkBoxValue], data)}
          />
        ) }
      </section>
    )
  }

  return (
    <>
      <form className="at-form__content grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <ModuleFormHeader
            className={"at-contact_details__icon"}
            title={`${ contactForm.type_id === 1 ? "Personal" : "Company" } Information`}
            moduleIcon={ contactForm.type_id === 1 ? "atlas-profile-circle" : "atlas-building-company" }/>
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title" required>
            Category
          </h2>
          <CustomRadioGroup
            horizontal={true}
            disabled={!permissionChecking}
            checkedValue={contactForm.type_id}
            containerClassName={"mycontacts-profiletypes-radiogrp"}
            options={profileTypeOptions}
            selectedRadioValue={(val) => onChangeFormField("type_id", val)}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title" required>
            Please select a contact type
          </h2>
          <FormGroup row>
            {ContactDictionary.tags.map((item) => (
              <CustomCheckbox
                content={item.name}
                disabled={!permissionChecking}
                checked={_.includes(contactForm.tags, item.id)}
                onChangeCheckboxValue={() => onChangeFormField("tags", item.id)}
              />
            ))}
          </FormGroup>
        </section>
        <section className="grid-half-col">
          {ContactDictionary.tags.indexOf(5) > -1 && (
            <>
              <h2 className="at-form-input__title">{`REN Tag`}</h2>
              <CustomFormInput
                type="text"
                className={"mt-2"}
                footerClass="mb-0"
                disabled={!permissionChecking}
                value={contactForm.ren_tag}
                onChangeValue={(val) => onChangeFormField("ren_tag", val)}
                required={false}
                inputError={""}
                placeholder={`Is this a property agent? If so, add the agent/REG tag`}
              />
            </>
          )}
        </section>
        {formFields.map((data) => {
          if (data.section === 1) {
            return renderInput(data)
          }
        })}
      </form>
      <form className="at-form__content grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <ModuleFormHeader
            title={"Contact Details"}
            moduleIcon={"atlas-call"} />
        </section>
        {formFields.map((data) => {
          if (data.section === 2) {
            return renderInput(data)
          }
        })}
      </form>
      <form className="at-form__content grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <ModuleFormHeader
            title={"Bank Details"}
            moduleIcon={"atlas-bank"} />
        </section>
        {formFields.map((data) => {
          if (data.section === 3) {
            return renderInput(data)
          }
        })}
      </form>
      <form className="at-form__content grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <ModuleFormHeader
            title={"Contact Information"}
            moduleIcon={"atlas-message-text"} />
        </section>
        {formFields.map((data) => {
          if (data.section === 4) {
            return renderInput(data)
          }
        })}
      </form>
      <form className="at-form__content grid-control" onSubmit={(e) => e.preventDefault()}>
        <section className="grid-full-col">
          <ModuleFormHeader
            title={"Representative"}
            moduleIcon={"atlas-user-tie"} />
        </section>
        <section className="grid-full-col">
          <CustomCheckbox
            disabled={!permissionChecking}
            content={"Does this contact has representative or agent?"}
            checked={contactForm.has_representative}
            onChangeCheckboxValue={(e) =>
              onChangeFormField("has_representative", e.target.checked)
            }
          />
        </section>
        {contactForm.has_representative &&
          RepresentativeFormData.map((data) => (
            <section className="grid-half-col">
              <h2 className="at-form-input__title">{data.label}</h2>
              <CustomFormInput
                type="text"
                className={"at-form-input__new mt-2"}
                disabled={!permissionChecking}
                value={contactForm[data.value]}
                onChangeValue={(val) => onChangeFormField(data.value, val)}
                required={false}
                inputError={""}
                placeholder={data.placeholder || `Enter ${data.label}`}
              />
            </section>
          ))}
      </form>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          type={"button"}
          disabled={!permissionChecking || checkingRequiredField || contactForm.tags.length === 0}
          className={`btn-new btn-new--${context === 'new' ? 'primary' : 'success'}`}
          onClick={() => {
            const checkOccupation = contactForm?.occupation !== '' && contactForm?.occupation !== null
              ?  _.find(OccupationOptions, {
                value: contactForm?.occupation ?? "",
              }) 
              : true;
            const checkNationality = contactForm?.nationality !== '' && contactForm?.nationality !== null
             ? _.find(nationalities, { value: contactForm.nationality })
             : true;
            if (!checkNationality && contactForm.type_id === 1) {
              requestError("Please select nationality from the list");
            } else if (!checkOccupation && contactForm.type_id === 1) {
              requestError("Please select occupation from the list.");
            } else {
              onClickSubmit(contactForm)
            }
          }}
        >
          {context === "new" ? "Create" : "Update"}
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={onClickClose}
        >
          {context === "new" ? "Cancel" : "Close"}
        </button>
      </div>
      {onLoadContact && <LoadingModal />}
    </>
  );
};

export default TownShipHOC(Edit);
