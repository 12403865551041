import React, { useState, useCallback, useEffect } from "react";
import { compose } from "redux";
import _ from "lodash";
import Moment from "moment";
import { PulseLoader } from "react-spinners";

import AtlasButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomTypeahead from "components/Typeahead/new";
import DatePicker from "components/Input/datetimepicker";

import ManualUpdateHOC from "../actions/manualUpdate";
import AgentSearchHOC from "actions/agentSearch";

const ManualUpdateComponent = ({
  agentList,
  resetFields,
  onLoadAgent,
  getAgentsBySearch,

  manualUpdate,
  onChangeManualHOC,
  onChangeAgentHOC,
  onLoadInternalAgent,
  onLoadManualUpdate,
  onCloseModal,
}) => {
  const [selectedAgent, onChangeAgent] = useState(null);
  const [status, toggleStatus] = useState("Fail");
  const [examDate, onChangeDate] = useState(null);

  useEffect(() => {
    if (resetFields) {
      onChangeAgent(null);
      toggleStatus("Fail ");
      onChangeDate(null);
      onChangeManualHOC(false, "resetFields");
    }
  }, [resetFields]);

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  return (
    <>
      <div className="d-flex">
        <h2 className="at-form-input__title mr-10">Select an Agent</h2>
        <PulseLoader
          sizeunit={"px"}
          size={10}
          color={"#16232c"}
          loading={onLoadAgent}
        />
      </div>
      <CustomTypeahead
        disabled={onLoadAgent}
        typeaheadId={"internal_agent"}
        options={agentList ? agentList : []}
        selectedValue={selectedAgent ? [selectedAgent] : []}
        filterBy={["full_name", "email", "mobile_contact_number"]}
        labelKey={"full_name"}
        onSearch={(val) => {
          onChangeAgent(null);
          onChangeTypeaheadSearch(val);
        }}
        onSelect={(val) => val && val.length > 0 && onChangeAgent(val[0])}
        helpText={"e.g. Full Name, Email, Mobile Number"}
        childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
        highlighterData={[
          (option) => `Team: ${option.team_name}`,
          <br />,
          (option) =>
            `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
        ]}
      />
      <h2 className="at-form-input__title mt-10">Exam Date</h2>
      <div className="at-form-input w-100">
        <DatePicker
          dateFormat="DD MMM YYYY"
          value={examDate}
          onChange={(val) => onChangeDate(Moment(val).format("DD MMM YYYY"))}
        />
      </div>
      <h2 className="at-form-input__title mt-10 mb-10">Exam Status</h2>
      <AtlasRadioGroup
        horizontal={true}
        checkedValue={status}
        containerClassName={"w-100"}
        selectedRadioValue={(val) => toggleStatus(val)}
        options={[
          { value: "Pass", label: "Pass" },
          { value: "Fail", label: "Fail" },
        ]}
      />
      <div className="d-flex mt-20 mb-20">
        <AtlasButton
          className="btn-new btn-new--success"
          children={"Update"}
          disabled={!selectedAgent || !examDate}
          onClick={() => {
            let temp = {
              user_id: selectedAgent.id,
              start_date: examDate,
              status_id: status === "Fail" ? 2 : 1,
            };
            manualUpdate(temp);
          }}
        />
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onCloseModal()}
        >
          Cancel
        </button>
      </div>
      {(onLoadInternalAgent || onLoadManualUpdate) && <LoadingModal />}
    </>
  );
};

export default compose(ManualUpdateHOC, AgentSearchHOC)(ManualUpdateComponent);
