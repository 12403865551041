import React, { useState, useEffect } from "react";
import _ from "lodash";
import Moment from "moment";
import { DialogContent, MenuItem } from "@material-ui/core";
import ReactHTMLParser from "react-html-parser";

import Tab from "components/Tab";
import AtlasButton from "components/Button";
import AtSelectNew from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasTextarea from "components/Input/textarea";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import AtPaginatedTable from "components/NewPaginatedTable";
import ConfirmationModal from "components/Modal/confirmation";
import DatePicker from "components/Input/datetimepicker";

import AgreementUserHOC from "./actions/user";
import { StatusLib, AgreementType } from "./assets";
import permissionsChecker from "utils/permissionsChecker";

const FieldData = [
  { label: "Title", value: "title", type: "text" },
  { label: "Publish on", value: "publish_on", type: "date" },
  { label: "Status", value: "status_id", type: "radio", options: StatusLib },
  { label: "Type", value: "type_id", type: "radio", options: AgreementType },
  { label: "Country", value: "country_id", type: "select" },
  {
    label: "Version",
    value: "version",
    type: "number",
    placeholder: "e.g. 1.2",
  },
];

const columnData = [
  {
    header: "Name",
    accessor: "user_full_name",
    searchFlag: "user_full_name",
  },
  {
    header: "Email",
    accessor: "user_email",
    searchFlag: "user_email",
  },
  {
    header: "Team",
    accessor: "user_team_display_name",
    searchFlag: "user_team_display_name",
  },
  {
    header: "Mobile Number",
    accessor: `user_mobile_contact_number`,
    searchFlag: "user_mobile_contact_number",
  },
  {
    header: "Created At",
    searchFlag: "created_at",
    renderColumn: (rowData) => (
      <>{Moment(rowData.created_at).format("DD MMM YYYY hh:mm")}</>
    ),
  },
];

const EditAgreement = ({
  selectedAgreement,
  onCloseModal,
  onLoadAgreement,
  onClickSubmit,
  showPreviewModal,

  data,
  previewData,
  getAgreementUser,
  onLoadAgreementUser,
  deleteAgreementUser,
  showDeleteConfirmation,
  selectedAgreementUser,
  agreementUserPages,
  searchParams,
  agreementsUser,
  onChangeAgreementUserHOC,
  onChangeAgreementHOC,
  previewAgreement,
}) => {
  const [tabContent, setTabContent] = useState("Agreement");
  const [editData, onChangeEditData] = useState({
    content: "",
    title: "",
    publish_on: null,
    version: null,
    status_id: "1",
    type_id: 1,
  });
  const { can_update, can_destroy } = permissionsChecker("Agreements", data);

  useEffect(() => {
    onChangeEditData(selectedAgreement);
    getAgreementUser(1, "");
  }, []);

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(editData);
    temp[context] = val;
    onChangeEditData(temp);
  };

  const renderEditAgreement = () => (
    <>
      <div className="row">
        {FieldData.map((item) => (
          <div className="col-md-4" key={item.value}>
            <h2 className="at-form-input__title" required>
              {item.label}
            </h2>
            {["text", "number"].indexOf(item.type) > -1 && (
              <CustomFormInput
                type={"text"}
                required={true}
                numericOnly={item.type === "number"}
                disabled={!can_update}
                value={editData[item.value]}
                onChangeValue={(val) => {
                  let temp =
                    item.type === "number" ? val.replace(/[^$0-9.]/g, "") : val;

                  onChangeField(temp, item.value);
                }}
                placeholder={item.placeholder}
              />
            )}
            {item.type === "date" && (
              <div className="at-form-input">
                <DatePicker
                  dateFormat="DD MMM YYYY"
                  disabled={!can_update}
                  maxDate={Moment()}
                  value={Moment(editData[item.value])}
                  onChange={(val) =>
                    onChangeField(Moment(val).format("DD MMM YYYY"), item.value)
                  }
                />
                <span className="at-form-input__required">required</span>
              </div>
            )}
            {item.type === "radio" && (
              <AtlasRadioGroup
                valueKey={"id"}
                labelKey={"name"}
                horizontal={true}
                disabled={!can_update}
                checkedValue={`${editData[item.value]}`}
                containerClassName={"w-100"}
                selectedRadioValue={(val) => onChangeField(val, item.value)}
                options={item.options}
              />
            )}
            {item.type === "select" && (
              <AtSelectNew
                value={editData[item.value]}
                required={true}
                onChange={(e) => onChangeField(e.target.value, item.value)}
                style={{ display: "flex", flexDirection: "column" }}
              >
                {data.dictionaryReducer.countries.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </AtSelectNew>
            )}
          </div>
        ))}
        <div className="col-lg-12">
          <h2 className="at-form-input__title">Content</h2>
          <AtlasTextarea
            rows={10}
            required={true}
            disabled={!can_update}
            value={editData.content}
            onChangeValue={(val) => onChangeField(val, "content")}
          />
        </div>
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        {can_update && (
          <AtlasButton
            disabled={_.values(editData).some((val) => !val)}
            className="btn-new btn-new--success"
            children={"Update"}
            onClick={() =>
              onClickSubmit({
                ...editData,
                status_id: +editData.status_id,
                type_id: +editData.type_id,
              })
            }
          />
        )}
        <AtlasButton
          className="btn-new btn-new--secondary"
          children={"Preview"}
          onClick={() => previewAgreement(editData.id)}
        />
        <AtlasButton
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onCloseModal()}
          children={"Close"}
        />
      </div>
    </>
  );

  const renderUserList = () => (
    <>
      <AtPaginatedTable
        rowData={agreementsUser.data}
        meta={agreementsUser.meta}
        columns={columnData}
        enableSort={true}
        actionColumnContent={[
          {
            name: "view",
            tooltipContent: "View Agreement",
            onClick: (rowData) => window.open(rowData.url, "blank"),
          },
          {
            name: "delete",
            color: "#F04438",
            onShow: () => can_destroy,
            onClick: (rowData) => {
              Promise.all([
                onChangeAgreementUserHOC(rowData, "selectedAgreementUser"),
              ]).then(() => {
                onChangeAgreementUserHOC(true, "showDeleteConfirmation");
              });
            },
          },
        ]}
        searchParams={searchParams}
        onChangeSearchParams={(val) =>
          onChangeAgreementUserHOC(val, "searchParams")
        }
        getListAPI={getAgreementUser}
        totalPages={agreementUserPages}
      />
    </>
  );

  const renderTabContent = () => {
    switch (tabContent) {
      case "Agreement":
        return renderEditAgreement();
      case "Users":
        return renderUserList();
    }
  };

  return (
    <>
      <ModalDialog
        title={"Edit Agreement"}
        onLoad={onLoadAgreement}
        fullWidth={true}
        fullHeight={true}
        onClose={onCloseModal}
        children={
          <>
            <Tab
              sections={["Agreement", "Users"]}
              selectedSection={tabContent}
              onSelectSection={(val) => setTabContent(val)}
            />
            {renderTabContent()}
          </>
        }
      />
      {showPreviewModal && (
        <ModalDialog
          title={"Preview"}
          fullWidth={true}
          fullHeight={true}
          onClose={() => onChangeAgreementHOC(false, "showPreviewModal")}
          children={
            <DialogContent style={{ position: "relative" }}>
              {ReactHTMLParser(previewData)}
            </DialogContent>
          }
        />
      )}
      <ConfirmationModal
        open={showDeleteConfirmation}
        title={" "}
        message={"Are you sure you want to delete this user agreement ?"}
        loading={onLoadAgreementUser}
        mode={"alert"}
        positiveAction={() =>
          deleteAgreementUser(selectedAgreement.id, selectedAgreementUser.id)
        }
        negativeAction={() =>
          onChangeAgreementUserHOC(false, "showDeleteConfirmation")
        }
      />
      {(onLoadAgreement || onLoadAgreementUser) && <LoadingModal />}
    </>
  );
};

export default AgreementUserHOC(EditAgreement);
