import React, { Component, Fragment } from "react";
import _ from "lodash";
import Moment from "moment";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { MdQrCode } from "react-icons/md";
import { AiFillStar } from "react-icons/ai";

import CustomTab from "components/Tab";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";

import DefaultImage from 'assets/images/av-male.jpg'
import { TrainingTypes } from 'dictionary/eventTrainingType'
import './cal_events.scss'

const EventTypes = [
  { id: 1, name: "Event" },
  { id: 2, name: "Training" },
];

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

class CalendarOverlay extends Component {
  state = {
    selectedSection: "Details",
    interval: null,
  };

  onClickCloseModal = () => this.props.onClickCloseOverlay();

  renderEventIcon = (status) => {
    let icon = "";
    let title = "";

    switch (status) {
      case 1:
        icon = "atlas-calendar-2";
        title = "Not yet registered";
        break;
      case 2:
        icon = "atlas-calendar-pending";
        title = "Registered with pending approval";
        break;
      case 3:
        icon = "atlas-calendar-approve";
        title = "Registration approved";
        break;
      default:
        break;
    }

    return (
      <DarkTooltip
        classes={{ tooltip: "tooltip-arrow top" }}
        placement={"top"}
        enterTouchDelay={0}
        title={title}
      >
        <div
          className={"at-calendar__day-affair_icons"}
          style={{ minWidth: 30 }}
        >
          <AtlasIcon svgHref={icon} />
        </div>
      </DarkTooltip>
    );
  };

  renderLabels = () => {
    const is_event = (type) => type.id === 1;
    const { selected_event_original, selectedData } = this.props;
    let {
      type_id,
      training_type_id,
      registration_status,
      open_for_registration,
      is_payment_needed,
    } = selected_event_original;
    let tmpType = _.find(EventTypes, { id: type_id });
    let eventType = `${is_payment_needed ? "paid-" : ""}${tmpType?.name.toLowerCase()}`;
    let tmpTrainingType = _.find(TrainingTypes, { id: training_type_id });

    return (
      <div className={`at-cal_events__overlay-label ${eventType}`}>
        {this.renderEventIcon(registration_status)}
        {tmpType && (
          <DarkTooltip
            classes={{ tooltip: "tooltip-arrow top" }}
            enterTouchDelay={0}
            placement={"top"}
            title={`${is_payment_needed ? "Paid" : ""} ${tmpType.name} ${
              tmpTrainingType ? "| " + tmpTrainingType.name : ""
            }`}
          >
            <div className="at-cal_events__overlay-event_meta_label">
              {`${is_payment_needed ? "Paid" : ""} ${tmpType.name} ${tmpTrainingType ? "| " + tmpTrainingType.name : ""}`}
            </div>
          </DarkTooltip>
        )}
        {this.props.selected_event_original.is_limited_seat && (
          <DarkTooltip
            classes={{ tooltip: "tooltip-arrow top" }}
            enterTouchDelay={0}
            placement={"top"}
            title={`Limited`}
          >
            <div
              style={{
                marginRight: 10,
                backgroundColor: "#A0D0D0",
              }}
              className="at-cal_events__overlay-event_meta_label"
            >
              <span className="at-cal_events__day-event_icon-text">
                Limited
              </span>
            </div>
          </DarkTooltip>
        )}
      </div>
    );
  };

  renderImage = (eventUrl, birthdayUrl) => {
    const renderPartyEffect = () => {
      let partyElement = [];
      for (let i = 0; i < 50; i++) {
        partyElement.push(<i></i>);
      }
      return partyElement;
    };
    const { placeholderImage } = this.props;
    return (
      <div className="at-cal_events__overlay-image_cont">
        <img
          className="at-cal_events__overlay-image"
          onError={(e) => {
            if (e.target) {
              e.target.src = placeholderImage;
            }
          }}
          src={eventUrl ? eventUrl : placeholderImage}
          alt={""}
        />
        {birthdayUrl && (
          <>
            <div className="at-cal_events__overlay-avatar_cont">
              <AiFillStar />
              <img
                className="at-cal_events__overlay-avatar"
                src={birthdayUrl}
                onError={(e) => {
                  if (e.target) {
                    e.target.src = DefaultImage;
                  }
                }}
              />
              <div>{renderPartyEffect()}</div>
            </div>
          </>
        )}
      </div>
    );
  };

  renderDetails = () => {
    const { listKeys, selectedData, selected_event_original } = this.props;

    const countWords = () => {
      let tmpWordCountMax = 0;

      listKeys.map((key) => {
        let tmpWords = key.split(" ");
        tmpWordCountMax =
          tmpWordCountMax > tmpWords.length ? tmpWordCountMax : tmpWords.length;
      });
      return tmpWordCountMax;
    };

    const renderChild = (param, title, wordNum) => {
      const checkRedirect =
        title !== "Email Link" && title !== "Mobile Number Link";
      const renderRedirectLink = () => {
        return (
          <a style={{ color: "#2563EB" }} href={selectedData[`${title} Link`]}>
            {param}
          </a>
        );
      };
      const allowToDisplay = ![
        "Email",
        "Mobile Number",
        "Date",
        "Cutoff Date",
        "Price",
        "Fee"
      ].some(item => item === title) && listKeys.some( item => item === title);
      
      if (param && checkRedirect) {
        return (
          <div key={title} className="text-row">
            <strong
              style={{ minWidth: wordNum >= 3 ? "12rem" : "" }}
            >{`${title}:`}</strong>
            {(title === "Email" || title === "Mobile Number") &&
              renderRedirectLink()}
            {title === "Date" && (
              <span>{Moment(param, "DD-MM-YYYY").format("DD MMM YYYY")}</span>
            )}
            {title === "Cutoff Date" && (
              <span>{Moment(param, "YYYY-MM-DD").format("DD MMM YYYY")}</span>
            )}
            {(title === "Price" || title === "Fee") &&
              param instanceof Array &&
              param.length > 0 && (
                <span>
                  {param.map((item) => (
                    <>
                      <span>{`${item.title || "N/A"} - RM ${Number(item.price).toFixed(2) || "N/A"}`}</span>
                      <br />
                    </>
                  ))}
                </span>
              )}
            {(allowToDisplay && param) && (
              <span style={{ wordBreak: "break-word" }}>{param}</span>
            )}
          </div>
        );
      }
    };

    const renderLink = (link, title, wordNum) => {
      return (
        <div key={title} className="text-row">
          <strong
            style={{ minWidth: wordNum >= 3 ? "12rem" : "" }}
          >{`${title}:`}</strong>
          {link && (
            <a
              style={{ color: "#2563EB", lineHeight: 1 }}
              href={link}
              target="_blank"
            >
              <span>{"Click here for the details"}</span>
            </a>
          )}
          {!link && <span>N/A</span>}
        </div>
      );
    };

    return (
      <div className="at-cal_events__overlay-detail">
        <h2>{selectedData.Event}</h2>
        {listKeys.map((key) => {
          if (key === "Location") {
            return renderLink(selectedData[key], key, countWords());
          } else if (
            (!["Entry Pass", "Price", "Fee"].includes(key) || selected_event_original.is_payment_needed)
          ) {
            return renderChild(selectedData[key], key, countWords());
          }
        })}
      </div>
    );
  };

  render = () => {
    const { selected_event_original, selectedData } = this.props;
    const { role, qr_code_image_url } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    let {
      type_id,
      training_type_id,
      registration_status,
      open_for_registration,
      ticket_types,
      is_registered,
    } = selected_event_original;
    let tmpType = _.find(EventTypes, { id: type_id });
    const filterTrainingTypeId = [0, 4, 6].indexOf(training_type_id) !== -1;

    return (
      <ModalDialog
        title={"Event Details"}
        maxWidth="lg"
        responsiveSize="lg"
        onClose={() => this.onClickCloseModal()}
        children={
          <div>
            {this.renderImage(
              this.props.selectedData["Event Image"],
              this.props.selectedData["Profile Picture"],
            )}
            {this.renderLabels()}
            {is_registered && (
              <CustomTab
                sections={["Details", "Ticket List"]}
                selectedSection={this.state.selectedSection}
                onSelectSection={(section) =>
                  this.setState({ selectedSection: section })
                }
              />
            )}
            {this.state.selectedSection === "Details" && this.renderDetails()}
            {this.state.selectedSection === "Ticket List" && (
              <>
                {ticket_types?.length > 0 && (
                  <div className="d-flex flex-wrap g-3">
                    {ticket_types?.map((ticket) => {
                      const isScanned =
                        ticket.tickets_count === ticket.tickets_scanned;

                      return (
                        <div
                          key={ticket.ticket_type_id}
                          className="d-flex w-100"
                        >
                          <div className="at-cal_events__overlay-ticket">
                            <div
                              className={`at-cal_events__overlay-ticket-left ${isScanned ? "at-cal_events__overlay-ticket-left-scanned" : ""}`}
                            >
                              <div className="top"></div>
                              <div className="rip"></div>
                              <div className="bottom"></div>
                            </div>
                            <div className="at-cal_events__overlay-ticket-right"></div>
                            <div className="at-cal_events__overlay-ticket-content">
                              {isScanned && (
                                <AtlasIcon
                                  svgHref={"atlas-ticket-expired"}
                                  style={{ fill: "#D1D3D8" }}
                                />
                              )}
                              {!isScanned && (
                                <AtlasIcon
                                  svgHref={"atlas-ticket-star"}
                                  style={{ fill: "#F0631D " }}
                                />
                              )}
                              <h2 className="mx-2">
                                {ticket.ticket_type_title || "N/A"}
                              </h2>
                              <p>
                                ({ticket.tickets_scanned}/{ticket.tickets_count}
                                )
                              </p>
                            </div>
                          </div>
                          {!isScanned && (
                            <button
                              className="btn-new btn-new--primary"
                              onClick={() => this.props.onClickCheckIn(ticket)}
                            >
                              Check In
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                {ticket_types?.length === 0 && (
                  <div className="d-flex justify-content-center">
                    <img
                      alt={"agent_qr_code"}
                      src={qr_code_image_url}
                      style={{ width: 250, height: 250 }}
                    />
                  </div>
                )}
              </>
            )}
            {this.props.onLoadEvents && <LoadingModal />}
          </div>
        }
        footer={
          <div className="d-flex">
            {open_for_registration &&
              tmpType &&
              registration_status === 1 &&
              (filterTrainingTypeId || type_id !== 2) &&
              ["Agent", "Personal Assistant"].includes(role) && (
                <CustomButton
                  disabled={registration_status !== 1}
                  className={"btn-new btn-new--primary mr-2"}
                  children={"Register Now"}
                  onClick={() => this.props.onClickJoinEvent(selectedData)}
                />
              )}
            <CustomButton
              className={"btn-new btn-new--outline-secondary"}
              children={"Close"}
              onClick={() => this.onClickCloseModal()}
            />
          </div>
        }
      />
    );
  };
}

export default CalendarOverlay;
