import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import Moment from "moment";

import CustomFormInput from "components/Input/formInput";
import AtlasFormGroup from "components/FormGroup/input";

import ConfirmationFormHOC from "../../../actions/confirmationForm";
import PaperFormHOC from "../../../actions/paperForm";
import UnclaimedHOC from "../../../actions/unclaimed";
import "stylesheets/containers/subsales-claims/index.scss";

class BookingDetails extends Component {
  state = {
    user_id: 0,
  };

  render = () => {
    let {
      permissionsChecker,
      selected_claim,

      onChangeUpdateSelectedClaim
    } = this.props;
    const { can_update } = permissionsChecker;
    const { is_main_info_locked } = selected_claim
    const tmpDisabled = is_main_info_locked || !can_update
    return (
      <>
        <div className="grid-control" style={{ gridGap: "1rem" }}>
          <section className="grid-half-col">
            <div className="d-flex">
              <h2 className="at-form-input__title">
                {"Confirmation Form Number"}
              </h2>
            </div>
            <CustomFormInput
              type="text"
              value={`${selected_claim.confirmation_form_number}`}
              onChangeValue={(val) => {
                onChangeUpdateSelectedClaim("confirmation_form_number", val);
              }}
              disabled={true}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"Booking Date"}</h2>
            <AtlasFormGroup
              type={"calendar"}
              value={selected_claim.booking_date || null}
              disabledDate={
                (selected_claim.claim_status !== "Draft" &&
                  selected_claim.claim_status !== "Rejected") ||
                  tmpDisabled
              }
              onSelectDate={(val) =>
                onChangeUpdateSelectedClaim(
                  "booking_date",
                  ["", null].indexOf(val) === -1
                    ? Moment(val).format("YYYY-MM-DD")
                    : null
                )
              }
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">{"SPA Date / TA Date"}</h2>
            <AtlasFormGroup
              type={"calendar"}
              maxDate={Moment().add(14, "days").toDate()}
              value={selected_claim.agreement_date || null}
              disabledDate={tmpDisabled}
              onSelectDate={(val) =>
                onChangeUpdateSelectedClaim(
                  "agreement_date",
                  ["", null].indexOf(val) === -1
                    ? Moment(val).format("YYYY-MM-DD")
                    : null
                )
              }
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">
              {"Selling Price / Monthly Rental (RM)"}
            </h2>
            <CustomFormInput
              placeholder="Enter Selling Price / Monthly Rental"
              type="number"
              disabled={tmpDisabled}
              value={`${selected_claim.net_price}`}
              onChangeValue={(val) => {
                onChangeUpdateSelectedClaim("net_price", val);
              }}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">
              {"Earnest Deposit Received (RM)"}
            </h2>
            <CustomFormInput
              type="number"
              placeholder="Enter Earnest Deposit Received"
              disabled={tmpDisabled}
              value={`${selected_claim.earnest_deposit_received_amount}`}
              onChangeValue={(val) => {
                onChangeUpdateSelectedClaim(
                  "earnest_deposit_received_amount",
                  val,
                );
              }}
            />
          </section>
        </div>
      </>
    );
  };
}

export default compose(
  ConfirmationFormHOC,
  PaperFormHOC,
  UnclaimedHOC,
)(BookingDetails);
