import React, { useEffect, useRef } from 'react';
import { MenuItem } from '@material-ui/core'
import Moment from 'moment'

import CustomButton from 'components/Button'
import AtSelectNew from 'components/Select/new'
import CustomCheckbox from 'components/Checkbox';
import AtlasRadioGroup from 'components/RadioGroup'
import ModuleFormHeader from "components/Form/header"
import CustomFormInput from 'components/Input/formInput'
import CustomImageUploader from 'components/Input/imageUpload'
import CustomDatepicker from 'components/Input/datetimepicker'

import permissionsChecker from "utils/permissionsChecker";
import { TrainingTypes } from "dictionary/eventTrainingType";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const StatusTypes = [
  { name: "Draft", value: 1 },
  { name: "Published", value: 2 },
  { name: "Cancelled", value: 3 },
];

const RadioOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const EventTrainingForm = ({
  mode,
  data,
  onChangeEditEventHOC,
  onChangeField,
  onChangeFile,
  onClose,

  eventDetails,
  getSelectedEvent,
  submitEventForm,
  branchRegions,
}) => {
  let filepond = useRef( null )
  const { can_create, can_update, can_destroy } = permissionsChecker( 'Company Events', data )

  const {
    name,
    telegram_link,
    start_date_time,
    end_date_time,
    cut_off_date,
    location,
    location_url,
    latitude,
    longitude,
    speaker,
    audience_id,
    type_id,
    description,
    entry_pass,
    photo,
    photo_file_name,
    maximum_seat,
    need_approval,
    is_limited_seat,
    event_photo_url,
    training_type_id,
    clock_in_required,
    branch_region_id,
    is_exam_link_published,
    exam_link,
    is_payment_needed,
    enable_bank_in,
    enable_payment_gateway,
    is_eligible_for_ims,
    ims_points,
    minimum_perc_of_attendance,
    status_id,
    minimum_passing_minute,
    open_for_registration,
  } = eventDetails;

  useEffect(() => {
    if (mode === "edit" && eventDetails && eventDetails.id) {
      getSelectedEvent(eventDetails.id);
    }
  }, []);

  return (
    <>
      <div className='row'>
        <div className='col-lg-4'>
          <div className='at-form__content'>
            <ModuleFormHeader
              title={ 'Status' }
              className={ 'mb-3' }
              moduleIcon={ 'atlas-send-2' } />
            <div className='grid-control'>
              <div className='grid-full-col'>
                <h2 className="at-form-input__title">Status </h2>
                <AtlasRadioGroup
                  labelKey={ 'name' }
                  horizontal={ true }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  checkedValue={ status_id }
                  containerClassName={ 'w-100' }
                  selectedRadioValue={ val => onChangeField( Number( val ), 'status_id' ) }
                  options={ StatusTypes } />
              </div>
              <div className='grid-full-col'>
                <h2 className="at-form-input__title">Eligible for IMS</h2>
                <AtlasRadioGroup
                  horizontal={ true }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  checkedValue={ is_eligible_for_ims }
                  containerClassName={ 'w-100' }
                  selectedRadioValue={ val => onChangeField( val, 'is_eligible_for_ims' ) }
                  options={ RadioOptions } />
              </div>
              {
                is_eligible_for_ims && (
                  <div className='grid-full-col'>
                    <h2 className="at-form-input__title">IMS Point</h2>
                    <CustomFormInput
                      type="text"
                      value={ ims_points }
                      disabled={ ( mode === 'edit' && !can_update ) }
                      onChangeValue={ val => onChangeField( val, 'ims_points' ) } />
                  </div>
                )
              }
              <div className='grid-full-col'>
                <h2 className="at-form-input__title">Open For Registration?</h2>
                <AtlasRadioGroup
                  horizontal={ true }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  checkedValue={ open_for_registration }
                  containerClassName={ 'w-100' }
                  selectedRadioValue={ val => onChangeField( val, 'open_for_registration' ) }
                  options={ RadioOptions } />
              </div>
              <div className='grid-full-col'>
                <h2 className="at-form-input__title">Is Approval Needed?</h2>
                <AtlasRadioGroup
                  horizontal={ true }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  checkedValue={ need_approval }
                  containerClassName={ 'w-100' }
                  selectedRadioValue={ val => onChangeField( val, 'need_approval' ) }
                  options={ RadioOptions } />
              </div>
              <div className='grid-full-col'>
                <h2 className="at-form-input__title">Is Payment Needed?</h2>
                <AtlasRadioGroup
                  horizontal={ true }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  checkedValue={ is_payment_needed }
                  containerClassName={ 'w-100' }
                  selectedRadioValue={ val => onChangeField( val, 'is_payment_needed' ) }
                  options={ RadioOptions } />
                {
                  is_payment_needed && (
                    <>
                      <CustomCheckbox
                        labelStyle={{}}
                        labelClassname={ 'mb-3' }
                        checked={ enable_payment_gateway }
                        content={ 'Payment Gateway' }
                        onChangeCheckboxValue={ e => onChangeField( e.target.checked, 'enable_payment_gateway' ) } />
                      <CustomCheckbox
                        labelStyle={{}}
                        labelClassname={ 'mb-3' }
                        checked={ enable_bank_in }
                        content={ 'Bank In' }
                        onChangeCheckboxValue={ e => onChangeField( e.target.checked, 'enable_bank_in' ) } />
                      {
                        mode === 'edit' && (
                          <div>
                            Terms & conditions
                            <CustomButton
                              containerClass={ 'd-inline m-1' }
                              className={ 'btn-new btn-new--secondary d-inline m-1' }
                              onClick={ () => onChangeEditEventHOC( true, 'showTncEditor' ) }
                              children={ 'Edit' } 
                            />
                          </div>
                        )
                      }
                    </>
                  )
                }
              </div>
              {
                !( open_for_registration && is_payment_needed ) && (
                  <>
                    <div className='grid-full-col'>
                      <h2 className="at-form-input__title">Is Seat Limited?</h2>
                      <AtlasRadioGroup
                        horizontal={ true }
                        disabled={ ( mode === 'edit' && !can_update ) }
                        checkedValue={ is_limited_seat }
                        containerClassName={ 'w-100' }
                        selectedRadioValue={ val => onChangeField( val, 'is_limited_seat' ) }
                        options={ RadioOptions } />
                      {
                        is_limited_seat && (
                          <>
                            <h2 className="at-form-input__title mt-3">Maximum Seat</h2>
                            <CustomFormInput
                              type="text"
                              value={ maximum_seat }
                              disabled={ ( mode === 'edit' && !can_update ) }
                              onChangeValue={ val => onChangeField( val, 'maximum_seat' ) } />
                          </>
                        )
                      }
                    </div>
                    <div className='grid-full-col'>
                      <h2 className="at-form-input__title">Clock In Required?</h2>
                      <AtlasRadioGroup
                        horizontal={ true }
                        disabled={ ( mode === 'edit' && !can_update ) }
                        checkedValue={ clock_in_required }
                        containerClassName={ 'w-100' }
                        selectedRadioValue={ val => onChangeField( val, 'clock_in_required' ) }
                        options={ RadioOptions } />
                    </div>
                  </>
                )
              }
              {
                type_id === 2 && (
                  <div className='grid-full-col'>
                    <h2 className="at-form-input__title">Publish Exam Link?</h2>
                    <AtlasRadioGroup
                      horizontal={ true }
                      disabled={ ( mode === 'edit' && !can_update ) }
                      checkedValue={ is_exam_link_published }
                      containerClassName={ 'w-100' }
                      selectedRadioValue={ val => onChangeField( val, 'is_exam_link_published' ) }
                      options={ RadioOptions } />
                    {
                      is_exam_link_published && (
                        <>
                          <h2 className="at-form-input__title">Exam Link</h2>
                          <CustomFormInput
                            type="text"
                            value={ exam_link }
                            disabled={ ( mode === 'edit' && !can_update ) }
                            onChangeValue={ val => onChangeField( val, 'exam_link' ) } />
                        </>
                      )
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className='col-lg-8'>
          <div className='at-form__content'>
            <ModuleFormHeader
              title={ 'Details' }
              className={ 'mb-3' }
              moduleIcon={ 'atlas-document-text' } />
            <div className='grid-control'>
              <div className='grid-full-col'>
                <h2 className="at-form-input__title" required>Event Name</h2>
                <CustomFormInput
                  type="text"
                  value={ name }
                  required={ true }
                  placeholder={ 'Event Name' }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  onChangeValue={ val => onChangeField( val, 'name' ) } />
              </div>
              <div className='grid-full-col'>
                <h2 className="at-form-input__title">Description</h2>
                <CustomFormInput
                  type="text"
                  value={ description }
                  placeholder={ 'Description' }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  onChangeValue={ val => onChangeField( val, 'description' ) } />
              </div>
              <div className='grid-full-col'>
                <h2 className="at-form-input__title">Event Image</h2>
                <CustomImageUploader
                  fullWidth
                  required={ true }
                  ref={ ref => filepond = ref }
                  files={ ( event_photo_url || photo )
                    ? [{
                      source: event_photo_url || photo,
                      name: photo_file_name || ''
                    }] 
                    : [] 
                  }
                  allowMultiple={ false }
                  acceptedFileTypes={ [ "image/png", "image/jpeg", "image/jpg" ] }
                  accept="image/png, image/jpeg, image/jpg"
                  maxFiles={ 1 }
                  onRemoveFile={ () => {
                    if( event_photo_url )
                      onChangeField( null, "event_photo_url" )
                    if( photo )
                      onChangeField( "", "photo" )
                  }}
                  onChangeFile={ fileItems => onChangeFile( fileItems, "photo" ) }/>
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Event / Activity</h2>
                <AtSelectNew
                  value={ type_id }
                  onChange={ e => onChangeField( e.target.value , "type_id" ) }
                  style={{ display: 'flex', flexDirection: 'column' }} >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value={ 1 }>Event</MenuItem>
                  <MenuItem value={ 2 }>Training</MenuItem>
                </AtSelectNew>
              </div>
              {
                type_id === 2 && (
                  <div className='grid-half-col'>
                    <h2 className="at-form-input__title">Training Type</h2>
                    <AtSelectNew
                      value={ training_type_id }
                      disabled={ ( mode === 'edit' && !can_update ) }
                      onChange={ e => onChangeField( e.target.value , "training_type_id" ) }
                      style={{ display: 'flex', flexDirection: 'column' }} >
                      {
                        TrainingTypes.map( item => {
                          return (
                            <MenuItem key={ item.id } value={ item.value }>{ item.name }</MenuItem>
                          )
                        })
                      }
                    </AtSelectNew>
                  </div>
                )
              }
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Speaker</h2>
                <CustomFormInput
                  type="text"
                  value={ speaker }
                  placeholder={ 'Speaker' }
                  disabled={ mode === 'edit' && !can_update }
                  onChangeValue={ val => onChangeField( val, 'speaker' ) } />
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Audience </h2>
                <AtSelectNew
                  value={ audience_id }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  onChange={ e => onChangeField( e.target.value , "audience_id" ) }
                  style={{ display: 'flex', flexDirection: 'column' }} >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value={ "1" }>Agent</MenuItem>
                  <MenuItem value={ "2" }>Buyer</MenuItem>
                  <MenuItem value={ "3" }>Other</MenuItem>
                </AtSelectNew>
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Entry Pass</h2>
                <CustomFormInput
                  type="text"
                  value={ entry_pass }
                  placeholder={ 'Entry Pass' }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  onChangeValue={ val => onChangeField( val, 'entry_pass' ) } />
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Telegram Link</h2>
                <CustomFormInput
                  type="text"
                  value={ telegram_link }
                  placeholder={ 'Telegram Link' }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  onChangeValue={ val => onChangeField( val, 'telegram_link' ) } />
              </div>
            </div>
          </div>
          <div className='at-form__content'>
            <ModuleFormHeader
              title={ 'Location' }
              className={ 'mb-3' }
              moduleIcon={ 'atlas-location' } />
            <div className='grid-control'>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Branch </h2>
                <AtSelectNew
                  value={ branch_region_id || "" }
                  onChange={ e => onChangeField( e.target.value , "branch_region_id" ) }
                  style={{ display: 'flex', flexDirection: 'column' }} >
                  <MenuItem value="" disabled>Select Branch</MenuItem>
                  {
                    branchRegions.map( item => {
                      return (
                        <MenuItem key={ item.id } value={ item.id }>{ item.name }</MenuItem>
                      )
                    })
                  }
                </AtSelectNew>
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Venue</h2>
                <CustomFormInput
                  type="text"
                  value={ location }
                  placeholder={ 'Millerz Square, Kuala Lumpur' }
                  disabled={ mode === 'edit' && !can_update }
                  onChangeValue={ val => onChangeField( val, 'location' ) } />
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Location URL</h2>
                <CustomFormInput
                  type="text"
                  value={ location_url }
                  placeholder={ "URL generated from google map, eg https://www.google.com/maps/place/Millerz+Square+@+Old+Klang+Road+-+Elmwood+Tower+E/@3.0902672,101.6690903,17z/data=!3m1!4b1!4m6!3m5!1s0x31cc4bf5034288d7:0xf79accb0d00b74f4!8m2!3d3.0902619!4d101.6739612!16s%2Fg%2F11rww9k00_?entry=ttu" }
                  disabled={ mode === 'edit' && !can_update }
                  onChangeValue={ val => onChangeField( val, 'location_url' ) } />
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Latitude</h2>
                <CustomFormInput
                  type="number"
                  value={ latitude ?? '' }
                  placeholder={ 'Latitude' }
                  disabled={ mode === 'edit' && !can_update }
                  onChangeValue={ val => onChangeField( val, 'latitude' ) } />
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Longitude</h2>
                <CustomFormInput
                  type="number"
                  value={ longitude ?? '' }
                  placeholder={ 'Longitude' }
                  disabled={ mode === 'edit' && !can_update }
                  onChangeValue={ val => onChangeField( val, 'longitude' ) } />
              </div>
            </div>
          </div>
          <div className='at-form__content'>
            <ModuleFormHeader
              title={ 'Date and Time' }
              className={ 'mb-3' }
              moduleIcon={ 'atlas-calendar' } />
            <div className='grid-control'>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Start Date Time</h2>
                <div className='at-form-input'>
                  <CustomDatepicker
                    id={ 'start_date_time' }
                    type="datetime-local"
                    value={ Moment(start_date_time) }
                    disabled={ ( mode === 'edit' && !can_update ) }
                    onChange={ value => onChangeField( value, 'start_date_time' ) }
                  />
                </div>
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">End Date Time</h2>
                <div className='at-form-input'>
                  <CustomDatepicker
                    id={ 'end_date_time' }
                    type="datetime-local"
                    value={ Moment(end_date_time) }
                    minDateTime={ Moment( start_date_time ).format( "YYYY-MM-DDTHH:mm" ) }
                    disabled={ ( mode === 'edit' && !can_update ) }
                    onChange={ value => onChangeField( value, 'end_date_time' ) } />
                </div>
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Cut Off Date</h2>
                <div className='at-form-input'>
                  <CustomDatepicker
                    type="date"
                    id={ 'cut_off_date' }
                    value={ Moment(cut_off_date) }
                    minDate={ Moment( new Date()).format( "YYYY-MM-DD" ) }
                    disabled={ ( mode === 'edit' && !can_update ) }
                    onChange={ value => onChangeField( value, 'cut_off_date' ) }/>
                </div>
              </div>
              <div className='grid-half-col'>
                <h2 className="at-form-input__title">Minimum Passing Minute</h2>
                <CustomFormInput
                  type="text"
                  value={ minimum_passing_minute }
                  disabled={ ( mode === 'edit' && !can_update ) }
                  onChangeValue={ val => onChangeField( val, 'minimum_passing_minute' ) } />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        {
          (( mode === 'create' && can_create ) 
          || ( mode === 'edit' && can_update )) && (
            <CustomButton
              className={ `btn-new btn-new--${mode === 'create' ? 'primary' : 'success'}` }
              onClick={ () => submitEventForm() }
              children={ mode === 'edit' ? 'Update' : 'Create' } 
            />
          )
        }
        <button 
          type={ 'button' }
          className="btn-new btn-new--outline-secondary"
          onClick={ () => onClose() }>
          { mode === 'edit' ? 'Close' : 'Cancel' }
        </button>
      </div>
    </>
  );
};

export default EventTrainingForm;
