import React, { useState, useMemo } from "react";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { IoMdHelpCircle } from "react-icons/io";

import LoadingModal from "components/LoadingModal";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import CountryCodeContent from "components/country_code";

const CreateForm = ({
  showLoading,

  onClickClose,
  onClickSubmit,
}) => {
  const FieldData = [
    { label: "full name", value: "full_name", input_type: "text" },
    { label: "email", value: "email", input_type: "text" },
    {
      label: "mobile contact",
      value: "mobile_contact_number",
      input_type: "number",
      instruction:
        "Phone number must include country code, for example, a Malaysian phone number will be like 6012345678",
    },
    { label: "current address", value: "current_address", input_type: "text" },
  ];

  const [showCountryCodeModal, onToggleCountryCode] = useState(false);
  const [createFormData, onChangeFormData] = useState({
    full_name: "",
    email: "",
    mobile_contact_number: "",
    current_address: "",
  });

  let diabledSubmit = useMemo(() => {
    return _.keys(createFormData).some(
      (key) =>
        key !== "current_address" &&
        (!createFormData[key] || createFormData[key].length < 1),
    );
  }, [createFormData]);

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(createFormData);
    temp[context] = val;

    onChangeFormData(temp);
  };

  return (
    <>
      <ModalDialog
        title={"Create Client"}
        onClose={onClickClose}
        children={
          <>
            <div className={"grid-control grid_gap-0"}>
              {FieldData.map((item) => (
                <section key={item.value} className="grid-full-col">
                  <div className="d-flex">
                    <h2
                      className="at-form-input__title text-capitalize"
                      required
                    >
                      {item.label}
                    </h2>
                    {item.value === "mobile_contact_number" && (
                      <Tooltip
                        title={
                          "You may refer the country code provided by clicking this button."
                        }
                      >
                        <IoMdHelpCircle
                          style={{
                            cursor: "pointer",
                            marginLeft: 8,
                            width: 20,
                            height: 20,
                          }}
                          onClick={() => onToggleCountryCode(true)}
                        />
                      </Tooltip>
                    )}
                  </div>
                  <CustomFormInput
                    type={item.input_type}
                    numericOnly={item.input_type === "number"}
                    value={createFormData[item.value]}
                    required={true}
                    onChangeValue={(val) => onChangeField(val, item.value)}
                    placeholder={`Please enter client's ${item.label}`}
                  />
                </section>
              ))}
            </div>
          </>
        }
        footer={
          <div className="d-flex g-3">
            <button
              disabled={diabledSubmit}
              onClick={() => onClickSubmit(createFormData)}
              className={"btn-new btn-new--success"}
            >
              Create
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onClickClose()}
            >
              Cancel
            </button>
          </div>
        }
      />
      {showCountryCodeModal && (
        <CountryCodeContent onClose={() => onToggleCountryCode(false)} />
      )}
      {showLoading && <LoadingModal />}
    </>
  );
};

export default CreateForm;
