import { CKEditor } from "@ckeditor/ckeditor5-react";
import { MenuItem } from "@material-ui/core";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import _ from "lodash";
import Moment from "moment";
import { useEffect, useState } from "react";
import { BiCalendarAlt } from "react-icons/bi";

import CustomFormInput from "components/Input/formInput";
import CustomSelect from "components/Select/new";
import CustomTypeahead from "components/Typeahead/new";
import CustomUpload from "components/Upload";
import DatePicker from "components/Input/datetimepicker";

import defaultAvatar from "assets/images/av-male.jpg";
import {
  user as UserDictionary,
  board_registration_options,
} from "dictionary/index";
import { convertImgToBase64URL } from "utils/imgBase64";

const DatepickerInput = ({ ...props }) => (
  <div className={"at-form-input profile-datepicker-form-control"}>
    <input type={"text"} {...props} readOnly />
    <BiCalendarAlt />
  </div>
);

const Profile = ({
  avatar_url,
  full_name,
  fullNameError,
  display_name,
  displayNameError,
  email,
  emailError,
  mobile_contact_number,
  role_id,
  identification_number,
  referrer,
  current_address,
  agent_registration_attributes,
  slug,
  wechat_id,
  branch_id,
  facebook_url,
  telegram_url,
  instagram_url,
  user_branches = [],
  country_id,
  team_id,
  race_id,
  status_id,
  youtube_playlist_url,
  dictionaryReducer,
  date_of_birth,
  profileRole,

  board_registration_type_id,
  board_registration_number,

  branches,
  onLoadAgent,
  agentList,
  selectedUser,
  branchesByCountry,

  onChangeTypeaheadSearch,
  onChangeSubBranch,
  onRemoveSubBranch,
  onChangeEmail,
  onChangeState,
}) => {
  const { team, countries = [] } = dictionaryReducer;

  const [subBranches, setSubBranches] = useState([]);

  useEffect(() => {
    let temp = _.cloneDeep(branches);
    if (branch_id) {
      _.remove(temp, (item) => item.id === branch_id);
    }

    setSubBranches(temp);
  }, [branch_id, user_branches]);

  const renderBoardRegistrationPrefix = () => {
    switch (board_registration_type_id) {
      case 1:
        return "REN";
      case 2:
        return "PEA";
      case 3:
        return "E";
      case 4:
        return "PV";
      case 5:
        return "V";
      default:
        return "";
    }
  };

  const formInputProps = {
    type: "text",
    className: "mb-0",
    containerStyle: { height: 45 },
  };

  return (
    <>
      <section className="grid-full-col at-profile__avatars">
        <h4 className="at-form-input__title">Avatar</h4>
        <div className="at-profile__avatar-images">
          <CustomUpload
            className="at-profile__main-avatar"
            containerStyle={{ width: 200, height: 200 }}
            imageUrl={
              (avatar_url && !_.includes(avatar_url, "missing.png"))
                ? avatar_url
                : defaultAvatar
            }
            accept={`image/png,image/jpg,image/jpeg'`}
            uploadImage={(fileVal) => {
              Promise.all([
                onChangeState(fileVal, "tempFile"),
              ]).then(() => {
                onChangeState(true, "loading")
              })
              Promise.all([
                convertImgToBase64URL(
                  fileVal,
                  (val) => onChangeState(val, "selectedEdit"),
                  "img/png"
                )
              ]).then(() => {
                onChangeState(false, "loading")
              })
              
            }}
          />
        </div>
      </section>

      <section className="grid-full-col mb-20">
        <p className="at-avatar-note">
          <strong>Note:</strong> The avatar will be displayed on the website.
        </p>
      </section>

      <section className="grid-half-col">
        <h2 className="at-form-input__title" required>
          Full Name
        </h2>
        <CustomFormInput
          {...formInputProps}
          value={full_name}
          onChangeValue={(val) =>
            onChangeState(val, "full_name", "fullNameError")
          }
          required={true}
          inputError={fullNameError}
        />
      </section>

      <section className="grid-half-col">
        <h2 className="at-form-input__title" required>
          Display Name
        </h2>
        <CustomFormInput
          {...formInputProps}
          value={display_name}
          onChangeValue={(val) =>
            onChangeState(val, "display_name", "displayNameError")
          }
          required={true}
          inputError={displayNameError}
        />
      </section>

      <section className="grid-half-col">
        <h2 className="at-form-input__title" required>
          Email
        </h2>
        <CustomFormInput
          {...formInputProps}
          value={email}
          onChangeValue={(val) => onChangeEmail(val)}
          required={true}
          inputError={emailError}
        />
      </section>

      <section className="grid-half-col">
        <h2 className="at-form-input__title" required>
          Mobile Contact
        </h2>
        <CustomFormInput
          {...formInputProps}
          value={mobile_contact_number}
          onChangeValue={(val) => onChangeState(val, "mobile_contact_number")}
          required={true}
        />
      </section>

      {[5, 7].indexOf(role_id) > -1 && (
        <section className="grid-half-col">
          <h2 className="at-form-input__title">Date Of Birth</h2>
          <div className="at-form-input">
            <DatePicker
              maxDate={new Date(Moment().subtract(18, "years"))}
              customInput={<DatepickerInput />}
              value={Moment(date_of_birth)}
              onChange={(val) => onChangeState(Moment(val).format("DD MMM YYYY"), "date_of_birth")}
            />
          </div>
        </section>
      )}
      {[3, 4, 8, 6].indexOf(role_id) < 0 && (
        <>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              NRIC/Passport No.
            </h2>
            <CustomFormInput
              {...formInputProps}
              value={identification_number}
              onChangeValue={(val) =>
                onChangeState(val, "identification_number")
              }
              required={true}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Referrer</h2>
            <CustomTypeahead
              containerClassName={"m-0"}
              containerStyle={{ height: 40 }}
              disabled={onLoadAgent}
              typeaheadId={"internal_agent"}
              options={agentList || []}
              filterBy={["full_name", "email", "mobile_contact_number"]}
              selectedValue={referrer ? [referrer] : []}
              labelKey={"full_name"}
              helpText={"e.g. Full Name, Email, Mobile Number"}
              onSearch={(val) => onChangeTypeaheadSearch(val)}
              onSelect={(val) =>
                val && val.length > 0 && onChangeState(val[0], "referrer")
              }
              childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
              highlighterData={[
                (option) => `Team: ${option.team_name}`,
                <br />,
                (option) =>
                  `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
              ]}
              isLoading={onLoadAgent}
            />
          </section>
          {role_id === 5 && (
            <section className="grid-half-col">
              <h2 className="at-form-input__title" required>
                Current Address
              </h2>
              <CustomFormInput
                {...formInputProps}
                value={current_address}
                required={true}
                onChangeValue={(val) => onChangeState(val, "current_address")}
              />
            </section>
          )}
        </>
      )}
      {role_id === 5 && (
        <>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Emergency Person Name
            </h2>
            <CustomFormInput
              {...formInputProps}
              value={agent_registration_attributes.emergency_person_name}
              required={true}
              onChangeValue={(val) =>
                onChangeState(
                  val,
                  "agent_registration_attributes",
                  null,
                  "emergency_person_name"
                )
              }
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Emergency Contact No.
            </h2>
            <CustomFormInput
              {...formInputProps}
              value={agent_registration_attributes.emergency_contact_number}
              required={true}
              onChangeValue={(val) =>
                onChangeState(
                  val,
                  "agent_registration_attributes",
                  null,
                  "emergency_contact_number"
                )
              }
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Public Profile Username
            </h2>
            <CustomFormInput
              {...formInputProps}
              value={slug}
              required={true}
              onChangeValue={(val) => onChangeState(val, "slug")}
            />
          </section>
        </>
      )}
      {[5, 7].indexOf(role_id) > -1 && (
        <section className="grid-half-col">
          <h2 className="at-form-input__title">WeChat ID</h2>
          <CustomFormInput
            {...formInputProps}
            value={wechat_id}
            required={false}
            onChangeValue={(val) => onChangeState(val, "wechat_id")}
          />
        </section>
      )}
      {role_id === 5 && (
        <>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Youtube Playlist Link</h2>
            <CustomFormInput
              {...formInputProps}
              value={youtube_playlist_url}
              required={false}
              onChangeValue={(val) =>
                onChangeState(val, "youtube_playlist_url")
              }
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Facebook Link</h2>
            <CustomFormInput
              {...formInputProps}
              value={facebook_url}
              required={false}
              onChangeValue={(val) => onChangeState(val, "facebook_url")}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Telegram Link</h2>
            <CustomFormInput
              {...formInputProps}
              value={telegram_url}
              required={false}
              onChangeValue={(val) => onChangeState(val, "telegram_url")}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Instagram Link</h2>
            <CustomFormInput
              {...formInputProps}
              value={instagram_url}
              required={false}
              onChangeValue={(val) => onChangeState(val, "instagram_url")}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Board Registration Type</h2>
            <CustomSelect
              value={board_registration_type_id}
              onChange={(e) =>
                onChangeState(e.target.value, "board_registration_type_id")
              }
            >
              {board_registration_options.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title">Board Registration Number</h2>
            <CustomFormInput
              {...formInputProps}
              value={board_registration_number}
              prefix={renderBoardRegistrationPrefix()}
              required={false}
              onChangeValue={(val) =>
                onChangeState(val, "board_registration_number")
              }
            />
          </section>
        </>
      )}
      {[3, 4, 5, 6].indexOf(role_id) > -1 && (
        <>
          <div className="grid-third-col">
            <h2 className="at-form-input__title">Branch</h2>
            <CustomSelect
              value={branch_id}
              onChange={(e) => onChangeState(e.target.value, "branch_id")}
            >
              {branchesByCountry.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
          <div className="grid-third-col">
            <h2 className="at-form-input__title">
              Sub-Branch
              <span style={{ marginLeft: 5, fontSize: 12 }}>
                (For QR based door access)
              </span>
            </h2>
            <CustomSelect
              multiple={true}
              value={user_branches}
              onChange={(e) => onChangeSubBranch(e.target.value)}
            >
              {subBranches.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        </>
      )}
      {role_id === 5 && (
        <div className="grid-third-col">
          <h2 className="at-form-input__title">Country</h2>
          <CustomSelect
            value={country_id}
            onChange={(e) => onChangeState(e.target.value, "country_id")}
          >
            {countries.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
      )}
      {profileRole !== "Admin" && (
        <div className="grid-third-col">
          <h2 className="at-form-input__title">Role</h2>
          <CustomSelect
            value={role_id}
            onChange={(e) => onChangeState(e.target.value, "role_id")}
          >
            {UserDictionary.roles.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
      )}

      <div className="grid-third-col">
        <h2 className="at-form-input__title">Team</h2>
        <CustomSelect
          value={team_id}
          onChange={(e) => onChangeState(e.target.value, "team_id")}
        >
          {team?.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </CustomSelect>
      </div>

      {role_id === 5 && country_id === 1 && (
        <div className="grid-third-col">
          <h2 className="at-form-input__title">Race</h2>
          <CustomSelect
            value={race_id}
            onChange={(e) => onChangeState(e.target.value, "race_id")}
          >
            <MenuItem value={1}>Malay</MenuItem>
            <MenuItem value={2}>Chinese</MenuItem>
            <MenuItem value={3}>Indian</MenuItem>
            <MenuItem value={4}>Others</MenuItem>
          </CustomSelect>
        </div>
      )}

      {profileRole !== "Admin" && (
        <div className="grid-third-col">
          <h2 className="at-form-input__title">Status</h2>
          <CustomSelect
            value={status_id}
            onChange={(e) => onChangeState(e.target.value, "status_id")}
          >
            {UserDictionary.statuses.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </div>
      )}
      {role_id === 5 && (
        <div className="grid-full-col">
          <h2 className="at-form-input__title">Biography</h2>
          <CKEditor
            editor={ClassicEditor}
            data={selectedUser.description}
            config={{ toolbar: [[]] }}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChangeState(data, "description");
            }}
          />
        </div>
      )}
    </>
  );
};

export default Profile;
