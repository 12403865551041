import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const AnalyticCenterHOC = (WrappedComponent) => {
  class HOCWrappedComponent extends Component {
    state = {
      loading: false,

      selectedTab: "Active User",
      activeUser: {
        data: [],
        chart: {},
      },
      topActivities: "",
      selectedTopActivity: {
        data: [],
        chart: {},
      },
      activityFilter: "",
      activityOptions: [],
      folderClick: "",
      selectedFolderClick: {
        data: [],
        chart: {},
      },
      folderClickFilter: "",
      folderClickOptions: [],
      searchActivity: {
        data: [],
        chart: {},
      },
      newAgent: {
        data: [],
        chart: {},
      },
      motdStats: {
        data: [],
        chart: {},
      },
      newsfeedStats: {
        data: {},
        chart: {},
      },
      localeChanges: {
        data: [],
        locales: [],
      }
    };

    load = (param) => this.setState({ loading: param });

    onChangeAnalyticHOC = (val, context) => this.setState({ [context]: val });

    getActiveUser = (search) => {
      let tmp = "";
      tmp = search.replace(/[\q[\]']/g, "");

      Get(
        `/admin/analytics/active_users?${tmp}`,
        this.getActiveUserSuccess,
        this.getActiveUserError,
        this.load,
      );
    };
    getActiveUserSuccess = (payload) => {
      let labels = [];
      let dataSet = {
        web: [],
        android: [],
        ios: [],
      };
      dataSet.web = [];
      dataSet.android = [];
      dataSet.ios = [];

      payload.map((item) => {
        item.date = item.date.replace("-", " ");
        item.web =
          _.find(item.total_users, { source: "Web" })?.active_user_count || 0;
        item.android =
          _.find(item.total_users, { source: "Mobile-android" })
            ?.active_user_count || 0;
        item.ios =
          _.find(item.total_users, { source: "Mobile-ios" })
            ?.active_user_count || 0;
        labels.push(item.date.replace("-", " "));
        dataSet.web.push(
          _.find(item.total_users, { source: "Web" })?.active_user_count || 0,
        );
        dataSet?.android.push(
          _.find(item.total_users, { source: "Mobile-android" })
            ?.active_user_count || 0,
        );
        dataSet.ios.push(
          _.find(item.total_users, { source: "Mobile-ios" })
            ?.active_user_count || 0,
        );
      });

      this.setState({
        activeUser: {
          data: payload,
          chart: {
            labels: labels,
            datasets: [
              {
                label: "Web",
                backgroundColor: "rgb(255, 99, 132)",
                data: dataSet.web,
              },
              {
                label: "Android",
                backgroundColor: "rgb(76, 192, 192)",
                data: dataSet.android,
              },
              {
                label: "IOS",
                backgroundColor: "rgb(154, 102, 255)",
                data: dataSet.ios,
              },
            ],
          },
        },
      });
    };
    getActiveUserError = (error) => requestError(error);

    processTopActivity = (data) => {
      let labels = [];
      let dataSet = {
        total_hit: [],
        total_unique_users: [],
      };
      if (data && data.length > 0) {
        let temp = _.cloneDeep(data);
        let selectedActivity = _.find(
          temp,
          (item) => item.date === this.state.activityFilter,
        );
        selectedActivity.top_ten_activities.map((item) => {
          labels.push(item.activity.replace("api/v1/", ""));
          dataSet.total_hit.push(item.total_hits);
          dataSet.total_unique_users.push(item.total_unique_users);
        });
        this.setState({
          selectedTopActivity: {
            data: selectedActivity.top_ten_activities,
            chart: {
              labels: labels,
              datasets: [
                {
                  label: "Total Hit",
                  backgroundColor: "rgb(255, 99, 132)",
                  data: dataSet.total_hit,
                },
                {
                  label: "Total Unique User",
                  backgroundColor: "rgb(75, 192, 192)",
                  data: dataSet.total_unique_users,
                },
              ],
            },
          },
        });
      }
    };

    processFolderClick = (data) => {
      let labels = [];
      let dataSet = {
        total_hit: [],
        total_unique_users: [],
      };
      if (data && data.length > 0) {
        let temp = _.cloneDeep(data);
        let selectedFolderClick = _.find(
          temp,
          (item) => item.date === this.state.folderClickFilter,
        );
        selectedFolderClick.clicks.map((item) => {
          labels.push(item.file_path);
          dataSet.total_hit.push(item.total_hits);
          dataSet.total_unique_users.push(item.unique_users);
        });
        this.setState({
          selectedFolderClick: {
            data: selectedFolderClick.clicks,
            chart: {
              labels: labels,
              datasets: [
                {
                  label: "Total Hit",
                  backgroundColor: "rgb(255, 99, 132)",
                  data: dataSet.total_hit,
                },
                {
                  label: "Total Unique User",
                  backgroundColor: "rgb(75, 192, 192)",
                  data: dataSet.total_unique_users,
                },
              ],
            },
          },
        });
      }
    };

    getTopActivities = (search) => {
      let tmp = "";
      tmp = search.replace(/[\q[\]']/g, "");

      Get(
        `/admin/analytics/top_activities?${tmp}`,
        this.getTopActivitiesSuccess,
        this.getTopActivitiesError,
        this.load,
      );
    };
    getTopActivitiesSuccess = (payload) => {
      let temp = [];
      payload.map((item) => {
        item.date = item.date = item.date.replace("-", " ");
        temp.push(item.date);
      });
      this.setState(
        {
          topActivities: payload,
          activityFilter: temp[0],
          activityOptions: temp,
        },
        () => this.processTopActivity(payload),
      );
    };
    getTopActivitiesError = (error) => requestError(error);

    getFolderClick = (search) => {
      let tmp = "";
      tmp = search.replace(/[\q[\]']/g, "");
      tmp = tmp.replace("uery", "query");

      Get(
        `/admin/analytics/folder_clicks?${tmp}`,
        this.getFolderClickSuccess,
        this.getFolderClickError,
        this.load,
      );
    };
    getFolderClickSuccess = (payload) => {
      let temp = [];
      payload.map((item) => {
        item.date = item.date.replace("-", " ");
        temp.push(item.date);
      });
      this.setState(
        {
          folderClick: payload,
          folderClickFilter: temp[0],
          folderClickOptions: temp,
        },
        () => this.processFolderClick(payload),
      );
    };
    getFolderClickError = (error) => requestError(error);

    getNewAgent = (search) => {
      let tmp = "";
      tmp = search.replace(/[\q[\]']/g, "");

      Get(
        `/admin/analytics/new_agents?${tmp}`,
        this.getNewAgentSuccess,
        this.getNewAgentError,
        this.load,
      );
    };
    getNewAgentSuccess = (payload) => {
      let labels = [];
      let dataSet = {
        web: [],
        android: [],
        ios: [],
      };
      payload.map((item) => {
        item.date = item.date.replace("-", " ");
        item.web = _.find(item.new_users, { source: "Web" })
          ? _.find(item.new_users, { source: "Web" }).total_count
          : 0;
        item.android = _.find(item.new_users, { source: "Mobile-android" })
          ? _.find(item.new_users, { source: "Mobile-android" }).total_count
          : 0;
        item.ios = _.find(item.new_users, { source: "Mobile-ios" })
          ? _.find(item.new_users, { source: "Mobile-ios" }).total_count
          : 0;
        labels.push(item.date.replace("-", " "));
        dataSet.web.push(
          _.find(item.new_users, { source: "Web" })
            ? _.find(item.new_users, { source: "Web" }).total_count
            : 0,
        );
        dataSet.android.push(
          _.find(item.new_users, { source: "Mobile-android" })
            ? _.find(item.new_users, { source: "Mobile-android" }).total_count
            : 0,
        );
        dataSet.ios.push(
          _.find(item.new_users, { source: "Mobile-ios" })
            ? _.find(item.new_users, { source: "Mobile-ios" }).total_count
            : 0,
        );
      });
      this.setState({
        newAgent: {
          data: payload,
          chart: {
            labels: labels,
            datasets: [
              {
                label: "Web",
                backgroundColor: "rgb(255, 99, 132)",
                data: dataSet.web,
              },
              {
                label: "Android",
                backgroundColor: "rgb(75, 192, 192)",
                data: dataSet.android,
              },
              {
                label: "IOS",
                backgroundColor: "rgb(153, 102, 255)",
                data: dataSet.ios,
              },
            ],
          },
        },
      });
    };
    getNewAgentError = (error) => requestError(error);

    getSearchActivity = (search) => {
      let tmp = "";
      tmp = search.replace(/[\q[\]']/g, "");
      tmp = tmp.replace("uery", "query[]");

      Get(
        `/admin/analytics/search_activity?${tmp}`,
        this.getSearchActivitySuccess,
        this.getSearchActivityError,
        this.load,
      );
    };
    getSearchActivitySuccess = (payload) => {
      let labels = [];
      let dataSet = {
        total_hit: [],
        unique_user: [],
      };
      payload.map((item) => {
        if (item.activities && item.activities.length > 0) {
          item.action = item.activities[0].action;
          item.total_hits = item.activities[0].total_hits;
          item.unique_users = item.activities[0].unique_users;
        } else {
          item.action = "";
          item.total_hits = 0;
          item.unique_users = 0;
        }
        labels.push(item.date);
        dataSet.total_hit.push(item.total_hits);
        dataSet.unique_user.push(item.unique_users);
      });
      this.setState({
        searchActivity: {
          data: payload,
          chart: {
            labels: labels,
            datasets: [
              {
                label: "Total Hit",
                backgroundColor: "rgb(255, 99, 132)",
                data: dataSet.total_hit,
              },
              {
                label: "Total Unique User",
                backgroundColor: "rgb(75, 192, 192)",
                data: dataSet.unique_user,
              },
            ],
          },
        },
      });
    };
    getSearchActivityError = (error) => requestError(error);

    getMOTDStats = (search) => {
      let tmp = "";
      tmp = search.replace(/[\q[\]']/g, "");

      Get(
        `/admin/quote_audio_stats/latest?${tmp}`,
        this.getMOTDStatsSuccess,
        this.getMOTDStatsError,
        this.load,
      );
    };
    getMOTDStatsSuccess = (payload) => {
      let tempData = [];
      let tempLabels = [];
      let tempWebTotal = [];
      let tempAppTotal = [];

      Object.keys(payload).map((key) => {
        tempLabels.push(key);
        tempData.push({
          date: key,
          web_total: payload[key].web_total,
          app_total: payload[key].app_total,
        });
        tempWebTotal.push(payload[key].web_total);
        tempAppTotal.push(payload[key].app_total);
      });

      this.setState({
        motdStats: {
          data: tempData,
          chart: {
            labels: tempLabels,
            datasets: [
              {
                stack: "yAxis",
                data: tempAppTotal,
                backgroundColor: "rgba(32,182,167, 0.5 )",
                label: "Total play count on App",
                fill: true,
              },
              {
                stack: "yAxis",
                data: tempWebTotal,
                backgroundColor: "rgb(238,109,77, 0.5)",
                label: "Total play count on Web",
                fill: true,
              },
            ],
          },
        },
      });
    };
    getMOTDStatsError = (error) => requestError(error);

    getNewsfeedStats = (search) => {
      let tmp = "";
      tmp = search.replace(/[\q[\]']/g, "");

      Get(
        `/admin/analytics/newsfeed?${tmp}`,
        this.getNewsfeedStatsSuccess,
        this.getNewsfeedStatsError,
        this.load,
      );
    };
    getNewsfeedStatsSuccess = (payload) => {
      let labels = [];
      let dataSet = {
        views_count: [],
      };

      payload.data.map((item) => {
        labels.push(`${item.publish_at} ${item.title}`);
        dataSet.views_count.push(item.views_count);
      });

      this.setState({
        newsfeedStats: {
          data: payload.data,
          chart: {
            labels: labels,
            datasets: [
              {
                label: "View Count",
                backgroundColor: "rgb(255, 99, 132)",
                data: dataSet.views_count,
              },
            ],
          },
        },
      });
    };
    getNewsfeedStatsError = (error) => requestError(error);

    getLocaleChange = (search) => {
      let tmp = "";
      tmp = search.replace(/[\q[\]']/g, "");

      Get(
        `/admin/analytics/locale_changes?${tmp}`,
        this.getLocaleChangeSuccess,
        this.getLocaleChangeError,
        this.load,
      );
    }
    getLocaleChangeSuccess = (payload) => this.setState({ localeChanges: payload })
    getLocaleChangeError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadAnalysis={this.state.loading}
            getNewAgent={this.getNewAgent}
            getMOTDStats={this.getMOTDStats}
            getActiveUser={this.getActiveUser}
            getFolderClick={this.getFolderClick}
            getLocaleChange={this.getLocaleChange}
            getNewsfeedStats={this.getNewsfeedStats}
            getTopActivities={this.getTopActivities}
            getSearchActivity={this.getSearchActivity}
            processTopActivity={this.processTopActivity}
            processFolderClick={this.processFolderClick}
            onChangeAnalyticHOC={this.onChangeAnalyticHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(HOCWrappedComponent);
};

export default AnalyticCenterHOC;
