import React, { Component } from "react";
import { compose } from "redux";

import CustomFormInput from "components/Input/formInput";
import LoadingModal from "components/LoadingModal";
import AtlasIcon from "components/Icon/atlasIcon";

import VersionCheckHOC from "actions/versionCheck";
import PasswordHOC from "./actions/password";

import { initGA } from "utils/ga";


const passwordChecks = password => [
  {
    label: "Lowercase characters",
    condition: /[a-z]/.test(password),
  },
  {
    label: "Uppercase characters",
    condition: /[A-Z]/.test(password),
  },
  {
    label: "Digit number",
    condition: /\d/.test(password),
  },
  {
    label: "Special character (@#$%^&+!=)",
    condition: /[@#$%^&+!=]/.test(password),
  },
  {
    label: "12 characters minimum",
    condition: password.length >= 12,
  }
]

class ChangePassword extends Component {
  componentDidMount = () => initGA("/dashboard/profile/change-password");

  onChangeNewPassword = (val) => {
    Promise.all([this.props.onChangePWHOC(val, "password")]).then(() => {
      if (!val) {
        const tmpPasswordError = val ? "" : "Please fill in the password field."
        return this.props.onChangePWHOC(tmpPasswordError, "passwordError");
      }
      const tmpBothPasswordsConfirmationError = val === this.props.password_confirmation
        ? ""
        : "Password and confirm password must be the same."
      const tmpPasswordValidationError = val.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@\#$%^&+=!]).{12,}$/)
        ? ""
        : "Password must contain at least 12 characters, including uppercase, lowercase, number, and special character (@#$%^&+!=)."

      this.props.onChangePWHOC(tmpBothPasswordsConfirmationError, "passwordConfirmationError");
      this.props.onChangePWHOC(tmpPasswordValidationError, "passwordError");
    });
  };

  onChangeConfirmNewPassword = (val) => {
    Promise.all([this.props.onChangePWHOC(val, "password_confirmation")]).then(
      () => {
        if (!val) {
          const tmpPasswordConfirmationError = val ? "" : "Please fill in the password field."
          return this.props.onChangePWHOC(tmpPasswordConfirmationError, "passwordConfirmationError");
        }
        const tmpBothPasswordsConfirmationError = val === this.props.password
          ? ""
          : "Password and confirm password must be the same."
        this.props.onChangePWHOC(tmpBothPasswordsConfirmationError, "passwordConfirmationError");
      }
    );
  };

  onClickUpdatePassword() {
    if (
      this.props.password !== "" &&
      this.props.password_confirmation !== "" &&
      !this.props.passwordError &&
      !this.props.passwordConfirmationError
    ) {
      this.props.onSubmitPassword({
        password: this.props.password,
        password_confirmation: this.props.password_confirmation,
      });
    }
  }

  render = () => {
    return (
      <div className="at-form__content at-profile__change-password">
        <form className="grid-control" onSubmit={(e) => e.preventDefault()}>
          <section className="grid-full-col">
            <h2 style={{ fontSize: 18, fontWeight: 600, color: "#111827" }}>
              Change Password
            </h2>
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              New Password
            </h2>
            <CustomFormInput
              type="password"
              value={this.props.password}
              onChangeValue={(val) => this.onChangeNewPassword(val)}
              inputError={this.props.passwordError}
              errorMessagePlain
              placeholder={"Enter your new password"}
            />
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Confirm New Password
            </h2>
            <CustomFormInput
              type="password"
              value={this.props.password_confirmation}
              onChangeValue={(val) => this.onChangeConfirmNewPassword(val)}
              inputError={this.props.passwordConfirmationError}
              errorMessagePlain
              placeholder={"Confirm your new password"}
            />
          </section>
          <section 
            className="grid-full-col" 
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}>
            {passwordChecks(this.props.password)?.map(({ label, condition }, index) => (
              <div key={index}>
                <AtlasIcon
                  svgHref={`atlas-${ condition ? 'tick' : 'close'}-circle` }
                  style={{width: 20, height: 20, marginRight: 2, fill: condition ? "#12B76A" : "#F04438"}}  
                /> 
                {label}
              </div>
            ))}
          </section>  
          <section className="grid-full-col">
            <button
              type={"button"}
              disabled={
                !this.props.password ||
                !this.props.password_confirmation ||
                this.props.passwordError ||
                this.props.passwordConfirmationError
              }
              className="btn-new btn-new--success"
              onClick={() => this.onClickUpdatePassword()}
            >
              Update Password
            </button>
          </section>
        </form>
        {this.props.onLoadPW && <LoadingModal />}
      </div>
    );
  };
}

export default compose(PasswordHOC, VersionCheckHOC)(ChangePassword);
