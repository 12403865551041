import React, { useCallback, useEffect, useState } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import _ from "lodash";
import Moment from "moment";

import AtlasSelect from "components/Select/new";
import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasFormInput from "components/Input/formInput";
import CustomTypeahead from "components/Typeahead/new";
import AtlasAutosuggest from "components/Autosuggest";
import DatePicker from "components/Input/datetimepicker";

const GenderOptions = [
  { id: "M", label: "Male", value: "M" },
  { id: "F", label: "Female", value: "F" },
];

const appendYearPrefix = (DOB) => {
  let checkAbove00Gen = new RegExp(/[0-3]/);
  let checkBelow90Gen = new RegExp(/[4-9]/);
  let firstDigit = DOB[0];

  if (checkAbove00Gen.test(firstDigit)) {
    return `20${DOB}`;
  } else if (checkBelow90Gen.test(firstDigit)) {
    return `19${DOB}`;
  }
};

const FormInput = ({ allowToHideContact, formMeta, formData, regions, processNationality }) => {
  const { label, value, type, show, placeholder = "", options, required, search_value } = formMeta;
  const {
    data,
    title,
    type: titleContext,
    disableEdit,
    bank_id,
    bank_account_number,
    payable_name,
    country,
    referral_sources,
    banks,
    claim_status,
    form_type_id,
    storedAddresses,
    township,
    nationalities,
    disabled,
    can_update,
    onChangeValue,
    getSelectedTownShip,
  } = formData;

  const tmpRequired = typeof required === "function" 
    ? required({type: formData.type}) 
    : required

  const countries = _.map(data.dictionaryReducer.countries, (country) => {
    return { ...country, value: country.name };
  });
  const [states, setStates] = useState([]);
  const [townshipList, setTownshipList] = useState([]);
  const [isError, setError] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const checkAddEdit = (title === "Add" && !isInitialLoad) || title === "Edit";
    if(checkAddEdit) {
      if(value === "date_of_birth" && formData.nric) {
        setError(_.isEmpty(formData[value]))
      } else if(["select", "typeahead", "autosuggest"].indexOf(type) > -1 && value !== "address") {
        if(value === "nationality_id") {
          const tmpOptions = renderOptions(value)
          const selectedObj = _.find(tmpOptions, item => item.id === formData[value]);  
          setError(!selectedObj || (tmpRequired && !formData[value]))
        } else {
          const tmpOptions = options || renderOptions(value)
          const selectedObj = _.find(tmpOptions, item => item.value === formData[value]);  
          setError(!selectedObj || (tmpRequired && !formData[value]))
        }
      } else if(tmpRequired) {
        setError(!formData[value])
      }
    }
  }, [formData, states, townshipList])

  useEffect(() => {
    if(township) {
      setTownshipList(township)
    }
  },[township])

  useEffect(() => {
    let temp = _.find(countries, (item) => item.value === country);
    setTownshipList([])
    if (temp) {
      let tempStates = _.map(temp.states, (state) => {
        return { ...state, value: state.name };
      });
      setStates(_.sortBy(tempStates, (i) => i.name?.toLowerCase()));
    }
  }, [country]);

  useEffect(() => {
    if (value === "town" && formData.state) {
      const temp = _.find(states, (item) => item.value === formData.state);
      if (temp?.id){
        getSelectedTownShip(temp.id);
      }
    }
  }, [states, formData.state])

  const onChangeAutoSuggest = (context, value) => {
    onChangeValue(context, value);

    if(context === "state") {
      let temp = _.find(states, (item) => item.value === value);
      if (temp) {
        getSelectedTownShip(temp.id);
      }
    }
  }

  const onChangeIsSelected = index => {
    let temp = _.cloneDeep(formData).referral_sources;
    temp[index].is_selected = !temp[index].is_selected;
    onChangeValue("referral_sources", temp);
  };

  const onChangeReferralSources = (index, val) => {
    let temp = _.cloneDeep(formData).referral_sources;
    temp[index].amount = val;
    onChangeValue("referral_sources", temp);
  };

  const dobGenerator = (val) => {
    let tmpVal = val.toString();
    let digitLength = tmpVal.length;
    let nricChecking = /^\d{6}-\d{2}-\d{4}$/;
    let tmpBirthday = tmpVal.substr(0, 6);
    tmpBirthday = appendYearPrefix(tmpBirthday);
    let birthday = Moment(tmpBirthday, "YYYYMMDD");
    let isValid = birthday.isValid();

    if ((!isNaN(tmpVal) && digitLength >= 6) || nricChecking.test(tmpVal)) {
      onChangeValue(
        "date_of_birth",
        isValid ? birthday.format("DD-MM-YYYY") : "",
      );
    } else {
      onChangeValue("date_of_birth", "");
    }
  };

  const renderAmount = () => {
    return (
      show(formData) && (
        <>
          {value && (
            <div className={"at-form_field-col-6"}>
              <h2 className="at-form-input__title" required={tmpRequired}>
                {label}
              </h2>
              <AtlasFormInput
                type="number"
                placeholder={placeholder}
                required={tmpRequired}
                disabled={disabled}
                value={`${formData[value]}`}
                onChangeValue={(val) => onChangeValue(value, val)}
              />
            </div>
          )}
          {((value && !_.isNaN(formData[value]) && formData[value] > 0) ||
            !value) && (
            <div className="at-form__content at-form_fields_cont at-form_field-col-12 mb-0 mt-3">
              <div className="at-form_field-col-6">
                <h2 className="at-form-input__title">{"Bank Name"}</h2>
                <AtlasSelect
                  disabled={
                    disabled ||
                    (title !== "Add" &&
                      claim_status !== "Draft" &&
                      claim_status !== "Rejected")
                  }
                  value={bank_id || ""}
                  onChange={(e) => onChangeValue("bank_id", e.target.value || 0)}
                >
                  <MenuItem
                    classes={{ root: `at-select__dropdown-item at-select__dropdown-placeholder` }}
                    key={"placeholder"}
                    value={""}
                  >
                    Select Bank Name
                  </MenuItem>
                  {banks &&
                    banks.length > 0 &&
                    banks.map((item) => (
                      <MenuItem
                        classes={{ root: `at-select__dropdown-item` }}
                        key={item.id}
                        value={item.id}
                      >
                        {item.name} - {item.country?.name ?? "N/A"}
                      </MenuItem>
                    ))}
                </AtlasSelect>
              </div>
              <div className="at-form_field-col-6">
                <h2 className="at-form-input__title">
                  {"Bank Account Number"}
                </h2>
                <AtlasFormInput
                  type="text"
                  placeholder={"Enter Bank Account Number"}
                  disabled={disabled}
                  value={`${bank_account_number ? bank_account_number : ""}`}
                  onChangeValue={(val) =>
                    onChangeValue("bank_account_number", val)
                  }
                />
              </div>
              <div className="at-form_field-col-6">
                <h2 className="at-form-input__title">{"Bank Payable Name"}</h2>
                <AtlasFormInput
                  type="text"
                  placeholder={"Enter Bank Payable Name"}
                  disabled={disabled}
                  value={`${payable_name ? payable_name : ""}`}
                  onChangeValue={(val) => onChangeValue("payable_name", val)}
                />
              </div>
              {title !== "Add" && value === "commission_amount" && (
                <section className="at-subsales_claims-commission_deduct-cont at-form_field-col-6 col-sm-12">
                  <h2 className="at-form-input__title">
                    {"Deduct referral fee from (RM)"}
                  </h2>
                  {referral_sources &&
                    referral_sources.length > 0 &&
                    referral_sources.map((item, index) => (
                      <div
                        key={index}
                        className="w-auto at-subsales_claims-commission_deduct-list p-0 d-inline-flex"
                      >
                        <CustomCheckbox
                          labelClassname={"mr-10"}
                          content={item.internal_agent_display_name}
                          checked={item.is_selected}
                          onChangeCheckboxValue={() =>
                            onChangeIsSelected(index)
                          }
                        />
                        <TextField
                          disabled={disableEdit}
                          value={item.amount}
                          onChange={(e) =>
                            onChangeReferralSources(index, e.target.value)
                          }
                        />
                      </div>
                    ))}
                  {!(referral_sources && referral_sources.length > 0) && (
                    <div>No referrals found.</div>
                  )}
                  <p>
                    Note: Referral commission should not be more than 20% of
                    commission received.
                  </p>
                </section>
              )}
            </div>
          )}
        </>
      )
    );
  };

  const renderOptions = (value) => {
    switch (value) {
      case "address":
        return storedAddresses || [];
      case "nationality":
      case "nationality_id":
        return nationalities || [];
      case "country":
        return countries || [];
      case "state":
        return states || [];
      case "town":
        return townshipList || [];
      default:
        return [];
    }
  };

  if (type === "radio") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {label}
          </h2>
          <AtlasRadioGroup
            disabled={disabled}
            required={tmpRequired}
            options={value === "gender" ? GenderOptions : []}
            checkedValue={formData[value]}
            selectedRadioValue={(val) => onChangeValue(value, val)}
          />
        </div>
      )
    );
  }

  if (type === "form") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" 
            required={tmpRequired}>
            {label}
          </h2>
          <AtlasFormInput
            type="text"
            className={`${isError ? "at-form-input--error" : ""}`}
            required={tmpRequired}
            disabled={disabled || (typeof formMeta.onDisable === "function" && formMeta.onDisable(formData))}
            placeholder={placeholder}
            value={`${formData[value] || ""}`}
            onChangeValue={(val) => {
              setIsInitialLoad(false)
              Promise.all([
                onChangeValue(
                  value,
                  ![
                    "company_registration_no",
                    "company_name",
                    "full_name",
                    "job_title"
                  ].includes(value)
                    ? val.replace(" ", "")
                    : val,
                ),
              ]).then(() => {
                if (value === "nric") {
                  dobGenerator(val);
                }
              });
            }}
          />
          {(allowToHideContact 
            && form_type_id !== 2 
            && formMeta.checkBoxValue 
            && formMeta.showCheckBox 
            && formData.type !== "Referral") && (
              <CustomCheckbox
                labelClassname={"mb-2 mt-3"}
                checked={formData[formMeta.checkBoxValue]}
                content={
                  <span className="fw-500">
                    {formMeta.checkBoxDescription}
                  </span>
                }
                onChangeCheckboxValue={() => onChangeValue(formMeta.checkBoxValue, !formData[formMeta.checkBoxValue])}
              />
            ) 
          }
        </div>
      )
    );
  }

  if (type === "calendar") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {label}
          </h2>
          {can_update && (
            <div className={`at-form-input ${disabled ? 'at-form-input__disabled' : ''} ${isError ? "at-form-input--error" : ""}`}>
              <DatePicker
                disabled={disabled}
                value={formData[value] || null}
                onChange={(val) => {
                  setIsInitialLoad(false)
                  onChangeValue(value, Moment(val).format("DD-MM-YYYY"))
                }}
              />
              {tmpRequired && <div className="at-form-input__required">required *</div>}
            </div>
          )}
          {!can_update && <p>{formData[value]}</p>}
        </div>
      )
    );
  }

  if (type === "select") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {label}
          </h2>
          <CustomSelect
            selectClassName={isError ? "at-form-input--error" : ""}
            placeholder={placeholder}
            required={tmpRequired}
            disabled={!can_update}
            className="w-100"
            selectItems={renderOptions(value)}
            valueKey="value"
            currentlySelected={
              renderOptions(value)?.find((item) => item?.name === formData?.[value]) ?? {}
            }
            updateSelect={(selectedItem) => {
              setIsInitialLoad(false)
              onChangeValue(value, selectedItem?.name || "")
            }}
          />
        </div>
      )
    )
  }

  if (type === "typeahead" && value === "nationality") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {label}
          </h2>
          <CustomTypeahead
            placeholder={placeholder}
            disabled={!can_update}
            required={tmpRequired}
            selectedValue={processNationality(formData[value], nationalities)}
            typeaheadId={"nationality"}
            options={nationalities}
            labelKey={"label"}
            filterBy={["label"]}
            onSelect={(val) => {
              if (val && val.length > 0) {
                setIsInitialLoad(false)
                onChangeValue("nationality", val[0].name);
              }
            }}
            childrenHead={(rowItem) => <p>{rowItem.label}</p>}
          />
        </div>
      )
    );
  }

  if (type === "typeahead" && value === "region") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {"Region"}
          </h2>
          <CustomTypeahead
            placeholder={placeholder}
            disabled={!can_update}
            required={tmpRequired}
            selectedValue={
              _.find(regions, { name: formData[value] })
                ? [_.find(regions, { name: formData[value] })]
                : []
            }
            typeaheadId={"region"}
            options={regions || []}
            labelKey={"label"}
            filterBy={["label"]}
            onSelect={(val) => {
              if (val && val.length > 0) {
                setIsInitialLoad(false)
                onChangeValue("region", val[0].name);
              }
            }}
            childrenHead={(rowItem) => <p>{rowItem.label}</p>}
            highlighterData={[(columnData) => columnData.reference_id]}
          />
        </div>
      )
    );
  }

  if (type === "autosuggest") {
    const tmpOptions = options || renderOptions(value)
    
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {label}
          </h2>
          <AtlasAutosuggest
            className={ `${isError ? "at-form-input--error" : ""}`}
            placeholder={placeholder}
            value={ 
              value === "nationality_id"
                ? (formData["nationality"] || "")
                : (formData[value] || "")
              }
            disabled={disabled}
            updateValue={async (val) => {
              if(value === "nationality_id") {
                let { label, id } = await _.find(tmpOptions, option => option?.label?.toLowerCase() === val?.toLowerCase()) || {};
                await onChangeAutoSuggest(value, label ? id : "")
                await onChangeValue(search_value, val)
              } else {
                await onChangeAutoSuggest(value, val)
              }
              setIsInitialLoad(false)
            }}
            onSelect={ obj => onChangeValue(value, value === "nationality_id" ? obj.id : obj.value)}
            required={tmpRequired}
            options={tmpOptions}
          />
        </div>
      )
    );
  }

  if (type === "expandable") {
    return renderAmount();
  }

  return <></>;
};

export default FormInput;
