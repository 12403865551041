import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { TextField } from "@material-ui/core";
import { RiShareBoxFill } from "react-icons/ri";

import CustomCard from "components/Card";
import CustomForm from "components/Form";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import DatePicker from "components/Input/datetimepicker";

import permissionsChecker from "utils/permissionsChecker";
import ExamIntegrationHOC from "./actions";
import "./index.scss";

class ExamIntegration extends Component {
  componentDidMount = () => this.props.getCurrentQuiz();

  renderCardContent = () => {
    const { exam_url, start_date, end_date } = this.props.currentQuiz.settings;
    const { can_update } = permissionsChecker(
      "Exam Integration",
      this.props.data,
    );

    const onChangeFieldData = (val, context) => {
      let temp = _.cloneDeep(this.props.currentQuiz);
      temp.settings[context] = val;
      this.props.onChangeExamIntegrationHOC(temp, "currentQuiz");
    };

    return (
      <CustomForm
        formSection={[
          {
            formTitle: `Current Exam - ${Moment().format("MMM YYYY")}`,
            formContent: (
              <div className="grid-control at-exam-integration__content">
                <section className="grid-half-col">
                  <p className="title">Start date time</p>
                  <div className="at-form-input w-100">
                    <DatePicker
                      id={"start_date_time"}
                      type="datetime-local"
                      value={Moment(start_date)}
                      disabled={!can_update}
                      onChange={(val) => onChangeFieldData(val ? Moment(val).format("YYYY-MM-DDTHH:mm") : "", "start_date")}
                    />
                  </div>
                </section>
                <section className="grid-half-col">
                  <p className="title">End date time</p>
                  <div className='at-form-input'>
                    <DatePicker
                      id={ 'end_date_time' }
                      type="datetime-local"
                      value={ Moment(end_date) }
                      disabled={ !can_update }
                      minDateTime={ Moment( start_date ).format( "YYYY-MM-DDTHH:mm" ) }
                      onChange={ value => onChangeFieldData( value, 'end_date' ) } />
                  </div>
                </section>
                <section className="grid-full-col">
                  <a href={exam_url} target={"_blank"}>
                    Exam link
                    <RiShareBoxFill
                      style={{ width: 14, height: 14, marginLeft: 10 }}
                    />
                  </a>
                </section>
              </div>
            ),
          },
        ]}
        actionColumns={[
          {
            label: "Update",
            className: "btn-new btn-new--success",
            onShow: () => can_update,
            onClick: () =>
              this.props.updateCurrentQuiz({
                id: this.props.currentQuiz.id,
                settings: this.props.currentQuiz.settings,
              }),
          },
        ]}
      />
    );
  };

  render = () => {
    return (
      <>
        <ModuleHeader
          title={"Exam Integration"}
          moduleIcon={"icon-exam-integration"}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {this.props.onLoadExamIntegration && <LoadingModal />}
      </>
    );
  };
}

export default ExamIntegrationHOC(ExamIntegration);
