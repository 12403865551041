import _ from "lodash";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { Chip } from "@material-ui/core";
import { BiTable } from "react-icons/bi";
import { FaRegChartBar } from "react-icons/fa";
import { HorizontalBar } from "react-chartjs-2";

import CustomCard from "components/Card";
import CustomButton from "components/Button";
import EmptyState from "components/EmptyState";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import AtPaginatedTable from "components/NewTable";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import CustomFormInput from "components/Input/formInput";
import DatePicker from "components/Input/datetimepicker";

import { numberWithCommas } from "utils/thousandSeparator";
import LeaderTeamSalesHOC from "./actions";
import "./index.scss";

const leaderFilter = [
  { label: "Group VP", value: 10 },
  { label: "VP", value: 11 },
  { label: "STM", value: 12 },
  { label: "Team Manager", value: 1 },
  { label: "HOT", value: 2 },
  { label: "Team Leader", value: 24 },
  { label: "Leader", value: 25 },
];

const columnData = [
  {
    header: "Agent",
    searchFlag: "agentName",
    accessor: "agentName",
  },
  {
    header: "Total Sales",
    searchFlag: "totalAmount",
    columnStyle: { width: "40%" },
    renderColumn: (rowData) => `RM ${numberWithCommas(rowData.totalAmount)}`,
  },
];

const LeaderTeamSales = ({
  data,
  reports,
  leaderList,
  nameFilter,
  reportChart,
  searchParams,
  onLoadReports,
  initialLeaderList,

  getLeaders,
  getSalesByLeader,
  onChangeProjectReportsHOC,
}) => {
  const { role, job_title_reference_id, supervisor_designation } =
    window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;
  const userLevel =
    role === "Personal Assistant"
      ? _.findIndex(leaderFilter, {
          value: supervisor_designation.job_title_reference_id,
        })
      : _.findIndex(leaderFilter, { value: job_title_reference_id });
  const [visual, setVisual] = useState("chart");
  const [showResult, setShowResult] = useState(false);
  const [leaderOptions, setLeaderOptions] = useState([]);
  const [selectedLeader, setSelectedLeader] = useState([]);

  useEffect(() => {
    getLeaders();
  }, []);

  useEffect(() => {
    if (leaderList.length > 0) {
      let temp = [];
      leaderList.forEach((item) => {
        if (selectedLeader.includes(item.job_title_id)) {
          temp.push(item);
        }
      });
      setLeaderOptions(temp);
    }
  }, [leaderList, selectedLeader]);

  const onToggleLeaders = (val) => {
    if (selectedLeader.includes(val.value)) {
      setSelectedLeader(selectedLeader.filter((item) => item !== val.value));
    } else {
      setSelectedLeader([...selectedLeader, val.value]);
    }
  };

  const onToggleUsers = (val) => {
    let temp = _.cloneDeep(searchParams);
    if (temp[2].param.includes(val.agent_id)) {
      temp[2].param = temp[2].param.filter((item) => item !== val.agent_id);
    } else {
      temp[2].param = [...temp[2].param, val.agent_id];
    }
    onChangeProjectReportsHOC(temp, "searchParams");
  };

  const onChangeDate = (date, index) => {
    let temp = _.cloneDeep(searchParams);
    temp[index].param = date;
    onChangeProjectReportsHOC(temp, "searchParams");
  };

  const onToggleReset = () => {
    let temp = _.cloneDeep(searchParams);
    temp[2].param = "";
    setShowResult(false);
    onChangeProjectReportsHOC(temp, "searchParams");
  };

  const onChangeNameFilter = (val) => {
    if (val === "") {
      onChangeProjectReportsHOC(initialLeaderList, "leaderList");
    } else {
      let temp = _.cloneDeep(initialLeaderList);
      temp = temp.filter((item) =>
        item.agent_name.toLowerCase().includes(val.toLowerCase()),
      );
      onChangeProjectReportsHOC(temp, "leaderList");
    }
    onChangeProjectReportsHOC(val, "nameFilter");
  };

  const renderSearchResult = () => {
    return (
      <>
        <CustomButton
          className={"btn-new btn-new--outline-secondary mb-10"}
          onClick={() => onToggleReset()}
          children={
            <>
              <AtlasIcon
                svgHref={"atlas-arrow-left-2"}
                style={{ width: 20, height: 20 }}
              />
              Return To Search
            </>
          }
        />
        <div className={"at-form__content"}>
          <h2 className="at-form-input__title w-100">Search Attributes: </h2>
          {searchParams.map((item, index) => {
            let temp = item.param;
            if (item.value === "leader_ids") {
              temp = item.param
                .map((item) => {
                  let temp = leaderList.find(
                    (leader) => leader.agent_id === item,
                  );
                  return temp ? temp.agent_name : "";
                })
                .join(", ");
            } else {
              temp = Moment(item.param).format("DD MMM YYYY");
            }
            return (
              <Chip
                key={index}
                classes={{
                  root: "mt-10 mr-2",
                  outlinedPrimary: "at-analytic-centre__outline-chip-primary",
                }}
                variant={"outlined"}
                color={"primary"}
                label={`${item.label}: ${temp}`}
              />
            );
          })}
        </div>
        <div
          className="d-flex align-items-center mb-10"
          style={{
            backgroundColor: "#E5E7EB",
            borderRadius: 8,
            width: "fit-content",
          }}
        >
          <button
            className={`btn-float ${visual === "chart" ? "btn-float-selected" : ""}`}
            onClick={() => setVisual("chart")}
          >
            <FaRegChartBar style={{ width: 20, height: 20 }} />
            Chart
          </button>
          <button
            className={`btn-float ${visual === "table" ? "btn-float-selected" : ""}`}
            onClick={() => setVisual("table")}
          >
            <BiTable style={{ width: 20, height: 20 }} />
            Table
          </button>
        </div>
        <div className="at-form__content">
          {visual === "chart" && (
            <div
              style={{
                height: reportChart.labels
                  ? `${reportChart.labels.length * 50}px`
                  : "100px",
                minHeight: 300,
              }}
            >
              <HorizontalBar
                data={reportChart}
                options={{
                  maintainAspectRatio: false,
                  tooltips: {
                    callbacks: {
                      label: (tooltipItem, data) => {
                        return `Sales Amount: RM${numberWithCommas(tooltipItem.xLabel)}`;
                      },
                    },
                  },
                  plugins: {
                    datalabels: {
                      anchor: "end",
                      align: "end",
                      formatter: (value) => numberWithCommas(value),
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          fontSize: 12,
                          callback: (value) => numberWithCommas(value),
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          fontSize: 12,
                          callback: (label) => {
                            if (window.innerWidth > 1024 && label.length > 20) {
                              return `${label.substring(0, 20)}...`;
                            } else if (
                              window.innerWidth <= 1024 &&
                              label.length > 10
                            ) {
                              return `${label.substring(0, 10)}...`;
                            } else {
                              return label;
                            }
                          },
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          )}
          {visual === "table" && (
            <AtPaginatedTable
              pagination={true}
              hideSearch={true}
              columns={columnData}
              rowData={reports || []}
              searchParams={[]}
            />
          )}
        </div>
      </>
    );
  };

  const renderSearch = () => {
    return (
      <div className={"at-form__content"}>
        <label className="at-form-input__title mb-0">Step 1</label>
        <p style={{ fontSize: 14, marginBottom: 12 }}>
          Select the date range to get sales figures
        </p>
        <div className="row">
          <div className="col-md-6">
            <label style={{ fontSize: 14, fontWeight: 500, marginBottom: 5 }}>
              From
            </label>
            <div className="at-form-input">
              <DatePicker
                maxDate={Moment()}
                value={Moment(searchParams[0].param)}
                onChange={(date) => onChangeDate(Moment(date).format("YYYY-MM-DD"), 0)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label className="at-form-input__title">To</label>
            <div className="at-form-input">
              <DatePicker
                maxDate={Moment()}
                value={Moment(searchParams[1].param)}
                onChange={(date) => onChangeDate(Moment(date).format("YYYY-MM-DD"), 1)}
              />
            </div>
          </div>
        </div>
        <hr />
        <label className="at-form-input__title mb-0">Step 2</label>
        <p style={{ fontSize: 14, marginBottom: 12 }}>
          Choose one or more leaders:{" "}
        </p>
        {leaderFilter.map((item, index) => {
          if (userLevel > -1 && index > userLevel) {
            return (
              <CustomCheckbox
                key={index}
                content={item.label}
                checked={_.includes(selectedLeader, item.value)}
                onChangeCheckboxValue={() => onToggleLeaders(item)}
              />
            );
          }
        })}
        <div className="at-leader-team-sales__agent-cont">
          {leaderOptions.length > 0 && (
            <>
              <CustomFormInput
                type="search"
                value={nameFilter}
                className={"m-1"}
                onChangeValue={(val) => onChangeNameFilter(val)}
                placeholder={"Enter leader name"}
              />
              <div className="at-leader-team-sales__agent">
                {leaderOptions.map((item, index) => (
                  <CustomCheckbox
                    key={index}
                    content={`${item.agent_name} (${item.phone_number})`}
                    checked={_.includes(searchParams[2].param, item.agent_id)}
                    onChangeCheckboxValue={() => onToggleUsers(item)}
                  />
                ))}
              </div>
            </>
          )}
          {leaderOptions.length === 0 && (
            <>
              <div></div>
              <EmptyState
                title={"No Leader Found"}
                description={
                  "Please select one or more leader type to view the leaders."
                }
              />
            </>
          )}
        </div>
        <div className="d-flex">
          <CustomButton
            disabled={_.values(searchParams).some((item) => !item.param)}
            className="btn-new btn-new--primary"
            children={"Run"}
            onClick={() => getSalesByLeader(() => setShowResult(true))}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <ModuleHeader
        title={"Leader Team Sales Ranking"}
        atlasIcon={"atlas-medal-star"}
        showPABadge={role === "Personal Assistant"}
        description={`This report will allow you to view the total group sales for each of your leaders and compare and contrast. Select a start and end date range, click one or more of the tickboxes to filter by the type of leaders that you want to choose from and select any number of leaders from the list.`}
      />
      <CustomCard
        containerStyle={{ background: "#F3F4F6" }}
        cardContent={
          <>
            {!showResult && renderSearch()}
            {showResult && renderSearchResult()}
            {onLoadReports && <LoadingModal />}
          </>
        }
      />
    </>
  );
};

export default LeaderTeamSalesHOC(LeaderTeamSales);
