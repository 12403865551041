import React, { useEffect } from "react";
import Moment from "moment";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";

import defaultImage from "assets/images/iqi_logo_only.png";
import { TrainingTypes } from "dictionary/eventTrainingType";

import { requestWarning } from "utils/requestHandler";
import "../index.scss";

const EventTypes = [
  { id: 1, name: "Event" },
  { id: 2, name: "Training" },
];

const eventData = [
  { label: "Event", value: "name" },
  { label: "Start Time", value: "start_date_time" },
  { label: "End Time", value: "end_date_time" },
  { label: "Register By", value: "cut_off_date" },
  { label: "Venue", value: "location" },
  { label: "Location URL", value: "location_url" },
  { label: "Speaker", value: "speaker" },
  { label: "Description", value: "description" },
  { label: "Price", value: "price" },
  { label: "Entry Pass", value: "entry_pass" },
];

const EventDetailsContent = ({
  branchRegions,
  selectedEvent,
  onLoadEventTraining,

  getSelectedEvent,
  onChangeEventHOC
 }) => {

  useEffect( () => {
    getSelectedEvent( selectedEvent.id )
  }, [] )

  const tempData = {
    ...selectedEvent,
    price: `RM ${Number(selectedEvent.price).toFixed(2)}`,
    tmpType: _.find(EventTypes, { id: selectedEvent.type_id }),
    tmpTrainingType: _.find(TrainingTypes, {
      id: selectedEvent.training_type_id,
    }),
    start_date_time: Moment(selectedEvent.start_date_time).format("DD MMM YYYY HH:MM"),
    end_date_time: Moment(selectedEvent.end_date_time).format("DD MMM YYYY HH:MM"),
  };
  const eventType = `${tempData.is_payment_needed ? "paid-" : ""}${tempData.tmpType?.name.toLowerCase()}`;

  return (
    <>
      <div style={{ maxWidth: 800, margin: 'auto' }}>
        <div className='at-cal_events__overlay-image_cont mt-3'>
          <img 
            src={ selectedEvent.event_photo_url ? selectedEvent.event_photo_url : defaultImage } 
            style={{ width: '100%', maxHeight: '60vh', objectFit: 'contain' }} />
        </div>
        <div className={`at-cal_events__overlay-label ${eventType}`}>
          <div
            className={"at-calendar__day-affair_icons"}
            style={{ minWidth: 30 }}
          >
            <AtlasIcon svgHref={"atlas-calendar-2"} />
          </div>
          <div className="at-cal_events__overlay-event_meta_label">
            {`${tempData.is_payment_needed ? "Paid" : ""} ${tempData.tmpType.name} ${tempData.tmpTrainingType ? "| " + tempData.tmpTrainingType.name : ""}`}
          </div>
          {tempData.is_limited_seat && (
            <div className="at-cal_events__overlay-event_meta_label">
              Limited
            </div>
          )}
        </div>
        <div className="at-cal_events__overlay-detail">
          {eventData.map((item, index) => {
            if (
              !["Entry Pass", "Price", "Register By"].includes(item.label) ||
              selectedEvent.is_payment_needed
            ) {
              return (
                <div key={index} className="text-row">
                  <strong style={{ maxWidth: 150 }}>{item.label}:</strong>
                  {item.value === "location_url" && (
                    <a
                      style={{ color: "#2563EB" }}
                      href={tempData[item.value]}
                      target="_blank"
                    >
                      <span>{"Click here for the details"}</span>
                    </a>
                  )}
                  {item.value !== "location_url" && (
                    <span
                      style={{ wordBreak: "break-word" }}
                    >{`${tempData[item.value]}`}</span>
                  )}
                </div>
              );
            }
          })}
          {selectedEvent.branch_region_id && branchRegions.length > 0 && (
            <div key={"branch"} className="text-row">
              <strong className="at-form-input__title">{"Branch Region:"}</strong>
              <span
                style={{ wordBreak: "break-word" }}
              >{`${_.find(branchRegions, { id: selectedEvent.branch_region_id }).name}`}</span>
            </div>
          )}
        </div>
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          className="btn-new btn-new--secondary"
          children={ 'Copy Event Link' }
          onClick={ () => {
            navigator.clipboard.writeText( `${ window.location.origin }/#/dashboard/iqi-calendar/events?event_id=${ selectedEvent.id }` )
            requestWarning( 'Link Copied' )
          }} />
        <button
          className='btn-new btn-new--outline-secondary'
          children={ 'Close' }
          onClick={ () => onChangeEventHOC( false, 'showEditEventDialog' ) } />
      </div>
      {onLoadEventTraining && <LoadingModal />}
    </>
  );
};

export default EventDetailsContent;
