import React, { Component } from "react";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomFormInput from "components/Input/formInput";

import { getTranslation } from "assets/translation";

const passwordChecks = password => [
  {
    key: "label.lowercase",
    condition: /[a-z]/.test(password),
  },
  {
    key: "label.uppercase",
    condition: /[A-Z]/.test(password),
  },
  {
    key: "label.digit_number",
    condition: /\d/.test(password),
  },
  {
    key: "label.special_character",
    condition: /[@#$%^&+!=]/.test(password),
  },
  {
    key: "label.12_characters_minimum",
    condition: password.length >= 12,
  }
]

class ResetPassword extends Component {
  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      return this.props.onClickSubmitPassword();
    }
  };

  onChangeNewPassword = (val) => {
    Promise.all([this.props.onChangeHOC(val, "password")]).then(() => {
      if(!val) {
        const tmpPasswordError = val 
          ? "" 
          : getTranslation("label.fill_in_password", this.props.language)
        return this.props.onChangeHOC(tmpPasswordError, "passwordError");
      }
      
      const tmpBothPasswordsConfirmationError = val === this.props.confirmedPassword
        ? ""
        : getTranslation("label.password_and_confirm_password_must_be_same", this.props.language)
      const passwordValidationError = val.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@\#$%^&+=!]).{12,}$/)
        ? ""
        : getTranslation("label.password_requirements", this.props.language)
      
      this.props.onChangeHOC(tmpBothPasswordsConfirmationError, "passwordConfirmationError");
      this.props.onChangeHOC(passwordValidationError, "passwordError");
    });
  };

  onChangeConfirmNewPassword = (val) => Promise.all([
    this.props.onChangeHOC(val, "confirmedPassword")
  ]).then( () => {
    if(!val) {
      const tmpPasswordConfirmationError = val 
        ? "" 
        : getTranslation("label.fill_in_password", this.props.language)
      return this.props.onChangeHOC(tmpPasswordConfirmationError, "passwordConfirmationError");
    }
    const tmpBothPasswordsConfirmationError = val === this.props.password
      ? ""
      : getTranslation("label.password_and_confirm_password_must_be_same", this.props.language)
    this.props.onChangeHOC(tmpBothPasswordsConfirmationError, "passwordConfirmationError"
    );
  });

  render() {
    return (
      <div
        className="login-form-container"
        tabIndex="0"
        onKeyPress={(event) => this.handleKeyPress(event)}
      >
        <h4 className="at-form-input__title">{getTranslation("label.password", this.props.language)}</h4>
        <CustomFormInput
          type="password"
          value={this.props.password}
          disabled={!this.props.resetTokenValid} 
          onChangeValue={(val) => this.onChangeNewPassword(val)}
          inputError={this.props.passwordError}
          errorMessagePlain
          placeholder={getTranslation("label.password", this.props.language)}
        />
        <h4 className="at-form-input__title mt-20">{getTranslation("label.confirmed_password", this.props.language)}</h4>
        <CustomFormInput
          type="password"
          value={this.props.confirmedPassword}
          disabled={!this.props.resetTokenValid}
          onChangeValue={(val) => this.onChangeConfirmNewPassword(val)}
          inputError={this.props.passwordConfirmationError}
          errorMessagePlain
          placeholder={getTranslation("label.confirmed_password", this.props.language)}
        />
        <div style={{display: "flex", flexDirection: "column", margin: "24px 0", gap: 8}}>
          {passwordChecks(this.props.password)?.map(({ key, condition }, index) => (
            <div key={index}>
              <AtlasIcon
                svgHref={`atlas-${ condition ? 'tick' : 'close' }-circle`}
                style={{width: 20, height: 20, marginRight: 2, fill: condition ? "#12B76A" : "#F04438"}}/> 
              {getTranslation(key, this.props.language)}
            </div>
          ))}
        </div>  
        <div style={{ textAlign: "right" }}>
          <button
            disabled={
              !this.props.password ||
              !this.props.confirmedPassword ||
              this.props.passwordError ||
              this.props.passwordConfirmationError ||
              !this.props.resetTokenValid
            }
            className="btn-new btn-new--primary text-uppercase w-100"
            type="submit"
            onClick={() => this.props.onClickSubmitPassword()}
          >
            {getTranslation("submit", this.props.language)}
          </button>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
