import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { Card } from "@material-ui/core";
import { ChromePicker } from "react-color";

import SVGIcon from "components/Icon";
import CustomCard from "components/Card";
import CustomDialog from "components/Dialog";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";
import CustomFormInput from "components/Input/formInput";
import DatePicker from "components/Input/datetimepicker";
import CustomImageDisplay from "./image";

const radioChoice = [
  { id: "1", label: "Yes" },
  { id: "2", label: "No" },
];

const CreateAppTheme = ({
  onChangeAppThemeManagementHOC,
  selectedAppTheme,
  createAppTheme,
  onLoadAppTheme,
  updateAppTheme,
  open,
}) => {
  const [mode, setMode] = useState("new");
  const [appTheme, setAppTheme] = useState({});

  useEffect(() => {
    if (open && selectedAppTheme) {
      setMode("edit");
      setAppTheme({
        ...selectedAppTheme,
        background_image: selectedAppTheme.background_image_url,
      });
    }
    if (open && !selectedAppTheme) {
      setMode("new");
      setAppTheme({
        name: "",
        bgcolour: { hex: "#FFFFFF" },
        icon_colour: { hex: "#FFFFFF" },
        is_current_theme: "2",
        background_image: "",
        background_image_file_name: "",
      });
    }
  }, [open]);

  const onCheckCreateButton = () =>
    _.values(appTheme).some((x) => !x || x === {});

  const onChangeInputValue = (val, context) => {
    let tmp = _.cloneDeep(appTheme);
    tmp[context] = val;
    setAppTheme(tmp);
  };

  let fileInput = null;
  const fileInputRef = (element) => (fileInput = element);
  const focusFileInput = () => fileInput && fileInput.click();
  const onClickAddBackground = () => focusFileInput();

  const onUploadImage = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      let files = event.target.files;
      Object.keys(event.target.files).map((key) => {
        let reader = new FileReader();
        reader.onload = (e) => {
          let temp = _.cloneDeep(appTheme);
          temp["background_image_file_name"] = files[key].name;
          temp["background_image"] = e.target.result;
          temp["changeImage"] = true;
          setAppTheme(temp);
        };
        reader.readAsDataURL(event.target.files[key]);
      });
    }
  };

  return (
    <CustomDialog open={open}>
      <CustomCard
        className={`h-100`}
        cardContent={
          <>
            <div className="at-card__header">
              <AtlasCloseButton
                onClick={() =>
                  Promise.all([
                    onChangeAppThemeManagementHOC(null, "selectedAppTheme"),
                  ]).then(() => {
                    onChangeAppThemeManagementHOC(
                      false,
                      "showCreateAppThemeModal",
                    );
                  })
                }
              />
              <h4 className="at-card__title">
                {mode === "new" ? "New App Theme" : "Edit App Theme"}
              </h4>
            </div>
            <Card style={{ padding: 16, marginBottom: 20 }}>
              <div className={"row"}>
                <div className="col-md-12">
                  <label className="at-form-input__title" required>
                    App Theme Name
                  </label>
                  <CustomFormInput
                    containerClass={"mb-0 flex-1"}
                    required={true}
                    type={"text"}
                    value={appTheme.name}
                    onChangeValue={(val) => onChangeInputValue(val, "name")}
                    placeholder={`Enter app theme name here`}
                  />
                </div>
                <div className="col-md-6">
                  <label className="at-form-input__title">Icon Colour</label>
                  <ChromePicker
                    className="my-2"
                    color={
                      !_.isEmpty(appTheme.icon_colour)
                        ? appTheme.icon_colour
                        : ""
                    }
                    disableAlpha={"false"}
                    triangle={"hide"}
                    onChangeComplete={(val) =>
                      onChangeInputValue(val, "icon_colour")
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label className="at-form-input__title">Icon BG Colour</label>
                  <ChromePicker
                    className="my-2"
                    color={
                      !_.isEmpty(appTheme.bgcolour) ? appTheme.bgcolour : ""
                    }
                    disableAlpha={"false"}
                    triangle={"hide"}
                    onChangeComplete={(val) =>
                      onChangeInputValue(val, "bgcolour")
                    }
                  />
                </div>
                <div className="col-md-6">
                  <label className="at-form-input__title" required>
                    {"Current Theme"}
                  </label>
                  <AtlasRadioGroup
                    valueKey={"id"}
                    required={true}
                    horizontal={true}
                    containerClassName={"w-100"}
                    checkedValue={appTheme.is_current_theme}
                    selectedRadioValue={(val) =>
                      onChangeInputValue(val, "is_current_theme")
                    }
                    options={radioChoice}
                  />
                </div>
                <div className="col-md-6">
                  <label className="at-form-input__title">{"Publish On"}</label>
                  <div className="at-form-input">
                    <DatePicker
                      withPortal
                      dateFormat={"DD MMM YYYY"}
                      value={
                        appTheme.publish_on
                          ? appTheme.publish_on
                          : "Select publish date"
                      }
                      onChange={(val) =>
                        onChangeInputValue(
                          Moment(val).format("DD MMM YYYY"),
                          "publish_on",
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="at-form-input__title" required>
                    Background Image
                  </label>
                  {_.isEmpty(appTheme.background_image) && (
                    <>
                      <input
                        ref={fileInputRef}
                        type={"file"}
                        multiple={true}
                        required={true}
                        onChange={(event) => {
                          onUploadImage(event);
                        }}
                        style={{ display: "none" }}
                        accept="image/*"
                      />
                      <div
                        className="project-detail__upload-photo flex-center bd-rd-2 my-2"
                        onClick={() => {
                          onClickAddBackground();
                        }}
                      >
                        <SVGIcon
                          className="icon-size-4 mr-2 svg-icon-plus-circle"
                          svgHref={"icon-plus-circle"}
                        />
                      </div>
                    </>
                  )}
                  {!_.isEmpty(appTheme.background_image) && (
                    <div className="at-app-theme__upload-photo-container">
                      <CustomImageDisplay
                        imgSrc={appTheme.background_image}
                        tooltipChildren={"Remove image"}
                        onClickRemoveImage={() => {
                          let temp = _.cloneDeep(appTheme);
                          temp["background_image_file_name"] = "";
                          temp["background_image"] = "";
                          setAppTheme(temp);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex mt-20">
                <CustomButton
                  disabled={onCheckCreateButton()}
                  className={"btn-new btn-new--success"}
                  children={mode === "new" ? "Submit" : "Update"}
                  onClick={() => {
                    mode === "new"
                      ? createAppTheme(appTheme)
                      : updateAppTheme(appTheme);
                  }}
                />
                <button
                  type={"button"}
                  className="btn-new btn-new--outline-secondary ml-2"
                  onClick={() =>
                    onChangeAppThemeManagementHOC(
                      false,
                      "showCreateAppThemeModal",
                    )
                  }
                >
                  {mode === "new" ? "Cancel" : "Close"}
                </button>
              </div>
            </Card>
            {onLoadAppTheme && <LoadingModal />}
          </>
        }
      />
    </CustomDialog>
  );
};

export default CreateAppTheme;
