import React, { Component } from "react";
import _ from "lodash";

import Tab from "components/Tab";
import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import MOTDStatsContent from "./containers/motd";
import NewAgentContent from "./containers/newAgent";
import IQIDriveContent from "./containers/folderClick";
import ActiveUserContent from "./containers/activeUser";
import NewsfeedStatsContent from "./containers/newsfeed";
import TopActivityContent from "./containers/topActivity";
import MultiLingualContent from "./containers/multilingual";
import SearchActivityContent from "./containers/searchActivity";

import AnalyticCenterHOC from "./actions";
import "./index.scss";

const tabOptions = [
  { label: "Active User" },
  { label: "New User" },
  { label: "IQI Drive" },
  { label: "MOTD" },
  { label: "Newsfeed" },
  { label: "Multilingual" },
  { label: "Top Activity" },
  { label: "Search Activity" },
];

class AdminAnalyticCenter extends Component {
  renderTabContent = () => {
    switch (this.props.selectedTab) {
      case "Active User":
        return (
          <ActiveUserContent
            data={this.props.data}
            activeUser={this.props.activeUser}
            getActiveUser={this.props.getActiveUser}
          />
        );
      case "Top Activity":
        return (
          <TopActivityContent
            data={this.props.data}
            topActivities={this.props.topActivities}
            activityFilter={this.props.activityFilter}
            activityOptions={this.props.activityOptions}
            selectedTopActivity={this.props.selectedTopActivity}
            getTopActivities={this.props.getTopActivities}
            processTopActivity={this.props.processTopActivity}
            onChangeAnalyticHOC={this.props.onChangeAnalyticHOC}
          />
        );
      case "IQI Drive":
        return (
          <IQIDriveContent
            data={this.props.data}
            folderClick={this.props.folderClick}
            folderClickFilter={this.props.folderClickFilter}
            folderClickOptions={this.props.folderClickOptions}
            selectedFolderClick={this.props.selectedFolderClick}
            getFolderClick={this.props.getFolderClick}
            processFolderClick={this.props.processFolderClick}
            onChangeAnalyticHOC={this.props.onChangeAnalyticHOC}
          />
        );
      case "MOTD":
        return (
          <MOTDStatsContent
            data={this.props.data}
            motdStats={this.props.motdStats}
            getMOTDStats={this.props.getMOTDStats}
          />
        );
      case "Newsfeed":
        return (
          <NewsfeedStatsContent
            newsfeedStats={this.props.newsfeedStats}
            getNewsfeedStats={this.props.getNewsfeedStats}
          />
        );
      case "Multilingual":
        return (
          <MultiLingualContent
            localeChanges={this.props.localeChanges}
            getLocaleChange={this.props.getLocaleChange}
          />
        );
      case "New User":
        return (
          <NewAgentContent
            data={this.props.data}
            newAgent={this.props.newAgent}
            getNewAgent={this.props.getNewAgent}
          />
        );
      case "Search Activity":
        return (
          <SearchActivityContent
            data={this.props.data}
            searchActivity={this.props.searchActivity}
            activityFilter={this.props.activityFilter}
            activityOptions={this.props.activityOptions}
            getSearchActivity={this.props.getSearchActivity}
            onChangeAnalyticHOC={this.props.onChangeAnalyticHOC}
          />
        );
    }
  };

  renderCardContent = () => (
    <>
      <Tab
        sections={tabOptions}
        selectedSection={this.props.selectedTab}
        onSelectSection={(val) =>
          this.props.onChangeAnalyticHOC(val, "selectedTab")
        }
      />
      {this.renderTabContent()}
    </>
  );

  render = () => {
    return (
      <>
        <ModuleHeader title={"User Activities"} moduleIcon={"icon-events"} />
        <CustomCard
          containerStyle={{ background: "#F3F4F6", overflowX: "none" }}
          cardContent={this.renderCardContent()}
        />
        {this.props.onLoadAnalysis && <LoadingModal />}
      </>
    );
  };
}

export default AnalyticCenterHOC(AdminAnalyticCenter);
