import React, { Component } from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";

import "./style.scss";

const Tabs = ({
  containerClass,
  containerStyle,
  sections,
  disabled,
  renderLabel,
  selectedSection,
  onSelectSection,
  getLocalised
}) => {
  return (
    <div
      className={`info-tabs ${containerClass || ""}`}
      style={containerStyle || {}}
    >
      {sections.map((item) => {
        if (typeof item === "string") {
          return (
            <button
              key={item}
              disabled={disabled}
              className={`at-tab__button ${selectedSection === item ? "at-tab__selected" : ""}`}
              onClick={() => onSelectSection(item)}
            >
              {renderLabel ? renderLabel(item) : item}
            </button>
          );
        } else {
          const id = item.id || item.label
          const localizedLabel = getLocalised ? getLocalised(item.key, item.label) : item.label;
          return (
            <button
              key={id}
              disabled={disabled}
              className={`at-tab__button ${selectedSection === id ? "at-tab__selected" : ""}`}
              onClick={() => onSelectSection(id)}
            >
              {item.icon && (
                <AtlasIcon
                  svgHref={item.icon}
                  style={{ width: 14, height: 14 }}
                />
              )}
              {renderLabel ? renderLabel(item) : localizedLabel}
            </button>
          );
        }
      })}
    </div>
  );
};

export default Tabs;