import React, { useEffect } from "react";

import CustomButton from "components/Button";

const QRCode = ({ eventQr, selectedEvent, getEventQrCode }) => {
  useEffect(() => {
    getEventQrCode(selectedEvent.id);
  }, []);

  return (
    <>
      {eventQr && (
        <div className="text-center">
          <img src={eventQr} alt={"event-qr-code"} />
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
              gap: 10,
            }}
          >
            <CustomButton
              className="btn-new btn-new--secondary m-auto mb-10"
              onClick={() => getEventQrCode(selectedEvent.id)}
              children={"Re-Generate"}
            />
            <a
              href={eventQr}
              download={`${selectedEvent?.name?.replace(/ /g, "_")}.png`}
            >
              <CustomButton
                className="btn-new btn-new--primary m-auto mb-10"
                children={"Download"}
              />
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default QRCode;
