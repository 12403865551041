import React, { useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { BsTrash3Fill, BsChevronDown, BsChevronUp } from "react-icons/bs";

import CustomButton from "components/Button";
import CustomSelect from "components/Select";
import FormHeader from "components/Form/header";
import CustomCheckbox from "components/Checkbox";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import DatePicker from "components/Input/datetimepicker";

const CreateHoliday = (props) => {
  const countries = props.data.dictionaryReducer.countries;
  const [selectedCountry, setSelectedCountry] = useState(countries?.[0]);
  const [formData, setFormData] = useState([
    {
      name: "",
      date: "",
      country_id: countries?.[0].id,
      state_ids: countries?.[0]?.states?.map((item) => item.id),
    },
  ]);

  const onChangeData = (index, key, value) => {
    const temp = _.cloneDeep(formData);
    temp[index][key] = value;
    setFormData(temp);
  };

  const onChangeCountry = (country) => {
    let temp = _.cloneDeep(formData);
    temp = temp.map((item) => ({
      ...item,
      country_id: country.id,
      state_ids: country?.states?.map((item) => item.id),
    }));
    setFormData(temp);
    setSelectedCountry(country);
  };

  const onToggleAdd = () => {
    let temp = _.cloneDeep(formData);
    temp.push({
      name: "",
      date: "",
      country_id: selectedCountry?.id ?? "",
      state_ids: selectedCountry?.states?.map((item) => item.id) ?? [],
      showRegion: false,
    });
    setFormData(temp);
  };

  return (
    <ModalDialog
      title={"New Public Holiday"}
      fullWidth={true}
      fullHeight={true}
      onClose={() => props.onChangeHolidayHOC(false, "showCreateModal")}
      children={
        <div className="d-flex flex-column" style={{ gap: 12 }}>
          <div className="d-flex flex-column">
            <h2 className="at-form-input__title">Country</h2>
            <CustomSelect
              className={"at-datepicker__select"}
              selectItems={countries}
              updateSelect={(item) => onChangeCountry(item)}
              currentlySelected={selectedCountry}
            />
          </div>
          <FormHeader title={"Holidays"} moduleIcon={"atlas-calendar-2"} />
          {formData?.map((data, index) => (
            <div
              key={index}
              className="at-form__content mb-0 g-2"
              style={{ backgroundColor: "#F3F4F6" }}
            >
              <div className="at-public-holiday__cont">
                <div className="grid-control w-100">
                  <div className="grid-half-col">
                    <CustomFormInput
                      type="text"
                      value={data?.name}
                      className="w-100"
                      onChangeValue={(val) => onChangeData(index, "name", val)}
                      placeholder={"Name"}
                    />
                  </div>
                  <div className="grid-half-col">
                    <div className="at-form-input w-100">
                      <DatePicker
                        value={Moment(data?.date)}
                        onChange={(date) => onChangeData(index, "date", Moment(date).format("DD/MM/YYYY"))}
                      />
                    </div>
                  </div>
                </div>
                <CustomButton
                  className="btn-new btn-new--outline-secondary w-100"
                  containerClass={`at-public-holiday__delete-btn ${index === 0 ? "hide" : ""}`}
                  onClick={() => {
                    const newFormData = [...formData];
                    newFormData.splice(index, 1);
                    setFormData(newFormData);
                  }}
                  children={
                    <>
                      <BsTrash3Fill
                        style={{ width: 16, height: 16, color: "#F04438" }}
                      />
                      <span>Delete</span>
                    </>
                  }
                />
              </div>
              <div className="d-flex align-items-center">
                <CustomCheckbox
                  content={"All Regions"}
                  checked={_.values(selectedCountry.states)?.every((item) =>
                    data.state_ids.includes(item.id),
                  )}
                  onChangeCheckboxValue={(e) => {
                    if (e.target.checked) {
                      onChangeData(
                        index,
                        "state_ids",
                        selectedCountry?.states.map((item) => item.id),
                      );
                    } else {
                      onChangeData(index, "state_ids", []);
                    }
                  }}
                />
                <CustomButton
                  className="btn-new align-items-center"
                  style={{ color: "#2563EB" }}
                  onClick={() =>
                    onChangeData(index, "showRegion", !data.showRegion)
                  }
                  children={
                    <div className="d-flex align-items-center">
                      {data.showRegion ? "Hide" : "Show"} All
                      {data.showRegion ? (
                        <BsChevronUp
                          style={{
                            width: 16,
                            height: 16,
                            marginLeft: 8,
                            color: "#2563EB",
                          }}
                        />
                      ) : (
                        <BsChevronDown
                          style={{
                            width: 16,
                            height: 16,
                            marginLeft: 8,
                            color: "#2563EB",
                          }}
                        />
                      )}
                    </div>
                  }
                />
              </div>
              {data.showRegion && (
                <div className="d-flex align-items-center flex-wrap g-3">
                  {!_.isEmpty(selectedCountry) &&
                    selectedCountry?.states.map((item) => (
                      <CustomCheckbox
                        content={item.name}
                        checked={data.state_ids.includes(item.id)}
                        onChangeCheckboxValue={(e) => {
                          const newFormData = [...formData];
                          if (e.target.checked) {
                            newFormData[index].state_ids.push(item.id);
                          } else {
                            newFormData[index].state_ids = newFormData[
                              index
                            ].state_ids.filter((id) => id !== item.id);
                          }
                          setFormData(newFormData);
                        }}
                      />
                    ))}
                </div>
              )}
            </div>
          ))}
          <CustomButton
            className="btn-new btn-new--outline-secondary"
            onClick={() => onToggleAdd()}
            children={
              <>
                <AtlasIcon
                  svgHref={"atlas-add-linear"}
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 8,
                    fill: "#3B82F6",
                  }}
                />
                Add Holiday
              </>
            }
          />
        </div>
      }
      footer={
        <div className="d-flex g-3">
          <CustomButton
            className="btn-new btn-new--primary"
            disabled={
              formData.some((item) => !item.name || !item.date) ||
              formData.length === 0 ||
              !selectedCountry
            }
            onClick={() => props.createHoliday(formData)}
            children={"Submit"}
          />
          <CustomButton
            className="btn-new btn-new--outline-secondary"
            onClick={() => props.onChangeHolidayHOC(false, "showCreateModal")}
            children={"Cancel"}
          />
        </div>
      }
    />
  );
};

export default CreateHoliday;
