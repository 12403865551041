import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { IoMdCloseCircle, IoMdAlert } from "react-icons/io";
import Moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import { FaArrowCircleDown } from "react-icons/fa";

import AlertBox from "components/AlertBox";
import CustomCheckbox from "components/Checkbox";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import ConfirmationModal from "components/Modal/confirmation";
import MeasurementInfo from "./components/MeasurementInfo";
import SubsalesTooltip from "../../../components/tooltip";
import CobrokeInfo from "./components/Cobroke";
import BasicInfo from "./components/BasicInfo";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomSelect from "components/Select";
import AtlasAutosuggest from "components/Autosuggest";
import CustomTypeahead from "components/Typeahead/new";
import IOSButton from "components/Button/toggle";
import DatePicker from "components/Input/datetimepicker";
import { edgePropStateMap } from "containers/Dashboard/SuperadminSubsales/assets.js";

import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import edgePropLogo from "assets/images/edgeprop_logo.png";
import { getTranslation } from "assets/translation";
import permissionsChecker from "utils/permissionsChecker";
import useOnScreen from "utils/hooks/useOnScreen";
import {
  categories,
  bathroom_options,
  bedroom_options,
  car_park_options,
  measurements,
  tenure_types,
  title_types,
  unit_types,
  furnishing_statuses,
  directions,
  occupancies,
  statuses,
  facilties
} from "dictionary/subsales";

import "../../../index.scss";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000DD",
    fontSize: 15,
  },
}))(Tooltip);

const Details = (props) => {
  const { can_update } = permissionsChecker("Listing Centre", props.data);
  
  const topSection = useRef();
  const selectedLocalised = useSelector((state) => state.languageReducer.language);
  const onScreen = useOnScreen(topSection);
  const [tempStatus, setTempStatus] = useState(null);
  const [RTEMounted, setRTEMounted] = useState(false);

  const {
    subsalesEntryCreationProps,
    isPublishedIQI,
    isPublishedJuwai,
    isPublishedEdge,
    selectedMeasurement,
    dictionary,
    selectedListingCategory,
    publishListing,
    availableDate,
    otherFacilities,
    selectedStatus,
    description,
    zh_description,
    descriptionType,
    selectedCategory,
    selectedPropertyType,

    getLocalised,
    renderSpecialMessage,
    edgeAutoSuggest,
    onChangeValue,
    onChangeEditHOC,
    onChangeSubsalesHOC,
    onChangeDetailsHOC,
    onCheckCheckboxValue,
    onClickUpdateDetails,
  } = props;

  const { currencies = [], countries = [] } = dictionary;
  useEffect(() => {
    setTimeout(() => {
      setRTEMounted(true);
    }, 1000);

    return () => setRTEMounted(false);
  }, []);

  const onClickTop = () => {
    document
      .getElementById(`top-section`)
      .scrollIntoView({ behavior: "smooth", block: "bottom" });
  };

  const onClickBottom = () => {
    if (window.innerWidth > 992) {
      document
        .getElementById(`description-section`)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      document
        .getElementById(`partner-section`)
        .scrollIntoView({ behavior: "smooth" });
    }
  };

  const onSelectPropertyName = (val) => {
    if (val) {
      let tempCountry = _.find(countries, { name: "Malaysia" });
      let tempStateMapping = _.find(edgePropStateMap, { id: val.state_id_i });
      let tempState = _.find(tempCountry.states, {
        id: tempStateMapping.map_id,
      });
      onChangeValue("Edge Prop Asset ID", val.asset_id_i);
      onChangeValue("Property Name", val.name_s_lower);
      onChangeValue("Post Code", val.postal_code_i);
      onChangeValue("Country", tempCountry || {});
      onChangeValue("State", tempState || {});
      onChangeValue("Township", val.area_s_lower);
    }
  };

  const debounceAutoSuggest = useCallback(
    _.debounce((param) => edgeAutoSuggest(param), 600),
    [],
  );
  const onChangePropertyName = (query) => {
    onChangeValue("Property Name", query);
    if (query) {
      debounceAutoSuggest({ query, selectedCategory, selectedPropertyType });
    }
  };

  const renderChineseDesc = () => {
    return (
      renderSpecialMessage(getLocalised(
        "label.details.helptext.description.message_ch", 
        `Please take note that the following Chinese description is only
        applicable and will be shown in listings exported to juwai.com and will
        not apply to listings published in iqiglobal.com. There are three ways
        to export the Chinese description of your listing’s to Juwai. The first
        method will be prioritised, followed by the second, and third.

        The 2 methods are as follows:
        1. You can opt to write your own Chinese description for the listing
        which will overwrite the existing Chinese description when exporting
        your listing to Juwai.
        2. You may choose to translate your listings English description into
        the Chinese language via professional translation services provided by
        Juwai, on condition that you have subscribed to any of the Juwai
        packages. Should you wish to choose this method, you may leave the
        Chinese description blank.`
    )));
  }

  const renderEnglishDesc = () => {
    return (
      <p>
        {getLocalised(
          "label.details.helptext.description.message_en", 
          `Please write the description of your listing in English here. The
          listing will not be imported into Juwai if there are any Chinese
          characters in the description. Please use the Chinese description
          segment, if you wish to key in Chinese characters for your listing.`
        )}
      </p>
    );
  };

  const renderScrollToPartner = () => {
    return (
      <DarkTooltip
        classes={{ tooltip: "tooltip-arrow top" }}
        aria-label={"action-button"}
        placement={"top"}
        title={onScreen 
          ? getTranslation("scroll_to_bottom", selectedLocalised) 
          : getTranslation("scroll_to_top", selectedLocalised)
        }
      >
        <button
          className="at-listing__admin-platform-btn"
          style={{
            transform: onScreen ? "rotate( 0deg )" : "rotate( 180deg )",
          }}
          onClick={() => (onScreen ? onClickBottom() : onClickTop())}
        >
          <FaArrowCircleDown />
        </button>
      </DarkTooltip>
    );
  };

  const renderAddressSection = () => {
    const {
      sub_area,
      address,
      hidden_address,
      selectedCountry,
      selectedState,
      postalCode,
      township,
      property_name,
      autoSuggestOptions,
      townshipArray,
      subAreaArray
    } = props;
    return (
      <div className="grid-control">
        <section className="grid-full-col">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h2 className="at-form-input__title">{getLocalised("label.details.address.property_name", "Property Name")}</h2>
            <SubsalesTooltip
              data={{
                showToolTip: true,
                showEdgeTooltip: true,
                toolTip: "propertyName",
              }}
            />
          </div>
          <i style={{ fontSize: 13, marginBottom: 10, color: "red" }}>
            {getLocalised(
              "label.details.helptext.address.property_name", 
              `Select the official name of the project/township from the drop-down list, or type it in manually if it isn"t in the list.`
            )}
          </i>
          <CustomTypeahead
            typeaheadId={"property_name"}
            options={autoSuggestOptions || []}
            selectedValue={property_name ? [property_name] : []}
            filterBy={[
              "name_s_lower",
              "state_s_lower",
              "street_name_s_lower",
              "area_s_lower",
            ]}
            labelKey={"name_s_lower"}
            onSearch={(val) => onChangePropertyName(val)}
            onSelect={(val) =>
              val && val.length > 0 && onSelectPropertyName(val[0])
            }
            childrenHead={(rowItem) => <p>{rowItem.name_s_lower}</p>}
            highlighterData={[
              (option) =>
                `Area: ${option.area_s_lower} | State: ${option.state_s_lower}`,
            ]}
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title" required>{getLocalised("label.details.address.property_address", "Property Address")}</h2>
          <CustomFormInput
            type="text"
            required={true}
            value={address}
            onChangeValue={(val) => onChangeValue("Address", val)}
          />
        </section>
        <section className="grid-full-col">
          <h2 className="at-form-input__title">{getLocalised("label.details.address.hidden_address", "Hidden Address")}</h2>
          <CustomFormInput
            type="text"
            value={hidden_address}
            onChangeValue={(val) => onChangeValue("Hidden Address", val)}
          />
        </section>
        <section className="grid-half-col">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h2 className="at-form-input__title" required>
              {getLocalised("label.details.address.country", "Country")}
            </h2>
            <SubsalesTooltip
              data={{
                showJuwaiAsiaToolTip: true,
                showJuwaiToolTip: true,
                toolTip: "country",
              }}
            />
          </div>
          <CustomSelect
            required={true}
            className="mb-3 w-100"
            selectItems={countries}
            currentlySelected={selectedCountry}
            updateSelect={(val) => onChangeValue("Country", val)}
          />
        </section>
        <section className="grid-half-col">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h2 className="at-form-input__title" required>
              {getLocalised("label.details.address.state_province", "State / Province")}
            </h2>
            <SubsalesTooltip
              data={{
                showToolTip: true,
                showEdgeTooltip: true,
                showJuwaiAsiaToolTip: true,
                toolTip: "state",
              }}
            />
          </div>
          <CustomSelect
            required={true}
            className="mb-2 w-100"
            selectItems={
              selectedCountry && selectedCountry.states
                ? _.orderBy(selectedCountry.states, ["name"], ["asc"])
                : []
            }
            currentlySelected={selectedState}
            updateSelect={(val) => onChangeValue("State", val)}
          />
        </section>
        <section className="grid-half-col">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h2 className="at-form-input__title">{getLocalised("label.details.address.area_city", "Area / City")}</h2>
            <SubsalesTooltip
              data={{
                showToolTip: true,
                showJuwaiAsiaToolTip: true,
                showJuwaiToolTip: true,
                showEdgeTooltip: true,
                toolTip: "townShip",
              }}
            />
          </div>
          <CustomTypeahead
            required={true}
            typeaheadId={"township"}
            options={townshipArray}
            selectedValue={
              _.find(townshipArray, { name: township })
                ? [_.find(townshipArray, { name: township })]
                : []
            }
            labelKey={"name"}
            filterBy={["name"]}
            onSearch={(val) =>
              onChangeValue(
                "Township",
                val && val.length > 0 ? val[0].name : "",
              )
            }
            onSelect={(val) =>
              onChangeValue(
                "Township",
                val && val.length > 0 ? val[0].name : "",
              )
            }
            childrenHead={(rowItem) => <p>{rowItem.name}</p>}
          />
        </section>
        <section className="grid-half-col">
          <div className={"mb-10"}>
            <h2 className="at-form-input__title">{getLocalised("label.details.address.sub_area", "Sub Area")}</h2>
            <AtlasAutosuggest
              className={"at-listing__info-autosuggest"}
              placeholder=" "
              value={sub_area || ""}
              inputStyle={{ height: 40, borderColor: "#e0e0e0", fontSize: 15 }}
              updateValue={(val) => onChangeValue("Sub Area", val)}
              options={subAreaArray}
            />
          </div>
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title" required>
            {getLocalised("label.details.address.postal_code", "Postal Code")}
          </h2>
          <CustomFormInput
            type="text"
            required={true}
            value={postalCode}
            onChangeValue={(val) => onChangeValue("Post Code", val)}
          />
        </section>
      </div>
    );
  };

  const renderPriceSection = () => {
    const {
      selectedCurrency,
      askingPrice,
      askingPricePerMeasurementUnit,
      monthlyMaintainanceFee,
      onCheckCheckboxValue,
      isMonthlyMaintainanceFeePerMeasurementUnit
    } = props;
    return (
      <div className="grid-control">
        <section className="grid-half-col">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h2 className="at-form-input__title">{getLocalised("label.details.price.currency", "Currency")}</h2>
            <SubsalesTooltip
              data={{
                showToolTip: true,
                showJuwaiToolTip: true,
                showEdgeTooltip: true,
                toolTip: "currency",
              }}
            />
          </div>
          <CustomSelect
            className="mb-3 w-100"
            selectItems={currencies.name || []}
            currentlySelected={selectedCurrency}
            updateSelect={(val) => onChangeValue("Currency", val)}
          />
        </section>
        <section className="grid-half-col">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h2 className="at-form-input__title" required>
              {getLocalised("label.details.price.asking_price", "Asking Price")}
            </h2>
            <SubsalesTooltip
              data={{
                showToolTip: true,
                showJuwaiToolTip: true,
                showJuwaiAsiaToolTip: true,
                showEdgeTooltip: true,
                toolTip: "askingPrice",
              }}
            />
          </div>
          <CustomFormInput
            type="number"
            required={true}    
            value={askingPrice}
            onChangeValue={(val) => onChangeValue("Asking Price", val)}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title" required>
          {getLocalised(
            `label.details.price.asking_price_per_${
              selectedMeasurement.name === "Square Feet" ? "square_feet" :
              selectedMeasurement.name === "Square Meter" ? "square_meter" :
              selectedMeasurement.name === "Hectare" ? "hectares" :
              selectedMeasurement.name === "Acres" ? "acres" :
              ""
            }_unit`, 
            "Asking Price Per Square Feet Unit"
          )}
          </h2>
          <CustomFormInput
            type="number"
            required={true}
            value={askingPricePerMeasurementUnit}
            onChangeValue={(val) =>
              onChangeValue("Asking Price Per Measurement Unit", val)
            }
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title" required>
            {getLocalised("label.details.price.monthly_maintenance_fee", "Monthly Maintenance Fee")}
          </h2>
          <CustomFormInput
            type="number"
            required={true}
            value={monthlyMaintainanceFee}
            onChangeValue={(val) =>
              onChangeValue("Monthly Maintenance Fee", val)
            }
          />
          <CustomCheckbox
            labelClassname="my-3"
            checked={isMonthlyMaintainanceFeePerMeasurementUnit}
            content={
              getLocalised(
              `label.details.price.monthly_maintenance_fee_${
                selectedMeasurement.name === "Square Feet" ? "sqft" :
                selectedMeasurement.name === "Square Meter" ? "sqm" :
                selectedMeasurement.name === "Hectare" ? "hectare" :
                "acres"
              }`, 
              `Monthly Maintenance Fee is per ${selectedMeasurement.name} unit`
            )}
            onChangeCheckboxValue={() =>
              onCheckCheckboxValue(
                "Monthly maintainance fee per measurement unit",
              )
            }
          />
        </section>
      </div>
    );
  };

  const renderIntegratedMessage = () => {
    const localisedText = getLocalised(
      "label.details.details.main_message", 
      `Move your mouse pointer over the {icon_1} or {icon_2} or {icon_3} or {icon_4} 
      beside the fields below to see how your input will reflect on the Subsales listing 
      on both www.iqiglobal.com and www.juwai.com respectively.
      <br/>
      To see examples of how your listing will be published on the site, click on the buttons below.`
    );
    return renderSpecialMessage(localisedText, "icon");
  };
    
  const renderInfo = () => {
    return (
      <div>
        <div className="at-form__content" id="top-section" ref={topSection}>
          <p className="fs-2">
            {
              renderIntegratedMessage()
            }
            <a
              onClick={() => {
                onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
                onChangeSubsalesHOC("IQI", "listingPreviewType");
              }}
            >
              <img style={{ width: 25, margin: "0px 5px" }} src={iqiLogo} />
            </a>
            <a
              onClick={() => {
                onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
                onChangeSubsalesHOC("JUWAI", "listingPreviewType");
              }}
            >
              <img style={{ width: 25, margin: "0px 5px" }} src={juwaiLogo} />
            </a>
            <a
              onClick={() => {
                onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
                onChangeSubsalesHOC("JUWAIASIA", "listingPreviewType");
              }}
            >
              <img
                style={{ width: 25, margin: "0px 5px" }}
                src={juwaiAsiaLogo}
              />
            </a>
            <a
              onClick={() => {
                onChangeSubsalesHOC(true, "showSubsalesListingPreviewModal");
                onChangeSubsalesHOC("EDGE", "listingPreviewType");
              }}
            >
              <img
                style={{ width: 25, margin: "0px 5px", borderRadius: "50%" }}
                src={edgePropLogo}
              />
            </a>
          </p>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="at-form__content">
              <div
                className="at-subsales-listing__view-title"
                style={{ marginTop: 2 }}
              >
                <AtlasIcon
                  svgHref={"atlas-building-3"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.details.title", "Details")}
              </div>
              <BasicInfo
                categories={categories}
                unit_types={unit_types}
                countries={countries}
                title_types={title_types}
                occupancies={occupancies}
                furnishing_statuses={furnishing_statuses}
                tenure_types={tenure_types}
                selectedListingCategory={(val) => selectedListingCategory(val)}
                onChangeValue={(title, val) => onChangeValue(title, val)}
                {...props}
              />
            </div>
            <div className="at-form__content">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-location"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.address.title", "Address")}
              </div>
              {renderAddressSection()}
            </div>
            <div className="at-form__content">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-dollar-circle"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.price.title", "Price")}
              </div>
              {renderPriceSection()}
            </div>
            <div className="at-form__content">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-home"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.specification.title", "Specification")}
              </div>
              <MeasurementInfo
                bedroom_options={bedroom_options}
                bathroom_options={bathroom_options}
                directions={directions}
                measurements={measurements}
                onChangeValue={(title, val) => onChangeValue(title, val)}
                onCheckCheckboxValue={(title) => onCheckCheckboxValue(title)}
                currencies={currencies ? currencies.name : []}
                car_park_options={car_park_options}
                {...props}
              />
            </div>
            <div className="at-form__content">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-weight"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.facilities.title", "Facilities")}
              </div>
              <section className="grid-full-col">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h2 className="at-form-input__title">{getLocalised("label.details.facilities.title", "Facilities")}</h2>
                  <SubsalesTooltip
                    data={{
                      showToolTip: true,
                      showJuwaiToolTip: true,
                      showJuwaiAsiaToolTip: true,
                      toolTip: "facilities",
                    }}
                  />
                </div>
              </section>
              <div className="grid-control">
                {facilties.map((item) => {
                  return (
                    <section className="grid-third-col mb-10">
                      <CustomCheckbox
                        checked={props[item.props] || false}
                        content={item.name}
                        translation={item.key}
                        onChangeCheckboxValue={() =>
                          onCheckCheckboxValue(item.name)
                        }
                        getLocalised={getLocalised}
                      />
                    </section>
                  );
                })}
              </div>
              <section className="grid-full-col mt-10">
                <h2 className="at-form-input__title">{getLocalised("label.details.facilities.other_facilities", "Other Facilities")}</h2>
                <CustomFormInput
                  type="text"
                  value={otherFacilities}
                  onChangeValue={(val) =>
                    onChangeValue("Other Facilities", val)
                  }
                  required={false}
                  placeholder={getLocalised("label.details.placeholder.facilities.other_facilities", "Enter your facilities here")}
                />
              </section>
            </div>
            <div className="at-form__content" id="description-section">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-message-text-1"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.description.title", "Description")}
              </div>
              <section className="grid-full-col">
                <div className="at-listing__description-tab-cont">
                  <button
                    className={
                      descriptionType === "English"
                        ? "at-listing__description-selected-tab"
                        : ""
                    }
                    onClick={() =>
                      onChangeEditHOC("English", "descriptionType")
                    }
                  >
                    {getLocalised("label.details.tab.description.en", "English")}
                  </button>
                  <button
                    className={
                      descriptionType === "Chinese"
                        ? "at-listing__description-selected-tab"
                        : ""
                    }
                    onClick={() =>
                      onChangeEditHOC("Chinese", "descriptionType")
                    }
                  >
                    {getLocalised("label.details.tab.description.zh", "Chinese")}
                  </button>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <h2 className="at-form-input__title">
                    {descriptionType === "English"
                      ? getLocalised("label.details.description.subtitle_1_en", "Property Description in English")
                      : getLocalised("label.details.description.subtitle_1_zh", "Property Description in Chinese")}
                  </h2>
                  <SubsalesTooltip
                    data={{
                      showToolTip: true,
                      showJuwaiAsiaToolTip: true,
                      showJuwaiToolTip: true,
                      showEdgeTooltip: true,
                      toolTip: "description",
                    }}
                  />
                </div>
                <p className="fs-2 my-2">
                  {descriptionType === "English"
                    ? getLocalised("label.details.description.subtitle_2_en", "Please write the description of your listing in English here.")
                    : getLocalised("label.details.description.subtitle_2_en", "Please write the description of your listing in Chinese here.")}
                </p>
                <AlertBox
                  mode={"info"}
                  containerStyle={{ marginBottom: "8px" }}
                  description={
                    descriptionType === "Chinese"
                      ? renderChineseDesc()
                      : renderEnglishDesc()
                  }
                />
                {RTEMounted && descriptionType === "English" && (
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      onChangeValue("Description", data);
                    }}
                    config={{
                      toolbar: [],
                      mediaEmbed: {
                        previewsInData: true,
                        providers: [
                          {
                            name: "youtube",
                            url: [
                              /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
                              /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
                              /^youtube\.com\/embed\/([\w-]+)/,
                              /^youtu\.be\/([\w-]+)/,
                            ],
                            html: (match) => {
                              const id = match[1];

                              return (
                                '<div style="aspect-ratio: 16/9;">' +
                                `<iframe src="https://www.youtube.com/embed/${id}" ` +
                                'style="position: relative; width: 100%; height: 100%;" ' +
                                'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                                "</iframe>" +
                                "</div>"
                              );
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
                {RTEMounted && descriptionType === "Chinese" && (
                  <CKEditor
                    editor={ClassicEditor}
                    data={zh_description}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      onChangeValue("Chinese Description", data);
                    }}
                    config={{
                      toolbar: [],
                      mediaEmbed: {
                        previewsInData: true,
                        providers: [
                          {
                            name: "youtube",
                            url: [
                              /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
                              /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
                              /^youtube\.com\/embed\/([\w-]+)/,
                              /^youtu\.be\/([\w-]+)/,
                            ],
                            html: (match) => {
                              const id = match[1];

                              return (
                                '<div style="aspect-ratio: 16/9;">' +
                                `<iframe src="https://www.youtube.com/embed/${id}" ` +
                                'style="position: relative; width: 100%; height: 100%;" ' +
                                'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                                "</iframe>" +
                                "</div>"
                              );
                            },
                          },
                        ],
                      },
                    }}
                  />
                )}
              </section>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="at-form__content">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-send-2"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.status.title", "Status")}
              </div>
              <div className="grid-control">
                <section className="grid-full-col">
                  <h2 className="at-form-input__title">{getLocalised("label.details.status.available_date", "Available Date")}</h2>
                  <div className="at-form-input">
                    <DatePicker
                      showYearDropdown={true}
                      placeholderText={getLocalised("label.details.placeholder.status.available_date", "Select available date")}
                      value={Moment(availableDate)}
                      onChange={(val) =>
                        onChangeDetailsHOC(
                          Moment(val).format("YYYY-MM-DD"),
                          "availableDate",
                        )
                      }
                    />
                  </div>
                </section>
                <section className="grid-full-col">
                  <h2 className="at-form-input__title">{getLocalised("label.details.status.status", "Status")}</h2>
                  <AtlasRadioGroup
                    mode={"old"}
                    valueKey={"id"}
                    checkedValue={selectedStatus}
                    containerClassName={"w-100"}
                    selectedRadioValue={(val) => {
                      selectedStatus === 1
                        ? setTempStatus(val)
                        : onChangeValue("Status", val);
                    }}
                    renderLabel={(val, label) => (
                      <>
                        <span>{label}</span>
                        {val === 4 && (
                          <Tooltip
                            placement={"top"}
                            title={getLocalised("label.details.helptext.status.delete", 
                              "Click this only if you are absolutely sure. Once updated, you are unable to change the status of this listing anymore."
                            )}
                          >
                            <IoMdAlert
                              className={"color-danger"}
                              style={{ width: 20, height: 20, marginLeft: 10 }}
                            />
                          </Tooltip>
                        )}
                      </>
                    )}
                    options={statuses}
                    getLocalised={getLocalised}
                  />
                </section>
              </div>
            </div>
            <div className="at-form__content">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-shake-hand"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.co_broke.title", "Co-broke")}
              </div>
              <CobrokeInfo {...props} />
            </div>
            <div className="at-form__content" id="partner-section">
              <div className="at-subsales-listing__view-title mb-20">
                <AtlasIcon
                  svgHref={"atlas-global"}
                  style={{ fill: "#F0631D", marginRight: 10 }}
                />
                {getLocalised("label.details.partner_platform.title", "Partner Platform")}
              </div>
              {selectedStatus !== 1 && (
                <AlertBox
                  mode={"warning"}
                  containerStyle={{ marginBottom: "8px" }}
                  description= {getLocalised(
                    "label.details.helptext.partner_platform.message", 
                    "Please set the status to Active and save unsaved changes before clicking on the toggle below."
                  )}
                />
              )}
              <section className="grid-full-col">
                <div>
                  <IOSButton
                    className={"at-toggle__secondary mr-2"}
                    disabled={!can_update}
                    currentState={!isPublishedIQI}
                    onToggleButton={() =>
                      publishListing(
                        subsalesEntryCreationProps.id,
                        1,
                        !isPublishedIQI,
                      )
                    }
                  />
                  <div
                    style={{ width: 20, height: 20, marginRight: 10 }}
                    className={"svg-icon-info"}
                    children={<img src={iqiLogo} />}
                  />
                  <span className="at-form-input__title">IQI Website</span>
                </div>
                <div>
                  <IOSButton
                    className={"at-toggle__secondary mr-2"}
                    disabled={!can_update}
                    currentState={!isPublishedJuwai}
                    onToggleButton={() =>
                      publishListing(
                        subsalesEntryCreationProps.id,
                        2,
                        !isPublishedJuwai,
                      )
                    }
                  />
                  <div
                    style={{ width: 20, height: 20, marginRight: 10 }}
                    className={"svg-icon-info"}
                    children={<img src={juwaiLogo} />}
                  />
                  <span className="at-form-input__title">Juwai Website</span>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                    className={"svg-icon-info"}
                    children={<img src={juwaiAsiaLogo} />}
                  />
                  <span className="at-form-input__title">Juwai Asia</span>
                </div>
                {subsalesEntryCreationProps.country_id === 1 && (
                  <div>
                    <IOSButton
                      className={"at-toggle__secondary mr-2"}
                      disabled={!can_update}
                      currentState={!isPublishedEdge}
                      onToggleButton={() =>
                        publishListing(
                          subsalesEntryCreationProps.id,
                          4,
                          !isPublishedEdge,
                        )
                      }
                    />
                    <div
                      style={{ width: 20, height: 20, marginRight: 10 }}
                      className={"svg-icon-info"}
                      children={
                        <img
                          src={edgePropLogo}
                          style={{ borderRadius: "50%" }}
                        />
                      }
                    />
                    <span className="at-form-input__title">Edge Property</span>
                  </div>
                )}
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStatusUpdate = (val) => {
    return (
      <ConfirmationModal
        open={tempStatus}
        maxWidth={"md"}
        mode={"alert"}
        message={
          <>
            <p>
              {renderSpecialMessage(getLocalised("label.details.status.delete.confirmation_1", "You are about to change status of an <strong>active</strong> listing. The following will occur:"))}
            </p>
            <p className={"mt-3"}>
              <IoMdCloseCircle
                className={"color-danger"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              {renderSpecialMessage(getLocalised("label.details.status.delete.confirmation_2", "All Co-broke agreements for this listing will be set to <strong>No Longer Available</strong>."))}
            </p>
            <p className={"mt-2"}>
              <IoMdAlert
                className={"color-danger"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              {renderSpecialMessage(getLocalised("label.details.status.delete.confirmation_3", "A notification will be sent to any existing Co-broke agents for this listing."))}
            </p>
            <p className={"mt-2"}>
              <IoMdAlert
                className={"color-danger"}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              {renderSpecialMessage(getLocalised("label.details.status.delete.confirmation_4", "This listing will not show up on IQI Global, Juwai.com etc."))}
            </p>
          </>
        }
        positiveAction={() => {
          onChangeValue("Status", tempStatus);
          setTempStatus(null);
        }}
        negativeAction={() => setTempStatus(null)}
        getLocalised={getLocalised}
      />
    );
  };

  return (
    <div className="subsales-details" style={{ marginBottom: 60 }}>
      {renderInfo()}
      {renderStatusUpdate()}
      {renderScrollToPartner()}
      <div className="d-flex">
        <button
          disabled={!can_update}
          className="btn-new btn-new--success mr-3"
          onClick={() => onClickUpdateDetails()}
        >
          {getTranslation("update", selectedLocalised)}
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={() => onChangeSubsalesHOC(false, "showEditModal")}
        >
          {getTranslation("close", selectedLocalised)}
        </button>
      </div>
    </div>
  );
};

export default Details;
