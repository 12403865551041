import React, { Component } from "react";
import _ from "lodash";

import ConfirmationModal from "components/Modal/delete";
import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Delete } from "utils/axios";

const searchParamsUser = [
  {
    label: "Full Name",
    value: "user_full_name_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Role",
    value: "user_role_id_eq",
    type: "radio",
    param: "",
    options: [
      { label: "Admin", value: 4 },
      { label: "Agent", value: 5 },
      { label: "Assistant Agent", value: 7 },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      showCreateUser: false,
      loading: false,

      showConfirmationModal: false,
      showConfirmDeleteModal: false,
      searchParams: searchParamsUser,

      toRemoveID: false,
      userLists: [],
      userPages: [],
    };

    onChangeUsersHOC = (key, value) => this.setState({ [key]: value });

    load = (param, loading) =>
      this.setState({ [loading ? loading : "loading"]: param });

    getUserLists = (page = 1, search = "") => {
      const { id } = this.props.selectedPermissionGroup;
      Get(
        `/admin/permission_groups/${id}/permission_group_users?${search}page=${page}`,
        this.getUserListsSuccess,
        this.getUserListsError,
        this.load,
      );
    };
    getUserListsSuccess = (payload) => {
      let tempPages = [];
      if (payload.meta.pages && payload.meta.pages > 0) {
        for (let i = 0; i < payload.meta.pages; i++) {
          tempPages.push(i);
        }
      }

      this.setState({
        userLists: payload,
        userPages: tempPages,
      });
    };
    getUserListsError = (errorMessage) => {
      requestError(errorMessage);
    };

    createUserList = (dataToSubmit) => {
      let data = {
        permission_group_id: this.props.selectedPermissionGroup.id,
        user_id: dataToSubmit.id,
        id: dataToSubmit.id,
      };
      Post(
        `/admin/permission_groups/${this.props.selectedPermissionGroup.id}/permission_group_users`,
        data,
        this.createUserListSuccess,
        this.createUserListError,
        this.load,
      );
    };
    createUserListSuccess = () => {
      this.getUserLists(this.props.selectedPermissionGroup.id, 1, "");
      this.setState({ showCreateUser: false });
      requestSuccess("The user is added successfully.");
    };
    createUserListError = (errorMessage) => {
      requestError(errorMessage);
    };

    deleteUserList = () => {
      Delete(
        `/admin/permission_groups/${this.props.selectedPermissionGroup.id}/permission_group_users/${this.state.toRemoveID}`,
        this.deleteUserListSuccess,
        this.deleteUserListError,
        this.load,
      );
    };
    deleteUserListSuccess = () => {
      this.setState({ showConfirmDeleteModal: false });
      requestSuccess("The user is deleted from the list successfully.");
      this.getUserLists(this.props.selectedPermissionGroup.id, 1, "");
    };
    deleteUserListError = (errorMessage) => {
      requestError(errorMessage);
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            userLists={this.state.userLists}
            userPages={this.state.userPages}
            onLoadUsers={this.state.loading}
            showCreateUser={this.state.showCreateUser}
            searchParams={this.state.searchParams}
            onChangeUsersHOC={this.onChangeUsersHOC}
            getUserLists={this.getUserLists}
            createUserList={this.createUserList}
            deleteUserList={this.deleteUserList}
          />

          <ConfirmationModal
            open={this.state.showConfirmDeleteModal}
            loading={this.state.loading}
            message={"Are you sure to remove the selected record?"}
            positiveAction={() => {
              this.deleteUserList();
            }}
            negativeAction={() =>
              this.setState({ showConfirmDeleteModal: false })
            }
          />
        </>
      );
    };
  }

  return WithHOC;
};

export default HOC;
