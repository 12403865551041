import React, { Component, createRef } from "react";
import _ from "lodash";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import SideBarIcon from "components/Icon/sideBarIcon";
import CustomFormInput from "components/Input/formInput";
import CustomNewAccordion from "./newAccordion";

import { SuperAdminRoutingData } from "./routingData/superadmin";
import { AssociatedAgentRoutingData } from "./routingData/associatedAgent";
import { SidebarMenuData } from "./routingData/agent";
import { PARoutingData } from "./routingData/personalAssistant";
import { AdminRoutingData } from "./routingData/admin";
import { PMRoutingData } from "./routingData/projectManager";

import packageJSON from "../../../package.json";
import IQILogo from "assets/images/iqi_logo_only.png";
import { getTranslation } from "assets/translation";
import { getItem } from "utils/tokenStore";

import "stylesheets/components/sidebar/mobile.scss";

const HideMyPA = [{ name: "My PA" }];

const HiddenInactiveMenu = [{ name: "IQI Academy" }, { name: "My PA" }];

const iconGradients = [
  {
    deg: 15,
    stops: [
      { offset: "21.93%", stopColor: "#FF7E5A" },
      { offset: "99.47%", stopColor: "#C53617" },
    ],
  },
  {
    deg: 15,
    stops: [
      { offset: "35.64%", stopColor: "#FFB018" },
      { offset: "89.57%", stopColor: "#EC5D0D" },
    ],
  },
  {
    deg: 15,
    stops: [
      { offset: "32.77%", stopColor: "#61DAA7" },
      { offset: "93.31%", stopColor: "#0A8449" },
    ],
  },
  {
    deg: 15,
    stops: [
      { offset: "35.2%", stopColor: "#8EB2FF" },
      { offset: "98.52%", stopColor: "#2657C0" },
    ],
  },
];

class Mobile extends Component {
  state = {
    filteredRoutingData: [],
    filteredSearchMenu: [],
    selectionParentMenu: [],
    searchKeyword: "",
    activeParentMenu: "",
    active_link_menu: "",
  };

  componentDidMount = () => {
    document.addEventListener(
      "click",
      (e) => {
        if (e.target.className === "at-sidebar__overlay") {
          this.props.onCloseSidebarModal();
        }
      },
      true,
    );
  };

  componentDidUpdate = (prevProps) => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    const compareProfileReducer =
      prevProps.data.profileReducer !== this.props.data.profileReducer;
    const compareCurrentSignInReducer =
      prevProps.data.currentSignInProfileReducer !==
      this.props.data.currentSignInProfileReducer;
    const comparePermissionReducer =
      prevProps.data.permissionsReducer !== this.props.data.permissionsReducer;
    const compareAppSettingReducer =
      prevProps.data.appSettingReducer !== this.props.data.appSettingReducer;

    if (
      compareProfileReducer ||
      compareCurrentSignInReducer ||
      comparePermissionReducer ||
      compareAppSettingReducer
    ) {
      const { role } = tmpProfileReducer;

      const tempFilteredRoutingData = [];
      let tempRead = null;
      if (role === "Personal Assistant") {
        tempRead = _.filter(
          this.props.data.permissionsReducer.pa_permissions,
          (o) => o.can_read,
        );
        tempRead.push({
          module_id: 1,
          module_name: "Dashboard",
        });
        PARoutingData?.forEach((menuData) => {
          const temp = this.filterRoutingData("access", menuData, tempRead);
          temp && tempFilteredRoutingData.push(temp);
        });
      } else if (role === "Associated Agent") {
        tempRead = _.filter(
          this.props.data.permissionsReducer.aa_permissions,
          (o) => {
            return o.can_read;
          },
        );
        AssociatedAgentRoutingData?.forEach((menuData) => {
          const temp = this.filterRoutingData("access", menuData, tempRead);
          temp && tempFilteredRoutingData.push(temp);
        });
      } else if (role === "Admin") {
        tempRead = _.filter(
          this.props.data.permissionsReducer.admin_permissions,
          (o) => o.can_read,
        );
        tempRead.push({
          module_id: 0,
          module_name: "My Admin Roles",
        });
        tempRead.push({
          module_id: 1,
          module_name: "Dashboard",
        });
        tempRead.push({
          module_id: 99,
          module_name: "Analytics Center",
        });
        AdminRoutingData?.forEach((menuData) => {
          const temp = this.filterRoutingData("access", menuData, tempRead);
          temp && tempFilteredRoutingData.push(temp);
        });
      } else if (role === "Project Manager") {
        tempRead = _.filter(
          this.props.data.permissionsReducer.pm_permissions,
          (o) => o.can_read,
        );
        PMRoutingData?.forEach((menuData) => {
          const temp = this.filterRoutingData("access", menuData, tempRead);
          temp && tempFilteredRoutingData.push(temp);
        });
      }
      this.setState({ filteredRoutingData: tempFilteredRoutingData });
    }
  };

  processItemToShow = (itemChild) => {
    const { role, status_id, is_superior } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (role !== "Agent") return false;
    else if (status_id === 2)
      return _.differenceBy(itemChild, HiddenInactiveMenu, "name");
    else if (!is_superior) return _.differenceBy(itemChild, HideMyPA, "name");
    else return itemChild;
  };

  filterRoutingData = (mode, menuData, tempRead) => {
    if (menuData.children && !menuData.id) {
      const tempContent = [];

      menuData.children.forEach((menu) => {
        const temp = this.filterRoutingData(mode, menu, tempRead);
        temp && tempContent.push(temp);
      });

      if (tempContent.length > 0) {
        return {
          ...menuData,
          children: tempContent,
        };
      } else {
        return false;
      }
    }

    if (tempRead) {
      const tempModules = _.find(tempRead, (menu) => {
        if (mode === "access") {
          if (typeof menuData.id === "number") {
            return menu.module_id === menuData.id;
          } else {
            return menuData.id ? menuData.id.includes(menu.module_id) : true;
          }
        } else if (mode === "forbidden") {
          return menu.name !== menuData.name;
        } else if (mode === "search" && menuData.name) {
          return getTranslation(menuData.key, this.props.data.languageReducer.language)?.toLowerCase()?.includes(menu.name.toLowerCase());
        }
      });
      return tempModules ? menuData : false;
    }

    return menuData;
  };

  onSelectMenu = (param) => {
    this.setState({ active_link_menu: param.name, activeParentMenu: "" });
    this.props.history.push(`${this.props.idPath}${param.navigateTo}`);
    this.props.onCloseSidebarModal();
    if (param.href) {
      if (param.name === "IQI Academy") {
        this.props.getSSOToken("https://academy.iqiglobal.com");
      } else {
        window.open(param.href, "_blank");
      }
    }
  };

  onHandleAgentMenu = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate") > -1
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const token = window.location.href.indexOf("/admin-impersonate") > -1
      ? getItem("IQI_ATLAS_JWT_AGENT_TOKEN")
      : getItem("IQI_ATLAS_JWT_TOKEN");
    const AgentRoutingData = _.cloneDeep(SidebarMenuData(token));

    if (
      [
        "VP",
        "Group VP",
        "HOT",
        "Team Leader",
        "Leader",
        "Team Manager",
      ].indexOf(tmpProfileReducer.job_title_name) > -1
    ) {
      return AgentRoutingData;
    }

    const tempFilter = [];

    AgentRoutingData?.forEach((menuData) => {
      const temp = this.filterRoutingData("forbidden", menuData, [
        { name: "Leader Team Sales Ranking" },
      ]);
      temp && tempFilter.push(temp);
    });
    return tempFilter;
  };

  assignRoutingData = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    switch (tmpProfileReducer.role) {
      case "Super Admin":
        return SuperAdminRoutingData;
      case "Admin":
        return this.state.filteredRoutingData;
      case "Agent":
        return this.onHandleAgentMenu();
      case "Personal Assistant":
        return this.state.filteredRoutingData;
      case `Associated Agent`:
        return this.state.filteredRoutingData;
      case "Project Manager":
        return this.state.filteredRoutingData;

      default:
        return [];
    }
  };

  onChangeSearchValue = (val, routingData) => {
    const tempFilter = [];
    const tempRoutingData = _.cloneDeep(routingData);
    const searchSelectionParentMenu = [];
    this.setState({ searchKeyword: val }, () => {
      if (val.length > 0) {
        tempRoutingData.forEach((menuData) => {
          const temp = this.filterRoutingData("search", menuData, [
            { name: val },
          ]);
          if (temp) {
            tempFilter.push({ ...temp, open: true });
            searchSelectionParentMenu.push(temp.name);
          };
        });
        this.setState({ filteredSearchMenu: tempFilter, selectionParentMenu: searchSelectionParentMenu});
      } else {
        this.setState({ filteredSearchMenu: routingData, selectionParentMenu: [] });
      }
    });
  };

  renderMobileMenu = (item) => {
    const { activeParentMenu, selectionParentMenu, searchKeyword } = this.state;
    const { history, cartCount, data } = this.props;
    const isActivateLinkMenu = this.state.active_link_menu === item.name;

    if (item.children) {
      return (
        <CustomNewAccordion
          mode="mobile"
          key={item.name}
          expand={selectionParentMenu.includes(item.name)}
          item={item}
          history={history}
          idPath={this.props.idPath}
          cartCount={cartCount}
          itemToShow={this.processItemToShow(item.children)}
          activeParentMenu={activeParentMenu}
          selectionParentMenu={selectionParentMenu}
          onSeletedMenu={(key,val) => this.setState({ [key]: val })}  
          searchKeyword={searchKeyword}
          onCloseMenu={this.props.onCloseSidebarModal}
          currentSelected={item.children.find((o) =>
            this.props.history.location.pathname.endsWith(o.navigateTo),
          )}
          getSSOToken={this.props.getSSOToken}
        />
      );
    } else {
      return (
        <button
          key={item.name}
          className={`justify-content-start at-sidebar_link_menu-cont
            ${
              isActivateLinkMenu
              ? "router-link-exact-active active"
              : ""
            }`}
          onClick={() => {
            this.onSelectMenu(item);
            this.props.onCloseSidebarModal();
          }}
        >
          { isActivateLinkMenu && <AtlasIcon svgHref={item.icon} className={"at-sidebar__icon"} /> }
          { !isActivateLinkMenu && <SideBarIcon svgHref={item.icon} className={"at-sidebar__icon"} gradient={item.gradient} /> }
          <span className={"at-sidebar__name"}>{getTranslation(item.key, data.languageReducer.language) || item.name}</span>
        </button>
      );
    }
  };

  addGradient = (menu) => {
    let x = 0;
    return menu.map((item) => {
      let newMenu = { ...item, gradient: iconGradients[x] };
      if (item.children) {
        let y = x
        let newChild = [];
        y = y < 3 ? ( y + 1 ) : 0
        item.children.map((child)=>{
          newChild.push( {...child,  gradient: iconGradients[y]})
          y = y < 3 ? ( y+ 1 ) : 0
        })
        x = x < 3 ? ( x + 1 ) : 0
        newMenu = { ...newMenu, children: newChild }
      } else {
        x = x < 3 ? ( x + 1 ) : 0
      }
      return newMenu
    });
  };

  render = () => {
    const { expand } = this.props;
    const tempRoutingData = this.addGradient(this.assignRoutingData());

    return (
      <>
        <div className={`at-new-sidebar-mobile ${expand ? "active" : ""}`}>
          <CustomButton
            className={"at-new-sidebar-mobile__btn_close"}
            children={
              <SideBarIcon
                svgHref={"atlas-X"}
                style={{
                  width: 12,
                  height: 12,
                  fill: "white",
                  margin: "auto",
                }}
              />
            }
            onClick={this.props.onCloseSidebarModal}
          />
          <div className="at-new-sidebar__avatar-cont">
            <img src={IQILogo} alt={"user avatar icon"} />
            <h4>
              ATLAS
              <p style={{ fontSize: 10 }}>{`Version ${packageJSON.version}`}</p>
            </h4>
          </div>
          <CustomFormInput
            type="text"
            className={"at-sidebar__search"}
            value={this.state.searchKeyword}
            showClearButton={true}
            onChangeValue={(val) =>
              this.onChangeSearchValue(val, tempRoutingData)
            }
            prefix={
              <SideBarIcon
                svgHref={"atlas-search-normal"}
                style={{ width: 18, height: 18 }}
              />
            }
            placeholder={getTranslation("label.search_menu_keyword", this.props.data.languageReducer.language)}
            />

          <div className="at-sidebar-mobile__inner-cont">
            <section className="at-sidebar-mobile__section">
              {this.state.searchKeyword.length > 0 && (
                <>
                  {this.state.filteredSearchMenu.length > 0 &&
                    this.state.filteredSearchMenu.map((item) =>
                      this.renderMobileMenu(item),
                    )}
                  {!this.state.filteredSearchMenu.length > 0 && (
                    <span className="at-sidebar__no-result">
                      No results found
                    </span>
                  )}
                </>
              )}
              {!this.state.searchKeyword.length > 0 &&
                tempRoutingData.map((item) => this.renderMobileMenu(item))}
            </section>
          </div>
        </div>
        {expand && <div className="at-sidebar__overlay"></div>}
      </>
    );
  };
}

export default Mobile;