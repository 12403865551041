import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";

import { requestSuccess, requestError } from "utils/requestHandler";

import { Get, Post, Put } from "utils/axios";
import EventBg from "assets/images/event_notification.jpg";

import { eventDataKey } from "../assets";

const EventsHOC = (WrappedComponent) => {
  class EventsWrappedComponent extends Component {
    state = {
      initialEvents: [],
      selectedTicketType: {},

      loading: false,
      paymentReference: {},
      showCheckInDialog: false,
      showEventDetailsDialog: false,
      showEventCheckoutDialog: false,
      showRegistrationSuccess: false,
      showPaymentMethod: false,
      showErrorMessage: false,
      errorPaymentMessage: "",
      currentDisplayMonth: new Date(),
      filterBranchRegion: { label: "All", value: 0 },
      branchRegions: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeCompanyEventsHOC = (val, context) =>
      this.setState({ [context]: val });

    onToggleEventDetailsOverlay = (param) =>
      this.setState({ showEventDetailsDialog: param });

    getDateRange = (start_date_time, end_date_time) => {
      const startDate = !_.isEmpty(start_date_time) ? Moment(start_date_time).format("DD MMM YYYY HH:mm") : "N/A"
      const endDate = !_.isEmpty(end_date_time) ? Moment(end_date_time).format("DD MMM YYYY HH:mm") : "N/A"
      
      return `${startDate} - ${endDate}`
    }
    
    getEvents = () =>
      Get(`/events`, this.getEventsSuccess, this.getEventsError, this.load);
    getEventsSuccess = (payload) => {
      let tmpPayload = _.map(payload, (event) => {
        let start_day = event.start_date_time
          ? Moment(event.start_date_time).dayOfYear()
          : "";
        let end_day = event.end_date_time
          ? Moment(event.end_date_time).dayOfYear()
          : "";
        let isOneDayEvent = end_day === start_day;
        let tmpEventInfo = { isOneDayEvent: isOneDayEvent };
        eventDataKey.map((eventInfo) => {
          const { key, value } = eventInfo;
          return (tmpEventInfo[key] = event[value]);
        });
        return {
          ...tmpEventInfo,
          type: event && event.type_id === 1 ? "event" : "training",
        };
      });
      return this.setState({
        initialEvents: tmpPayload.filter((item) => item.date !== null),
      });
    };
    getEventsError = (error) => requestError("Failed to obtain events.");

    getSelectedEvent = (id) =>
      Get(
        `/events/${id}`,
        this.getSelectedEventSuccess,
        this.getSelectedEventError,
        this.load,
      );
    getSelectedEventSuccess = (payload) => {
      let {
        id,
        name,
        start_date_time,
        end_date_time,
        clock_in_time,
        clock_out_time,
        registration_date,
        cut_off_date,
        location,
        location_url,
        speaker,
        event_photo_url,
        branch_region,
        description,
        entry_pass,
        pricing,
      } = payload;

      let tmp = {
        id: id,
        Event: name || "N/A",
        "Event Date": this.getDateRange(start_date_time, end_date_time),
        "Cutoff Date": !_.isEmpty(cut_off_date) ? cut_off_date : "",
        "Registration Date": !_.isEmpty(registration_date) ? registration_date : "",
        "Checked In/Out": this.getDateRange(clock_in_time, clock_out_time),
        Venue: location || "N/A",
        Location: location_url || "",
        Speaker: speaker || "N/A",
        "Branch Region": branch_region && (branch_region.name || "N/A"),
        "Event Image": event_photo_url || "N/A",
        Description: description || "N/A",
        [[4, 6].indexOf(payload.training_type_id) !== -1 ? "Fee" : "Price"]:
          pricing || "N/A",
        "Entry Pass": entry_pass || "N/A",
        Type: "event",
      };

      this.props.onChangeCalendarHOC(EventBg, "defaultModalImage");
      this.props.onChangeCalendarHOC(tmp, "selectedData");
      this.props.onChangeCalendarHOC(payload, "selected_data_original");
      this.props.onChangeCalendarHOC(true, "showDetailsDialog");
      this.setState({ currentDisplayMonth: start_date_time });
    };
    getSelectedEventError = (error) => {
      this.props.onChangeCalendarHOC(EventBg, "defaultModalImage");
      this.props.onChangeCalendarHOC(true, "showDetailsDialog");

      requestError(error);
    };

    joinSelectedEvent = (id, dataToSubmit = {}) => {
      const {
        paymentProof = "",
        ticket_pricing_id = 0,
        payment_id,
      } = dataToSubmit;
      return Post(
        `/events/${id}/join`,
        {
          ...(paymentProof && {
            proof_of_payment: paymentProof.file,
            proof_of_payment_file_name: paymentProof.filename,
          }),
          payment_id,
          ticket_pricing_id,
        },
        () => this.joinSelectedEventSuccess(paymentProof),
        this.joinSelectedEventError,
        this.load,
      );
    };
    joinSelectedEventSuccess = (paymentProof) => {
      const { selected_data_original } = this.props;

      if (!paymentProof) {
        requestSuccess(
          selected_data_original.need_approval
            ? `Your registration request for the event is currently being processed. You will be notified when it's approved.`
            : "You have successfully registered for the event.",
        );
      }

      this.setState({
        showEventCheckoutDialog: false,
        ...(paymentProof && {
          showRegistrationSuccess: true,
        }),
      });
      this.getEvents();
      this.getSelectedEvent(this.props.selectedData.id);
    };
    joinSelectedEventError = (error) => requestError(error);

    savePaymentApproval = (dataToSubmit) =>
      Post(
        `/payments`,
        dataToSubmit,
        this.savePaymentApprovalSuccess,
        this.savePaymentApprovalError,
        this.load,
      );
    savePaymentApprovalSuccess = (payload) =>
      this.setState({
        paymentReference: payload,
        errorPaymentMessage: "",
        showPaymentMethod: true,
      });
    savePaymentApprovalError = (error, response) => {
      this.setState({ showErrorMessage: true }, () => {
        if (response) {
          if (response.status === 422 && error) {
            return this.setState({ errorPaymentMessage: error });
          } else if ([500, 404].indexOf(response.status) === -1) {
            return this.setState({
              errorPaymentMessage: "An unexpected condition was encountered.",
            });
          } else if ([422].indexOf(response.status) === -1) {
            return this.setState({ errorPaymentMessage: error });
          }
        }
      });
    };

    updatePaymentApproval = (dataToSubmit, onSubmitStatus) => {
      Put(
        `/payments/${this.state.paymentReference.payment_id}`,
        dataToSubmit,
        (payload) => this.updatePaymentApprovalSuccess(payload, onSubmitStatus),
        (error, response) =>
          this.updatePaymentApprovalError(error, response, onSubmitStatus),
        this.load,
      );
    };
    updatePaymentApprovalSuccess = (payload, onSubmitStatus) => {
      if (onSubmitStatus) {
        onSubmitStatus(true);
      }
      this.setState({
        paymentReference: {
          ...this.state.paymentReference,
          updatedPayment: payload,
        },
        errorPaymentMessage: "",
        showPaymentMethod: true,
      });
    };
    updatePaymentApprovalError = (error, response, onSubmitStatus) => {
      if (onSubmitStatus) {
        onSubmitStatus(false);
      }
      this.setState({ showErrorMessage: true }, () => {
        if (response) {
          if (response.status === 422 && error) {
            return this.setState({ errorPaymentMessage: error });
          } else if ([500, 404].indexOf(response.status) === -1) {
            return this.setState({
              errorPaymentMessage: "An unexpected condition was encountered.",
            });
          } else if ([422].indexOf(response.status) === -1) {
            return this.setState({ errorPaymentMessage: error });
          }
        } else {
          this.setState({ errorPaymentMessage: error || "" });
        }
      });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadEvents={this.state.loading}
            initialEvents={this.state.initialEvents}
            paymentReference={this.state.paymentReference}
            selectedTicketType={this.state.selectedTicketType}
            currentDisplayMonth={this.state.currentDisplayMonth}
            showCheckInDialog={this.state.showCheckInDialog}
            showEventCheckoutDialog={this.state.showEventCheckoutDialog}
            showRegistrationSuccess={this.state.showRegistrationSuccess}
            showPaymentMethod={this.state.showPaymentMethod}
            showErrorMessage={this.state.showErrorMessage}
            errorPaymentMessage={this.state.errorPaymentMessage}
            getEvents={this.getEvents}
            updatePaymentApproval={this.updatePaymentApproval}
            getSelectedEvent={this.getSelectedEvent}
            savePaymentApproval={this.savePaymentApproval}
            joinSelectedEvent={this.joinSelectedEvent}
            onChangeCompanyEventsHOC={this.onChangeCompanyEventsHOC}
          />
        </>
      );
    };
  }
  return EventsWrappedComponent;
};

export default EventsHOC;
