import React from "react";
import Moment from "moment";
import _ from "lodash";

import CustomSelect from "components/Select";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import CustomRadioGroup from "components/RadioGroup";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import DatePicker from "components/Input/datetimepicker";

import { priorityTypes } from "../assets";
import { LeadStatus } from "dictionary/lead";
import UpdateLeadsHOC from "../actions/update";

const LeadsQuickUpdateModal = ({
  onLoad,
  selectedLead,

  onChangeLeadsHOC,
  updateSelectedLead,
}) => {
  const {
    remark,
    type_id,
    close_date,
    status_id,
    priority_id,
    buyer_name,
    buyer_email,
    lead_source,
    buyer_phone_number,
  } = selectedLead;

  const StatusOptions = Object.keys(LeadStatus[type_id]).map((key) => ({
    label: LeadStatus[type_id][key],
    value: parseInt(key),
  }));

  const onChangeSelectedLead = (val, context) => {
    let temp = { ...selectedLead };
    temp[context] = val;
    onChangeLeadsHOC(temp, "selectedLead");
  };

  const onToggleUpdate = () => {
    let temp = {
      priority_id: parseInt(selectedLead.priority_id),
      close_date: selectedLead.close_date,
      status_id: selectedLead.status_id,
      remark: selectedLead.remark,
    };

    updateSelectedLead(selectedLead.id, temp);
  };

  return (
    <ModalDialog
      title={"Update Lead"}
      responsiveSize={"md"}
      onClose={() => onChangeLeadsHOC(false, "showQuickUpdateModal")}
      children={
        <>
          <div className="at-update-lead__lead-detail row">
            <div className="col-md-6">
              <p className="at-form-input__title">{buyer_name}</p>
              <p className="at-table__text-ellipsis mb-2">
                <AtlasIcon
                  svgHref={"atlas-sms"}
                  style={{
                    width: 16,
                    height: 16,
                    fill: "#F0631D",
                    marginRight: 5,
                  }}
                />
                <span style={{ fontSize: 12 }}>{buyer_email}</span>
                <AtlasIcon
                  svgHref={"atlas-sms-edit"}
                  className={"btn-new--social-media"}
                  style={{ width: 24, height: 24, fill: "#3B82F6" }}
                  onClick={() =>
                    window.open(`mailto:${buyer_email}`, "__blank")
                  }
                />
              </p>
              <p className="at-table__text-ellipsis">
                <AtlasIcon
                  svgHref={"atlas-call"}
                  style={{
                    width: 16,
                    height: 16,
                    fill: "#F0631D",
                    marginRight: 5,
                  }}
                />
                <span style={{ fontSize: 12 }}>
                  {buyer_phone_number || "N/A"}
                </span>
                <AtlasIcon
                  svgHref={"atlas-whatsapp"}
                  className={"btn-new--social-media mr-0"}
                  style={{ width: 24, height: 24, fill: "#12B76A" }}
                  onClick={() =>
                    window.open(
                      `https://api.whatsapp.com/send?phone=${buyer_phone_number}`,
                      "__blank",
                    )
                  }
                />
                <AtlasIcon
                  svgHref={"atlas-call"}
                  className={"btn-new--social-media"}
                  style={{ width: 24, height: 24, fill: "#3B82F6" }}
                  onClick={() =>
                    window.open(`tel:${buyer_phone_number}`, "__blank")
                  }
                />
              </p>
            </div>
            <div className="at-update-lead__lead-detail-source col-md-6">
              <p className="fs-2 mb-2">Source</p>
              {lead_source && lead_source.length > 0 && (
                <p className="fs-2 mb-2">{lead_source[0]}</p>
              )}
              {lead_source &&
                lead_source.length > 1 &&
                lead_source.map((item, index) => (
                  <p key={index} style={{ fontSize: 12, fontWeight: 500 }}>
                    {item}
                  </p>
                ))}
            </div>
          </div>
          <div className="d-flex align-items-center my-3 grid_gap-2">
            <div
              className="at-badge-icon at-badge-icon-warning"
              style={{ width: 32, minWidth: 32, height: 32 }}
            >
              <AtlasIcon svgHref={"atlas-notification-status"} />
            </div>
            <h2 className="fw-600 fs-3">Lead Status</h2>
          </div>
          <div className="grid-control">
            <section className="grid-half-col">
              <h2 className="at-form-input__title">Status</h2>
              <CustomSelect
                className="w-100"
                selectItems={StatusOptions}
                currentlySelected={_.find(StatusOptions, { value: status_id })}
                updateSelect={(val) =>
                  onChangeSelectedLead(val.value, "status_id")
                }
              />
            </section>
            <section className="grid-half-col">
              <h2 className="at-form-input__title">Close Date</h2>
              <div className="at-form-input">
                <DatePicker
                  value={Moment(close_date)}
                  onChange={(val) =>
                    onChangeSelectedLead(
                      Moment(val).format("DD MMM YYYY"),
                      "close_date",
                    )
                  }
                />
              </div>
            </section>
            <section className="grid-full-col">
              <h2 className="at-form-input__title">Priority</h2>
              <CustomRadioGroup
                checkedValue={priority_id}
                options={priorityTypes}
                selectedRadioValue={(val) =>
                  onChangeSelectedLead(val, "priority_id")
                }
              />
            </section>
            <section className="grid-full-col">
              <h2 className="at-form-input__title">Remark</h2>
              <CustomFormInput
                type="textarea"
                value={remark}
                onChangeValue={(val) => onChangeSelectedLead(val, "remark")}
              />
            </section>
          </div>
          {onLoad && <LoadingModal />}
        </>
      }
      footer={
        <div className="d-flex g-3">
          <CustomButton
            className={"btn-new btn-new--success"}
            children={"Update"}
            onClick={() => onToggleUpdate()}
          />
          <CustomButton
            className={"btn-new btn-new--outline-secondary"}
            children={"Cancel"}
            onClick={() => onChangeLeadsHOC(false, "showQuickUpdateModal")}
          />
        </div>
      }
    />
  );
};

export default UpdateLeadsHOC(LeadsQuickUpdateModal);
