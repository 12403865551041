import React, { Component } from "react";

class ChaskiqChat extends Component {

  componentDidMount = () => {
    const {
      email,
      name,
      role,
      avatarUrl,
      mobile_number,
      identifierKey,
    } = this.props

    const script = document.createElement('script')
    script.src = process.env.REACT_APP_BOT_SCRIPT_SRC
    script.async = true

    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(script, s)

    script.onload = () => new window.ChaskiqMessengerEncrypted({
      domain: process.env.REACT_APP_BOT_DOMAIN,
      ws: process.env.REACT_APP_BOT_WS,
      app_id: process.env.REACT_APP_BOT_ID,
      data: {
        email: email,
        identifier_key: identifierKey,
        mobile_number: mobile_number,
        properties: {
          name: name,
          role: role,
          avatarUrl: avatarUrl,
        }
      },
      lang: "en"
    })
  }

  componentWillUnmount = () => {
    const script = document.querySelector(`script[src="${process.env.REACT_APP_BOT_SCRIPT_SRC}"]`);
    const atlasBot = document.getElementById('ChaskiqMessengerRoot')
    if(atlasBot){
      atlasBot.remove()
    }
    if(script){
      script.parentNode.removeChild(script)
      script.onload = null
      script.remove()
    }
    this.setState({ onLoad: false })
  }

  render() {
    if(!this.props.token) return null;

    return (
      <div id="chaskiq-root"></div>
    )
  }
}
export default ChaskiqChat