import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";
import {
  Card,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import AtSelectNew from "components/Select/new";
import AtInput from "components/Input/formInput";
import AtlasRadioGroup from "components/RadioGroup";
import DatePicker from "components/Input/datetimepicker";

class SearchContent extends Component {
  renderSearchParams = () => {
    return (
      <>
        <div className="row mb-10">
          {this.props.searchParams &&
            this.props.searchParams.map((item, index) => {
              return (
                <div
                  key={item.value}
                  className={`col-md-${item.col ? item.col : 6} mb-2`}
                >
                  <label>{item.label}</label>
                  <br />
                  {item.type === "input" && (
                    <AtInput
                      value={item.param}
                      onChangeValue={(value) => {
                        let tmp = _.cloneDeep(this.props.searchParams);
                        tmp[index].param = value;
                        return this.props.onChangeSearchParams(tmp);
                      }}
                    />
                  )}
                  {item.type === "select" && (
                    <AtSelectNew
                      value={item.param}
                      onChange={(e) => {
                        let tmp = _.cloneDeep(this.props.searchParams);
                        tmp[index].param = e.target.value;
                        return this.props.onChangeSearchParams(tmp);
                      }}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {item.options.map((option) => {
                        return (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            style={{ width: "100%", fontSize: 14 }}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </AtSelectNew>
                  )}
                  {item.type === "radio" && (
                    <AtlasRadioGroup
                      horizontal={true}
                      checkedValue={item.param}
                      containerClassName={"w-100"}
                      selectedRadioValue={(val) => {
                        let tmp = _.cloneDeep(this.props.searchParams);
                        tmp[index].param = val;
                        this.props.onChangeSearchParams(tmp, tmp[index].value);
                      }}
                      options={item.options}
                    />
                  )}
                  {item.type === "date" && (
                    <div className="at-form-input">
                      <DatePicker
                        dateFormat={item.format ? item.format : "YYYY-MM-DD"}
                        value={item.param}
                        onChange={(val) => {
                          let tmp = _.cloneDeep(this.props.searchParams);
                          tmp[index].param = Moment(val).format("YYYY-MM-DD");
                          return this.props.onChangeSearchParams(tmp);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        {this.props.searchParams && this.props.searchParams.length > 0 && (
          <div className="d-flex" style={{ marginBottom: 15 }}>
            <button
              className="btn-new btn-new--secondary"
              onClick={() => {
                let tmp = "";
                this.props.searchParams.map((item) => {
                  tmp =
                    item.param && item.param !== ""
                      ? tmp + `q[${item.value}]=${item.param}&`
                      : tmp;
                });
                this.setState({ interval: 10 });
                this.props.getListAPI(1, tmp);
              }}
            >
              Search
            </button>
            <button
              className="btn-new btn-new--danger"
              style={{ marginLeft: 10 }}
              onClick={() => {
                let tmp = _.cloneDeep(this.props.searchParams);
                tmp.map((item) => {
                  item.param = "";
                });
                this.setState({ interval: 10 });
                this.props.onChangeSearchParams(tmp);
                this.props.getListAPI(1, "");
              }}
            >
              Reset
            </button>
          </div>
        )}
      </>
    );
  };

  render = () => {
    return (
      <Card style={{ padding: 15, marginBottom: 20 }}>
        {this.renderSearchParams()}
      </Card>
    );
  };
}

export default SearchContent;
