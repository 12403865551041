import React, { Component } from 'react'

import AtlasButton from 'components/Button' 
import AtlasIcon from 'components/Icon/atlasIcon'
import LoadingModal from 'components/LoadingModal'
import ModalDialog from 'components/Modal/ModalDialog'
import ZoomForm from './zoomForm'

import ZoomHOC from "../actions/zoom";
import permissionsChecker from "utils/permissionsChecker";
import CustomButton from "components/Button";

class ZoomSessionContent extends Component {
  componentDidMount = () => {
    Promise.all([
      this.props.getSelectedEvent(this.props.selectedEvent.id)
    ]).then(() => {
      this.props.getEventZoomSession(this.props.selectedEvent.id);
    })
  };

  renderCreateContent = () => {
    const {
      data,
      onLoadZoom,
      selectedEvent,
      showCreateModal,
      onChangeZoomHOC,
      createEventZoomSession,
    } = this.props;
    const { can_create } = permissionsChecker("Company Events", data);
    return (
      <>
        <h2 style={{ fontSize: 14, fontWeight: 400, color: "#1F2937", marginBottom: 16 }}>
          {"This event does not have any zoom session created."}
        </h2>
        {can_create && (
          <AtlasButton
            className={"btn-new btn-new--primary mr-10"}
            children={
              <> 
                <AtlasIcon svgHref={"atlas-zoom"} style={{ width: 20, height: 20, fill: "white" }} />
                Create Zoom Session
              </>
            }
            onClick={() => onChangeZoomHOC(true, "showCreateModal")}
          />
        )}
        {showCreateModal && (
          <ModalDialog
            title={"Create zoom session"}
            onClose={() => onChangeZoomHOC(false, "showCreateModal")}
            children={
              <ZoomForm
                mode={"create"}
                data={data}
                onLoadZoom={onLoadZoom}
                selectedEvent={selectedEvent}
                onClose={() => onChangeZoomHOC(false, "showCreateModal")}
                createEventZoomSession={createEventZoomSession}
              />
            }
          />
        )}
      </>
    );
  };

  renderEditContent = () => {
    const {
      data,
      onLoadZoom,
      selectedEvent,
      selectedEventZoom,
      editEventZoomSession,
      updateAttendance,
      refreshProcessingStatus,
      getStartZoomLink,
    } = this.props;

    return (
      <>
        <ZoomForm
          mode={"edit"}
          data={data}
          onLoadZoom={onLoadZoom}
          selectedEvent={selectedEvent}
          selectedEventZoom={selectedEventZoom}
          onClose={() =>
            this.props.onChangeZoomHOC(false, "showSelectLinkDialog")
          }
          editEventZoomSession={editEventZoomSession}
          getStartZoomLink={getStartZoomLink}
          updateAttendance={updateAttendance}
          refreshProcessingStatus={refreshProcessingStatus}
        />
      </>
    );
  };

  renderSelectLinkContent = () => {
    return (
      <ModalDialog
        title={ 'View Meetings' }
        onLoad={ this.props.onLoadZoom }
        onClose={ () => this.props.onChangeZoomHOC( false, 'showSelectLinkDialog' ) }
        children={ 
          <div className='d-flex flex-column g-2'>
            { this.props.zoomLinks.map( item => (
                <CustomButton
                  style={{ width: '100%' }}
                  containerClass={ 'btn-new btn-new--secondary mb-2' }
                  containerStyle={{ backgroundColor: item.background }}
                  onClick={ () => window.open( item.start_url, '_blank' )}>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                      <AtlasIcon svgHref={ 'atlas-zoom' } style={{ width: 28, height: 28, fill: 'white' }} />
                      <div className='d-flex flex-column g-1'>
                        <label className='at-form-input__title text-white'>{ item.name }</label>
                        <p className='fs-1 text-white'>{ `id: ${ item.zoom_meeting_id }` }</p>
                      </div>
                    </div>
                    <div className='my-3 border-left border-secondary mx-3'>Start</div>
                  </div>
                </CustomButton>
              ) ) }
          </div>
        }
        footer ={
          <div className='d-flex g-3'>
            <button
              className='btn-new btn-new--outline-secondary'
              onClick={ () => this.props.onChangeZoomHOC( false, 'showSelectLinkDialog' ) }>
              Close
            </button>
          </div>
        } />
    )
  }

  render = () => {
    return (
      <>
        { !this.props.selectedEventZoom && this.renderCreateContent() }
        { this.props.selectedEventZoom && this.renderEditContent() }
        { this.props.showSelectLinkDialog && this.renderSelectLinkContent() }
        { this.props.onLoadZoom && <LoadingModal/> }
      </>
    );
  };
}

export default ZoomHOC(ZoomSessionContent);
