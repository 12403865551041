import React, { Component } from "react";

import CustomTab from "components/Tab";
import CustomCard from "components/Card";
import ModuleHeader from "components/ModuleHeader";
import MarketingTools from "./MarketingTools";
import OrderHistory from "./OrderHistory";

import RequestFormHOC from "./actions";
import "./index.scss";

const tabSection = [
  { value: 1, label: "Marketing Tools" },
  { value: 2, label: "Order History" },
];

class RequestForm extends Component {
  state = {
    showFailureModal: false,
    showSuccessModal: false,
    selectedSection: "Marketing Tools",
  };

  renderCardContent = () => (
    <>
      <CustomTab
        sections={tabSection}
        selectedSection={this.state.selectedSection}
        onSelectSection={(val) => this.setState({ selectedSection: val })}
      />
      {this.state.selectedSection === "Marketing Tools" && (
        <MarketingTools {...this.props} />
      )}
      {this.state.selectedSection === "Order History" && (
        <OrderHistory {...this.props} />
      )}
    </>
  );

  render = () => {
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <>
        <ModuleHeader
          title={"Request Form"}
          atlasIcon={"atlas-request-form"}
          showPABadge={role === "Personal Assistant"}
          description={
            <>
              Easily initiate requests using the quick links provided. Monitor your active orders'
              progress and access your complete request history, all conveniently in one place.
            </>
          }
        />
        <CustomCard cardContent={this.renderCardContent()} />
      </>
    );
  };
}

export default RequestFormHOC(RequestForm);
