import React from "react";
import Moment from "moment";
import AtlasIcon from "components/Icon/atlasIcon";

export const occupationOptions = [
  { value: "Accounting", label: "Accounting" },
  {
    value: "Administration & Office Support",
    label: "Administration & Office Support",
  },
  { value: "Advertising, Arts & Media", label: "Advertising, Arts & Media" },
  {
    value: "Banking & Financial Services",
    label: "Banking & Financial Services",
  },
  {
    value: "Call Centre & Customer Service",
    label: "Call Centre & Customer Service",
  },
  {
    value: "Community Services & Development",
    label: "Community Services & Development",
  },
  { value: "Construction", label: "Construction" },
  { value: "Consulting & Strategy", label: "Consulting & Strategy" },
  { value: "Design & Architechture", label: "Design & Architechture" },
  { value: "Education & Training", label: "Education & Training" },
  { value: "Engineering", label: "Engineering" },
  {
    value: "Farming, Animals & Conservation",
    label: "Farming, Animals & Conservation",
  },
  { value: "Food and Beverage", label: "Food and Beverage" },
  { value: "Government & Defence", label: "Government & Defence" },
  { value: "Healthcare & Medical", label: "Healthcare & Medical" },
  { value: "Hospitality & Tourism", label: "Hospitality & Tourism" },
  {
    value: "Human Resources & Recruitment",
    label: "Human Resources & Recruitment",
  },
  {
    value: "Information & Communication Technology",
    label: "Information & Communication Technology",
  },
  { value: "Insurance & Superannuation", label: "Insurance & Superannuation" },
  { value: "Legal", label: "Legal" },
  {
    value: "Manufacturing, Transport & Logistics",
    label: "Manufacturing, Transport & Logistics",
  },
  { value: "Marketing & Communications", label: "Marketing & Communications" },
  { value: "Mining, Resources & Energy", label: "Mining, Resources & Energy" },
  { value: "Real Estate & Property", label: "Real Estate & Property" },
  { value: "Retail & Consumer Products", label: "Retail & Consumer Products" },
  { value: "Retired", label: "Retired" },
  { value: "Sales", label: "Sales" },
  { value: "Science & Technology", label: "Science & Technology" },
  { value: "Sport & Recreation", label: "Sport & Recreation" },
  { value: "Trades & Services", label: "Trades & Services" },
  { value: "Unemployed", label: "Unemployed" },
];

export const personalFieldData = [
  {
    value: "full_name",
    title: "Full Name",
    required: true,
    placeholder: "Enter Full Name",
    type: "text",
  },
  {
    value: "identification_number",
    title: "NRIC/Passport No.",
    required: true,
    placeholder: "Enter NRIC. e.g. 990101042222",
    type: "alphanumeric",
  },
  {
    value: "mobile_contact_number",
    title: "Mobile Number",
    onRequired: data =>  data?.party_id % 2 === 0,
    placeholder: "e.g. 60123456789",
    type: "number",
    instruction: "Phone number must include country code, for example, a Malaysian phone number will be like 6012345678",
  },
  { 
    value: "email", 
    title: "Email", 
    placeholder: "Enter Email", 
    type: "text", 
    required: true,
    onRequired: data => data?.party_id % 2 === 0,
  },
  { value: "gender", title: "Gender", required: true, type: "radio" },
  { value: "date_of_birth", title: "Date of Birth", placeholder: "e.g. 06-08-2019", required: true, type: "calendar" },
  {
    value: "occupation",
    title: "Occupation",
    placeholder: "Enter Occupation",
    required: false,
    type: "autosuggest",
    options: occupationOptions,
  },
  {
    value: "nationality",
    title: "Nationality",
    required: true,
    placeholder: "Enter Nationality",
    type: "autosuggest",
  },
  {
    value: "current_address",
    title: "Current Residence Address",
    required: true,
    placeholder: "Enter Current Residence Address",
    type: "text",
  },
  {
    value: "country",
    title: "Current Residence Country",
    required: true,
    placeholder: "Select Current Residence Country",
    type: "select",
  },
  {
    value: "state",
    title: "Current Residence State",
    required: true,
    placeholder: "Select Current Residence State",
    type: "select",
  },
  {
    value: "town",
    title: "Current Residence City",
    required: true,
    placeholder: "Enter Current Residence City",
    type: "autosuggest",
  },
  {
    value: "postcode",
    title: "Current Residence Postcode",
    required: true,
    placeholder: "Enter Current Residence Postcode",
    type: "number",
  },
];

export const companyFieldData = [
  {
    value: "company_name",
    title: `Company Name`,
    required: true,
    placeholder: "Enter Company Name",
    type: "text",
  },
  {
    value: "company_registration_no",
    title: `Company Reg. No. (12 Digits)`,
    required: true,
    placeholder: "e.g. 202005123456",
    type: "text",
  },
  {
    value: "job_title",
    title: "Representative's Designation",
    required: true,
    placeholder: "e.g. Director / Manager / PIC",
    type: "text",
  },
  {
    value: "full_name",
    title: "Representative's Name",
    required: true,
    placeholder: "Enter Representative's Name",
    type: "text",
  },
  {
    value: "identification_number",
    title: "Representative's NRIC/Passport No.",
    required: true,
    placeholder: "Enter NRIC. e.g. 990101042222",
    type: "alphanumeric",
  },
  {
    value: "mobile_contact_number",
    title: "Representative's Mobile Number",
    placeholder: "e.g. 60123456789",
    type: "number",
    instruction: "Phone number must include country code, for example, a Malaysian phone number will be like 6012345678",
    required: false
  },
  {
    value: "email",
    title: "Company/Representative's Email",
    placeholder: "Enter Company/Representative's Email",
    type: "text",
    required: false
  },
  {
    value: "current_address",
    title: "Company Address",
    required: true,
    placeholder: "Enter Company Address",
    type: "text",
  },
  {
    value: "country",
    title: "Country",
    required: true,
    placeholder: "Select Country",
    type: "select",
  },
  {
    value: "state",
    title: "State",
    required: true,
    placeholder: "Enter State",
    type: "select",
  },
  {
    value: "town",
    title: "Area / City",
    required: true,
    placeholder: "Enter Area / City",
    type: "autosuggest",
  },
  {
    value: "postcode",
    title: "Postcode",
    required: true,
    placeholder: "Enter Postcode",
    type: "number",
  },
];

export const personalDDFieldData = [
  {
    value: "financing_method_id",
    title: "Method of financing property sale/rental",
    placeholder: "Enter Method of financing property sale/rental",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Choose a method", disabled: true },
      { value: "1", label: "Cash" },
      { value: "2", label: "Loan" },
      { value: "3", label: "Cash & Loan" },
    ],
  },
  {
    value: "employer_name",
    title: "Name of Employer or if self-employed, Nature of Business",
    placeholder: "Enter Name of Employer or if self-employed, Nature of Business",
    className: "grid-half-col",
    type: "text",
  },
  {
    value: "salary_range_id",
    title: "Monthly Income",
    placeholder: "Enter Monthly Income",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Select a range", disabled: true },
      { value: "8", label: "None" },
      { value: "1", label: "Below RM5000" },
      { value: "2", label: "RM5,001 - RM10,000" },
      { value: "3", label: "RM10,001 - RM20,000" },
      { value: "4", label: "RM20,001 - RM30,000" },
      { value: "5", label: "RM30,001 - RM40,000" },
      { value: "6", label: "RM40,001 - RM50,000" },
      { value: "7", label: "Above RM50,000" },
    ],
  },
  {
    value: "identity_copy_url",
    title: "IC / Passport",
    placeholder: "Enter NRIC. e.g. 990101042222",
    className: "grid-half-col",
    type: "document",
  },
  {
    value: "transaction_purpose_id",
    title: "Purpose of Transaction",
    placeholder: "Enter Purpose of Transaction",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Choose one", disabled: true },
      { value: "1", label: "Own Stay/Use" },
      { value: "2", label: "Investment" },
    ],
  },
  {
    value: "declared_bankrupt",
    title: "Has the client ever been declared bankrupt in any country or jurisdiction?",
    placeholder: "",
    className: "grid-full-col",
    type: "radio",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
  },
];

export const companyDDFieldData = [
  {
    value: "nature_of_business",
    title: "Nature of Company",
    placeholder: "Enter Nature of Company",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Select an occupation", disabled: true },
      { value: "Accounting", label: "Accounting" },
      {
        value: "Administration & Office Support",
        label: "Administration & Office Support",
      },
      {
        value: "Advertising, Arts & Media",
        label: "Advertising, Arts & Media",
      },
      {
        value: "Banking & Financial Services",
        label: "Banking & Financial Services",
      },
      {
        value: "Call Centre & Customer Service",
        label: "Call Centre & Customer Service",
      },
      {
        value: "Community Services & Development",
        label: "Community Services & Development",
      },
      { value: "Construction", label: "Construction" },
      { value: "Consulting & Strategy", label: "Consulting & Strategy" },
      { value: "Design & Architechture", label: "Design & Architechture" },
      { value: "Education & Training", label: "Education & Training" },
      { value: "Engineering", label: "Engineering" },
      {
        value: "Farming, Animals & Conservation",
        label: "Farming, Animals & Conservation",
      },
      { value: "Food and Beverage", label: "Food and Beverage" },
      { value: "Government & Defence", label: "Government & Defence" },
      { value: "Healthcare & Medical", label: "Healthcare & Medical" },
      { value: "Hospitality & Tourism", label: "Hospitality & Tourism" },
      {
        value: "Human Resources & Recruitment",
        label: "Human Resources & Recruitment",
      },
      {
        value: "Information & Communication Technology",
        label: "Information & Communication Technology",
      },
      {
        value: "Insurance & Superannuation",
        label: "Insurance & Superannuation",
      },
      { value: "Legal", label: "Legal" },
      {
        value: "Manufacturing, Transport & Logistics",
        label: "Manufacturing, Transport & Logistics",
      },
      {
        value: "Marketing & Communications",
        label: "Marketing & Communications",
      },
      {
        value: "Mining, Resources & Energy",
        label: "Mining, Resources & Energy",
      },
      { value: "Real Estate & Property", label: "Real Estate & Property" },
      {
        value: "Retail & Consumer Products",
        label: "Retail & Consumer Products",
      },
      { value: "Sales", label: "Sales" },
      { value: "Science & Technology", label: "Science & Technology" },
      { value: "Sport & Recreation", label: "Sport & Recreation" },
      { value: "Trades & Services", label: "Trades & Services" },
    ],
  },
  {
    value: "transaction_purpose_id",
    title: "Purpose of Transaction",
    placeholder: "Enter Purpose of Transaction",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Choose one", disabled: true },
      { value: "1", label: "Own Stay/Use" },
      { value: "2", label: "Investment" },
    ],
  },
  {
    value: "financing_method_id",
    title: "Method of financing property sale/rental",
    placeholder: "Enter Method of financing property sale/rental",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Choose a method", disabled: true },
      { value: "1", label: "Cash" },
      { value: "2", label: "Loan" },
      { value: "3", label: "Cash & Loan" },
    ],
  },
  {
    value: "declared_bankrupt",
    title: "Has the client ever been declared bankrupt in any country or jurisdiction?",
    placeholder: "",
    className: "grid-full-col",
    type: "radio",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
  },
];

export const personalTableColumn = [
  {
    header: "Status",
    renderColumn: (rowData) =>
      rowData.indemnity_information.indemnity_status_id === 1
        ? "e-Signing link has been generated and sent"
        : rowData.indemnity_information.indemnity_status_id === 2
          ? "e-Signing has been completed for Letter of Indemnity"
          : rowData.indemnity_information.indemnity_status,
  },
  {
    header: "Date Time",
    renderColumn: (rowData) =>
      rowData.indemnity_information.indemnity_sent_at
        ? Moment(rowData.indemnity_information.indemnity_sent_at).format(
            "DD MMM YYYY hh:mm a",
          )
        : "N/A",
  },
];

export const companyTableColumn = [
  {
    header: "Full Name",
    renderColumn: (rowData) => (
      <div className={"d-flex align-items-center party-card__badge-title-icon"}>
        <AtlasIcon svgHref={"atlas-building-company"}/>
        <div className={"ml-1"}>
          {rowData.company_name}
          <br />({rowData.company_registration_no})
          <br />
          {rowData.full_name}
        </div>
      </div>
    ),
  },
  { header: "Email", accessor: "email" },
  { header: "Identity Number", accessor: "identification_number" },
  { header: "Mobile Number", accessor: "mobile_contact_number" },
  {
    header: "Status",
    renderColumn: (rowData) =>
      rowData.indemnity_information.indemnity_status_id === 1
        ? "e-Signing link generated and sent"
        : rowData.indemnity_information.indemnity_status,
  },
];

export const personalDDFormData = {
  due_diligence_type_id: "",
  financing_method_id: "",
  signing_link: "",
  salary_range_id: "",
  employer_name: "",
  copies_info: {},
  transaction_purpose_id: "",
  declared_bankrupt: false,
};

export const companyDDFormData = {
  due_diligence_type_id: "",
  nature_of_business: "",
  financing_method_id: "",
  transaction_purpose_id: "",
  declared_bankrupt: false,
};

export const declineFieldData = [
  {
    value: "supporting_document_url",
    title: "Supporting Document",
    placeholder: "",
    helpText:
      "i.e. screenshot of WhatsApp/Email conversation where client declined to share information",
    type: "document",
  },
];