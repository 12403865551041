import React, { useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { DialogContent, MenuItem } from "@material-ui/core";
import ReactHTMLParser from "react-html-parser";

import AtlasButton from "components/Button";
import AtSelectNew from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasTextarea from "components/Input/textarea";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import DatePicker from "components/Input/datetimepicker";

import { StatusLib, AgreementType } from "./assets";

const FieldData = [
  { label: "Title", value: "title", type: "text", placeholder: "Title" },
  {
    label: "Publish on",
    value: "publish_on",
    type: "date",
    placeholder: "Select Date",
  },
  { label: "Status", value: "status_id", type: "radio", options: StatusLib },
  { label: "Type", value: "type_id", type: "radio", options: AgreementType },
  {
    label: "Country",
    value: "country_id",
    type: "select",
    placeholder: "Select Country",
  },
  {
    label: "Version",
    value: "version",
    type: "number",
    placeholder: "eg: 1.2",
  },
];

const CreateAgreement = ({
  countries,
  onClickSubmit,
  onCloseModal,
  onLoadAgreement,
}) => {
  const [showPreviewModal, onTogglePreview] = useState(false);
  const [createData, onChangeCreateData] = useState({
    content: "",
    title: "",
    country_id: "",
    publish_on: null,
    version: null,
    status_id: null,
    type_id: 1,
  });

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(createData);
    temp[context] = val;
    onChangeCreateData(temp);
  };

  return (
    <>
      <ModalDialog
        title={"New Agreement"}
        onLoad={onLoadAgreement}
        fullWidth={true}
        fullHeight={true}
        onClose={onCloseModal}
        children={
          <div className="row">
            {FieldData.map((item) => (
              <div className="col-md-4" key={item.value}>
                <h2 className="at-form-input__title" required>
                  {item.label}
                </h2>
                {["text", "number"].indexOf(item.type) > -1 && (
                  <CustomFormInput
                    type={"text"}
                    required={true}
                    numericOnly={item.type === "number"}
                    value={createData[item.value]}
                    onChangeValue={(val) => {
                      let temp =
                        item.type === "number"
                          ? val.replace(/[^$0-9.]/g, "")
                          : val;

                      onChangeField(temp, item.value);
                    }}
                    placeholder={item.placeholder}
                  />
                )}
                {item.type === "date" && (
                  <div className="at-form-input">
                    <DatePicker
                      dateFormat="DD MMM YYYY"
                      maxDate={Moment()}
                      value={Moment(createData[item.value])}
                      onChange={(val) =>
                        onChangeField(
                          Moment(val).format("DD MMM YYYY"),
                          item.value,
                        )
                      }
                    />
                    <span className="at-form-input__required">required</span>
                  </div>
                )}
                {item.type === "radio" && (
                  <AtlasRadioGroup
                    valueKey={"id"}
                    labelKey={"name"}
                    horizontal={true}
                    checkedValue={`${createData[item.value]}`}
                    containerClassName={"w-100"}
                    selectedRadioValue={(val) => onChangeField(val, item.value)}
                    options={item.options}
                  />
                )}
                {item.type === "select" && (
                  <AtSelectNew
                    value={createData[item.value]}
                    required={true}
                    onChange={(e) => onChangeField(e.target.value, item.value)}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {countries.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </AtSelectNew>
                )}
              </div>
            ))}
            <div className="col-lg-12">
              <h2 className="at-form-input__title mb-2">Content</h2>
              <AtlasTextarea
                rows={10}
                value={createData.content}
                required={true}
                onChangeValue={(val) => onChangeField(val, "content")}
              />
            </div>
          </div>
        }
        footer={
          <div className="d-flex g-3">
            <AtlasButton
              disabled={_.values(createData).some((val) => !val)}
              className="btn-new btn-new--success"
              children={"Create"}
              onClick={() =>
                onClickSubmit({
                  ...createData,
                  status_id: +createData.status_id,
                  type_id: +createData.type_id,
                })
              }
            />
            <AtlasButton
              className="btn-new btn-new--secondary"
              children={"Preview"}
              onClick={() => onTogglePreview(true)}
            />
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onCloseModal()}
            >
              Cancel
            </button>
          </div>
        }
      />
      {showPreviewModal && (
        <ModalDialog
          title={"Preview"}
          fullWidth={true}
          fullHeight={true}
          onClose={() => onTogglePreview(false)}
          children={
            <DialogContent style={{ position: "relative" }}>
              {ReactHTMLParser(createData.content)}
            </DialogContent>
          }
        />
      )}
    </>
  );
};

export default CreateAgreement;
