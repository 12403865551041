import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { MdFilterList } from "react-icons/md";

import CustomTable from "components/NewTable";
import EmptyState from "components/EmptyState";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomSearchParam from "components/NewPaginatedTable/SearchParams"

import useSelectedLocalised from 'utils/hooks/useSelectedLocalised';

const columnData = [
  { header: "Locale", accessor: "language_name", searchFlag: "language_name" },
  { header: "Total Hit", accessor: "total_hits", searchFlag: "total_hits" },
  { header: "Total Unique User", accessor: "unique_users", searchFlag: "unique_users" },
]

const SearchParams = [
  {
    label: "Date From",
    value: "start_date",
    param: moment().startOf('month').format('YYYY-MM-DD'),
    type: "date",
  },
  {
    label: "Date To",
    value: "end_date",
    param: moment().format('YYYY-MM-DD'),
    type: "date",
  }
]

const Multilingual = ({
  localeChanges,
  getLocaleChange
}) => {
  const selectedLocalised = useSelectedLocalised();
  const [searchParams, setSearchParams] = useState(SearchParams);
  const [showSearchContent, setShowSearchContent] = useState(false);

  useEffect(() => {
    let tmp = "";
    searchParams.map((item) => {
      tmp =
        item.param && item.param !== ""
          ? item.value
            ? tmp + `q[${item.value}]=${item.param}&`
            : tmp + `q${item.param}&`
          : tmp;
    });

    getLocaleChange(tmp);
  }, []);

  return (
    <>
      <div className="mb-3">
        <button
          className={"btn-new btn-new--outline-secondary at-mobile-view__controller"}
          style={{ width: "100%" }}
          onClick={() => setShowSearchContent(!showSearchContent)}>
          <MdFilterList style={{ width: 20 }} />
          <h5 style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>
            Filter & Search
          </h5>
        </button>
        <div className="at-table__search-cont at-desktop-view__controller">
          <CustomSearchParam
            mode={"desktop"}
            showResetButton={true}
            searchParams={searchParams}
            selectedLocalised={selectedLocalised}
            onChangeSearchParams={setSearchParams}
            getListAPI={(page, search) => getLocaleChange(search)}
          />
        </div>
      </div>
      {(localeChanges && localeChanges.length > 0) && (
        <CustomTable
          pagination={true}
          hideSearch={true}
          rowData={localeChanges}
          columns={columnData}
        />
      )}
      {!(localeChanges && localeChanges.length > 0) && (
        <EmptyState
          title={`No statistic`}
          renderIcon={<AtlasIcon svgHref="atlas-document-text" />}
        />
      )}
      {showSearchContent && (
        <CustomSearchParam
          mode={"mobile"}
          searchParams={searchParams}
          showResetButton={true}
          onToggleSearchModal={setShowSearchContent}
          onChangeSearchParams={setSearchParams}
          getListAPI={(page, search) => getLocaleChange(search)}
        />
      )}
    </>
  )
}

export default Multilingual;