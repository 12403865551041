import React, { Component } from "react";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";
import { Post, Get, Put, Delete } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,
      isTncSaved: false,
      ticketTypeID: "",
      ticketPricingID: "",
      toBeDeletedId: "",
      tncText: "",
      attachments: [],
      showCreateTicketType: false,
      showUpdateTicketType: false,
      showCreateTicketPricing: false,
      showUpdateTicketPricing: false,
      showDeleteAttachedTicketConfirmation: false,
      showDeletePricingTicketConfirmation: false,
      showDeleteTicketTypeConfirmation: false,
      showAttachTicketPricing: false,
      showTncPreview: false,
      showTncEditor: false,
      selectedTicketTypeDetail: {},
      selectedTicketPricing: {},
      priceManagementList: [],
      jointTicketsList: [],
      availableJointTickets: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeEditEventHOC = (value, context) => (
      this.setState({ 
        [context]: value, 
        ...(context === "showTncEditor" && !value) 
          ? { isTncSaved: false } 
          : {} 
      })
    );

    updateEvent = (dataToSubmit) =>
      Put(
        `/admin/events/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateEventSuccess,
        this.updateEventError,
        this.load,
      );
    updateEventSuccess = (payload) => {
      this.props.getEventLists(1, "");
      this.props.getSelectedEvent(payload.id);
      this.setState({ isTncSaved: true });
      requestSuccess("Event updated successfully.");
    };
    updateEventError = (error) => requestError(error);

    getTicketTypeDetail = (ticket_type_id) =>
      Get(
        `/admin/events/${this.props.eventID}/ticket_types/${ticket_type_id}`,
        (payload) => this.getTicketTypeDetailSuccess(payload, ticket_type_id),
        this.getTicketTypeDetailError,
        this.load,
      );
    getTicketTypeDetailSuccess = (payload, ticket_type_id) => {
      this.getTicketPricingList(ticket_type_id);
      this.getJointTickets(ticket_type_id);
      this.setState({
        selectedTicketTypeDetail: payload,
        showCreateTicketType: false,
        showUpdateTicketType: true,
        ticketTypeID: ticket_type_id,
      });
    };
    getTicketTypeDetailError = (error) => requestError(error);

    createTicketType = (dataToSubmit) =>
      Post(
        `/admin/events/${this.props.eventID}/ticket_types`,
        dataToSubmit,
        this.createTicketTypeSuccess,
        this.createTicketTypeError,
        this.load,
      );
    createTicketTypeSuccess = (payload) => {
      this.props.getTicketTypeList(this.props.eventID);
      this.getTicketTypeDetail(payload.id);
      requestSuccess("Ticket type created successfully.");
    };
    createTicketTypeError = (error) => requestError(error);

    updateTicketType = () =>
      Put(
        `/admin/events/${this.props.eventID}/ticket_types/${this.state.ticketTypeID}`,
        this.state.selectedTicketTypeDetail,
        this.updateTicketTypeSuccess,
        this.updateTicketTypeError,
        this.load,
      );
    updateTicketTypeSuccess = (payload) => {
      this.props.getTicketTypeList(this.props.eventID);
      requestSuccess("Ticket type updated successfully.");
    };
    updateTicketTypeError = (error) => requestError(error);

    deleteSelectedTicketType = (ticket_type_id) =>
      Delete(
        `/admin/events/${this.props.eventID}/ticket_types/${ticket_type_id}`,
        this.deleteSelectedTicketTypeSuccess,
        this.deleteSelectedTicketTypeError,
        this.load,
      );
    deleteSelectedTicketTypeSuccess = (payload) => {
      this.props.getTicketTypeList(this.props.eventID);
      this.setState({ showDeleteTicketTypeConfirmation: false });
      requestSuccess("Ticket type deleted successfully.");
    };
    deleteSelectedTicketTypeError = (error) => requestError(error);

    getTicketPricingList = (ticket_type_id) =>
      Get(
        `/admin/events/${this.props.eventID}/ticket_types/${ticket_type_id}/ticket_pricings`,
        this.getTicketPricingListSuccess,
        this.getTicketPricingListError,
        this.load,
      );
    getTicketPricingListSuccess = (payload) =>
      this.setState({ priceManagementList: payload });
    getTicketPricingListError = (error) => requestError(error);

    createTicketPricing = (dataToSubmit) =>
      Post(
        `/admin/events/${this.props.eventID}/ticket_types/${this.state.ticketTypeID}/ticket_pricings`,
        dataToSubmit,
        this.createTicketPricingSuccess,
        this.createTicketPricingError,
        this.load,
      );
    createTicketPricingSuccess = (payload) => {
      this.getTicketPricingList(this.state.ticketTypeID);
      this.setState({ showCreateTicketPricing: false });
      requestSuccess("Ticket pricing created successfully.");
    };
    createTicketPricingError = (error) => requestError(error);

    updateTicketPricing = (ticket_pricing_id) =>
      Put(
        `/admin/events/${this.props.eventID}/ticket_types/${this.state.ticketTypeID}/ticket_pricings/${ticket_pricing_id}`,
        this.state.selectedTicketPricing,
        this.updateTicketPricingSuccess,
        this.updateTicketPricingError,
        this.load,
      );
    updateTicketPricingSuccess = (payload) => {
      this.getTicketPricingList(this.state.ticketTypeID);
      this.setState({ showUpdateTicketPricing: false });
      requestSuccess("Ticket pricing updated successfully.");
    };
    updateTicketPricingError = (error) => requestError(error);

    deleteTicketPricing = (ticket_pricing_id) =>
      Delete(
        `/admin/events/${this.props.eventID}/ticket_types/${this.state.ticketTypeID}/ticket_pricings/${ticket_pricing_id}`,
        this.deleteTicketPricingSuccess,
        this.deleteTicketPricingError,
        this.load,
      );
    deleteTicketPricingSuccess = (payload) => {
      this.getTicketPricingList(this.state.ticketTypeID);
      this.setState({ showDeletePricingTicketConfirmation: false });
      requestSuccess("Ticket pricing deleted successfully.");
    };
    deleteTicketPricingError = (error) => requestError(error);

    getAvailableJointTickets = (ticket_pricing_id) =>
      Get(
        `/admin/events/${this.props.eventID}/ticket_types/${this.state.ticketTypeID}/ticket_pricings/${ticket_pricing_id}/available_joint_tickets`,
        (payload) =>
          this.getAvailableJointTicketsSuccess(payload, ticket_pricing_id),
        this.getAvailableJointTicketsError,
        this.load,
      );
    getAvailableJointTicketsSuccess = (payload, ticket_pricing_id) =>
      this.setState({
        availableJointTickets: payload,
        ticketPricingID: ticket_pricing_id,
      });
    getAvailableJointTicketsError = (error) => requestError(error);

    getJointTickets = (ticket_type_id) =>
      Get(
        `/admin/events/${this.props.eventID}/ticket_types/${ticket_type_id}/joint_tickets`,
        this.getJointTicketsSuccess,
        this.getJointTicketsError,
        this.load,
      );
    getJointTicketsSuccess = (payload) => {
      let tmp = _.cloneDeep(payload);

      this.setState({
        jointTicketsList: tmp.map((item) => {
          const { attached_ticket_pricing, ticket_pricing } = item;

          return {
            ...item,
            primary_ticket_title: ticket_pricing?.title ?? "N/A",
            secondary_ticket_title: attached_ticket_pricing?.title ?? "N/A",
            secondary_ticket_event_name:
              attached_ticket_pricing?.event?.name ?? "N/A",
          };
        }),
      });
    };
    getJointTicketsError = (error) => requestError(error);

    attachJointTicket = (dataToSubmit) =>
      Post(
        `/admin/events/${this.props.eventID}/ticket_types/${this.state.ticketTypeID}/ticket_pricings/${this.state.ticketPricingID}/add_joint_ticket`,
        dataToSubmit,
        this.attachJointTicketSuccessToPrimary,
        this.attachJointTicketErrorToPrimary,
        this.load,
      );
    attachJointTicketSuccessToPrimary = (payload) => {
      this.getTicketPricingList(this.state.ticketTypeID);
      this.getJointTickets(this.state.ticketTypeID);
      this.setState({ showAttachTicketPricing: false });
      requestSuccess("Ticket pricing created successfully.");
    };
    attachJointTicketErrorToPrimary = (error) => requestError(error);

    deletedAttachedJointTicket = (attached_ticket_pricing_id) =>
      Delete(
        `/admin/events/${this.props.eventID}/ticket_types/${this.state.ticketTypeID}/ticket_pricings/${attached_ticket_pricing_id}/remove_joint_tickets`,
        this.deletedAttachedJointTicketSuccess,
        this.deletedAttachedJointTicketError,
        this.load,
      );
    deletedAttachedJointTicketSuccess = (payload) => {
      this.getTicketPricingList(this.state.ticketTypeID);
      this.getJointTickets(this.state.ticketTypeID);
      this.setState({ showDeleteAttachedTicketConfirmation: false });
      requestSuccess("Ticket pricing deleted successfully.");
    };
    deletedAttachedJointTicketError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showCreateTicketType={this.state.showCreateTicketType}
            showUpdateTicketType={this.state.showUpdateTicketType}
            tncText={this.state.tncText}
            isTncSaved={this.state.isTncSaved}
            toBeDeletedId={this.state.toBeDeletedId}
            showCreateTicketPricing={this.state.showCreateTicketPricing}
            showUpdateTicketPricing={this.state.showUpdateTicketPricing}
            showDeleteAttachedTicketConfirmation={this.state.showDeleteAttachedTicketConfirmation}
            showDeletePricingTicketConfirmation={this.state.showDeletePricingTicketConfirmation}
            showDeleteTicketTypeConfirmation={this.state.showDeleteTicketTypeConfirmation}
            showAttachTicketPricing={this.state.showAttachTicketPricing}
            selectedTicketTypeDetail={this.state.selectedTicketTypeDetail}
            selectedTicketPricing={this.state.selectedTicketPricing}
            priceManagementList={this.state.priceManagementList}
            jointTicketsList={this.state.jointTicketsList}
            availableJointTickets={this.state.availableJointTickets}
            showTncPreview={this.state.showTncPreview}
            showTncEditor={this.state.showTncEditor}
            onLoadEditEvent={this.state.loading}
            onChangeEditEventHOC={this.onChangeEditEventHOC}
            updateEvent={this.updateEvent}
            getTicketTypeDetail={this.getTicketTypeDetail}
            createTicketType={this.createTicketType}
            updateTicketType={this.updateTicketType}
            deleteSelectedTicketType={this.deleteSelectedTicketType}
            getTicketPricingList={this.getTicketPricingList}
            createTicketPricing={this.createTicketPricing}
            updateTicketPricing={this.updateTicketPricing}
            deleteTicketPricing={this.deleteTicketPricing}
            getAvailableJointTickets={this.getAvailableJointTickets}
            attachJointTicket={this.attachJointTicket}
            deletedAttachedJointTicket={this.deletedAttachedJointTicket}
          />
        </>
      );
    };
  }
  return WithHOC;
};

export default HOC;
