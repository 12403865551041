import React, { useState, useEffect } from "react";
import Moment from "moment";
import _ from "lodash";

import CustomFormInput from "components/Input/formInput";
import CustomTypeahead from "components/Typeahead/new";
import DatePicker from "components/Input/datetimepicker";

import "./index.scss";

const DateFieldsData = [
  { label: "Letter Date", value: "letterDate", type: "date" },
  { label: "Offer Date", value: "offerDate", type: "date" },
  { label: "Signed Date", value: "signed_date", type: "date" },
];

const DatepickerInput = ({ ...props }) => <input type="text" {...props} />;

const AppointmentLetter = ({
  selectedAppointmentLetter,
  userBranchID,
  selectedUserId,
  hideOfferDate,
  hideBranch,
  branches,

  downloadDocWithDate,
  sendEmailWithDate,
}) => {
  const [recipients, onChangeRecipient] = useState("");
  const [branchId, onChangeBranch] = useState("");
  const [appointementForm, onChangeFormData] = useState({
    signed_appointment_datetime: null,
    lastTrainingDate: null,
    letterDate: null,
    offerDate: null,
    signed_date: null,
  });

  const onChangeField = (val, context) => {
    let temp = _.cloneDeep(appointementForm);
    temp[context] = val;
    onChangeFormData(temp);
  };

  useEffect(() => {
    onChangeBranch(userBranchID);
    onChangeFormData(selectedAppointmentLetter);
  }, []);

  return (
    <>
      <h2 className="at-form-input__title">Signed Date:</h2>
      <i>{`${appointementForm.signed_appointment_datetime}`}</i>
      <h2 className="at-form-input__title mt-20">Last Training Date:</h2>
      <i>{`${appointementForm.lastTrainingDate}`}</i>
      <div className="row mt-20">
        {DateFieldsData.map((item) => {
          if (!hideOfferDate || item.value !== "offerDate") {
            return (
              <div key={item.value} className="col-6 mb-10">
                <h2 className="at-form-input__title">{item.label}</h2>
                <div className="at-form-input">
                  <DatePicker
                    customInput={<DatepickerInput />}
                    value={Moment(appointementForm[item.value])}
                    onChange={(val) => onChangeField(Moment(val).format("DD MMM YYYY"), item.value)}
                  />
                </div>
              </div>
            );
          }
        })}
        {!hideBranch && (
          <div className="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <h2 className="at-form-input__title">Branch</h2>
            <CustomTypeahead
              selectedValue={
                _.find(branches, { id: branchId })
                  ? [_.find(branches, { id: branchId })]
                  : []
              }
              options={branches ? branches : []}
              filterBy={["name"]}
              labelKey={"name"}
              typeaheadId={"branch"}
              onSelect={(val) =>
                onChangeBranch(val && val.length > 0 ? val[0].id : "")
              }
              childrenHead={(rowItem) => <p>{rowItem.name}</p>}
            />
          </div>
        )}
        <div className="col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <h2 className="at-form-input__title">Recipient's Email</h2>
          <CustomFormInput
            type="text"
            className={"mt-10"}
            value={recipients}
            onChangeValue={(val) => onChangeRecipient(val)}
            inputError={""}
            placeholder={
              "Enter recipient's email address if you want this appointment letter send via email"
            }
          />
        </div>
      </div>
      <div className={"d-flex mt-20"}>
        <button
          className={`btn-new btn-new--secondary mt-10`}
          onClick={() =>
            downloadDocWithDate({
              id: selectedUserId,
              branch_id: branchId,
              ...appointementForm,
            })
          }
        >
          Download
        </button>
        <button
          style={{ marginLeft: 10 }}
          className={`btn-new btn-new--secondary mt-10`}
          onClick={() =>
            sendEmailWithDate({
              id: selectedUserId,
              recipients: recipients,
              ...appointementForm,
            })
          }
        >
          Email
        </button>
      </div>
    </>
  );
};

export default AppointmentLetter;
