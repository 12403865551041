import React, { useEffect, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { IoMdAdd } from "react-icons/io";
import { BsTrash3Fill } from "react-icons/bs";

import CustomButton from "components/Button";
import CustomSelect from "components/Select";
import CustomCheckbox from "components/Checkbox";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import DatePicker from "components/Input/datetimepicker";
import CustomImageUploader from "components/Input/imageUpload";

const MeetingRoomForm = ({
  mode,
  branches,
  selectedMeetingRoom,

  onClose,
  createMeetingRoom,
  updateMeetingRoom,
}) => {
  const [formData, onChangeFormData] = useState({
    meeting_room_type_id: 1,
    name: "",
    capacity: "",
    status_id: "",
    branch_id: "",
    visible_to_roles: "",
    ms_resource_email: "",
    operating_end_time: "",
    operating_start_time: "",
    open_days: [],
    add_ons_attributes: [],
    business_lounge_pricings_attributes: [],
  });
  const [doc, setDoc] = useState({});

  useEffect(() => {
    if (selectedMeetingRoom && mode === "update") {
      setDoc({
        image: selectedMeetingRoom.image_url,
        image_file_name: selectedMeetingRoom.name,
      });
      onChangeFormData({
        ...selectedMeetingRoom,
        visible_to_roles: selectedMeetingRoom.visible_to_roles_id,
        operating_end_time: selectedMeetingRoom.operating_end_time
          ? Moment(selectedMeetingRoom.operating_end_time, "hh:mm a")
          : "",
        operating_start_time: selectedMeetingRoom.operating_start_time
          ? Moment(selectedMeetingRoom.operating_start_time, "hh:mm a")
          : "",
        add_ons_attributes: selectedMeetingRoom.add_ons,
        business_lounge_pricings_attributes: selectedMeetingRoom.business_lounge_pricings,
        is_closed_on_public_holidays:
          selectedMeetingRoom.is_closed_on_public_holidays,
      });
    }
  }, [selectedMeetingRoom]);

  const onChangeFile = (fileItems) => {
    let temp = {
      image: "",
      image_file_name: "",
    };

    if (fileItems.length > 0 && fileItems[0].file) {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          temp.image = e.target.result;
          temp.image_file_name = fileItems[0].file.name;
          setDoc(temp);
        };
        reader.readAsDataURL(fileItems[0].file);
      } catch (e) {
        console.log(e);
      }
    } else {
      setDoc({});
    }
  };

  const onToggleSave = () => {
    if (mode === "create") {
      createMeetingRoom({
        ...formData,
        ...doc,
      });
    } else if (
      mode === "update" &&
      doc.image === selectedMeetingRoom.image_url
    ) {
      updateMeetingRoom(formData);
    } else {
      updateMeetingRoom({
        ...formData,
        ...doc,
      });
    }
  };

  const daysOfWeek = [
    { day: "Everyday", day_id: 9 },
    { day: "Monday", day_id: 3 },
    { day: "Tuesday", day_id: 4 },
    { day: "Wednesday", day_id: 5 },
    { day: "Thursday", day_id: 6 },
    { day: "Friday", day_id: 7 },
    { day: "Saturday", day_id: 1 },
    { day: "Sunday", day_id: 2 },
    { day: "Public holiday", day_id: 8 }
  ]

  const onToggleDay = (day_id, checked) => {
    let tmp = _.cloneDeep(formData.open_days);
  
    const weekdays = daysOfWeek
      .filter(param => param.day_id >= 1 && param.day_id <= 7)
      .map(param => param.day_id);
    const everydayId = daysOfWeek.find(param => param.day === "Everyday").day_id;
    const publicHolidayId = daysOfWeek.find(param => param.day === "Public holiday").day_id;

    if (weekdays.includes(day_id)) {
      if (checked) {
        tmp.push(day_id);
        if (weekdays.every(param => tmp.includes(param))) {
          tmp.push(everydayId);
        }
      } else {
        tmp = tmp.filter(param => param !== day_id);
        tmp = tmp.filter(param => param !== everydayId);
      }
    } else if (day_id === everydayId) {
      if (checked) {
        tmp = [...new Set([...tmp, ...weekdays, everydayId])];
      } else {
        tmp = tmp.filter(param => !weekdays.includes(param));
        tmp = tmp.filter(param => param !== everydayId);
      }
    } else if (day_id === publicHolidayId) {
      if (checked) {
        tmp.push(day_id);
      } else {
        tmp = tmp.filter(param => param !== day_id);
      }
    }
  
    onChangeFormData({...formData, open_days: tmp});
  };

  return (
    <>
      <div className="at-form__content">
        <div className="d-flex align-items-center mb-3 grid_gap-2">
          <h2 className="fw-600 fs-3">Details</h2>
        </div>
        <div className="grid-control">
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Type
            </h2>
            <AtlasRadioGroup
              horizontal={true}
              checkedValue={formData.meeting_room_type_id}
              containerClassName={"w-100"}
              selectedRadioValue={(val) =>
                onChangeFormData({ ...formData, meeting_room_type_id: val })
              }
              options={[
                { value: 1, label: "Meeting Room" },
                { value: 2, label: "Business Lounge" },
              ]}
            />
          </section>
          {formData.meeting_room_type_id === 1 && (
            <section className="grid-full-col">
              <h2 className="at-form-input__title" required>
                Meeting Room Name
              </h2>
              <CustomFormInput
                type={"text"}
                required={true}
                value={formData.name}
                placeholder={"Meeting Room Name"}
                onChangeValue={(val) =>
                  onChangeFormData({ ...formData, name: val })
                }
              />
            </section>
          )}
          {formData.meeting_room_type_id === 2 && (
            <section className="grid-full-col">
              <h2 className="at-form-input__title" required>
                Business Lounge Name
              </h2>
              <CustomFormInput
                type={"text"}
                required={true}
                value={formData.name}
                placeholder={"Business Lounge Name"}
                onChangeValue={(val) =>
                  onChangeFormData({ ...formData, name: val })
                }
              />
            </section>
          )}
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Branch
            </h2>
            <CustomSelect
              selectItems={branches}
              valueKey={"id"}
              placeholder={"Select Branch"}
              currentlySelected={
                formData.branch_id
                  ? _.find(
                      branches,
                      (branch) => branch.id === formData.branch_id
                    )
                  : ""
              }
              updateSelect={(val) =>
                onChangeFormData({ ...formData, branch_id: val.id })
              }
            />
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Capacity
            </h2>
            <CustomFormInput
              type={"number"}
              required={true}
              value={formData.capacity}
              placeholder={"Capacity"}
              onChangeValue={(val) =>
                onChangeFormData({ ...formData, capacity: val })
              }
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Operating From
            </h2>
            <div className="at-form-input">
              <DatePicker
                id={"operating_start_time"}
                type={"time"}
                required={true}
                value={Moment(formData.operating_start_time)}
                placeholder={"Start Operation Time"}
                textFieldClass={"at-search-form-input"}
                onChange={(val) => onChangeFormData({ ...formData, operating_start_time: val })}
              />
            </div>
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Operating To
            </h2>
            <div className="at-form-input">
              <DatePicker
                id={"operating_end_time"}
                type={"time"}
                required={true}
                value={Moment(formData.operating_end_time)}
                textFieldClass={"at-search-form-input"}
                onChange={(val) => onChangeFormData({ ...formData, operating_end_time: val })}
              />
            </div>
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Open on
            </h2>
            <div className="d-flex">
              {daysOfWeek.map(({ day, day_id }) => (
                <CustomCheckbox
                  content={day}
                  checked={formData.open_days?.includes(day_id)}
                  contentStyle={{ fontWeight: 600 }}
                  onChangeCheckboxValue={(e) => onToggleDay(day_id, e.target.checked)}
                />
              ))}
            </div>
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Visible To
            </h2>
            <AtlasRadioGroup
              horizontal={true}
              checkedValue={formData.visible_to_roles}
              containerClassName={"w-100"}
              selectedRadioValue={(val) =>
                onChangeFormData({ ...formData, visible_to_roles: val })
              }
              options={[
                { value: 1, label: "Agent" },
                { value: 2, label: "Admin" },
                { value: 3, label: "Agent & Admin" },
              ]}
            />
          </section>
          <section className="grid-half-col">
            <h2 className="at-form-input__title" required>
              Availability
            </h2>
            <AtlasRadioGroup
              horizontal={true}
              checkedValue={formData.status_id}
              containerClassName={"w-100"}
              selectedRadioValue={(val) =>
                onChangeFormData({ ...formData, status_id: val })
              }
              options={[
                { value: 1, label: "Available" },
                { value: 2, label: "Hidden" },
              ]}
            />
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              MS Resource Email
            </h2>
            <CustomFormInput
              type={"text"}
              required={true}
              value={formData.ms_resource_email}
              placeholder={"MS Resource Email"}
              onChangeValue={(val) =>
                onChangeFormData({ ...formData, ms_resource_email: val })
              }
            />
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title" required>
              Image
            </h2>
            <CustomImageUploader
              fullWidth
              required={true}
              files={
                doc.image && doc.image_file_name
                  ? [
                      {
                        source: doc.image,
                        name: doc.image_file_name,
                      },
                    ]
                  : []
              }
              allowMultiple={false}
              acceptedFileTypes={["image/*"]}
              accept="image/*"
              maxFiles={1}
              onRemoveFile={() => setDoc({ image: "", image_file_name: "" })}
              onChangeFile={(fileItems) => onChangeFile(fileItems)}
            />
          </section>
        </div>
      </div>
      {(selectedMeetingRoom.meeting_room_type_id === 2 && formData.meeting_room_type_id === 2) && (
        <>
          <div className="at-form__content">
            <div className="d-flex align-items-center mb-3 grid_gap-2">
              <h2 className="fw-600 fs-3">Pricing</h2>
            </div>
            {formData?.business_lounge_pricings_attributes?.map(
              (item, index) => (
                <div className="at-meeting-room__item">
                  <div className="row w-100">
                    <section className="col-md-10 pl-1">
                      <h2 className="at-form-input__title" required>
                        Pricing Name
                      </h2>
                      <CustomFormInput
                        type={"text"}
                        value={item.name}
                        placeholder={"Pricing Name"}
                        onChangeValue={(val) => {
                          const temp = [
                            ...formData?.business_lounge_pricings_attributes,
                          ];
                          temp[index].name = val;
                          onChangeFormData({...formData, business_lounge_pricings_attributes: temp});
                        }}
                      />
                    </section>
                    <section className="col-md-2">
                      <h2 className="at-form-input__title" required>
                        Price
                      </h2>
                      <CustomFormInput
                        type={"number"}
                        value={item.price}
                        required={true}
                        placeholder={"Price"}
                        onChangeValue={(val) => {
                          const temp = [
                            ...formData?.business_lounge_pricings_attributes,
                          ];
                          temp[index].price = val;
                          onChangeFormData({...formData, business_lounge_pricings_attributes: temp});
                        }}
                      />
                    </section>
                  </div>
                  <CustomButton
                    className={"btn-new btn-new--outline-secondary"}
                    containerClass={"at-meeting-room__item-delete"}
                    onClick={() => {
                      const temp = [
                        ...formData?.business_lounge_pricings_attributes,
                      ];
                      temp.splice(index, 1);
                      onChangeFormData({...formData, business_lounge_pricings_attributes: temp});
                    }}
                  >
                    <BsTrash3Fill style={{ color: "#F04438" }} />
                  </CustomButton>
                </div>
              )
            )}
            <CustomButton
              key={`module_header-action_button-1`}
              className={`btn-new btn-new--secondary`}
              onClick={() => {
                const temp = [
                  ...formData?.business_lounge_pricings_attributes,
                  { name: "", price: "" },
                ];
                onChangeFormData({...formData, business_lounge_pricings_attributes: temp});
              }}
              children={
                <>
                  <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
                  New Pricing
                </>
              }
            />
          </div>
          <div className="at-form__content">
            <div className="d-flex align-items-center mb-3 grid_gap-2">
              <h2 className="fw-600 fs-3">Add On</h2>
            </div>
            {formData?.add_ons_attributes?.map(
              (item, index) => (
                <div className="at-meeting-room__item mb-3">
                  <div className="row w-100">
                    <section className="col-md-10 mb-md-0 pl-1">
                      <h2 className="at-form-input__title">
                        Add On Name
                      </h2>
                      <CustomFormInput
                        type={"text"}
                        value={item.name}
                        placeholder={"Price Name"}
                        onChangeValue={(val) => {
                          const temp = [
                            ...formData?.add_ons_attributes,
                          ];
                          temp[index].name = val;
                          onChangeFormData({...formData, add_ons_attributes: temp});
                        }}
                      />
                      <CustomFormInput
                        type={"text"}
                        value={item.description}
                        placeholder={"Description"}
                        containerStyle={{marginTop: 12}}
                        onChangeValue={(val) => {
                          const temp = [
                            ...formData?.add_ons_attributes,
                          ];
                          temp[index].description = val;
                          onChangeFormData({...formData, add_ons_attributes: temp});
                        }}
                      />
                    </section>
                    <section className="col-md-2 mb-0">
                      <h2 className="at-form-input__title">
                        Price
                      </h2>
                      <CustomFormInput
                        type={"number"}
                        value={item.price}
                        placeholder={"Pricing"}
                        onChangeValue={(val) => {
                          const temp = [
                            ...formData?.add_ons_attributes,
                          ];
                          temp[index].price = val;
                          onChangeFormData({...formData, add_ons_attributes: temp});
                        }}
                      />
                    </section>
                    <section className="col-md-12 mb-0">
                      <CustomCheckbox
                        content={"Required Quantity"}
                        checked={item.quantity_required}
                        labelStyle={{margin: 0, marginTop: 12, marginRight: 12}}
                        contentStyle={{ fontWeight: 600 }}
                        onChangeCheckboxValue={(e) => {
                            const temp = [
                              ...formData?.add_ons_attributes,
                            ];
                            temp[index].quantity_required = e.target.checked;
                            onChangeFormData({...formData, add_ons_attributes: temp});
                          }
                        }
                      />
                      <CustomCheckbox
                        content={"Mandatory on Weekend"}
                        checked={item.mandatory_for_weekend}
                        labelStyle={{margin: 0, marginTop: 12}}
                        contentStyle={{ fontWeight: 600 }}
                        onChangeCheckboxValue={(e) => {
                            let temp = _.cloneDeep(formData.add_ons_attributes);
                            temp[index].mandatory_for_weekend = e.target.checked;
                            onChangeFormData({...formData, add_ons_attributes: temp});
                          }
                        }
                      />
                    </section>
                  </div>
                  <CustomButton
                    className={"btn-new btn-new--outline-secondary"}
                    containerClass={"at-meeting-room__item-delete"}
                    onClick={() => {
                      const temp = [
                        ...formData?.add_ons_attributes,
                      ];
                      temp.splice(index, 1);
                      onChangeFormData({...formData, add_ons_attributes: temp});
                    }}
                  >
                    <BsTrash3Fill style={{ color: "#F04438" }} />
                    <p>Delete</p>
                  </CustomButton>
                </div>
              )
            )}
            <CustomButton
              key={`module_header-action_button-1`}
              className={`btn-new btn-new--secondary`}
              onClick={() => {
                const temp = [
                  ...formData?.add_ons_attributes,
                  { name: "", price: "" },
                ];
                onChangeFormData({...formData, add_ons_attributes: temp});
              }}
              children={
                <>
                  <IoMdAdd style={{ height: 18, width: 18, marginRight: 10 }} />
                  New Add On
                </>
              }
            />
          </div>
        </>
      )}

      <div className="at-modal_dialog-container-footer g-3">
        <CustomButton
          className={`btn-new btn-new--${mode === "create" ? "primary" : "success"}`}
          disabled={_.values(formData).some((val) => val === "" ) || _.isEmpty(doc)}
          children={mode.substring(0, 1).toUpperCase() + mode.substring(1)}
          onClick={() => onToggleSave()}
        />
        <CustomButton
          className="btn-new btn-new--outline-secondary"
          children={"Cancel"}
          onClick={() => onClose()}
        />
      </div>
    </>
  );
};

export default MeetingRoomForm;
