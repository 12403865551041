import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Slider from "react-slick";
import EmailValidator from "email-validator";
import { PulseLoader } from "react-spinners";
import { HiArrowLeft } from "react-icons/hi";

import AlertBox from "components/AlertBox";
import SupportButtonGroups from "components/Button/supportGroups";

import LoginImage1 from "../../assets/images/login_img1.png";
import LoginImage2 from "../../assets/images/login_img2.png";
import IQILogoBlack from "../../assets/images/iqi-logo_black.png";
import IQILogoWhite from "../../assets/images/iqi-logo_white.png";
import ForgetPasswordForm from "../../components/Form/forgetPassword";
import { getTranslation } from "assets/translation";
import { initGA } from "utils/ga";
import ForgetPasswordHOC from "./actions";

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
};

class ForgetPassword extends Component {
  componentDidMount = () => {
    if (window.location.host.indexOf("atlas.iqiglobal.com") > -1) {
      initGA("/forget-password");
    }
  };

  onClickBackToLogin = () => this.props.history.push("/login");

  onChangeEmail = (val) => {
    this.props.onChangeHOC(val, "email");
    this.props.onChangeHOC(EmailValidator.validate(val), "validEmail");
  };

  render = () => {
    const language = this.props.language || "en";
    return (
      <>
        <div className="d-flex" style={{ height: "100vh", overflow: "hidden" }}>
          <div className="at-desktop-view__controller at-login__banner">
            <div className="at-login__banner-header">
              <img
                src={IQILogoWhite}
                alt={"IQI Logo White"}
                style={{ width: 70, margin: "20px 0px" }}
              />
              <p>{getTranslation("label.sidebar.message", language)}</p>
            </div>
            <div className="at-login__banner-slider">
              <Slider {...SliderSettings}>
                <img src={LoginImage1} alt={"Login Image 1"} />
                <img src={LoginImage2} alt={"Login Image 2"} />
              </Slider>
            </div>
          </div>
          <div className="login-container">
            <div style={{ textAlign: "center", marginTop: "10%" }}>
              <img
                src={IQILogoBlack}
                alt={"IQI Logo Black"}
                style={{ width: "114px" }}
              />
              <div className="at-login__back-to">
                <HiArrowLeft
                  style={{ width: 16, height: 16, marginRight: 10 }}
                />
                <p onClick={() => this.onClickBackToLogin()}>{getTranslation("action.back_to_sign_in", language)}</p>
              </div>
              {!this.props.showSuccessMessage && (
                <>
                  <h2>{getTranslation("label.forgot_password", language)}</h2>
                  <p style={{ padding: "0px 20px" }}>
                    {getTranslation("label.forgot_password.description", language)}
                  </p>
                </>
              )}
              {this.props.showSuccessMessage && (
                <>
                  <h2>{getTranslation("label.reset_link_sent.title", language)}</h2>
                  <div className="login-form-container m-auto">
                    <p style={{ textAlign: "left", fontSize: 14 }}>
                      {getTranslation("label.reset_link_sent.message", language).replace("{{email}}", this.props.email)}
                      <br />
                      <br />
                      {getTranslation("label.reset_link_sent.message_2", language)}
                    </p>
                    <div className="mt-4">
                      <button
                        className="btn-new btn-new--primary text-uppercase w-100"
                        onClick={() => this.onClickBackToLogin()}
                      >
                        {getTranslation("action.back_to_sign_in", language)}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            {!this.props.showSuccessMessage && (
              <div className="login-panel">
                {!this.props.onLoadForgetPassword &&
                  this.props.notificationMessage && (
                    <AlertBox
                      mode={"danger"}
                      disabledClose={true}
                      containerStyle={{ margin: "10px 20px 0px 20px" }}
                      description={this.props.notificationMessage}
                    />
                  )}
                {this.props.onLoadForgetPassword && (
                  <PulseLoader
                    className={"d-flex align-items-center mb-10"}
                    sizeunit={"px"}
                    size={10}
                    color={"#16232c"}
                    loading={this.props.onLoadForgetPassword}
                  />
                )}
                <ForgetPasswordForm
                  email={this.props.email}
                  language={language}
                  cooldownTiming={this.props.cooldownTiming}
                  onChangeEmail={(val) => this.onChangeEmail(val)}
                  onClickBackToLogin={this.onClickBackToLogin}
                  showSuccessMessage={this.props.showSuccessMessage}
                  onClickSubmitEmail={() => this.props.onClickSubmitEmail(language)}
                />
              </div>
            )}
            <div className="at-login__footer">
              <p className="small">{`IQI Holdings Sdn Bhd (1018842-U)`}</p>
              <p className="small">
                {`26th -28th Floor, Tower D, Millerz Square, No. 357, Jalan Kelang Lama, 58000 Kuala Lumpur, Malaysia.`}
              </p>
              <p>{`T: +60374506655`}</p>
              <p
                className="small"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.iqiglobal.com/privacy-policy",
                    "__blank",
                  )
                }
              >
                <u className="mx-2">{getTranslation("label.terms_of_use", language)}</u>•
                <u className="mx-2">{getTranslation("label.privacy_policy", language)}</u>
              </p>
            </div>
          </div>
        </div>
        <SupportButtonGroups language={language}/>
      </>
    );
  };
}

const mapStateToProps = (state) => ({ language: state.languageReducer.language });

export default compose(
  connect(mapStateToProps, {}),
  ForgetPasswordHOC
)(ForgetPassword);
