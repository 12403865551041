import React, { Component } from "react";
import { compose } from "redux";
import Moment from "moment";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomFormInput from "components/Input/formInput";
import AtlasFormGroup from "components/FormGroup/input";

import ApptLetterHOC from "../../../actions/appointmentLetter";
import ConfirmationFormHOC from "../../../actions/confirmationForm";
import PaperFormHOC from "../../../actions/paperForm";

import "stylesheets/containers/subsales-claims/index.scss";

const PropertyType = [
  { id: 1, label: "Residential", value: 1 },
  { id: 2, label: "Commercial", value: 2 },
  { id: 3, label: "Industrial", value: 3 },
  { id: 4, label: "Agricultural", value: 4 },
  { id: 5, label: "Unknown", value: 5 },
];

class BookingDetails extends Component {
  render = () => {
    let {
      selected_claim,
      onChangeUpdateSelectedClaim,
      can_update,
    } = this.props;

    const { is_main_info_locked } = selected_claim
    const tmpDisabledSubmitButton = is_main_info_locked || !can_update

    return (
      <div className="grid-control" style={{ gridGap: "1rem" }}>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Confirmation Form Number"}</h2>
          <CustomFormInput
            type="text"
            value={`${selected_claim.confirmation_form_number}`}
            onChangeValue={(val) => {
              onChangeUpdateSelectedClaim("confirmation_form_number", val);
            }}
            disabled={true}
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"Booking Date"}</h2>
          <AtlasFormGroup
            type={"calendar"}
            value={selected_claim.booking_date || null}
            disabledDate={
              (selected_claim.claim_status !== "Draft" && selected_claim.claim_status !== "Rejected") 
              || tmpDisabledSubmitButton
            }
            onSelectDate={(val) =>
              onChangeUpdateSelectedClaim(
                "booking_date",
                ["", null].indexOf(val) === -1
                  ? Moment(val).format("YYYY-MM-DD")
                  : null
              )
            }
          />
        </section>

        <section className="grid-half-col">
          <h2 className="at-form-input__title">{"SPA Date / TA Date"}</h2>
          <AtlasFormGroup
            type={"calendar"}
            calendarProps={{
              showYearDropdown: true,
              maxDate: Moment().add(14, "days").toDate(),
            }}
            value={selected_claim.agreement_date || null}
            disabledDate={tmpDisabledSubmitButton}
            onSelectDate={(val) =>
              onChangeUpdateSelectedClaim(
                "agreement_date",
                ["", null].indexOf(val) === -1
                  ? Moment(val).format("YYYY-MM-DD")
                  : null
              )
            }
          />
        </section>
        <section className="grid-half-col">
          <h2 className="at-form-input__title">
            {"Selling Price / Monthly Rental (RM)"}
          </h2>
          <CustomFormInput
            placeholder="Enter Selling Price / Monthly Rental"
            className={"subsaleclaim-claimform-sellingprice"}
            type="number"
            value={`${selected_claim.net_price}`}
            onChangeValue={(val) => {
              onChangeUpdateSelectedClaim("net_price", val);
            }}
            disabled={
              tmpDisabledSubmitButton ||
              (selected_claim.claim_status !== "Draft" &&
                selected_claim.claim_status !== "Rejected")
            }
          />
        </section>

        <section className="grid-half-col">
          <h2 className="at-form-input__title">
            {"Earnest Deposit Received (RM)"}
          </h2>
          <CustomFormInput
            placeholder="Enter Earnest Deposit Received"
            classname={"subsaleclaim-claimform-earnestdeposit"}
            type="number"
            value={`${selected_claim.earnest_deposit_received_amount}`}
            onChangeValue={(val) => {
              onChangeUpdateSelectedClaim(
                "earnest_deposit_received_amount",
                val,
              );
            }}
            disabled={
              tmpDisabledSubmitButton ||
              (selected_claim.claim_status !== "Draft" &&
                selected_claim.claim_status !== "Rejected")
            }
          />
        </section>
      </div>
    );
  };
}

export default compose(
  ApptLetterHOC,
  ConfirmationFormHOC,
  PaperFormHOC,
)(BookingDetails);
