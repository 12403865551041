import React from "react";
import Moment from "moment";
import _ from "lodash";
import { MenuItem } from "@material-ui/core";

import AtSelectNew from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import DatePicker from "components/Input/datetimepicker";

const ReportSearch = ({
  startDate,
  endDate,
  onChangeProjectReportsHOC,
  getProjectReports,
  getSubsalesReports,
  visual,
  searchParams,
}) => {
  const onChangeFilter = (value) => {
    let tmp = _.cloneDeep(searchParams);
    tmp.param = value;

    return onChangeProjectReportsHOC("searchParams", tmp);
  };

  const onChangeStartDate = (val) => {
    val
      ? onChangeProjectReportsHOC("startDate", val)
      : onChangeProjectReportsHOC(
          "startDate",
          Moment().startOf("year").format("YYYY-MM-DD"),
        );
  };

  const onChangeEndDate = (val) => {
    val
      ? onChangeProjectReportsHOC("endDate", val)
      : onChangeProjectReportsHOC(
          "endDate",
          Moment(new Date()).format("YYYY-MM-DD"),
        );
  };

  return (
    <>
      <div className={"at-form__content"} style={{ padding: 15 }}>
        <div className="row">
          <div className="col-12 col-md-4">
            <label className="at-form-input__title">Start date</label>
            <div className="at-form-input">
              <DatePicker
                value={startDate}
                maxDate={Moment()}
                onChange={(val) => onChangeStartDate(val)}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <label className="at-form-input__title">End date</label>
            <div className="at-form-input">
              <DatePicker
                value={endDate}
                maxDate={Moment()}
                onChange={(val) => onChangeEndDate(val)}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <label className="at-form-input__title">Branch</label>
            <AtSelectNew
              value={searchParams.param}
              onChange={(e) => onChangeFilter(e.target.value)}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {searchParams.options.map((option) => (
                <MenuItem
                  classes={{ root: `at-select__dropdown-item` }}
                  key={option.value}
                  value={option.value}
                  style={{ width: "100%", fontSize: 14 }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </AtSelectNew>
          </div>
        </div>
        <div className="d-flex align-items-center mt-1 g-3">
          <button
            className="btn-new btn-new--outline-secondary"
            onClick={() => {
              onChangeProjectReportsHOC(
                "endDate",
                Moment(new Date()).format("YYYY-MM-DD"),
              );
              onChangeProjectReportsHOC(
                "startDate",
                Moment().startOf("year").format("YYYY-MM-DD"),
              );
              onChangeFilter(0);
              getProjectReports();
              getSubsalesReports();
            }}
          >
            Reset
          </button>
          <button
            className="btn-new btn-new--secondary"
            onClick={() => {
              getProjectReports(
                startDate !== "" ? Moment(startDate).format("YYYY-MM-DD") : "",
                endDate !== "" ? Moment(endDate).format("YYYY-MM-DD") : "",
                searchParams.param,
              );
              getSubsalesReports(
                startDate !== "" ? Moment(startDate).format("YYYY-MM-DD") : "",
                endDate !== "" ? Moment(endDate).format("YYYY-MM-DD") : "",
                searchParams.param,
              );
            }}
          >
            Search
          </button>
        </div>
      </div>
      <div
        className="d-flex align-items-center mt-2"
        style={{
          backgroundColor: "#E5E7EB",
          borderRadius: 8,
          width: "fit-content",
        }}
      >
        <button
          className={`btn-float ${visual === "chart" ? "btn-float-selected" : ""}`}
          onClick={() => onChangeProjectReportsHOC("visual", "chart")}
        >
          <AtlasIcon
            svgHref={"atlas-chart-3"}
            style={{ width: 20, height: 20, fill: "#374151" }}
          />
          Chart
        </button>
        <button
          className={`btn-float ${visual === "table" ? "btn-float-selected" : ""}`}
          onClick={() => onChangeProjectReportsHOC("visual", "table")}
        >
          <AtlasIcon
            svgHref={"atlas-grid-3"}
            style={{ width: 20, height: 20, fill: "#374151" }}
          />
          Table
        </button>
      </div>
    </>
  );
};

export default ReportSearch;
