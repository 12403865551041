import React, { useEffect, useState } from "react";
import _ from "lodash";
import { MenuItem } from "@material-ui/core";
import Moment from "moment";

import AtlasButton from "components/Button";
import AtSelectNew from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import CustomFormInput from "components/Input/formInput";
import DatePicker from "components/Input/datetimepicker";

import { statusOptions, typeOptions, REAFormData } from "./assets";

const Form = ({
  mode,
  selectedRenewal,

  submitData,
  onClose,
}) => {
  const [formData, setFormData] = useState({
    renewal_year: "",
    last_submit_date: "",
    status_id: "",
    type_id: "",
  });

  useEffect(() => {
    if (selectedRenewal) {
      setFormData({
        last_submit_date: Moment(
          selectedRenewal.last_submit_date,
          "DD/MM/YYYY",
        ).format("DD-MM-YYYY"),
        renewal_year: `${selectedRenewal.renewal_year}`,
        status_id: _.find(
          statusOptions,
          (status) => status.label === selectedRenewal.status,
        ).value,
        type_id: _.find(
          typeOptions,
          (type) => type.label === selectedRenewal.licence_renewal_type,
        ).value,
      });
    }
  }, [selectedRenewal]);

  const onChangeFieldData = (val, context) => {
    let temp = _.cloneDeep(formData);
    temp[context] = val;
    setFormData(temp);
  };

  return (
    <ModalDialog
      title={
        mode === "Create" ? "New License Renewal" : "Update License Renewal"
      }
      children={
        <div className="row mb-10">
          {REAFormData.map((item, index) => (
            <div key={index} className={`col-md-12`}>
              <label className="at-form-label" required>
                {item.label}
              </label>
              <br />
              {item.type === "date" && (
                <div className="at-form-input">
                  <DatePicker
                    showYearPicker={item.showYearPicker}
                    value={Moment(formData[item.value], item.format)}
                    onChange={(val) =>
                      onChangeFieldData(
                        Moment(val).format(item.format),
                        item.value,
                      )
                    }
                  />
                  <div className="at-form-input__required">required *</div>
                </div>
              )}
              {item.type === "select" && (
                <AtSelectNew
                  required={true}
                  value={formData[item.value]}
                  onChange={(e) =>
                    onChangeFieldData(e.target.value, item.value)
                  }
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {item.options.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      style={{ fontSize: 14 }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </AtSelectNew>
              )}
              {["select", "date"].indexOf(item.type) < 0 && (
                <CustomFormInput
                  required={true}
                  type={item.type}
                  value={formData[item.value]}
                  onChangeValue={(val) => onChangeFieldData(val, item.value)}
                  placeholder={item.label}
                />
              )}
            </div>
          ))}
        </div>
      }
      footer={
        <div className={`d-flex align-items-center g-3`}>
          <AtlasButton
            disabled={_.values(formData).some((x) => !x || x === "")}
            className={"btn-new btn-new--primary"}
            onClick={() => submitData(formData)}
            children={mode}
          />
          <AtlasButton
            className={"btn-new btn-new--outline-secondary"}
            onClick={() => onClose()}
            children={mode === "Create" ? "Cancel" : "Close"}
          />
        </div>
      }
      onClose={onClose}
    />
  );
};

export default Form;
