import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Post, Put, Get, Delete } from "utils/axios";
import { storeLastView } from "actions/lastView";
import { requestSuccess, requestError } from "utils/requestHandler";
import { TypeLib } from "./asset";

const searchParams = (category) => [
  {
    label: "Title",
    value: "title_cont",
    type: "input",
    param: "",
  },
  {
    label: "Category",
    value: "category",
    type: "select",
    param: "",
    options: category || [],
  },
  {
    label: "Type",
    value: "type_id_eq",
    type: "radio",
    param: "",
    options: [{ value: "", label: "All" }, ...TypeLib],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,

      reassignSubmissions: {},

      showDeleteConfirmation: false,
      showEditTemplateModal: false,
      showCreateTemplateModal: false,

      searchParams: searchParams(),
      templates: {
        data: [],
        meta: {
          page: 1,
          pages: 0,
        },
      },
      selectedTemplate: null,
      templatePages: [],
      category: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeTemplateHOC = (val, context) => this.setState({ [context]: val });

    getTemplates = (page, search) => {
      let tempSearch = _.replace(search, "q[category]", "category");
      let temp = {
        currentPage: page,
        searchParams: tempSearch,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/graphic_templates?${tempSearch}page=${page}`,
        this.getTemplatesSuccess,
        this.getTemplatesError,
        this.load,
      );
    };
    getTemplatesSuccess = (payload) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }
      let tempData = _.map(payload.data, (item) => {
        let tempLabel = _.find(TypeLib, { id: item.type_id });

        return {
          ...item,
          typeLabel: tempLabel.label || "",
          category:
            item.category_list &&
            item.category_list.length > 0 &&
            item.category_list[0],
        };
      });

      this.setState({
        templates: {
          data: tempData,
          meta: payload.meta,
        },
        templatePages: tempPages,
      });
    };
    getTemplatesError = (error) => requestError(error);

    getSelectedTemplate = (id) =>
      Get(
        `/admin/graphic_templates/${id}`,
        this.getSelectedTemplateSuccess,
        this.getSelectedTemplateError,
        this.load,
      );
    getSelectedTemplateSuccess = (payload) =>
      this.setState({
        selectedTemplate: {
          ...payload,
          type_id: +payload.type_id,
          category_list: payload.category_list,
        },
        showEditTemplateModal: true,
      });
    getSelectedTemplateError = (error) => requestError(error);

    getCategories = () =>
      Get(
        `/tags?type=categories`,
        this.getCategoriesSuccess,
        this.getCategoriesError,
        this.load,
      );
    getCategoriesSuccess = (payload) => {
      let options = _.map(payload, (item) => {
        return {
          ...item,
          label: item.name,
          value: item.name,
        };
      });
      this.setState({
        category: payload,
        searchParams: searchParams(options),
      });
    };
    getCategoriesError = (error) => requestError(error);

    createTemplate = (dataToSubmit) =>
      Post(
        `/admin/graphic_templates`,
        {
          ...dataToSubmit,
          category_list: [dataToSubmit.category_list[0].name],
        },
        this.createTemplateSuccess,
        this.createTemplateError,
        this.load,
      );
    createTemplateSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getCategories();
      this.getTemplates(currentPage, searchParams);
      this.setState({ showCreateTemplateModal: false });

      requestSuccess(`Template has been created successfully`);
    };
    createTemplateError = (error) => requestError(error);

    updateTemplate = (dataToSubmit) => {
      let tempData = _.cloneDeep(dataToSubmit);

      tempData.sample.indexOf("https") > -1 && delete tempData.sample;
      Put(
        `/admin/graphic_templates/${dataToSubmit.id}`,
        {
          ...tempData,
          category_list: tempData.category_list?.length > 0
            ? [tempData.category_list[0].name]
            : ""
        },
        this.updateTemplateSuccess,
        this.updateTemplateError,
        this.load,
      );
    };
    updateTemplateSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getCategories();
      this.getTemplates(currentPage, searchParams);
      this.setState({ showEditTemplateModal: false });

      requestSuccess(`Template has been updated successfully`);
    };
    updateTemplateError = (error) => requestError(error);

    deleteTemplate = (id) =>
      Delete(
        `/admin/graphic_templates/${id}`,
        this.deleteTemplateSuccess,
        this.deleteTemplateError,
        this.load,
      );
    deleteTemplateSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getTemplates(currentPage, searchParams);
      this.setState({ showDeleteConfirmation: false });

      requestSuccess(`Template has been deleted successfully.`);
    };
    deleteTemplateError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          templatePages={this.state.templatePages}
          templates={this.state.templates}
          searchParams={this.state.searchParams}
          selectedTemplate={this.state.selectedTemplate}
          showDeleteConfirmation={this.state.showDeleteConfirmation}
          showCreateTemplateModal={this.state.showCreateTemplateModal}
          showEditTemplateModal={this.state.showEditTemplateModal}
          onLoadTemplate={this.state.loading}
          category={this.state.category}
          onChangeTemplateHOC={this.onChangeTemplateHOC}
          getTemplates={this.getTemplates}
          getCategories={this.getCategories}
          getSelectedTemplate={this.getSelectedTemplate}
          createTemplate={this.createTemplate}
          updateTemplate={this.updateTemplate}
          deleteTemplate={this.deleteTemplate}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
