import React, { useState } from "react"
import moment from "moment"
import { IoMdClose } from "react-icons/io"
import { Popover } from "@material-ui/core"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import TextField from "@material-ui/core/TextField"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker"
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker"
import { useMediaQuery } from '@mui/material';

import AtlasIcon from "components/Icon/atlasIcon"

const CustomDatepicker = ({
  id = "",
  label,
  type = "date",
  className = "",
  disabled,
  value,
  dateFormat,
  clearable = true,
  defaultValue,
  withPortal = false,
  textFieldClass,
  minDate = "",
  maxDate = "",
  minTime = "",
  maxTime = "",
  minDateTime = "",
  maxDateTime = "",
  minutesStep = 1,
  shouldDisableTime,
  disableFuture,
  customClearBtnStyle,
  customSubsalesDisabledStyle,
  showYearPicker,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isDesktop = useMediaQuery('@media (pointer: fine)');
  
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
  }
  
  const open = Boolean(anchorEl)
  let defaultFormat = "";
  if (showYearPicker) {
    defaultFormat = "YYYY";
  } else if (dateFormat) {
    defaultFormat = dateFormat;
  } else {
    defaultFormat = "DD-MM-YYYY";
  }

  return (
    <>
      <div className="at-custom_datepicker-cont d-flex align-items-center w-100 position-relative">
        {(type === "date" && withPortal) && (
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en" moment={moment}>
            <MobileDatePicker
              className={`at-custom-datepicker-cont ${className}`}
              open={open}
              helperText={label || "DD/MM/YYYY"}
              value={value ? moment(value, "YYYY-MM-DD") : null}
              format={defaultFormat}
              defaultValue={defaultValue}
              clearable={clearable || false}
              disabled={disabled || false}
              disableFuture={disableFuture || false}
              showDaysOutsideCurrentMonth={true}
              views={showYearPicker ? ["year"] : ["year", "month", "day"]}
              onClose={handleClose}
              onChange={(val) => {
                if (val) {
                  const backendFormat = val.format("YYYY-MM-DD");
                  onChange(backendFormat)
                } else {
                  onChange(null); 
                }
              }}
              onAccept={(val) => {
                if (val) {
                  const backendFormat = val.format("YYYY-MM-DD");
                  onChange(backendFormat);
                }
              }}
              slotProps={{
                textField: {
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      padding: 0,
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: 0,
                      border: "none",
                      boxShadow: "none",
                      outline: "none",
                      fontSize: "14px",
                      paddingLeft: "5px",
                      "&:disabled":
                        customSubsalesDisabledStyle ?
                          {
                            backgroundColor: "#f3f4f6",
                            border: "1px solid #0275bc",
                            borderRadius: "0.4em",
                          }
                        : {},
                    },
                  },
                  onClick: () => {
                    if (!isDesktop) {
                      setAnchorEl(true)
                    }
                  }
                },
              }}
            />
          </LocalizationProvider>
        )}
        {(type === "date" && !withPortal) && (
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en" moment={moment}>
            <DatePicker
              className={`at-custom-datepicker-cont ${className}`}
              open={open}
              helperText={label || "DD/MM/YYYY"}
              value={value ? moment(value, "YYYY-MM-DD") : null}
              format={defaultFormat}
              clearable={clearable || false}
              minDate={minDate ? moment(minDate) : ""}
              maxDate={maxDate ? moment(maxDate) : ""}
              minutesStep={minutesStep}
              minTime={minTime}
              maxTime={maxTime}
              onClose={handleClose}
              onChange={(val) => {
                if (val) {
                  const backendFormat = val.format("YYYY-MM-DD");
                  onChange(backendFormat)
                } else {
                  onChange(null); 
                }
              }}
              onAccept={(val) => {
                if (val) {
                  const backendFormat = val.format("YYYY-MM-DD");
                  onChange(backendFormat);
                }
              }}
              disabled={disabled || false}
              disableFuture={disableFuture || false}
              disableOpenPicker={true}
              showDaysOutsideCurrentMonth={true}
              views={showYearPicker ? ["year"] : ["year", "month", "day"]}
              slotProps={{
                textField: {
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      padding: 0,
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                      "&:disabled":
                        customSubsalesDisabledStyle ?
                          {
                            color: "#374151",
                          }
                        : {},
                    },
                    "& .MuiInputBase-input": {
                      padding: 0,
                      border: "none",
                      boxShadow: "none",
                      outline: "none",
                      fontSize: "14px",
                      paddingLeft: "5px",
                      "&:disabled":
                        customSubsalesDisabledStyle ?
                          {
                            backgroundColor: "#f3f4f6",
                            border: "1px solid #0275bc",
                            borderRadius: "0.4em",
                          }
                        : {},
                    },
                  },
                  onClick: () => {
                    if (!isDesktop) {
                      setAnchorEl(true)
                    }
                  }
                },
              }}
            />
          </LocalizationProvider>
        )}
        {(type === "datetime-local" && withPortal) && (
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
            <MobileDateTimePicker
              id={`at-datetimepicker-${id}`}
              disabled={disabled || false}
              disableFuture={disableFuture || false}
              className={`at-form-input ${className}`}
              slotProps={{
                mobilePaper: {
                  className: "at-custom-datetimepicker-paper_dialog",
                },
                textField: {
                  className: `at-custom-datetimepicker-root at-datetimepicker-${id}`,
                },
              }}
              minutesStep={minutesStep}
              minTime={minTime}
              maxTime={maxTime}
              onChange={val => onChange(val)}
              value={moment(value)}
            />
          </LocalizationProvider>
        )}
        {(type === "datetime-local" && !withPortal) && (
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
            <TextField
              id={`custom-datepicker-${id}`}
              className={className}
              disabled={true}
              type="datetime-local"
              value={value ? moment(value).format("YYYY-MM-DDTHH:mm") : ""}
              onClick={handleClick}
              onError={e => console.log(e)}
              classes={{
                root: textFieldClass || "at-form-input at-custom-datetimepicker-root",
              }}
              InputProps={{ readOnly: true }}
              inputProps={{
                style: { fontSize: "14px" },
              }}
            />
            <Popover
              id={`custom-datepicker-${id}`}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}>
              <StaticDateTimePicker
                format={dateFormat ? dateFormat : "yyyy-MM-DDTHH:mm"}
                onAccept={val => {onChange(val.format("yyyy-MM-DDTHH:mm"))}}
                onClose={handleClose}
                views={["year", "month", "day", "hours", "minutes"]}
                id={`at-datetimepicker-${id}`}
                minDateTime={moment(minDateTime) || null}
                maxDateTime={moment(maxDateTime) || null}
                minutesStep={minutesStep}
                shouldDisableTime={shouldDisableTime}
                value={value ? moment(value, "YYYY-MM-DDTHH:mm") : null}
              />
            </Popover>
          </LocalizationProvider>
        )}
        {type === "time" && (
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en" moment={moment}>
            <MobileTimePicker
              id={`at-datetimepicker-${id}`}
              disabled={disabled || false}
              disableFuture={disableFuture || false}
              className={`${className}`}
              slotProps={{
                mobilePaper: {
                  className: "at-custom-datetimepicker-paper_dialog",
                },
                textField: {
                  className: `at-custom-datetimepicker-root at-datetimepicker-${id}`,
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      padding: 0,
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                    "& .MuiInputBase-input": {
                      padding: 0,
                      border: "none",
                      boxShadow: "none",
                      outline: "none",
                      fontSize: "14px",
                      paddingLeft: "5px",
                      "&:disabled":
                        customSubsalesDisabledStyle ?
                          {
                            backgroundColor: "#f3f4f6",
                            border: "1px solid #0275bc",
                            borderRadius: "0.4em",
                          }
                        : {},
                    },
                  },
                },
              }}
              minutesStep={minutesStep}
              minTime={minTime ? moment(minTime) : null}
              maxTime={maxTime ? moment(maxTime) : null}
              onClose={handleClose}
              onAccept={handleClick}
              onChange={val => onChange(val)}
              value={value ? moment(value) : null}
            />
          </LocalizationProvider>
        )}
        <div className="d-flex align-items-center">
          {!disabled && (
            <AtlasIcon
              svgHref={"atlas-calendar-2-linear"}
              style={{
                width: 16,
                height: 16,
                marginRight: 10,
                right: disabled ? 0 : 40,
                cursor: "pointer",
              }}
              onClick={e => {
                if (!disabled) {
                  if (type === "datetime-local" && !withPortal) {
                    handleClick(e)
                  } else if (type === "datetime-local" && withPortal) {
                    let elementFinder = document.getElementsByClassName(`at-datetimepicker-${id}`)[0]?.childNodes[0]
                    elementFinder && elementFinder.click()
                  } else {
                    let elementFinder =
                      document.getElementsByClassName(`at-custom-datepicker-input_adornment input_adornment-${id}`)[0]
                        ?.childNodes[0] ??
                      document.getElementsByClassName(`at-custom-datepicker-${id}`)[0]?.childNodes[0]
                    elementFinder && elementFinder.click()
                    handleClick(e)
                  }
                }
              }}
            />
          )}
          {(!disabled && clearable) && (
            <div
              className="at-datetimepicker-clear_btn-cont"
              style={{ ...customClearBtnStyle }}
              onClick={() => onChange(null)}>
              <IoMdClose className="at-datetimepicker-clear_btn" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CustomDatepicker
