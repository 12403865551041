import React, { createContext, useContext, useState } from 'react';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import { Get, Put } from 'utils/axios';

import LoadingModal from "components/LoadingModal";

import { requestError, requestSuccess } from 'utils/requestHandler';

import iqiLogo from "assets/images/iqi_logo_only.png";
import juwaiLogo from "assets/images/juwai_logo_only.png";
import juwaiAsiaLogo from "assets/images/juwai-asia-logo.png";
import edgePropLogo from "assets/images/edgeprop_logo.png";

export const LocalizationContext = createContext();

export const LanguageContextProvider = ({ children }) => {
  const [moduleKeys, setModuleKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const [moduleKeysToEdit, setModuleKeysToEdit] = useState([]);

  const load = (param) => setLoading(param);
  const onChangeLocalisation = (val, context) => setModuleKeys((prev) => ({ ...prev, [context]: val }));

  const getLocalised = (param, defaultLabel) => {
    let temp = _.find(moduleKeys, { key: param });
    return temp ? (temp.translation || temp.default_translation) : defaultLabel;
  };

  const getFilterKey = (param) => {
    let temp = _.find(moduleKeys, { translation: param });
    return temp ? (temp.default_translation) : param;
  };

  const defaultParse = (msg) => {
    if (Array.isArray(msg)) {
      return msg.flatMap((part, index) => {
        if (typeof part === "string") {
          return ReactHtmlParser(part);
        }
        return part;
      });
    }

    if (typeof msg === "string") {
      return ReactHtmlParser(msg);
    }

    return null;
  };

  const renderSpecialMessage = (message, type, param = {}) => {
    switch (type) {
      case "icon": {
        const parts = message.split(/(\{icon_\d\})/);
        return parts.map((part, index) => {
          switch (part) {
            case "{icon_1}":
              return <img key={index} style={{ width: 25, margin: "0px 5px" }} src={iqiLogo} />;
            case "{icon_2}":
              return <img key={index} style={{ width: 25, margin: "0px 5px" }} src={juwaiLogo} />;
            case "{icon_3}":
              return <img key={index} style={{ width: 25, margin: "0px 5px" }} src={juwaiAsiaLogo} />;
            case "{icon_4}":
              return <img key={index} style={{ width: 25, margin: "0px 5px", borderRadius: "50%" }} src={edgePropLogo} />;
            default:
              return defaultParse(part);
          }
        });
      }
      case "item": {
        if (Array.isArray(message)) {
          return message.map((item, index) => {
            let tmp = item.replace(/\{\{([\w.]+)\}\}/g, (_, key) => param[key] || '');
            return defaultParse(tmp);
          });
        } else {
          let tmp = message.replace(/\{\{([\w.]+)\}\}/g, (_, key) => param[key] || '');
          return defaultParse(tmp);
        }
      }
      default:
        return defaultParse(message);
    }
  };

  const getLocalisedSetting = () => Get(
    `/localization_settings?q[status_id_eq]=1`,
    getLocalisedSettingSuccess,
    getLocalisedSettingError,
    load
  );
  const getLocalisedSettingSuccess = (payload) => {
    const [english, otherLanguages] = _.partition(payload.data, setting => setting.language_name === 'English');
    const sortedOtherLanguages = _.sortBy(otherLanguages, ['language_name']);
    const sortedSettings = [...english, ...sortedOtherLanguages];
    setSettings(sortedSettings);
  }
  const getLocalisedSettingError = (error) => requestError(error);

  const getAllLocalisedSetting = () => Get(
    `/admin/localization_settings`,
    getAllLocalisedSettingSuccess,
    getAllLocalisedSettingError,
    load
  );
  const getAllLocalisedSettingSuccess = (payload) => {
    const [english, otherLanguages] = _.partition(payload.data, setting => setting.language_name === 'English');
    const sortedOtherLanguages = _.sortBy(otherLanguages, ['language_name']);
    const sortedSettings = [...english, ...sortedOtherLanguages];
    setSettings(sortedSettings);
  }
  const getAllLocalisedSettingError = (error) => requestError(error);

  const getAgentLocalisedSetting = () => Get(
    `/localization_settings?q[status_id_eq]=1`,
    getAgentLocalisedSettingSuccess,
    getAgentLocalisedSettingError,
    load
  );
  const getAgentLocalisedSettingSuccess = (payload) => {
      const [english, otherLanguages] = _.partition(payload.data, setting => setting.language_name === 'English');
    const sortedOtherLanguages = _.sortBy(otherLanguages, ['language_name']);
    const sortedSettings = [...english, ...sortedOtherLanguages];
    setSettings(sortedSettings);
  }
  const getAgentLocalisedSettingError = (error) => requestError(error);

  const getModuleKeys = (module_id, language_code, is_edit = false) => Get(
    `/admin/translations/module_keys?module_id=${module_id}&language=${language_code}`,
    payload => getModuleKeysSuccess(payload, is_edit),
    getModuleKeysError,
    load
  );
  const getModuleKeysSuccess = (payload, is_edit) => {
    if (is_edit) {
      setModuleKeysToEdit(payload);
    } else {
      setModuleKeys(payload);
    }
  };
  const getModuleKeysError = (error) => requestError(error);

  const getAgentModuleKeys = (module_id, language_code) => Get(
    `/translations?module_id=${module_id}&language=${language_code}`,
    payload => getAgentModuleKeysSuccess(payload, language_code),
    getAgentModuleKeysError,
    load
  );
  const getAgentModuleKeysSuccess = (payload, language_code) => {
    let temp = _.cloneDeep(payload.data);
    if (Array.isArray(temp) && temp.length > 0) {
      temp = _.map(temp, item => {
        return {
          default_translation: item.default_translation,
          key: item.key,
          id: item.id,
          translation: item.localised_language ? item.localised_language[language_code] : ""
        };
      });
    }
    setModuleKeys(temp);
  };
  const getAgentModuleKeysError = (error) => requestError(error);

  const permissibleBulkUpdate = (dataToSubmit, language_code) => Put(
    `/admin/translations/permissible_bulk_update`,
    { updates: dataToSubmit },
    () => permissibleBulkUpdateSuccess(language_code),
    permissibleBulkUpdateError,
    load
  );
  const permissibleBulkUpdateSuccess = (language_code) => {
    requestSuccess('Translation updated successfully');
    getModuleKeys(14, language_code, true);
  };
  const permissibleBulkUpdateError = (error) => requestError(error);

  return (
    <LocalizationContext.Provider value={{
      moduleKeys,
      setModuleKeys,
      loading,
      setLoading,
      settings,
      setSettings,
      moduleKeysToEdit,
      setModuleKeysToEdit,
      load,
      onChangeLocalisation,
      getLocalised,
      getFilterKey,
      defaultParse,
      renderSpecialMessage,
      getLocalisedSetting,
      getAllLocalisedSetting,
      getAgentLocalisedSetting,
      getModuleKeys,
      getAgentModuleKeys,
      permissibleBulkUpdate
    }}>
      {children}
      {loading && <LoadingModal />}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);