import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { requestError } from "utils/requestHandler";

import { checkNull } from "utils/checkNull";
import { numberWithCommas } from "utils/thousandSeparator";
import { Get } from "utils/axios";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      title: "",
      adminView: false,
      teamView: false,
      view: "Project",
      startDate: Moment()
        .year(new Date().getFullYear())
        .month(0)
        .date(1)
        .format("YYYY-MM-DD"),
      endDate: Moment(new Date()).format("YYYY-MM-DD"),
      teamViewSummary: "General",
      detailsModalOpen: false,

      personalSales: [],
      personalSalesDetails: [],
      personalSalesComm: {},
      leaderBoardData: [],
      agentSalesSummary: {},
    };

    load = (param) => this.setState({ loading: param });

    onChangeMysalesHOC = (val, context) => this.setState({ [context]: val });

    getPersonalSales = (encodedIdentity) => {
      let { startDate, endDate } = this.state;

      Get(
        `/connector/sales/date_summary?type_id=1&identity_id=${encodedIdentity}${startDate ? `&start_date=${startDate}&end_date=${endDate}` : ""}`,
        this.getPersonalSalesSuccess,
        this.getPersonalSalesError,
        this.load,
      );
    };
    getPersonalSalesSuccess = (payload) => {
      let tempSales = _.map(payload.sales, (item) => ({
        ...item,
        bookingDateShort: item.bookingDateShort
          ? Moment(item.bookingDateShort, "DD-MM-YYYY").format("DD MMM YYYY")
          : "N/A",
      }));
      this.setState({
        personalSales: tempSales,
        personalSalesComm: {
          totalEstimatedCommission: numberWithCommas(
            payload.grandTotalEstimatedComm,
          ),
          totalPaidCommission: numberWithCommas(payload.grandTotalPaidComm),
        },
      });
    };
    getPersonalSalesError = (error) => requestError(error);

    getPersonalSalesDetails = (encodedUnitID, encodedIdentityID) => {
      Get(
        `/connector/units/${encodedUnitID}?identity_id=${encodedIdentityID}`,
        this.getPersonalSalesDetailsSuccess,
        this.getPersonalSalesDetailsError,
        this.load,
      );
    };
    getPersonalSalesDetailsSuccess = (payload) => {
      this.setState({
        personalSalesDetails: payload,
        detailsModalOpen: true,
      });
    };
    getPersonalSalesDetailsError = (error) => requestError(error);

    getTeamPersonalSales = (encodedIdentityID, startDate, endDate) => {
      let team_param = ""
      
      if (window.location.href.includes("/team/")) {
        team_param = "team_sales=true&";
      }
      Get(
        `/connector/sales/date_summary?${team_param}type_id=1&identity_id=${encodedIdentityID}${startDate ? `&start_date=${startDate}&end_date=${endDate}` : ""}`,
        this.getTeamPersonalSalesSuccess,
        this.getTeamPersonalSalesError,
        this.load,
      );
    };
    getTeamPersonalSalesSuccess = (payload) => {
      let tempSales = _.map(payload.sales, (item) => ({
        ...item,
        bookingDateShort: item.bookingDateShort
          ? Moment(item.bookingDateShort, "DD-MM-YYYY").format("DD MMM YYYY")
          : "N/A",
      }));
      this.setState({
        personalSales: tempSales,
        personalSalesComm: {
          totalEstimatedCommission: numberWithCommas(
            payload.grandTotalEstimatedComm,
          ),
          totalPaidCommission: numberWithCommas(payload.grandTotalPaidComm),
        },
      });
      this.processAgentSalesSummary(payload);
    };
    getTeamPersonalSalesError = (error) => requestError(error);

    getLeaderBoardData = (id) =>
      Get(
        `/connector/top_performers?identity_id=${id}`,
        this.getLeaderBoardDataSuccess,
        this.getLeaderBoardDataError,
        this.load,
      );
    getLeaderBoardDataSuccess = (payload) =>
      this.setState({ leaderBoardData: payload });
    getLeaderBoardDataError = (error) => requestError(error);

    processAgentSalesSummary = (payload) => {
      const {
        projectBookedUnits,
        projectBookedUnitsAmount,
        projectCancelledUnits,
        projectcancelledUnitsAmount,
        projectConvertedUnits,
        projectConvertedUnitsAmount,

        subsaleBookedUnits,
        subsaleBookedUnitsAmount,
        subsaleCancelledUnits,
        subsaleCancelledUnitsAmount,
        subsaleConvertedUnits,
        subsaleConvertedUnitsAmount,

        rentalCancelledUnits,
        rentalCancelledUnitsAmount,
        rentalConvertedUnits,
        rentalConvertedUnitsAmount,

        numberOfAgents,
        numberOfAgentsSoldUnit,
      } = payload;
      return this.setState({
        agentSalesSummary: {
          ...payload,
          projectBookedUnits: Number(checkNull(projectBookedUnits).toFixed(0)),
          projectCancelledUnits: Number(
            checkNull(projectCancelledUnits).toFixed(0),
          ),
          projectConvertedUnits: Number(
            checkNull(projectConvertedUnits).toFixed(0),
          ),

          subsaleBookedUnits: Number(checkNull(subsaleBookedUnits).toFixed(0)),
          subsaleCancelledUnits: Number(
            checkNull(subsaleCancelledUnits).toFixed(0),
          ),
          subsaleConvertedUnits: Number(
            checkNull(subsaleConvertedUnits).toFixed(0),
          ),

          rentalCancelledUnits: Number(
            checkNull(rentalCancelledUnits).toFixed(0),
          ),
          rentalConvertedUnits: Number(
            checkNull(rentalConvertedUnits).toFixed(0),
          ),

          numberOfAgents: Number(checkNull(numberOfAgents).toFixed(0)),
          numberOfAgentsSoldUnit: Number(
            checkNull(numberOfAgentsSoldUnit).toFixed(0),
          ),

          projectConvertedUnitsAmount: Number(
            checkNull(projectConvertedUnitsAmount).toFixed(0),
          ),
          projectBookedUnitsAmount: Number(
            checkNull(projectBookedUnitsAmount).toFixed(0),
          ),
          projectCancelledUnitsAmount: Number(
            checkNull(projectcancelledUnitsAmount).toFixed(0),
          ),

          subsaleBookedUnitsAmount: Number(
            checkNull(subsaleBookedUnitsAmount).toFixed(0),
          ),
          subsaleCancelledUnitsAmount: Number(
            checkNull(subsaleCancelledUnitsAmount).toFixed(0),
          ),
          subsaleConvertedUnitsAmount: Number(
            checkNull(subsaleConvertedUnitsAmount).toFixed(0),
          ),

          rentalConvertedUnitsAmount: Number(
            checkNull(rentalConvertedUnitsAmount).toFixed(0),
          ),
          rentalCancelledUnitsAmount: Number(
            checkNull(rentalCancelledUnitsAmount).toFixed(0),
          ),
        },
      });
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadMySales={this.state.loading}
            title={this.state.title}
            adminView={this.state.adminView}
            teamView={this.state.teamView}
            view={this.state.view}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            leaderBoardData={this.state.leaderBoardData}
            teamViewSummary={this.state.teamViewSummary}
            detailsModalOpen={this.state.detailsModalOpen}
            personalSales={this.state.personalSales}
            personalSalesDetails={this.state.personalSalesDetails}
            personalSalesComm={this.state.personalSalesComm}
            agentSalesSummary={this.state.agentSalesSummary}
            getLeaderBoardData={this.getLeaderBoardData}
            onChangeMysalesHOC={this.onChangeMysalesHOC}
            getPersonalSales={this.getPersonalSales}
            getTeamPersonalSales={this.getTeamPersonalSales}
            getPersonalSalesDetails={this.getPersonalSalesDetails}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
