import { useState } from "react";
import { AiTwotoneMail } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";

import { MenuItem } from "@material-ui/core";

import AtlasButton from "components/Button";
import ModalDialog from "components/Modal/ModalDialog";
import AtlasSelect from "components/Select/new";
import DatePicker from "components/Input/datetimepicker";

import { hours, minutes } from "../assets";
import moment from "moment";

const Schedule = ({
  handleClose,
  handleUpdateDialogClose,
  selectedEmailCampaign,
  updateEmailCampaign,
}) => {
  const [active, setActive] = useState("send-now");
  const [values, setValues] = useState({
    date: new Date(),
    hours: "00",
    minutes: "00",
  });

  const handleClick = (card) => setActive(card);

  const handleDateChange = (val) => {
    setValues({
      ...values,
      date: val,
    });
  };

  const handleChange = (key) => (e) => {
    setValues({
      ...values,
      [key]: e.target.value,
    });
  };

  const handleSend = () => {
    if (active === "send-now") {
      updateEmailCampaign({
        ...selectedEmailCampaign,
        status: 3,
      });
    } else {
      const { date, hours, minutes } = values;

      const addZero = (num) => (num < 10 ? "0" + num : num);

      const day = addZero(date.date());
      const month = addZero(date.month() + 1);
      const year = date.year();

      updateEmailCampaign({
        ...selectedEmailCampaign,
        scheduled_at: `${year}-${month}-${day}T${hours}:${minutes}:00.000+08:00`,
        status: 2,
      });
    }

    handleUpdateDialogClose();
  };

  return (
    <ModalDialog
      responsiveSize={"sm"}
      title={"Schedule Campaign"}
      onClose={handleClose}
      footer={
        <div className={"d-flex g-2"}>
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--primary"}
            onClick={handleSend}
          >
            {active === "send-now" ? "Send Now" : "Schedule"}
          </AtlasButton>
          <AtlasButton
            type={"button"}
            className={"btn-new btn-new--outline-secondary"}
            onClick={handleClose}
          >
            Cancel
          </AtlasButton>
        </div>
      }
    >
      <div className={"form-row"}>
        <div className={"col d-flex justify-content-center"}>
          <div
            className={`at-email-campaigns__schedule-campaign-form__card mb-3 mb-sm-0 ${
              active === "send-now" ? "active" : ""
            }`}
            role={"button"}
            onClick={() => handleClick("send-now")}
          >
            <AiTwotoneMail />

            <p className={"mt-2"}>
              <strong>Send now</strong>
            </p>
          </div>
        </div>

        <div className={"col d-flex justify-content-center"}>
          <div
            className={`at-email-campaigns__schedule-campaign-form__card ${
              active === "schedule" ? " active" : ""
            }`}
            role={"button"}
            onClick={() => handleClick("schedule")}
          >
            <FaCalendarAlt />

            <p className={"mt-2"}>
              <strong>Schedule</strong>
            </p>
          </div>
        </div>
      </div>

      {active === "schedule" && (
        <div className={"form-row mt-4"}>
          <div className={"col-12"}>
            <label htmlFor={"date"} className={"d-inline-block mb-1"}>
              <strong>Date</strong>
            </label>

            <div className="at-form-input">
              <DatePicker
                type="date"
                value={values.date}
                onChange={handleDateChange}
                minDate={moment()}
              />
            </div>
          </div>

          <div className={"col-12"}>
            <label htmlFor={"date"} className={"d-inline-block mb-1"}>
              <strong>Time (GMT +08:00)</strong>
            </label>

            <div className={"d-flex g-2"}>
              <AtlasSelect
                value={values.hours}
                onChange={handleChange("hours")}
              >
                {hours &&
                  hours.map((hour) => (
                    <MenuItem
                      classes={{ root: `at-select__dropdown-item` }}
                      key={hour}
                      value={hour}
                      style={{ fontSize: 14 }}
                    >
                      {hour}
                    </MenuItem>
                  ))}
              </AtlasSelect>

              <AtlasSelect
                value={values.minutes}
                onChange={handleChange("minutes")}
              >
                {minutes &&
                  minutes.map((minute) => (
                    <MenuItem
                      classes={{ root: `at-select__dropdown-item` }}
                      key={minute}
                      value={minute}
                      style={{ fontSize: 14 }}
                    >
                      {minute}
                    </MenuItem>
                  ))}
              </AtlasSelect>
            </div>
          </div>
        </div>
      )}
    </ModalDialog>
  );
};

export default Schedule;
