import React, { Component } from "react";
import _ from "lodash";

import AtlasIcon from "components/Icon/atlasIcon";

import VersionCheckHOC from "actions/versionCheck";
import { cardsData } from "./assets";
class MarketingTools extends Component {
  renderMarketingTools = () => (
    <>
      <div className="d-flex justify-content-center">
        <ul
          id="roomListing"
          className="at-request_list__container no-gutters"
        >
          {cardsData.map((item, index) => {
            return (
              <li key={index} className={`at-request__item`}>
                <div className="at-request-form__container" onClick={() => this.props.redirectTo(item)}>
                  <div style={{ background: item.color, borderRadius: "8px", padding: "8px" }}>
                    <AtlasIcon
                      svgHref={item.icon}
                      style={{ width: 32, height: 32 }}
                    />
                  </div>
                  <label className={"at-request-form__title"}>{item.title}{" "}</label>
                  <span className={"at-request-form__desc"}>{item.description}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  )

  render = () => {
    return (
      <>
        {this.renderMarketingTools()}
      </>
    );
  };
}

export default VersionCheckHOC(MarketingTools);
