import React, { useCallback, useState } from "react";

const ArrowContent = ({ onClickRearrangeData, columnContent, rowData }) => {
  const [sortStatus, setSortStatus] = useState("");

  const onToggleSortMode = useCallback(
    (param, value) => {
      setSortStatus(param);
      onClickRearrangeData({
        mode: param,
        columnValue: value,
      });
    },
    [columnContent, sortStatus, rowData]
  );

  if (sortStatus === "asc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("desc", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--down arrow--active"></div>
      </div>
    );
  } else if (sortStatus === "desc") {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up arrow--active"></div>
      </div>
    );
  } else {
    return (
      <div
        className="at-table-head__arrow-container"
        onClick={() => onToggleSortMode("asc", columnContent)}
      >
        <div className="at-table-head__arrow at-table-head__arrow--up"></div>
        <div className="at-table-head__arrow at-table-head__arrow--down"></div>
      </div>
    );
  }
};

const TableHeader = ({
  mode,
  columns,
  rowData,
  containerStyle,
  actionLabel,
  maxActionLength = 0,
  currentPageData,
  actionColumnContent = [],
  actionColumStyle,
  onClickRearrangeData,
}) => {
  return (
    <ul className={`at-table-row at-table-head`} style={containerStyle || {}}>
      {columns.map(
        ({ header, accessor, searchFlag, columnStyle, contentClass, hideSort }, index) => (
          <li
            style={{
              cursor: "auto",
              width: "100%",
              ...columnStyle,
            }}
            key={`header-${index}`}
            className={`at-table-row__item at-table__${index} ${contentClass || ""}`}>
            <span className="at-table-head__title">
              {typeof header === "string" && (
                <span className="at-table-head__title">
                  {header}
                  {!hideSort && (
                    <div style={{ maxWidth: 0, maxHeight: 0, position: "relative" }}>
                      <ArrowContent
                        columnContent={accessor || searchFlag}
                        rowData={rowData}
                        onClickRearrangeData={onClickRearrangeData}
                      />
                    </div>
                  )}
                </span>
              )}
              {typeof header === "function" && header(currentPageData)}
            </span>
          </li>
        )
      )}
      {actionColumnContent.length > 0 && (
        <li
          style={{
            cursor: "auto",
            minWidth: (maxActionLength * 60) < 120
              ? 120 
              : (maxActionLength * 60),
            width: "100%",
            maxWidth:(maxActionLength * 60) < 120
              ? 120 
              : (maxActionLength * 70),
            ...actionColumStyle,
          }}
          className="at-table-row__item at-table__actions"
        >
          <span className="at-table-head__title">{actionLabel || "Actions"}</span>
        </li>
      )}
    </ul>
  );
};

export default TableHeader;
