import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Slider from "react-slick";
import EmailValidator from "email-validator";
import { PulseLoader } from "react-spinners";
import { HiArrowLeft } from "react-icons/hi";

import AlertBox from "components/AlertBox";
import LoadingModal from "components/LoadingModal";
import SupportButtonGroups from "components/Button/supportGroups";
import ForgetPasswordForm from "../../components/Form/forgetPassword";
import ResetPasswordForm from "../../components/Form/resetPassword";

import LoginImage1 from "../../assets/images/login_img1.png";
import LoginImage2 from "../../assets/images/login_img2.png";
import IQILogoBlack from "../../assets/images/iqi-logo_black.png";
import IQILogoWhite from "../../assets/images/iqi-logo_white.png";
import { getTranslation } from "assets/translation";
import { initGA } from "utils/ga";
import withLocalisation from 'actions/localisation';
import ForgetPasswordHOC from "./actions";

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
};

class ResetPassword extends Component {
  componentDidMount = () => {
    const resetToken = this.props.match.params.id;
    if (resetToken && resetToken !== "") {
      this.props.onChangeHOC(resetToken, "resetToken");
      this.props.validateResetToken(resetToken);
    }

    if (!resetToken && resetToken == "") {
      this.props.history.push("/login");
    }

    if (window.location.host.indexOf("atlas.iqiglobal.com") > -1) {
      initGA("/reset-password");
    }
  };

  onChangeEmail = (val) => {
    this.props.onChangeHOC(val, "email");
    this.props.onChangeHOC(EmailValidator.validate(val), "validEmail");
  };

  onClickBackToLogin = () => this.props.history.push("/login");

  onClickForgetPassword = () => this.props.history.push("/forget-password");

  renderSignInAfterResetNewPassword = () => {
    if (this.props.showSuccessMessage) {
      return (
        <>  
          <h2>{getTranslation("label.password_changed", this.props.data.languageReducer.language)}</h2>
          <div className="login-form-container m-auto">
            <p style={{ fontSize: 14 }}>
              {getTranslation("label.password_changed_success_message_description", this.props.data.languageReducer.language)}
            </p>
            <div className="mt-4">
              <button
                className="btn-new btn-new--primary text-uppercase w-100"
                onClick={() => this.onClickBackToLogin()}
              > {getTranslation("action.sign_in", this.props.data.languageReducer.language)}
              </button>
            </div>
          </div>
        </>
      )
    }
    return ""
  }

  renderResetPasswordForm = () => {
    if (!this.props.showSuccessMessage) {
      return (
        <div className="login-panel" style={{ textAlign: "left" }}>
          {!this.props.onLoadResetPassword && (
            <p style={{ margin: 10, color: !this.props.resetTokenValid ? "red" : "" }}>
              {this.props.notificationMessage}
            </p>
          )}
          <ResetPasswordForm
            email={this.props.email}  
            cooldownTiming={this.props.cooldownTiming}
            onLoadForgetPassword={this.props.onLoadForgetPassword}
            confirmedPassword={this.props.confirmedPassword}
            resetTokenValid={this.props.resetTokenValid}
            password={this.props.password}
            passwordError={this.props.passwordError}
            passwordConfirmationError={this.props.passwordConfirmationError}
            onChangeHOC={(val, context) =>
              this.props.onChangeHOC(val, context)
            }
            onClickSubmitPassword={() =>
              this.props.onClickSubmitPassword()
            }
            onClickForgetPassword={() => this.onClickForgetPassword()}
            language={this.props.data.languageReducer.language}
          />
          {this.props.onLoadResetPassword && <LoadingModal />}
        </div>
      )
    }
    return ""
  }

  renderSendResetLink = () => {
    if (this.props.showSuccessMessage) {
      return (
        <>
          <h2>{getTranslation("label.reset_link_sent.title", this.props.data.languageReducer.language)}</h2>
          <div className="login-form-container m-auto">
            <p style={{ textAlign: "left", fontSize: 14 }}>
              {getTranslation("label.reset_link_sent.message", this.props.data.languageReducer.language).replace("{{email}}", this.props.email)}
              <br />
              <br />
              {getTranslation("label.reset_link_sent.message_2", this.props.data.languageReducer.language)}
            </p>
            <div className="mt-4">
              <button
                className="btn-new btn-new--primary text-uppercase w-100"
                onClick={() => this.onClickBackToLogin()}
              > {getTranslation("action.back_to_sign_in", this.props.data.languageReducer.language)}
              </button>
            </div>
          </div>
        </>
      )
    }
    return ""
  }

  renderForgetPasswordPanel = () => {
    if (!this.props.showSuccessMessage) {
      return (
        <div className="login-panel">
          {(!this.props.onLoadForgetPassword && this.props.notificationMessage) && (
              <AlertBox
                mode={"danger"}
                disabledClose={true}
                containerStyle={{ margin: "10px 20px 0px 20px" }}
                description={this.props.notificationMessage}
              />
            )}
          {this.props.onLoadForgetPassword && (
            <PulseLoader
              className={"d-flex align-items-center mb-10"}
              sizeunit={"px"}
              size={10}
              color={"#16232c"}
              loading={this.props.onLoadForgetPassword}
            />
          )}
          <ForgetPasswordForm
            email={this.props.email}
            language={this.props.data.languageReducer.language}
            cooldownTiming={this.props.cooldownTiming}
            onChangeEmail={(val) => this.onChangeEmail(val)}
            showSuccessMessage={this.props.showSuccessMessage}
            onClickSubmitEmail={() => this.props.onClickSubmitEmail()}
          />
        </div>
      )
    }
    return ""
  }
  
  render = () => {
    return (
      <>
        <div
          className="d-flex flex-lg-nowrap flex-wrap"
          style={{ height: "100vh", overflow: "hidden" }}
        >
          <div className="at-desktop-view__controller at-login__banner">
            <div className="at-login__banner-header">
              <img
                src={IQILogoWhite}
                alt={"IQI Logo White"}
                style={{ width: 70, margin: "20px 0px" }}
              />
              <p>{getTranslation("label.sidebar.message", this.props.data.languageReducer.language)}</p>
            </div>
            <div className="at-login__banner-slider">
              <Slider {...SliderSettings}>
                <img src={LoginImage1} alt={"Login Image 1"} />
                <img src={LoginImage2} alt={"Login Image 2"} />
              </Slider>
            </div>
          </div>
          <div className="login-container">
            <div style={{ textAlign: "center", marginTop: "10%" }}>
              <img
                src={IQILogoBlack}
                alt={"IQI Logo Black"}
                style={{ width: "114px" }}
              />
              <div className="at-login__back-to">
                <HiArrowLeft
                  style={{ width: 16, height: 16, marginRight: 10 }}
                />
                <p onClick={() => this.onClickBackToLogin()}>{getTranslation("action.back_to_sign_in", this.props.data.languageReducer.language)}</p>
              </div>
              {this.props.resetTokenValid && (
                <>
                  {!this.props.showSuccessMessage && (
                    <>
                      <h2>{getTranslation("label.reset_password", this.props.data.languageReducer.language)}</h2>
                      <p style={{ padding: "0px 20px" }}>
                        {getTranslation("label.enter_new_password", this.props.data.languageReducer.language)}
                      </p>
                    </>
                  )}
                  {this.renderSignInAfterResetNewPassword()}
                  {this.renderResetPasswordForm()}
                </>
              )}
            </div>
            {!this.props.resetTokenValid && (
              <div className="login-panel">
                {!this.props.showSuccessMessage && (
                  <div style={{ textAlign: "center" }}>
                    <h2>{getTranslation("label.forgot_password", this.props.data.languageReducer.language)}</h2>
                  </div>
                )}
                {this.renderSendResetLink()}
                {this.renderForgetPasswordPanel()}
              </div>
            )}
            <div className="at-login__footer">
              <p className="small">{`IQI Holdings Sdn Bhd (1018842-U)`}</p>
              <p className="small" style={{ whiteSpace: "pre-line" }}>
                {`26th -28th Floor, Tower D, Millerz Square, No. 357, Jalan Kelang Lama, 58000 Kuala Lumpur, Malaysia. \nT: +60374506655`}
              </p>
              <p 
                className="small"
                style={{ cursor: "pointer", marginBottom: 24 }}
                onClick={() => window.open("https://www.iqiglobal.com/privacy-policy", "__blank")}
              >
                <u className="mx-2">{getTranslation("label.terms_of_use", this.props.data.languageReducer.language)}</u>•
                <u className="mx-2">{getTranslation("label.privacy_policy", this.props.data.languageReducer.language)}</u>
              </p>
            </div>
          </div>
        </div>
        <SupportButtonGroups language={this.props.data.languageReducer.language}/>
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });

export default 
compose(
  connect(mapStateToProps, {}),
  withLocalisation,
  ForgetPasswordHOC
)(ResetPassword);
