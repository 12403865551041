import {
  CURRENT_USER_PROFILE,
  UPDATE_CURRENT_USER_PROFILE,
} from "actions/type";

let initialState = {
  display_name: "",
  email: "",
  team_id: "",
  team_name: "",
  mobile_contact_number: "",
  home_contact_number: "",
  work_contact_number: "",
  role_id: "",
  role: "",
  status_id: "",
  status: "",
  description: "",
  remark: "",
  avatar_url: "",
  first_image_url: "",
  second_image_url: "",
  third_image_url: "",
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case CURRENT_USER_PROFILE: {
      let {
        id,
        branch_id,
        referrer_id,
        display_name,
        first_name,
        last_name,
        full_name,
        email,
        team_id,
        team_name,
        country_id,
        confirmation_forms_balance,
        mobile_contact_number,
        home_contact_number,
        work_contact_number,
        name_card_id,
        date_of_birth,
        agent_registration_id,
        role_id,
        role,
        status_id,
        status,
        description,
        remark,
        referrer_name,
        avatar_url,
        first_image_url,
        second_image_url,
        payment_status,
        third_image_url,
        created_at,
        qr_code_image_url,
        identity_qr_code_image_url,
        payment_status_id,
        payment_method_id,
        name_card_display_name,
        agent_status_id,
        atlas_bot_identifier,
        youtube_playlist_url,
        agent_emergency_person_name,
        agent_emergency_contact_number,
        name_card,
        referrer_code,
        current_address,
        encoded_identity,
        branch_name,
        branch_region_name,
        country_name,
        iqi_profile_page,
        identification_number,
        job_title_name,
        is_registration_admin,
        is_subsale_admin,
        is_project_admin,
        is_superior,
        supervisor,
        show_referral_link,
        show_public_qr,
        job_title_id,
        job_title_reference_id,
        job_title_display_name,
        superior_id,
        supervisor_designation,
        agent_via_recruitment_campaign,
        wechat_id,
        facebook_url,
        telegram_url,
        instagram_url,
        board_registration_type_id,
        board_registration_number,
        license_number,
        bank_account_number,
        bank_account_type,
        bank_branch,
        bank_name,
        bank_owner_name,
        furigana,
        emergency_contact_number,
        emergency_person_name,
        emergency_contact_relationship,
        area_of_activity,
        income_tax_number,
      } = action.payload;
      return {
        ...state,
        id,
        display_name,
        full_name,
        branch_id,
        referrer_id,
        first_name,
        last_name,
        name_card_id,
        agent_registration_id,
        email,
        team_id,
        team_name,
        country_id,
        confirmation_forms_balance,
        mobile_contact_number,
        home_contact_number,
        work_contact_number,
        role_id,
        role,
        status_id,
        status,
        description,
        payment_status,
        date_of_birth,
        payment_method_id,
        remark,
        avatar_url,
        first_image_url,
        second_image_url,
        third_image_url,
        created_at,
        qr_code_image_url,
        identity_qr_code_image_url,
        atlas_bot_identifier,
        name_card_display_name,
        agent_status_id,
        payment_status_id,
        youtube_playlist_url,
        agent_emergency_person_name,
        agent_emergency_contact_number,
        current_address,
        referrer_code,
        name_card,
        encoded_identity,
        branch_name,
        branch_region_name,
        country_name,
        iqi_profile_page,
        identification_number,
        referrer_name,
        job_title_name,
        is_registration_admin,
        is_subsale_admin,
        is_project_admin,
        is_superior,
        supervisor,
        supervisor_designation,
        show_referral_link,
        show_public_qr,
        superior_id,
        job_title_id,
        job_title_reference_id,
        job_title_display_name,
        agent_via_recruitment_campaign,
        wechat_id,
        facebook_url,
        telegram_url,
        instagram_url,
        board_registration_type_id,
        board_registration_number,
        license_number,
        bank_account_number,
        bank_account_type,
        bank_branch,
        bank_name,
        bank_owner_name,
        furigana,
        emergency_contact_number,
        emergency_person_name,
        emergency_contact_relationship,
        area_of_activity,
        income_tax_number,
      };
    }

    case UPDATE_CURRENT_USER_PROFILE: {
      let {
        display_name,
        email,
        team_id,
        team_name,
        mobile_contact_number,
        home_contact_number,
        work_contact_number,
        role_id,
        role,
        status_id,
        status,
        description,
        remark,
        avatar_url,
        first_image_url,
        second_image_url,
        third_image_url,
        created_at,
        wechat_id,
        facebook_url,
        telegram_url,
        instagram_url,
        board_registration_type_id,
        board_registration_number,
        license_number,
        bank_account_number,
        bank_account_type,
        bank_branch,
        bank_name,
        bank_owner_name,
        furigana,
        emergency_contact_number,
        emergency_person_name,
        emergency_contact_relationship,
        area_of_activity,
        income_tax_number
      } = action.payload;
      return {
        ...state,
        display_name,
        email,
        team_id,
        team_name,
        mobile_contact_number,
        home_contact_number,
        work_contact_number,
        role_id,
        role,
        status_id,
        status,
        description,
        remark,
        avatar_url,
        first_image_url,
        second_image_url,
        third_image_url,
        created_at,
        wechat_id,
        facebook_url,
        telegram_url,
        instagram_url,
        board_registration_type_id,
        board_registration_number,
        license_number,
        bank_account_number,
        bank_account_type,
        bank_branch,
        bank_name,
        bank_owner_name,
        furigana,
        emergency_contact_number,
        emergency_person_name,
        emergency_contact_relationship,
        area_of_activity,
        income_tax_number
      };
    }

    default:
      return state;
  }
}
