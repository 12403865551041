import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { MdClose } from "react-icons/md";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import AtlasIcon from "components/Icon/atlasIcon";

import { getTranslation } from "assets/translation";
import useSelectedLocalised from "utils/hooks/useSelectedLocalised";

import "stylesheets/components/form-input/index.scss";
import "./index.scss";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
);

const imageUploader = React.forwardRef(
  (
    {
      keys,
      required,
      disabled,
      files = [],
      allowMultiple = false,
      acceptedFileTypes = ["image/png", "image/jpeg", "image/jpg"],
      accept = "image/png, image/jpeg, image/jpg",
      maxFiles = 1,
      onChangeFile = () => {},
      onRemoveFile,
      maxFileSize,
      allowImageValidateSize,
      imageValidateSizeMinWidth,
      imageValidateSizeMinHeight,
      imageValidateSizeLabelImageSizeTooSmall,
      imageValidateSizeLabelExpectedMinSize,
      getLocalised,
      renderSpecialMessage
    },
    ref,
  ) => {
    const language = useSelectedLocalised();
    const [uploadedFiles, setUploadedFiles] = useState([]);

    useEffect(() => {
      if (files && files.length > 0) {
        setUploadedFiles(
          files.map((fileItem) => {
            if (keys) {
              let tmp = {};
              for (const [key, value] of Object.entries(keys)) {
                tmp[key] = fileItem[value];
              }
              return tmp;
            }
            return {
              source: fileItem.source,
              name: fileItem.name,
              size: fileItem.size,
            };
          }),
        );
      } else {
        setUploadedFiles([]);
      }
    }, [files]);

    const renderRequired = (param) => {
      if (param) {
        return (
          <div
            className="at-form-input__required position-static"
            style={{ textAlign: "right" }}
          >
            required *
          </div>
        );
      }
    };

    return (
      <div className="at-input_upload-cont custom-image_upload">
        {((!allowMultiple && uploadedFiles.length !== 1) ||
          (allowMultiple && uploadedFiles.length !== maxFiles)) && (
          <div
            className={`at-input_uploader-cont ${uploadedFiles.length > 0 ? "at-input_uploaded" : ""}`}
          >
            <AtlasIcon svgHref={"atlas-document-upload"} />
            <FilePond
              fullWidth
              {...(uploadedFiles.length > 0 && { files: [] })}
              disabled={disabled}
              className={`at-input_upload-file`}
              oninit={() => console.log("This pond is ready")}
              labelIdle={getTranslation("or_drag_and_drop", language)}
              ref={ref}
              allowMultiple={allowMultiple}
              acceptedFileTypes={acceptedFileTypes}
              accept={accept}
              maxFiles={maxFiles}
              maxFileSize={maxFileSize}
              onupdatefiles={(fileItems) => onChangeFile(fileItems)}
              allowImageValidateSize={allowImageValidateSize}
              imageValidateSizeMinWidth={imageValidateSizeMinWidth}
              imageValidateSizeMinHeight={imageValidateSizeMinHeight}
              imageValidateSizeLabelImageSizeTooSmall={
                imageValidateSizeLabelImageSizeTooSmall
              }
              imageValidateSizeLabelExpectedMinSize={
                imageValidateSizeLabelExpectedMinSize
              }
            />
          </div>
        )}
        {uploadedFiles.length === 0 && renderRequired(required)}
        <Grid container className="at-image_upload_items_list-cont">
          {uploadedFiles.map((item, index) => {
            return (
              item.source && (
                <Grid
                  item
                  key={`at-image_upload_item-${index}`}
                  xs={12}
                  style={{ height: item.source.includes("image") ? 240 : 60 }}
                  className={`at-image_upload_item-cont `}
                >
                  <div className="at-image_upload_item-overlay" />
                  <div className="at-image_upload_item-action">
                    <button
                      className="at-image_upload_item-btn_close"
                      onClick={() => onRemoveFile(item, index)}
                    >
                      <MdClose />
                    </button>
                    <div className="d-flex flex-column justify-content-center">
                      <p className="at-image_upload_item-file_name">
                        {item.name}
                      </p>
                      {item.size && <p className="fs-1">{item.size}</p>}
                    </div>
                    <div className="at-image_upload_item-action-overlay"></div>
                  </div>
                  {item.source.includes("image") && <img src={item.source} />}
                </Grid>
              )
            );
          })}
        </Grid>
      </div>
    );
  },
);

export default imageUploader;
