import React, { useState, useEffect, useMemo } from "react";
import _, { debounce } from "lodash";
import { connect } from "react-redux";
import Moment from "moment";
import { Select, MenuItem } from "@material-ui/core";
import CurrencyInput from "react-currency-input-field";

import CustomToggle from "components/Button/toggle";
import AlertBox from "components/AlertBox";
import DuoTab from "components/Tab/duo";
import AtlasRadioGroup from "components/RadioGroup";
import FormDialog from "../../../components/formDialog";
import SpecialConditionCKEditor from "./specialCondition";
import DatePicker from "components/Input/datetimepicker";

import { validationCheck } from "utils/checkNull";
import permissionsChecker from "utils/permissionsChecker";
import { PaymentTypes, DisbursementCost } from "../assets";
import subrentForm from "../assets/subrentLetter";
import { getColorBadge } from "dictionary/badgeColor";

const statusBadge = [
  {
    id: 1,
    value: "Submitted",
    colorName: "Green",
  },
  {
    id: 2,
    value: "Cancel",
    colorName: "Grey",
  },
];

const TermOptions = [
  {
    label: "year(s)",
    value: "0",
    id: "0"
  },
  {
    label: "month(s)",
    value: "1",
    id: "1"
  }
]

const TempSubrentConfirmationForm = ({
  data,
  menuExpand,
  FormTabs,
  selected_confirmation_form,
  PADisableUpdateFlag,
  autoCalculatedList,
  onChange,
  saveTnC,
  onChangeBlur,
  onChangeFocus,
  closedEditing,
  renderNavButton,
}) => {
  let {
    include_advanced_rental,
    disbursement_type_id,
    agency_fee_amount,
    tenant_disbursement_amount,
    landlord_disbursement_amount,
    monthly_rental_amount = 0,
    annum_interest_rate,
    property_address,
    claim_status,
    transaction_number,
    confirmation_date,
    advanced_rental_amount = 0,
    security_deposit_months = 0,
    security_deposit_amount = 0,
    utility_deposit_months = 0,
    utility_deposit_amount = 0,
    stamping_fee = 0,
    before_handover_amount,
    intended_tenure_unit_id,
    renewal_tenure_unit_id,
    intended_tenure_period,
    renewal_tenure_period,
    commencement_date,
    execution_days,
    other_conditions,
    has_extra_conditions,
    extra_conditions,
    one_month_earnest_deposit = 0,
    advanced_rental_months = 0,
    earnest_deposit_amount = 0,
    deposit_payment_method_id,
    deposit_cheque_number,
    stake_holder_id,
    stake_holder_company,
    stake_holder_registration_number,
    agency_fee_months,
    sales_tax_percentage,
    access_key_and_car_park_deposit_amount,
    disbursement_amount,
    is_claimed,
    is_buyer_claimed,
    is_owner_claimed
  } = selected_confirmation_form;

  const [switchMode, setSwitchMode] = useState(
    window.innerWidth > 1024 ? "Contract View" : "Form View",
  );
  //View: 1. contract 2. formlist

  const tmpAddress = validationCheck(property_address) ? property_address : "";

  const { colorName = "" } = useMemo(
    () => _.find(statusBadge, (i) => i.value === claim_status) || {},
    [claim_status],
  );

  useEffect(() => {
    const onChangeViewMode = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 1024) {
        if (switchMode === "Contract View") {
          setSwitchMode("Form View");
        }
      } else if (screenWidth > 1024) {
        if (switchMode === "Form View") {
          setSwitchMode("Contract View");
        }
      }
    };

    const debounceOnChangeView = debounce(onChangeViewMode, 400);

    window.addEventListener("resize", debounceOnChangeView);
    return () => window.removeEventListener("resize", debounceOnChangeView);
  }, [window.innerWidth]);

  const renderExtraConditionCheck = () => (
    <>
      <AlertBox
        mode={"info"}
        title={""}
        containerClassName="mb-3"
        description={
          <>
            <div style={{ fontSize: 14 }}>
              Untick the checkbox below will print your special conditions right
              after clauses 12 continuously.
            </div>
            <div style={{ marginBottom: 5, fontSize: 14 }}>
              Tick the checkbox below will print your special conditions in a
              new page.
            </div>
          </>
        }
      />
      <div style={{ display: "flex", alignItems: "center", marginBottom: 20, fontSize: 14 }}>
        <input
          type={"checkbox"}
          id={"extra_condition"}
          name={"extra_condition"}
          style={{ marginRight: 10, width: 16, height: 16 }}
          checked={has_extra_conditions}
          onChange={(e) => onChange("has_extra_conditions", e.target.checked)}
          disabled={closedEditing}
        />
        <span htmlFor={"extra_condition"}>
          {" "}
          I want the extra conditions in a new page.{" "}
        </span>
      </div>
    </>
  );

  const renderContractLayout = () => (
    <>
      <div className="d-flex flex-wrap justify-content-between align-items-end grid_gap-10">
        <h5 className="fw-600">Letter Of Confirmation For Rent</h5>
        <div>
          <p style={{ marginBottom: 10, fontSize: 16 }}>{` ${transaction_number}`}</p>
          <div>
            Date:{" "}
            <div className="at-form-input" style={{ display: "inline-block", height: "auto" }}>
              <DatePicker
                value={Moment(confirmation_date)}
                onChange={(val) =>
                  onChange("confirmation_date", Moment(val).format("YYYY-MM-DD"))
                }
                disabled={closedEditing}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <p style={{ marginBottom: 8, fontSize: 14 }}>
        For the avoidance of doubt, by executing this Letter of Confirmation,
        both Tenant(s) and the Landlord(s) unequivocally agree that the entire
        terms and conditions of this Letter shall be read collaboratively to be
        applicable to both parties with RM
        <CurrencyInput
          className="subsales-letter-input"
          style={{ paddingLeft: "0.2rem" }}
          name={"monthly_rental_amount"}
          defaultValue={1000}
          disabled={closedEditing}
          value={monthly_rental_amount}
          onBlur={() => onChangeBlur("monthly_rental_amount")}
          onFocus={() => onChangeFocus("monthly_rental_amount")}
          onValueChange={(value) => onChange("monthly_rental_amount", value)}
        />
        as agreed monthly rental.
      </p>
      <div className="d-flex flex-wrap grid_gap-1 fs-2" style={{ marginBottom: 8 }}>
        <p>
          <b>{tmpAddress || "N/A"}</b>
        </p>
      </div>
      <p className={"mb-10 fs-2"}>
        {" "}
        Tenant(s):
        <span className={"text-danger"}>
          {` (Info will be displayed according to the contract participants section)`}
        </span>
      </p>
      <p className={"mb-10 fs-2"}>
        {" "}
        NRIC/Passport No:
        <span className={"text-danger"}>
          {` (Info will be displayed according to the contract participants section)`}
        </span>
      </p>
      <ol className="fs-2">
        <li>
          I/We have inspected the above property and hereby offer to rent the
          said property on the following term & conditions
          <ol type={"i"}>
            <li className={`at-form_mngmt-form_list at-form_list-full_col mt-2`}
              style={{flexWrap: "nowrap"}}>
              <CustomToggle
                className={"at-toggle__secondary"}
                currentState={!include_advanced_rental}
                onToggleButton={() => onChange("include_advanced_rental", !include_advanced_rental)}/>
              <h2 className={`at-form-input__title fs-2`}>Include Advanced Rental</h2>
            </li>
            <li>
              Earnest Deposit for one month: RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"one_month_earnest_deposit"}
                defaultValue={1000}
                disabled={closedEditing}
                value={one_month_earnest_deposit}
                onBlur={() => onChangeBlur("one_month_earnest_deposit")}
                onFocus={() => onChangeFocus("one_month_earnest_deposit")}
                onValueChange={(value) =>
                  onChange("one_month_earnest_deposit", value)
                }
              />
            </li>
            {
              include_advanced_rental && (
                <li>
                  Advanced Rental (equivalent to
                  <CurrencyInput
                    className="subsales-letter-input"
                    style={{ paddingLeft: "0.2rem" }}
                    name={"advanced_rental_months"}
                    defaultValue={1000}
                    disabled={closedEditing}
                    value={advanced_rental_months}
                    onBlur={() => onChangeBlur("advanced_rental_months")}
                    onFocus={() => onChangeFocus("advanced_rental_months")}
                    onValueChange={(value) =>
                      onChange("advanced_rental_months", value)
                    }
                  />
                  month(s) rental): RM
                  <CurrencyInput
                    className="subsales-letter-input"
                    style={{ paddingLeft: "0.2rem" }}
                    name={"advanced_rental_amount"}
                    defaultValue={1000}
                    disabled={closedEditing}
                    value={advanced_rental_amount}
                    onBlur={() => onChangeBlur("advanced_rental_amount")}
                    onFocus={() => onChangeFocus("advanced_rental_amount")}
                    onValueChange={(value) =>
                      onChange("advanced_rental_amount", value)
                    }
                  />
                </li>
              )
            }
            <li>
              Security Deposit (equivalent to
              <input
                className="subsales-letter-input"
                type={"number"}
                value={security_deposit_months}
                onBlur={() => onChangeBlur("security_deposit_months")}
                onFocus={() => onChangeFocus("security_deposit_months")}
                onChange={(e) =>
                  onChange("security_deposit_months", e.target.value)
                }
                disabled={closedEditing}
              />
              month(s) rental): RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"security_deposit_amount"}
                defaultValue={1000}
                disabled={closedEditing}
                value={security_deposit_amount}
                onBlur={() => onChangeBlur("security_deposit_amount")}
                onFocus={() => onChangeFocus("security_deposit_amount")}
                onValueChange={(value) =>
                  onChange("security_deposit_amount", value)
                }
              />
            </li>
            <li>
              Utility Deposit (Electricity, Water and Sewerage) (equivalent to
              <input
                className="subsales-letter-input"
                type={"number"}
                value={utility_deposit_months}
                onBlur={() => onChangeBlur("utility_deposit_months")}
                onFocus={() => onChangeFocus("utility_deposit_months")}
                onChange={(e) =>
                  onChange("utility_deposit_months", e.target.value)
                }
                disabled={closedEditing}
              />
              month's rental): RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"utility_deposit_amount"}
                defaultValue={1000}
                disabled={closedEditing}
                value={utility_deposit_amount}
                onBlur={() => onChangeBlur("utility_deposit_amount")}
                onFocus={() => onChangeFocus("utility_deposit_amount")}
                onValueChange={(value) =>
                  onChange("utility_deposit_amount", value)
                }
              />
            </li>
            <li>
              Stamping fee of Tenancy Agreement: RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"stamping_fee"}
                defaultValue={1000}
                disabled={closedEditing}
                value={stamping_fee}
                onBlur={() => onChangeBlur("stamping_fee")}
                onFocus={() => onChangeFocus("stamping_fee")}
                onValueChange={(value) => onChange("stamping_fee", value)}
              />
            </li>
            <li>
              Disbursement of Tenancy Agreement (inclusive SST): RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"disbursement_amount"}
                defaultValue={1000}
                disabled={closedEditing}
                value={disbursement_amount}
                onBlur={() => onChangeBlur("disbursement_amount")}
                onFocus={() => onChangeFocus("disbursement_amount")}
                onValueChange={(value) =>
                  onChange("disbursement_amount", value)
                }
              />
            </li>
            <li>
              Access Card & car park deposit: RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"access_key_and_car_park_deposit_amount"}
                defaultValue={1000}
                disabled={closedEditing}
                value={access_key_and_car_park_deposit_amount}
                onBlur={() =>
                  onChangeBlur("access_key_and_car_park_deposit_amount")
                }
                onFocus={() =>
                  onChangeFocus("access_key_and_car_park_deposit_amount")
                }
                onValueChange={(value) =>
                  onChange("access_key_and_car_park_deposit_amount", value)
                }
              />
              .
            </li>
            <li>
              Total Outstanding to be paid before handover of keys: RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"before_handover_amount"}
                defaultValue={1000}
                disabled={closedEditing}
                value={before_handover_amount}
                onBlur={() => onChangeBlur("before_handover_amount")}
                onFocus={() => onChangeFocus("before_handover_amount")}
                onValueChange={(value) =>
                  onChange("before_handover_amount", value)
                }
              />
              .
            </li>
            <li>
              Period of Tenancy:
              <input
                type="number"
                className="subsales-letter-input"
                value={intended_tenure_period}
                onBlur={() => onChangeBlur("intended_tenure_period")}
                onFocus={() => onChangeFocus("intended_tenure_period")}
                onChange={(e) =>
                  onChange("intended_tenure_period", e.target.value)
                }
                disabled={closedEditing}
              />
              <Select
                style={{ width: "150px", fontSize: "14px" }}
                disabled={closedEditing}
                value={`${intended_tenure_unit_id}`}
                onChange={(e) =>
                  onChange("intended_tenure_unit_id", e.target.value)
                }
              >
                {TermOptions.map((item) => {
                  return (
                    <MenuItem className="fs-2" key={item.id} value={item.id}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
              with an option to renew for another
              <input
                type="number"
                className="subsales-letter-input"
                value={renewal_tenure_period}
                onBlur={() => onChangeBlur("renewal_tenure_period")}
                onFocus={() => onChangeFocus("renewal_tenure_period")}
                onChange={(e) =>
                  onChange("renewal_tenure_period", e.target.value)
                }
                disabled={closedEditing}
              />
              <Select
                style={{ width: "150px", fontSize: "14px" }}
                disabled={closedEditing}
                value={`${renewal_tenure_unit_id}`}
                onChange={(e) =>
                  onChange("renewal_tenure_unit_id", e.target.value)
                }
              >
                {TermOptions.map((item) => {
                  return (
                    <MenuItem className="fs-2" key={item.id} value={item.id}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
             {" "}at the prevailing market rate.
            </li>
            <li>
              Commencement date:&nbsp;
              <div className="at-form-input" style={{ display: "inline-flex", height: "22px", alignItems: "center" }}>
                <DatePicker
                  value={Moment(commencement_date)}
                  onChange={(val) =>
                    onChange(
                      "commencement_date",
                      Moment(val).format("YYYY-MM-DD"),
                    )
                  }
                  customSubsalesDisabledStyle
                  disabled={closedEditing}
                />
              </div>
            </li>
            <li>
              Fittings & Furnishing: The Rental shall include the property's
              fittings & furnishing as per attached inventory list (if any).
            </li>
            <li>
              Disbursement Cost:
              <AtlasRadioGroup
                mode="old"
                horizontal={false}
                valueKey={"id"}
                disabled={closedEditing}
                containerClassName={"w-100"}
                checkedValue={disbursement_type_id}
                selectedRadioValue={(val) =>
                  onChange("disbursement_type_id", val)
                }
                options={DisbursementCost}
              />
              {disbursement_type_id === 1 && (
                <>
                  the Tenant RM
                  <CurrencyInput
                    className="subsales-letter-input"
                    style={{ paddingLeft: "0.2rem" }}
                    name={"tenant_disbursement_amount"}
                    defaultValue={1000}
                    disabled={closedEditing}
                    value={tenant_disbursement_amount}
                    onBlur={() => onChangeBlur("tenant_disbursement_amount")}
                    onFocus={() => onChangeFocus("tenant_disbursement_amount")}
                    onValueChange={(value) =>
                      onChange("tenant_disbursement_amount", value)
                    }
                  />
                  and the Landlord RM
                  <CurrencyInput
                    className="subsales-letter-input"
                    style={{ paddingLeft: "0.2rem" }}
                    name={"landlord_disbursement_amount"}
                    defaultValue={1000}
                    disabled={closedEditing}
                    value={landlord_disbursement_amount}
                    onBlur={() => onChangeBlur("landlord_disbursement_amount")}
                    onFocus={() =>
                      onChangeFocus("landlord_disbursement_amount")
                    }
                    onValueChange={(value) =>
                      onChange("landlord_disbursement_amount", value)
                    }
                  />
                </>
              )}
            </li>
            <li>
              Vacant Possession: Delivery of Vacant Possession shall be upon the
              payment of Total Outstanding as stated above.
            </li>
          </ol>
        </li>
        <li>
          Execution: I/We agree to execute the Tenancy Agreement within
          <input
            className="subsales-letter-input"
            type="number"
            value={execution_days}
            onBlur={() => onChangeBlur("execution_days")}
            onFocus={() => onChangeFocus("execution_days")}
            onChange={(e) => onChange("execution_days", e.target.value)}
            disabled={closedEditing}
          />
          working days from the date of Landlord's acceptance{" "}
          <b>("Execution Period")</b>, failing which only the above Earnest
          Deposit shall be forfeited. In the event such other sums beyond the
          Earnest Deposit have been paid to the Landlord, the Landlord shall
          refund the same to IQI REALTY SDN BHD as stakeholders within five (5)
          working days from the expiry of the Execution Period, failing which an
          interest rate of
          <input
            className="subsales-letter-input"
            type="number"
            value={annum_interest_rate}
            onBlur={() => onChangeBlur("annum_interest_rate")}
            onFocus={() => onChangeFocus("annum_interest_rate")}
            onChange={(e) => onChange("annum_interest_rate", e.target.value)}
            disabled={closedEditing}
          />
          % per annum shall be charged upon such sums and the Tenant shall be at
          liberty to commence legal proceedings against the Landlord for the
          recovery of such sums and interest at the costs of the Landlord. For
          the avoidance of doubt, in such instance, the parties agreed that IQI
          REALTY SDN BHD shall not be held responsible and shall keep IQI REALTY
          SDN BHD indemnified (as the case may be) for any loss arising out of
          or in respect of the said recovery processes and/or such other
          processes related thereto.
        </li>
        <li>
          Other Conditions:
          <input
            className="subsales-letter-input"
            type="text"
            style={{ width: "100%", maxWidth: "100%" }}
            placeholder={
              has_extra_conditions &&
              "If the condition is short enough to put in one line, write it here"
            }
            value={other_conditions}
            onChange={(e) =>
              has_extra_conditions
                ? () => {}
                : onChange("other_conditions", e.target.value)
            }
            disabled={has_extra_conditions || closedEditing}
          />
          <div>
            <span style={{ color: "red", marginLeft: 5 }}>
              If you have conditions that more than one line, please key in
              conditions in the field "Edit your special conditions here" right
              after clauses 12.
            </span>
          </div>
        </li>
        <li>
          The Tenant hereby attaches the sum of RM
          <CurrencyInput
            className="subsales-letter-input"
            style={{ paddingLeft: "0.2rem" }}
            name={"earnest_deposit_amount"}
            defaultValue={1000}
            disabled={closedEditing}
            value={earnest_deposit_amount}
            onBlur={() => onChangeBlur("earnest_deposit_amount")}
            onFocus={() => onChangeFocus("earnest_deposit_amount")}
            onValueChange={(value) => onChange("earnest_deposit_amount", value)}
          />
          by {deposit_payment_method_id === 2 && `A/C Payee `}
          <Select
            style={{ width: "150px", fontSize: "14px" }}
            disabled={closedEditing}
            value={deposit_payment_method_id}
            onChange={(e) =>
              onChange("deposit_payment_method_id", e.target.value)
            }
          >
            {PaymentTypes.map((item) => {
              return (
                <MenuItem className="fs-2" key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>{" "}
          {deposit_payment_method_id === 2 && (
            <>
              (Cheque Number:
              <input
                className="subsales-letter-input"
                value={deposit_cheque_number ? deposit_cheque_number : ""}
                type={"text"}
                onChange={(e) =>
                  onChange("deposit_cheque_number", e.target.value)
                }
                disabled={closedEditing}
              />
              )
            </>
          )}{" "}
          payable to (
          <input
            type={"radio"}
            name={"party-selection"}
            value={1}
            checked={stake_holder_id === 1}
            onChange={(e) =>
              onChange("stake_holder_id", parseInt(e.target.value, 10))
            }
            disabled={closedEditing}
          />
          {`IQI REALTY SDN BHD  `}
          <input
            type={"radio"}
            name={"party-selection"}
            value={2}
            checked={stake_holder_id === 2}
            onChange={(e) =>
              onChange("stake_holder_id", parseInt(e.target.value, 10))
            }
            disabled={closedEditing}
          />
          {`Lawyer)`}{" "}
          {stake_holder_id === 2 && (
            <div style={{ border: "1px solid #000", padding: 10 }}>
              <div>
                <span> Lawyer Company Name: </span>
                <input
                  type={"text"}
                  className="subsales-letter-input"
                  style={{ width: "500px" }}
                  value={stake_holder_company ? stake_holder_company : ""}
                  onChange={(e) =>
                    onChange("stake_holder_company", e.target.value)
                  }
                  disabled={closedEditing}
                />
              </div>
              <div>
                <span> Lawyer Company Registration Number: </span>
                <input
                  type={"text"}
                  className="subsales-letter-input"
                  style={{ width: "500px" }}
                  value={
                    stake_holder_registration_number
                      ? stake_holder_registration_number
                      : ""
                  }
                  onChange={(e) =>
                    onChange("stake_holder_registration_number", e.target.value)
                  }
                  disabled={closedEditing}
                />
              </div>
            </div>
          )}
          as stakeholder, being the Earnest Deposit towards the Total
          Outstanding of the above property. This Earnest Deposit shall form
          part of the payment towards the rental of the property. The Tenant
          irrevocably permits IQI REALTY SDN BHD to bank in the cheque prior to
          the Landlord(s) acceptance of this offer. In the event that the
          Landlord(s) reject(s) this offer, the Earnest Deposit is to be
          refundable to the Tenant within five (5) working days.
        </li>
        <li>
          The Tenant and the Landlord agree to fully indemnify IQI REALTY SDN
          BHD against all claims by either party, if for any reason whatsoever,
          either party does not proceed to execute the Tenancy Agreement after
          the Earnest Deposit has been collected.
        </li>
        <li>
          The Tenant acknowledges that this offer is only effective upon the
          final acceptance by the Landlord(s) countersigning this Letter of
          Confirmation for Rent and the Landlord, being the registered
          owner/person authorized by the registered owner of the above mentioned
          property, acknowledges that by countersigning this offer, the Landlord
          accepts the terms and conditions as stated herein.{" "}
        </li>
        <li>
          The Landlord agrees to pay IQI REALTY SDN BHD a total agency fee of
          <input
            className="subsales-letter-input"
            disabled={closedEditing}
            type="text"
            value={agency_fee_months}
            onBlur={() => onChangeBlur("agency_fee_months")}
            onFocus={() => onChangeFocus("agency_fee_months")}
            onChange={(e) => onChange("agency_fee_months", e.target.value)}
            style={{ width: "200px" }}
          />
          month's rental plus {sales_tax_percentage}% SST amounted to RM
          <CurrencyInput
            className="subsales-letter-input"
            style={{ paddingLeft: "0.2rem" }}
            name={"agency_fee_amount"}
            defaultValue={1000}
            disabled={closedEditing}
            value={agency_fee_amount}
            onBlur={() => onChangeBlur("agency_fee_amount")}
            onFocus={() => onChangeFocus("agency_fee_amount")}
            onValueChange={(value) => onChange("agency_fee_amount", value)}
          />
          . The said agency fee shall be made payable immediately upon the
          signing of the TA or upon taking possession of the premises, whichever
          is earlier. The Landlord irrevocably instruct IQI Realty Sdn Bhd to
          deduct the agreed agency fee from the Earnest Deposit and release the
          remaining Earnest Deposit (if any) to Landlord and/or Landlord's
          appointed lawyer upon the signing of the Tenancy Agreement.
        </li>
        <li>
          In the event that after having accepted this offer and the Earnest
          Deposit, and not due to the reason of any dispute relating to the
          terms of the Tenancy Agreement, the Landlord refuses to rent the said
          premises to the Tenant, the Landlord agrees to immediately refund the
          Earnest Deposit to the Tenant plus agreed liquidated damages
          (compensation) being a sum equivalent to the Earnest Deposit and the
          Landlord agrees to pay IQI REALTY SDN BHD an equivalent to 50% of the
          above mentioned agency fee.
        </li>
        <li>
          In the event that the transaction is aborted by the Tenant's refusal
          to proceed with the Tenancy Agreement for whatsoever reason before the
          Tenancy Agreement is signed but after the Earnest Deposit is paid, and
          not due to the reason of any dispute relating to the terms of the
          Tenancy Agreement, the Earnest Deposit will be forfeited and the
          Landlord agrees to pay IQI REALTY SDN BHD a fee equivalent to 50% of
          the forfeited Earnest Deposit or 50% of the above mentioned agency
          fee, whichever is lesser.
        </li>
        <li>
          All signatories to this document hereby acknowledge and agree to comply with the Malaysia Anti-Corruption Commission Act 2009 and Amendment Act - Section 17A of the MACC Act 2009 (MACC 2009), Anti-Money Laundering, Anti-Terrorism Financing and Proceeds of Unlawful Activities Act 2001 (AML/CFT), 
          Personal Data Protection Act 2010 and further authorise IQI Realty Sdn Bhd and/or its group companies to disclose their information to third parties as necessary, inter alia, 
          at the request of regulatory authorities. For reference, parties may refer to our website at <a target="_blank" href="https://www.iqiglobal.com/amla">https://www.iqiglobal.com/amla</a> and <a target="_blank" href="https://www.iqiglobal.com/privacy-policy">https://www.iqiglobal.com/privacy-policy</a>.
        </li>
        <li>
          Each party further represents and agrees that each has duly reviewed
          all aspects of this Letter and fully understands the terms and
          conditions thereof, has authority to enter into this Letter and has
          executed this Letter based upon such party’s own judgment, and
          knowingly, voluntarily, and without duress, agrees to all of the terms
          set forth in this Letter.
        </li>
        <li>
          All payments/cheques should be made directly to{" "}
          <b>IQI REALTY SDN BHD</b>. Bank:{" "}
          <b>MAYBANK, ACC NO: 5641-6444-1931</b> (Client Account). Should you
          require a receipt, please ask for a receipt of the total amount paid
          and highlight any discrepancy between the receipt and the amount paid
          (should there be any).
        </li>
      </ol>
    </>
  );

  const renderFormListLayout = () => {
    const { address = [] } = data.addressReducer;
    return (
      <FormDialog
        formData={subrentForm({ address })}
        data={selected_confirmation_form}
        autoCalculatedList={autoCalculatedList}
        closedEditing={closedEditing}
        onChangeFocus={onChangeFocus}
        onChangeBlur={onChangeBlur}
        onChange={onChange}
      />
    );
  };

  return (
    <>
      <div className="at-form__content at-new_container-20">
        <h5 className="at-card_content-title">{"Letter Of Confirmation"}</h5>
        <p className="at-content-subtitle">
          Enter the details of the form below. ‘Form View’ provides a simplified
          version of the contract allowing you to quickly enter details on your
          mobile phone.
        </p>
        { (is_claimed 
          || is_buyer_claimed
          || is_owner_claimed)
          && (
            <div className="d-flex flex-wrap align-items-baseline">
              <p
                className="at-form_mngmt-claim-badge"
                style={{
                  border: "2px solid",
                  ...getColorBadge(colorName),
                }}
              >
                {claim_status}
              </p>
              <small>
                <em className="text-danger">*</em>This form is no longer editable
              </small>
            </div>
          )
          }
      </div>
      <DuoTab
        sections={FormTabs}
        selectedSection={switchMode}
        onSelectSection={(val) => setSwitchMode(val)}
      />
      <div
        className="at-form__content at-form-tnc bg-white mb-3 p-3"
        style={{ borderRadius: "0.375rem" }}
      >
        {switchMode === "Contract View" && renderContractLayout()}
        {switchMode === "Form View" && renderFormListLayout()}
      </div>
      <div className="at-form__content">
        {!PADisableUpdateFlag && (
          <SpecialConditionCKEditor
            type={"subrent"}
            closedEditing={closedEditing}
            additionalContent={renderExtraConditionCheck()}
            conditionText={extra_conditions}
            updateConditionText={(param) => onChange("extra_conditions", param)}
          />
        )}
      </div>
      <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
        <button
          disabled={closedEditing}
          className={`btn-new btn-new--success ${closedEditing ? "disabled" : ""}`}
          onClick={saveTnC}>
          Save Terms & Conditions
        </button>
        {renderNavButton && renderNavButton()}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(TempSubrentConfirmationForm);
