import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import Moment from "moment";
import {
  Tooltip,
  Typography,
  Card,
  Grid,
  MenuItem,
  IconButton,
  Dialog,
} from "@material-ui/core";
import { BiGroup } from "react-icons/bi";
import { numberWithCommas } from "utils/thousandSeparator";
import { IoIosMail, IoMdCall, IoIosAlert, IoMdAdd } from "react-icons/io";
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import defaultAvatar from "assets/images/av-male.jpg";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasAutosuggest from "components/Autosuggest";
import CustomTypeahead from "components/Typeahead/new";
import AtlasCloseButton from "components/Button/close";
import CustomFormInput from "components/Input/formInput";
import AtlasSelect from "components/Select/new";
import CustomCard from "components/Card/index";
import DatePicker from "components/Input/datetimepicker";
import RENCard from "./RENCard";
import SearchContent from "containers/Dashboard/Personal/ReportNewSales/component/searchContent";

import EditHOC from "../actions/edit";
import ProjectsHOC from "../actions/projects";
import checkValid from "utils/checkValid";
import { StatusColor } from "../assets";
import { getColorBadge } from "dictionary/badgeColor";

import "../index.scss";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FieldData = [
  {
    label: "Unit Number",
    value: "unit_number",
    type: "text",
  },
  {
    label: "Buyer",
    value: "buyer_name",
    type: "text",
  },
  {
    label: "Booking Date",
    value: "booking_date",
    type: "date",
  },
  {
    label: "Size",
    value: "size",
    type: "text",
  },
  {
    label: "SPA Price",
    value: "purchase_price",
    type: "number",
  },
  {
    label: "Net Price",
    value: "nett_price",
    type: "number",
  },
  {
    label: "Package",
    value: "package",
    type: "text",
    instruction: "Maximum of 155 character.",
    maxLength: 155,
  },
];

const statusData = [
  { value: "1", label: "Draft" },
  { value: "2", label: "Submitted" },
  { value: "3", label: "Approved" },
  { value: "4", label: "Rejected" },
  { value: "5", label: "Cancelled" },
  { value: "6", label: "Ready to Invoice" },
];

const projectTypes = [
  { value: "1", label: "Main" },
  { value: "2", label: "Ad-hoc" },
];

const getBadgeStyling = (param) => {
  const { colorName = "" } =
    StatusColor.find((item) => item.value === param) || {};
  return getColorBadge(colorName);
};

class SaleForms extends Component {
  componentDidMount = () => {
    const { selectedFormData } = this.props;

    this.props.getActiveProjects("0");
    this.props.getProjectLocations(`0`);
    selectedFormData &&
      this.props.onChangeEditHOC(selectedFormData, "formData");
  };

  componentDidUpdate = (pp) => {
    const { selectedFormData } = this.props;

    if (pp.selectedFormData !== selectedFormData && selectedFormData) {
      this.props.onChangeEditHOC(selectedFormData, "formData");
    }
  };

  onHandleDeleteREN = (item) => {
    const { formData } = this.props;

    if (item.id) {
      let tempFormData = _.cloneDeep(formData);
      let agentIndex = _.findIndex(formData.project_sale_agents_attributes, {
        id: item.id,
      });
      tempFormData.project_sale_agents_attributes[agentIndex]._destroy = "1";
      tempFormData.project_sale_agents_attributes[agentIndex].percentage = 0;

      return this.props.onChangeEditHOC(tempFormData, "formData");
    }

    return this.props.onDeleteREN(item.user_id);
  };

  onChangeCountryField = (val) => {
    let temp = _.cloneDeep(this.props.formData);
    temp.country_id = val;
    temp.project_name = "";
    temp.project_location_id = null;

    this.props.onChangeEditHOC(temp, "formData");
    this.props.getActiveProjects(val, "1");
    this.props.getProjectLocations(val);
  };

  onChangeProjectLocation = (refId) => {
    let temp = _.cloneDeep(this.props.formData);
    temp.project_location_id = refId;
    temp.project_name = "";

    if (refId) {
      this.props.getActiveProjects(refId, "2");
      this.props.onChangeEditHOC(temp, "formData");
    }
  };

  renderDialogs = () => {
    const { formData, showPDFModal, onChangeField, showAssignModal } =
      this.props;

    let tempNonDestroyAgent = _.filter(
      formData.project_sale_agents_attributes,
      (item) => item._destroy !== "1",
    );
    return (
      <>
        <AtlasDialog open={showPDFModal}>
          <AtlasCloseButton
            onClick={() => this.props.onChangeEditHOC(false, "showPDFModal")}
            containerStyle={{
              position: "absolute",
              right: 35,
              top: 50,
            }}
          />
          {checkValid(formData.booking_form).indexOf("pdf") > -1 && (
            <iframe
              className={"w-100 h-100"}
              src={formData.booking_form}
            ></iframe>
          )}
          {checkValid(formData.booking_form).indexOf("pdf") === -1 && (
            <img
              className={"w-100"}
              src={formData.booking_form}
              alt="booking form image"
            />
          )}
        </AtlasDialog>
        <Dialog maxWidth={"md"} fullWidth={true} open={showAssignModal}>
          <CustomCard
            cardContent={
              <SearchContent
                renInfo={tempNonDestroyAgent}
                onAddREN={(val) => {
                  onChangeField(
                    [...formData.project_sale_agents_attributes, val],
                    "project_sale_agents_attributes",
                  );
                }}
                onClose={() =>
                  this.props.onChangeEditHOC(false, "showAssignModal")
                }
              />
            }
          />
        </Dialog>
      </>
    );
  };

  renderAssignedAgent = () => {
    const { agent } = this.props.formData;

    return (
      <>
        <div className="d-flex align-items-center mb-10">
          <h2 className="at-form-input__title">{"Primary Agent"}</h2>
        </div>
        <Card
          style={{
            padding: 12,
            position: "relative",
            minHeight: 120,
            maxWidth: 500,
          }}
        >
          {Object.keys(agent).length < 1 && <p>{"No agent is assigned."}</p>}
          {Object.keys(agent).length > 1 && (
            <Grid container spacing={16} className={"align-items-center"}>
              <Grid item md={4} xs={12}>
                <img
                  alt="missing.png"
                  src={
                    agent.avatar_url &&
                    !_.includes(agent.avatar_url, "missing.png")
                      ? agent.avatar_url
                      : defaultAvatar
                  }
                  style={{ width: 100, borderRadius: "50%", height: 100 }}
                />
              </Grid>
              <Grid item md={8} xs={12}>
                <h2 className="at-form-input__title mb-2">
                  {checkValid(agent.agent_name)}
                </h2>
                <div className="d-flex flex-wrap">
                  <BiGroup style={{ width: 20, height: 20, marginRight: 10 }} />
                  <span>{checkValid(agent.agent_team)}</span>
                </div>
                <div className="d-flex">
                  <IoIosMail
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                  <span>{checkValid(agent.agent_email)}</span>
                </div>
                <div className="d-flex">
                  <IoMdCall
                    style={{ width: 20, height: 20, marginRight: 10 }}
                  />
                  <span>{checkValid(agent.agent_mobile_number)}</span>
                </div>
              </Grid>
            </Grid>
          )}
        </Card>
      </>
    );
  };

  render = () => {
    const {
      projectListings,
      projectLocations,
      formData,

      onLoadActiveProjects,
      onLoadSales,

      onChangeField,
    } = this.props;
    const { countries = [] } = this.props.data.dictionaryReducer;

    let tempNonDestroyAgent = _.filter(
      formData.project_sale_agents_attributes,
      (item) => item._destroy !== "1",
    );

    let totalRENValue = 0;
    tempNonDestroyAgent.map((item) => {
      let intVal = item.percentage ? parseFloat(item.percentage) : 0;
      totalRENValue = totalRENValue + intVal;
    });

    const tmpBadgeStyling = getBadgeStyling(formData.status);
    return (
      <>
        <div className="row">
          <div className="col-12">
            <h2 className="at-form-input__title">{"Project Type"}</h2>
            <AtlasRadioGroup
              horizontal={true}
              checkedValue={formData.type_id}
              selectedRadioValue={(val) => {
                formData.status_id !== "4" && onChangeField(val, "type_id");
              }}
              options={projectTypes}
            />
          </div>
          <div className="col-lg-6 col-12">
            <h2 className="at-form-input__title">{"Status"}</h2>
            <AtlasRadioGroup
              horizontal={true}
              checkedValue={formData.status_id}
              options={statusData}
              selectedRadioValue={(val) => onChangeField(val, "status_id")}
            />
          </div>
          <div className="col-lg-6 col-12">
            <h2 className="at-form-input__title">{"Assigned to"}</h2>
            <i>{formData.assigned_admin}</i>
          </div>
        </div>
        <hr />
        <div className="row mt-20">
          <div className="col-lg-6 mb-10">
            <h2 className="at-form-input__title">
              {"Filter the projects by country"}
            </h2>
            <AtlasSelect
              value={formData.country_id || ""}
              onChange={(e) => this.onChangeCountryField(e.target.value)}
            >
              {countries.map((item) => (
                <MenuItem
                  classes={{ root: `at-select__dropdown-item` }}
                  key={item.reference_id}
                  value={item.reference_id}
                >
                  {item.name}
                </MenuItem>
              ))}
            </AtlasSelect>
          </div>
          <div className="col-lg-6 mb-10">
            <h2 className="at-form-input__title">{"Project Location"}</h2>
            <CustomTypeahead
              typeaheadId={"location"}
              options={projectLocations || []}
              labelKey={"name"}
              filterBy={["name"]}
              onSelect={(val) => {
                if (val && val.length > 0) {
                  this.onChangeProjectLocation(val[0].reference_id);
                }
                return this.onChangeProjectLocation(null);
              }}
              selectedValue={
                _.find(projectLocations, {
                  reference_id: formData.project_location_id,
                })
                  ? [
                      _.find(projectLocations, {
                        reference_id: formData.project_location_id,
                      }),
                    ]
                  : []
              }
              childrenHead={(rowItem) => <p>{rowItem.name}</p>}
            />
          </div>
          <div className="col-lg-6 mb-10">
            <h2 className="at-form-input__title">{"Project"}</h2>
            <AtlasAutosuggest
              placeholder={"Please fill in the project's name."}
              wrapperStyle={{ marginTop: 5, marginBottom: 5 }}
              value={formData.project_name || ""}
              updateValue={(val) => onChangeField(val, "project_name")}
              options={projectListings || []}
            />
          </div>
          {FieldData.map((item) => (
            <div className="col-lg-6" key={item.value}>
              <h2 className="at-form-input__title" required>
                {item.label}
              </h2>
              {["text", "number"].indexOf(item.type) > -1 && (
                <CustomFormInput
                  type={"text"}
                  numericOnly={item.type === "number"}
                  instruction={item.instruction || ""}
                  maxLength={item.maxLength || null}
                  value={formData[item.value]}
                  onChangeValue={(val) => {
                    let temp =
                      item.type === "number"
                        ? numberWithCommas(`${val.replace(/[^$0-9.]/g, "")}`)
                        : val;
                    onChangeField(temp, item.value);
                  }}
                  required={true}
                  placeholder={item.placeholder}
                />
              )}
              {item.type === "date" && (
                <div className="at-form-input">
                  <DatePicker
                    maxDate={Moment()}
                    dateFormat={"DD MMM YYYY"} 
                    value={Moment(formData.booking_date)}
                    onChange={(val) =>
                      onChangeField(
                        Moment(val).format("DD MMM YYYY"),
                        "booking_date",
                      )
                    }
                  />
                </div>
              )}
            </div>
          ))}
          <div className="col-12">
            <h2 className="at-form-input__title">Admin Remark</h2>
            <textarea
              cols="30"
              rows="10"
              value={formData.admin_remark}
              disabled={false}
              onChange={(e) => onChangeField(e.target.value, "admin_remark")}
            />
          </div>
          <div className="col-12">
            <h2 className="at-form-input__title">Remark</h2>
            <textarea
              cols="30"
              rows="10"
              value={formData.remark}
              disabled={true}
              onChange={(e) => onChangeField(e.target.value, "remark")}
            />
          </div>
          <div className="col-12" style={{ marginBottom: 5 }}>
            <h4 className="at-card__title mb-10 p-0">
              Agent Information
              {tempNonDestroyAgent.length < 6 && (
                <IconButton
                  style={{
                    background: "#F18E05",
                    borderRadius: "50%",
                    padding: 4,
                    marginLeft: 15,
                  }}
                  onClick={() =>
                    this.props.onChangeEditHOC(true, "showAssignModal")
                  }
                >
                  <IoMdAdd style={{ height: 18, width: 18, color: "#FFFF" }} />
                </IconButton>
              )}
            </h4>
            <p style={{ color: totalRENValue !== 100 ? "#FB404B" : "#000" }}>
              {`Total percentage: ${totalRENValue}% out of total 100%`}
              {totalRENValue !== 100 && (
                <Tooltip
                  title={
                    "Please ensure the total percentage to be 100% before submitting."
                  }
                >
                  <IoIosAlert
                    style={{
                      width: 19,
                      color: "#FB404B",
                      marginLeft: 10,
                      display: "none",
                    }}
                    className={"d-md-inline-block"}
                  />
                </Tooltip>
              )}
            </p>
          </div>
          {tempNonDestroyAgent.length < 1 && (
            <div className="col-lg-4 col-md-6 col-xs-12">
              <Card
                classes={{ root: "at-assigned-card at-assigned-empty-card" }}
              >
                <Typography variant="h6" gutterBottom>
                  {"No agent has been added yet."}
                </Typography>
              </Card>
            </div>
          )}
          {tempNonDestroyAgent.length > 0 &&
            tempNonDestroyAgent.map((item, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-xs-12">
                <RENCard
                  mode={"edit"}
                  index={index}
                  item={item}
                  disabled={false}
                  onDeleteREN={(item) => this.onHandleDeleteREN(item)}
                  onChangeRENPercentage={this.props.onChangeRENPercentage}
                  isAssignedAgent={formData.user_id === item.user_id}
                />
              </div>
            ))}
        </div>
        <hr />
        <div className="d-flex mt-20">
          <button
            className="btn-new btn-new--success mr-2"
            style={{ marginLeft: 5 }}
            onClick={() => this.props.onUpdateProjectSaleHOC(formData.id)}
          >
            Update
          </button>
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary"
            onClick={() => this.props.onClose()}
          >
            Close
          </button>
        </div>
        {this.renderDialogs()}
        {(onLoadSales || onLoadActiveProjects) && <LoadingModal />}
      </>
    );
  };
}

export default compose(EditHOC, ProjectsHOC)(SaleForms);
