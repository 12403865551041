import { Avatar, Card, CardContent, CardHeader } from "@material-ui/core";

import AtlasIcon from "../Icon/atlasIcon";

const ProjectCard = ({ icon, title, children, containerClass = '' }) => {
  return (
    <Card component={"section"} className={`at-card--new mb-3 ${containerClass}`}>
      <CardHeader
        classes={{ title: "project-detail__title" }}
        avatar={
          <Avatar
            style={{
              backgroundColor: "#FFEDD5",
              width: 32,
              height: 32,
              marginRight: 12,
            }}
          >
            <AtlasIcon
              svgHref={`atlas-${icon}`}
              style={{
                width: 16,
                height: 16,
                fill: "#F0631D"
              }}
            />
          </Avatar>
        }
        title={title}
      />
      <CardContent className={"pt-0 pb-3"}>{children}</CardContent>
    </Card>
  );
};

export default ProjectCard;
