import React, { useState, useCallback, useEffect } from "react";
import _ from "lodash";

import AtlasAutosuggest from "components/Autosuggest";
import CustomSelect from "components/Select";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import CustomTypeahead from "components/Typeahead/new";
import AtlasMultiSelect from "components/Select/multi";
import {
  categories,
  tenure_types,
  bedroom_options,
  bathroom_options,
} from "dictionary/subsales";
import { unit_types } from "containers/Dashboard/SubsalesClaims/assets";

const typeDictionary = [
  { id: 1, label: "Residential" },
  { id: 2, label: "Commercial" },
  { id: 3, label: "Industrial" },
  { id: 4, label: "Agricultural" },
  { id: 5, label: "Unknown" },
];

const tenureTypeDictionary = [
  { id: 1, label: "Freehold" },
  { id: 3, label: "Leasehold" },
];

const PropertyContent = ({
  data,
  onChange,
  formData,
  autoSuggestLoading,
  townshipsList = [],

  autoSuggestOptions = [],
  disableSubmitButton,
  edgeAutoSuggest,
  getSelectedTownShip,
}) => {
  const countriesList = data
    ? _.map(data.dictionaryReducer?.countries, (country) => {
        return { ...country, value: country?.name };
      })
    : [];
  const [statesList, setStates] = useState([]);
  const [selectedPropertyType, onSelectPropertyType] = useState({});
  const [selectedPropertyProject, onSelectPropertyProject] = useState(null);
  const [propertyTypes, onChangePropertyTypes] = useState([]);
  const [searchWord, onSearchWord] = useState("");

  const {
    property_name = "",
    postcode = "",
    built_up = 0.0,
    land_area = 0.0,
    category_id = "",
    property_type_id = "",
    tenure_type_id = "",
    unit_type_id = "",
    bedrooms = "",
    bathrooms = "",
    area_s_lower = "",
    state_s_lower = "",
    name_s_lower = "",
    street_name_s_lower = "",
    township_id = "",
    country_id = "",
    state_id = "",
    unit_no = "",
    address,
  } = formData.property_location_attributes ?? {};

  const onChangeForm = (val, context) => {
    let tmpFormData = _.cloneDeep(formData.property_location_attributes ?? {});
    tmpFormData[context] = val;

    if (context === "country_id") {
      tmpFormData.state_id = "";

      let temp = _.find(countriesList, (item) => item?.id === val);
      if (temp) {
        let tempStates = _.cloneDeep(temp?.states);
        setStates(_.sortBy(tempStates, (i) => i?.name?.toLowerCase()));
      }
    }
    if (context === "state_id") {
      tmpFormData.township_id = "";

      if (val) {
        getSelectedTownShip(val);
      }
    }
    onChange("property_location_attributes", tmpFormData);
  };

  useEffect(() => {
    const { name: townName } = townshipsList?.find((item) => item?.id === township_id) || {}
    onSearchWord(townName || "")
  }, [townshipsList, township_id])

  useEffect(() => {
    if (property_name) {
      let tmp_property_project = {
        name_s_lower: property_name,
        postcode,
        built_up,
        land_area,
        category_id,
        property_type_id,
        tenure_type_id,
        unit_type_id,
        area_s_lower,
        state_s_lower,
        street_name_s_lower,
        state_id_i: state_id,
      };

      let tmp_selected_category = _.find(categories, { id: +category_id });
      processPropertyType(tmp_selected_category, property_type_id);
      onSelectPropertyProject(tmp_property_project);
    }

    let temp = _.find(countriesList, (item) => item?.id === country_id);

    if (temp) {
      let tempStates = _.cloneDeep(temp?.states);
      setStates(_.sortBy(tempStates, (i) => i?.name?.toLowerCase()));
    }
  }, [formData.property_location_attributes]);

  useEffect(() => {
    let tmpSelectedCategory = _.find(categories, { id: +category_id });
    processPropertyType(tmpSelectedCategory, property_type_id);
  }, [category_id]);

  useEffect(() => {
    if (state_id) {
      getSelectedTownShip(state_id);
    }
  }, [state_id]);
  
  useEffect(() => {
    if (area_s_lower) {
      let tmp = _.find(townshipsList, (item) => item?.name === area_s_lower);

      if (tmp && tmp?.id) {
        onChangeForm(tmp?.id, "township_id");
      }
    }
  }, [townshipsList]);

  const debounceAutoSuggest = useCallback(
    _.debounce((val, data) => {
      onChange("property_location_attributes", data);
      if (val?.trim()) {
        edgeAutoSuggest(val, {
          ...data,
          property_type: selectedPropertyType?.name ?? "",
        });
      }
    }, 800),
    [formData.property_location_attributes, selectedPropertyType]
  );

  const processPropertyType = (selectedCategory, property_type_id) => {
    let tmpPropertyTypes = selectedCategory
      ? selectedCategory?.group_types?.map((item) => {
          let children = item?.children?.map((child) => {
            let tmpData = {
              id: child?.id,
              name: child?.name,
              label: child?.name,
              value: child?.id,
              groupName: item?.name,
            };
            return tmpData;
          });
          let tmpParent = {
            name: item?.name,
            children: children,
          };
          return tmpParent;
        })
      : [];

    let tmpSelectedPropertyType = {};
    tmpPropertyTypes?.map((item) => {
      item.children?.map((child) => {
        if (child?.id === property_type_id) {
          tmpSelectedPropertyType = child;
        }
      });
    });
    onSelectPropertyType(tmpSelectedPropertyType);
    onChangePropertyTypes(tmpPropertyTypes);
  };

  const onSelectPropertyName = (val) => {
    onSelectPropertyProject(val);
    let temp = _.cloneDeep(formData.property_location_attributes);
    temp.edge_prop_asset_id = val?.asset_id_i ?? temp?.edge_prop_asset_id;
    temp.property_name = val?.name_s_lower ?? temp?.property_name;
    temp.postcode = val?.postal_code_i ?? "";
    temp.name_s_lower = val?.name_s_lower ?? "";
    temp.area_s_lower = val?.area_s_lower ?? "";
    temp.state_s_lower = val?.state_s_lower ?? "";
    temp.address = val?.street_name_s_lower ?? "";
    let tmpTenure = _.find(tenure_types, { name: val?.tenure_s }) || { id: "" };
    temp.tenure_type_id = tmpTenure?.id ?? "";
    temp.country_id = 1;

    let tempSelectedCountry = _.find(
      countriesList,
      (item) => item?.id === temp?.country_id,
    );
    let tempSelectedState = _.find(
      tempSelectedCountry?.states,
      (item) => item?.label === val?.state_s_lower,
    );
    temp.state_id = tempSelectedState?.id || "";
    if (tempSelectedCountry) {
      let tempStates = _.cloneDeep(tempSelectedCountry?.states);
      setStates(_.sortBy(tempStates, (i) => i?.name?.toLowerCase()));
    }
    if (temp?.state_id) {
      getSelectedTownShip(temp?.state_id);
    }

    onChange("property_location_attributes", temp);
  };

  const renderTownship = () => {
    let showErrorChecking = ''
    let selectedObj = _.find(townshipsList, option => option?.name === searchWord);
    showErrorChecking = !selectedObj && searchWord !== ""
    return (
      <AtlasAutosuggest
        required
        placeholder={"Enter Area / City"}
        className={`${showErrorChecking ? "at-form-input--error" : ""}`}
        disabled={!state_id || disableSubmitButton}
        value={searchWord}
        updateValue={(val) => {
          let { name, id } = _.find(townshipsList, option => option?.name?.toLowerCase() === val?.toLowerCase()) || {};
          if(name){
            onChangeForm(id, "township_id")
            onSearchWord(name)
          } else {
            onSearchWord(val)
          }
        }}
        onSelect={ obj => onChangeForm(obj?.id, "township_id")}
        options={townshipsList}
      />
    )
  }

  return (
    <>
      <div className="at-form_fields_cont">
        <section className="at-form_field-col-12">
          <label className={"at-form-input__title"} required>
            {"Property Category"}
          </label>
          <AtlasRadioGroup
            required
            disabled={disableSubmitButton}
            valueKey={"id"}
            horizontal={true}
            checkedValue={+category_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => {
              let temp =
                _.cloneDeep(formData.property_location_attributes) ?? {};
              temp.property_type_id = "";
              temp.category_id = +val;

              Promise.all([
                onChange("property_location_attributes", temp),
              ]).then(() => {
                onChange("property_type_id", +val);
              });
            }}
            options={typeDictionary}
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"Property Type"}
          </label>
          <AtlasMultiSelect
            required
            placeholder="Select Property Type"
            disabled={!category_id || disableSubmitButton}
            selectType={"section"}
            className="w-100"
            selectItems={propertyTypes || []}
            updateSelect={(val) => {
              onSelectPropertyType(val);
              onChangeForm(val.id, "property_type_id");
            }}
            currentlySelected={selectedPropertyType}
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"}>{"Property Name"}</label>
          <CustomTypeahead
            required={false}
            showClearButton
            placeholder="Enter Property Name"
            disabled={disableSubmitButton || !property_type_id || !category_id}
            isLoading={autoSuggestLoading}
            typeaheadId={"listing-location"}
            options={
              autoSuggestOptions.length > 0
                ? autoSuggestOptions
                : !_.isEmpty(selectedPropertyProject)
                  ? [{ ...selectedPropertyProject }]
                  : []
            }
            selectedValue={
              selectedPropertyProject ? [selectedPropertyProject] : []
            }
            filterBy={[
              "name_s_lower",
              "state_s_lower",
              "street_name_s_lower",
              "area_s_lower",
            ]}
            labelKey={(option) => `${option?.name_s_lower}`}
            onSearch={(val) => {
              let temp = _.cloneDeep(formData.property_location_attributes);

              temp.property_name = val;
              temp.name_s_lower = val;

              onSelectPropertyProject({
                ...temp,
                asset_id_i: "",
                name_s_lower: temp?.property_name,
                area_s_lower: temp?.township_id || "",
                state_s_lower: temp?.state_s_lower || "",
                postal_code_i: temp?.postcode || "",
              });

              debounceAutoSuggest(val, temp);
            }}
            onSelect={(val) => {
              val && val.length > 0 && onSelectPropertyName(val[0]);
            }}
            onClear={(val) => {
              onSelectPropertyName(val);
              onSelectPropertyProject(val);
            }}
            childrenHead={(rowItem) => (
              <p key={rowItem?.asset_id_i}>{rowItem?.name_s_lower || ""}</p>
            )}
            highlighterData={[
              (option) =>
                `Area: ${option?.area_s_lower || "N/A"} | State: ${option?.state_s_lower || "N/A"}`,
            ]}
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"Unit No."}
          </label>
          <CustomFormInput
            required
            placeholder="Enter Unit No."
            disabled={disableSubmitButton}
            type="text"
            value={unit_no}
            onChangeValue={(val) => onChangeForm(val, "unit_no")}
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"Street Name"}
          </label>
          <CustomFormInput
            required
            placeholder="Enter Street Name"
            disabled={disableSubmitButton}            
            type="text"
            value={address}
            onChangeValue={(val) => onChangeForm(val, "address")}
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"Country"}
          </label>
          <CustomSelect
            required
            disabled={disableSubmitButton}
            placeholder={"Select Country"}
            className="w-100"
            selectItems={countriesList}
            valueKey="id"
            currentlySelected={
              countriesList.find((item) => item?.id === country_id) || ""
            }
            updateSelect={(selectedItem) =>
              onChangeForm(selectedItem.id, "country_id")
            }
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"State / Province"}
          </label>
          <CustomSelect
            required
            className="w-100"
            placeholder={"Select State / Province"}
            disabled={!country_id || disableSubmitButton}
            selectItems={statesList}
            currentlySelected={
              statesList.find((item) => item?.id === state_id) || ""
            }
            updateSelect={(selectedItem) =>
              onChangeForm(selectedItem.id, "state_id")
            }
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"Area / City"}
          </label>
          { renderTownship() } 
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"Postcode"}
          </label>
          <CustomFormInput
            required
            placeholder="Enter Postcode"
            disabled={disableSubmitButton}
            type="text"
            value={postcode}
            onChangeValue={(val) =>
              onChangeForm(val.replace(/[^0-9]/, ""), "postcode")
            }
          />
        </section>
        {/* <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"Land Area"}
          </label>
          <CustomFormInput
            required
            disabled={disableSubmitButton}
            containerClass={"mb-3"}
            type="number"
            helpText="In Square feet"
            value={land_area}
            onChangeValue={(val) => onChangeForm(val, "land_area")}
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"Built Up"}
          </label>
          <CustomFormInput
            required
            disabled={disableSubmitButton}
            containerClass={"mb-3"}
            type="number"
            helpText="In Square feet"
            value={built_up}
            onChangeValue={(val) => onChangeForm(val, "built_up")}
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"Layout"}
          </label>
          <CustomSelect
            required
            disabled={disableSubmitButton}
            className="w-100"
            selectItems={unit_types}
            currentlySelected={
              unit_types.find((item) => item?.value === unit_type_id) || ""
            }
            updateSelect={(selectedItem) =>
              onChangeForm(selectedItem.value, "unit_type_id")
            }
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={"at-form-input__title"} required>
            {"Tenure"}
          </label>
          <AtlasRadioGroup
            required
            disabled={disableSubmitButton}
            valueKey={"id"}
            horizontal={true}
            checkedValue={+tenure_type_id}
            containerClassName={"w-100"}
            selectedRadioValue={(val) => onChangeForm(+val, "tenure_type_id")}
            options={tenureTypeDictionary}
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={`at-form-input__title`} required>
            {"Bedrooms"}
          </label>
          <CustomSelect
            required
            disabled={disableSubmitButton}
            className="w-100"
            selectItems={bedroom_options}
            currentlySelected={bedroom_options.find(
              (item) => item?.value === bedrooms || "",
            )}
            updateSelect={(selectedItem) =>
              onChangeForm(selectedItem.value, "bedrooms")
            }
          />
        </section>
        <section className="at-form_field-col-6">
          <label className={`at-form-input__title`} required>
            {"Bathrooms"}
          </label>
          <CustomSelect
            required
            disabled={disableSubmitButton}
            className="w-100"
            selectItems={bathroom_options}
            currentlySelected={
              bathroom_options.find((item) => item?.value === bathrooms) || ""
            }
            updateSelect={(selectedItem) =>
              onChangeForm(selectedItem.value, "bathrooms")
            }
          />
        </section> */}
      </div>
    </>
  );
};

export default PropertyContent;
