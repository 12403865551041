import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@material-ui/core';
import { BiCalendarAlt } from "react-icons/bi";
import moment from "moment";

const CustomDatepicker = ({
  label, 
  value, 
  format,
  clearable, 
  minDate,
  maxDate,
  minutesStep,
  minTime,
  maxTime,
  onChange,
  disabled,

}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="at-form-input">
        <DatePicker
          label={label || ""}
          value={value ? moment(value) : moment()}
          format={format || "DD/MM/YYYY"}
          clearable={clearable || false}
          minDate={minDate ? moment(minDate) : ""}
          maxDate={maxDate ? moment(maxDate) : ""}
          minutesStep={minutesStep}
          minTime={minTime}
          maxTime={maxTime}
          onChange={(value) => onChange(value)}
          onAccept={(value) => onChange(value)}
          disabled={disabled || false}
          showDaysOutsideCurrentMonth={true}
          views={['year', 'month', 'day']} // Allow year, month, and day views
          className="at-custom-datepicker-cont"
          slotProps={{
            textField: {
              sx: {
                '& .MuiOutlinedInput-root': {
                  padding: 0,
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
                '& .MuiInputBase-input': {
                  padding: 0,
                  border: 'none',
                  boxShadow: 'none',
                  outline: 'none',
                },
              },
            },
          }}      
          renderInput={(params) => (
            <div className="at-form-input__container">
              <TextField
                {...params}
                className="at-custom-datepicker__input"
              />
              <BiCalendarAlt />
            </div>
          )}
        />
      </div>
    </LocalizationProvider>
  );
};

export default CustomDatepicker;