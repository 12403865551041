import React, { Component } from "react";

import IconSprite from "assets/stylesheets/atlasIcon.svg";

const defaultGradient = ({
  svgHref,
  gradientId,
  gradient
}) => {
  if(svgHref === "atlas-pilot") { // safari browser compatibility
    return (
      <defs>
        <linearGradient id="atlas-icon-pilot-ab" x1="13.32" y1="2.662" x2="13.32" y2="15.225" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFCE34"/>
          <stop offset="1" stop-color="#A7820E"/>
        </linearGradient>
        <linearGradient id="atlas-icon-pilot-ac" x1="18.14" y1="5.803" x2="21" y2="19" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFEA64"/>
          <stop offset="1" stop-color="#D2A70D"/>
        </linearGradient>
        <radialGradient id="atlas-icon-pilot-aa" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(23.00002 -13.50002 10.50908 17.90435 5 19.5)">
          <stop offset=".001" stop-color="#FC4622"/>
          <stop offset="1" stop-color="#FF8C00"/>
        </radialGradient>
      </defs>
    )
  }
  return (
    <defs>
      <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%" gradientTransform={`rotate(${gradient.deg})`}>
        {
          gradient.stops.map((stop, index) => (
            <stop key={index} offset={stop.offset} stopColor={stop.stopColor} />
          ))
        }
      </linearGradient>
    </defs>
    
  )
}
class SideBarIcon extends Component {
  render() {
    const { id, className, svgHref, style, onClick, gradient } = this.props;
    const gradientId = Math.random().toString().slice(-6);
    const grandElement = gradient
      ? defaultGradient({ ...this.props, gradientId })
      : (
        <defs>
          <linearGradient id={gradientId}>
            <stop offset="100%" stopColor="#8d8d8d" />
          </linearGradient>
        </defs>
      )

    return (
      <svg id={id} className={className} style={style} onClick={onClick}>
        { grandElement }
        <use
          id={id}
          href={`${IconSprite}#${svgHref}`}
          xlinkHref={`${IconSprite}#${svgHref}`}
          fill={`url(#${gradientId})`}
        />
      </svg>
    );
  }
}

export default SideBarIcon;
