import React from "react";
import { RiGroup2Fill } from "react-icons/ri";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";

const UserInfo = ({
  name,
  team_name,
  email,
  phone_number,
  hideRedirect,
}) => {
  return (
    <div className="d-flex flex-column" style={{ gap: 4 }}>
      {name && <p className={"fw-600"}>{name}</p>}
      {team_name && (
        <div style={{ fontSize: 12 }}>
          <RiGroup2Fill
            style={{ width: 16, height: 16, fill: "#E5712F", marginRight: 4 }}
          />
          {team_name}
        </div>
      )}
      {phone_number && (
        <div className="at-table__text-ellipsis" 
          style={{ fontSize: 12 }}>
          <AtlasIcon
            svgHref={"atlas-call"}
            style={{ width: 16, height: 16, fill: "#E5712F", marginRight: 4 }}
          />
          <span>{`+${phone_number}` || "N/A"}</span>
          {!hideRedirect && (
            <>
              <AtlasIcon
                svgHref={"atlas-whatsapp"}
                className={"btn-new--social-media at-desktop-view__controller"}
                style={{ fill: "#12B76A" }}
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?phone=${phone_number}`,
                  )
                }
              />
              <AtlasIcon
                svgHref={"atlas-call"}
                className={"btn-new--social-media at-desktop-view__controller"}
                style={{ fill: "#3B82F6" }}
                onClick={() => window.open(`tel:${phone_number}`)}
              />
            </>
          )}
        </div>
      )}
      {email && (
        <div className="at-table__text-ellipsis" 
          style={{ fontSize: 12 }}>
          <AtlasIcon
            svgHref={"atlas-sms"}
            style={{ width: 16, height: 16, fill: "#E5712F", marginRight: 4 }}
          />
          <span>{email || "N/A"}</span>
          {!hideRedirect && (
            <AtlasIcon
              svgHref={"atlas-sms-edit"}
              className={"btn-new--social-media at-desktop-view__controller"}
              style={{ fill: "#3B82F6" }}
              onClick={() => (window.location = `mailto:${email}`)}
            />
          )}
        </div>
      )}
      {!hideRedirect && (
        <div className="d-flex at-mobile-view__controller mt-2 g-2" 
          style={{ fontSize: 12 }}>
          {email && (
            <CustomButton
              className={"btn-new btn-new--outline-secondary w-100"}
              containerStyle={{ minWidth: 90, flex: "1 1 0" }}
              onClick={() => window.open(`mailto:${email}`)}
              children={
                <>
                  <AtlasIcon
                    svgHref={"atlas-sms-edit"}
                    style={{
                      width: 16,
                      height: 16,
                      fill: "#3B82F6",
                      marginRight: 4,
                    }}
                  />
                  Email
                </>
              }
            />
          )}
          {phone_number && (
            <>
              <CustomButton
                className={"btn-new btn-new--outline-secondary w-100"}
                containerStyle={{ minWidth: 120, flex: "1 1 0" }}
                onClick={() =>
                  window.open(`https://wa.me/${phone_number}`, "_blank")
                }
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-whatsapp"}
                      style={{
                        width: 16,
                        height: 16,
                        fill: "#12B76A",
                        marginRight: 4,
                      }}
                    />
                    Message
                  </>
                }
              />
              <CustomButton
                className={"btn-new btn-new--outline-secondary w-100"}
                containerStyle={{ minWidth: 90, flex: "1 1 0" }}
                onClick={() => window.open(`tel:${phone_number}`)}
                children={
                  <>
                    <AtlasIcon
                      svgHref={"atlas-call-calling"}
                      style={{
                        width: 16,
                        height: 16,
                        fill: "#3B82F6",
                        marginRight: 4,
                      }}
                    />
                    Call
                  </>
                }
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UserInfo;
